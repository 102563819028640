import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';

import classes from './MyAutocomplete.module.css';

const MyAutocomplete = (props) => {
    // const defaultProps = {
    //     options: props.options,
    //     getOptionLabel: (option) => option,
    // };

    let elementClasses = [classes.FormControl];

    if (props.fullWidth) elementClasses.push(classes.FullWidth);
    else if (props.narrow) elementClasses.push(classes.Narrow);
    else if (props.veryNarrow) elementClasses.push(classes.VeryNarrow);

    if (props.disabled) elementClasses.push(classes.Disabled);
    if (!props.label) elementClasses.push(classes.NoLabel);

    if (props.validated && props.isValid) elementClasses.push(classes.IsValid);
    else if (props.validated && props.isValid === false) elementClasses.push(classes.IsInvalid);

    return (
        <FormControl className={elementClasses.join(' ')} style={props.style}>
            <Autocomplete
                options={props.options}
                getOptionLabel={(option) => option.label}
                value={props.value}
                groupBy={props.groupBy ? (option) => option[props.groupBy] : null}
                disableClearable={props.disableClearable ? true : false}
                onChange={(event, newValue) => {
                    props.onChange(newValue);
                }}
                // {...defaultProps}
                // value={props.value}
                // onChange={(event, newValue) => {
                //     props.onChange(newValue);
                // }}
                // renderInput={(params) => <TextField {...params} label={props.label} />}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.value}>
                            {option.label}
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </FormControl>
    );
};

export default MyAutocomplete;
