import classes from './Badge.module.css';

const Badge = (props) => {
    let elClasses = [classes.Badge];
    if (props.color === 'success') elClasses.push(classes.Success);
    else if (props.color === 'danger') elClasses.push(classes.Danger);
    else if (props.color === 'warning') elClasses.push(classes.Warning);
    else if (props.color === 'info') elClasses.push(classes.Info);
    else if (props.color === 'white') elClasses.push(classes.White);

    if (props.size === 'small') elClasses.push(classes.Small);
    if (props.size === 'large') elClasses.push(classes.Large);

    if (props.type === 'circle') elClasses.push(classes.Circle);

    return (
        <div className={elClasses.join(' ')} onClick={props.onClick ? props.onClick : null}>
            {props.icon}
            <div className={props.label ? null : classes.Hide}>{props.label}</div>
        </div>
    );
};

export default Badge;
