import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';

import ScenarioChartsEnergy from './ScenarioChartsEnergy';
import ScenarioChartsGeneral from './ScenarioChartsGeneral';
import ScenarioChartsEmissions from './ScenarioChartsEmissions';
import Loader from '../../../features/UI/Loader/Loader2';
import classes from './AllCharts.module.css';
import { getScenarioFilteredMetrics } from '../MyProjectsAsyncActions';
import { myProjectsActions } from '../myProjectsSlice';

const ScenarioStats = () => {
    const dispatch = useDispatch();

    const [buildingValues, setBuildingValues] = useState(null);
    const [showTab, setShowTab] = useState('general');
    const [hasResults, setHasResults] = useState(true);

    const scenariosMetrics = useSelector((state) => state.myProjects.scenariosMetrics);
    const scenarioFilteredMetrics = useSelector((state) => state.myProjects.scenarioFilteredMetrics);
    const selectedScenario = useSelector((state) => state.myProjects.selectedScenario);
    const filterData = useSelector((state) => state.myProjects.filterData);

    const prepareBuildingValues = (data) => {
        if (!data) return;
        if (!data.per_month) return;

        // No results
        if (data.buildings_num === 0) {
            setHasResults(false);
        } else {
            setHasResults(true);
        }

        const demanded_monthly_data = [
            { name: 'Heating', data: data.per_month['Demanded Heating'], color: '#ab0d0d' },
            { name: 'Cooling', data: data.per_month['Demanded Cooling'], color: '#4671c7' },
            { name: 'Lighting', data: data.per_month['Demanded Interior Lighting'], color: '#ffdc40' },
            { name: 'Equipment', data: data.per_month['Demanded Interior Equipment'], color: '#4ba03e' },
        ];
        const primary_monthly_data = [
            { name: 'Heating', data: data.per_month['Primary Energy Heating'], color: '#ab0d0d' },
            { name: 'Cooling', data: data.per_month['Primary Energy Cooling'], color: '#4671c7' },
            { name: 'Lighting', data: data.per_month['Primary Interior Lighting'], color: '#ffdc40' },
            { name: 'Equipment', data: data.per_month['Primary Interior Equipment'], color: '#4ba03e' },
        ];

        const consumed_monthly_data = [
            { name: 'Heating', data: data.per_month['Consumed Energy Heating'], color: '#ab0d0d' },
            { name: 'Cooling', data: data.per_month['Consumed Energy Cooling'], color: '#4671c7' },
            { name: 'Lighting', data: data.per_month['Consumed Interior Lighting'], color: '#ffdc40' },
            { name: 'Equipment', data: data.per_month['Consumed Interior Equipment'], color: '#4ba03e' },
        ];

        const co2_monthly_data = [
            { name: 'Heating', data: data.per_month['CO2 Heating'], color: '#ab0d0d' },
            { name: 'Cooling', data: data.per_month['CO2 Cooling'], color: '#4671c7' },
            { name: 'Lighting', data: data.per_month['CO2 Interior Lighting'], color: '#ffdc40' },
            { name: 'Equipment', data: data.per_month['CO2 Interior Equipment'], color: '#4ba03e' },
        ];

        const values = {
            end_uses_demanded_heating: data.total_demanded_heating_per_m2,
            end_uses_demanded_cooling: data.total_demanded_cooling_per_m2,
            end_uses_demanded_interior_lighting: data.total_demanded_interior_lighting_per_m2,
            end_uses_demanded_interior_equipment: data.total_demanded_interior_equipment_per_m2,
            end_uses_consumed_heating: data.total_consumed_heating_per_m2,
            end_uses_consumed_cooling: data.total_consumed_cooling_per_m2,
            end_uses_consumed_interior_lighting: data.total_consumed_interior_lighting_per_m2,
            end_uses_consumed_interior_equipment: data.total_consumed_interior_equipment_per_m2,
            end_uses_primary_heating: data.total_primary_heating_per_m2,
            end_uses_primary_cooling: data.total_primary_cooling_per_m2,
            end_uses_primary_interior_lighting: data.total_primary_interior_lighting_per_m2,
            end_uses_primary_interior_equipment: data.total_primary_interior_equipment_per_m2,
            end_uses_co2_heating: data.total_co2_heating_per_m2,
            end_uses_co2_cooling: data.total_co2_cooling_per_m2,
            end_uses_co2_interior_lighting: data.total_co2_interior_lighting_per_m2,
            end_uses_co2_interior_equipment: data.total_co2_interior_equipment_per_m2,
            utility_use_per_conditioned_floor_area_hvac_heating: data.total_utility_use_per_conditioned_floor_area_hvac_heating_per_m2,
            utility_use_per_conditioned_floor_area_hvac_cooling: data.total_utility_use_per_conditioned_floor_area_hvac_cooling_per_m2,
            utility_use_per_conditioned_floor_area_lighting: data.total_utility_use_per_conditioned_floor_area_lighting_per_m2,
            utility_use_per_conditioned_floor_area_other: data.total_utility_use_per_conditioned_floor_area_other_per_m2,
            demanded_monthly_data: demanded_monthly_data,
            primary_monthly_data: primary_monthly_data,
            consumed_monthly_data: consumed_monthly_data,
            co2_monthly_data: co2_monthly_data,
            per_usage: data.per_usage,
            co2_monthly_per_usage: data.co2_monthly_per_usage,
            total_building_area: data.total_building_area,
            total_unconditioned_area: data.total_unconditioned_area,
            usage_areas: data.usage_areas,
        };

        setBuildingValues(values);
    };

    useEffect(() => {
        if (filterData.length) {
            dispatch(getScenarioFilteredMetrics(selectedScenario.id, filterData));
        } else {
            if (!scenariosMetrics) return;
            prepareBuildingValues(scenariosMetrics[selectedScenario.id]);
        }
    }, [selectedScenario, filterData, scenariosMetrics]);

    useEffect(() => {
        if (!scenarioFilteredMetrics) return;

        prepareBuildingValues(scenarioFilteredMetrics);
    }, [scenarioFilteredMetrics]);

    return (
        <div className={classes.AllCharts}>
            <div className={classes.Tabs}>
                <div className={showTab === 'general' ? [classes.Tab, classes.Active].join(' ') : classes.Tab} onClick={() => setShowTab('general')}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <circle cx='12' cy='12' r='10'></circle>
                        <line x1='12' y1='16' x2='12' y2='12'></line>
                        <line x1='12' y1='8' x2='12.01' y2='8'></line>
                    </svg>
                    <span>General</span>
                </div>

                <div className={showTab === 'energy' ? [classes.Tab, classes.Active].join(' ') : classes.Tab} onClick={() => setShowTab('energy')}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <polygon points='13 2 3 14 12 14 11 22 21 10 12 10 13 2'></polygon>
                    </svg>
                    <span>Energy</span>
                </div>

                <div className={showTab === 'emissions' ? [classes.Tab, classes.Active].join(' ') : classes.Tab} onClick={() => setShowTab('emissions')}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <path d='M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z'></path>
                    </svg>
                    <span>Emissions</span>
                </div>
            </div>

            <div className={classes.TabContent}>
                {buildingValues ? (
                    hasResults ? (
                        <Scrollbars
                            style={{ width: '100%', height: '100%' }}
                            renderThumbVertical={(props) => <div {...props} className='thumb-vertical' />}
                            renderThumbHorizontal={(props) => <div {...props} className='thumb-horizontal' />}
                        >
                            {showTab === 'general' && <ScenarioChartsGeneral buildingValues={buildingValues} />}
                            {showTab === 'energy' && <ScenarioChartsEnergy buildingValues={buildingValues} />}
                            {showTab === 'emissions' && <ScenarioChartsEmissions buildingValues={buildingValues} />}
                        </Scrollbars>
                    ) : (
                        <div className={classes.NoResults}>No results where found. Try to change the filters.</div>
                    )
                ) : (
                    <div className={classes.LoaderContainer}>
                        <Loader show={true} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ScenarioStats;
