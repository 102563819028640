import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classes from './CheckoutForm.module.css';
import { checkout, getSystemData } from '../SystemData/systemDataAsyncActions';
import { systemDataActions } from '../SystemData/systemDataSlice';
import { layoutActions } from '../../features/Layout/layoutSlice';
import MainButton from '../../features/UI/MainButton/MainButton';
import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import Widget from '../../features/Widget/Widget';

const CheckoutForm = (props) => {
    const dispatch = useDispatch();

    const systemData = useSelector((state) => state.systemData.systemData);
    const checkoutLoading = useSelector((state) => state.systemData.checkoutLoading);

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('Purchase'));
        dispatch(getSystemData());

        return () => {
            dispatch(systemDataActions.setSystemData([]));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch]);

    const onSubmit = (e) => {
        e.preventDefault();

        dispatch(checkout(props.productId));
    };

    const product = useMemo(() => {
        const found = systemData.find((p) => p.id == props.productId);
        if (found) return found;
        return null;
    }, [systemData, props.productId]);

    return (
        <div className={classes.CheckoutForm}>
            <Breadcrumb link1='System data' link2='/ Purchase' />

            <div className={classes.ContentInner}>
                <Widget>
                    <div className={classes.TitleRow}>
                        <h2>Purchase</h2>
                    </div>
                    {product && (
                        <div className={classes.WidgetContent}>
                            <div className={classes.Product}>
                                <div className={classes.ProductImage}>
                                    <img src={require(`../../assets/images/${product.image}`)} alt='Product' />
                                </div>

                                <div className={classes.ProductDescription}>
                                    <span>Product</span>
                                    <span>Price</span>

                                    <span>{product.name}</span>
                                    <span>€ {product.price.toLocaleString('en-US', { minimumFractionDigits: 2 })}</span>
                                </div>
                            </div>

                            <div className={classes.Totals}>
                                <h3>Total</h3>
                                <span>€ {product.price.toLocaleString('en-US', { minimumFractionDigits: 2 })}</span>
                            </div>

                            <form className={classes.SubmitForm} onSubmit={onSubmit} method='POST'>
                                <MainButton label='Checkout' color='action' loading={checkoutLoading} />
                            </form>
                        </div>
                    )}
                </Widget>
            </div>
        </div>
    );
};

export default CheckoutForm;
