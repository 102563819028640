import React, { useState, useEffect } from 'react';

import ColumnChart from '../../../features/Charts/ColumnChart';
import BarChart from '../../../features/Charts/BarChart';
import LineChart from '../../../features/Charts/LineChart';
import classes from './AllCharts.module.css';

const AllChartsEnergy = (props) => {
    const [buildingValues, setBuildingValues] = useState(null);

    useEffect(() => {
        const selectedFeature = props.selectedFeature.feature;
        if (!selectedFeature) return;

        const data = JSON.parse(selectedFeature.getProperty('building_data'));
        if (!data) return;

        // Building metrics
        const building_metric = data.building_metrics.building_metric;

        const end_uses_demanded_cooling = building_metric?.end_uses_demanded_cooling;
        const end_uses_demanded_heating = building_metric?.end_uses_demanded_heating;
        const end_uses_demanded_interior_equipment = building_metric?.end_uses_demanded_interior_equipment;
        const end_uses_demanded_interior_lighting = building_metric?.end_uses_demanded_interior_lighting;

        const end_uses_consumed_cooling = building_metric?.end_uses_consumed_cooling;
        const end_uses_consumed_heating = building_metric?.end_uses_consumed_heating;
        const end_uses_consumed_interior_equipment = building_metric?.end_uses_consumed_interior_equipment;
        const end_uses_consumed_interior_lighting = building_metric?.end_uses_consumed_interior_lighting;

        const end_uses_primary_cooling = building_metric?.end_uses_primary_cooling;
        const end_uses_primary_heating = building_metric?.end_uses_primary_heating;
        const end_uses_primary_interior_equipment = building_metric?.end_uses_primary_interior_equipment;
        const end_uses_primary_interior_lighting = building_metric?.end_uses_primary_interior_lighting;

        const utility_use_per_conditioned_floor_area_hvac_cooling = building_metric?.utility_use_per_conditioned_floor_area_hvac_cooling;
        const utility_use_per_conditioned_floor_area_hvac_heating = building_metric?.utility_use_per_conditioned_floor_area_hvac_heating;
        const utility_use_per_conditioned_floor_area_other = building_metric?.utility_use_per_conditioned_floor_area_other;
        const utility_use_per_conditioned_floor_area_lighting = building_metric?.utility_use_per_conditioned_floor_area_lighting;

        // Monthly data
        const building_metric_monthly = data.building_metrics.building_monthly_metric;

        const found_demanded_heating_monthly = building_metric_monthly.find((x) => x.metric_type_id === 1);
        const found_demanded_cooling_monthly = building_metric_monthly.find((x) => x.metric_type_id === 2);
        const found_demanded_equipment_monthly = building_metric_monthly.find((x) => x.metric_type_id === 7);
        const found_demanded_lighting_monthly = building_metric_monthly.find((x) => x.metric_type_id === 8);
        const found_consumed_heating_monthly = building_metric_monthly.find((x) => x.metric_type_id === 3);
        const found_consumed_cooling_monthly = building_metric_monthly.find((x) => x.metric_type_id === 4);
        const found_consumed_equipment_monthly = building_metric_monthly.find((x) => x.metric_type_id === 9);
        const found_consumed_lighting_monthly = building_metric_monthly.find((x) => x.metric_type_id === 10);
        const found_primary_heating_monthly = building_metric_monthly.find((x) => x.metric_type_id === 5);
        const found_primary_cooling_monthly = building_metric_monthly.find((x) => x.metric_type_id === 6);
        const found_primary_equipment_monthly = building_metric_monthly.find((x) => x.metric_type_id === 11);
        const found_primary_lighting_monthly = building_metric_monthly.find((x) => x.metric_type_id === 12);

        const demanded_heating_monthly = found_demanded_heating_monthly ? found_demanded_heating_monthly.building_monthly_metric.map((e) => e.value) : null;
        const demanded_cooling_monthly = found_demanded_cooling_monthly ? found_demanded_cooling_monthly.building_monthly_metric.map((e) => e.value) : null;
        const demanded_equipment_monthly = found_demanded_equipment_monthly
            ? found_demanded_equipment_monthly.building_monthly_metric.map((e) => e.value)
            : null;
        const demanded_lighting_monthly = found_demanded_lighting_monthly ? found_demanded_lighting_monthly.building_monthly_metric.map((e) => e.value) : null;
        const consumed_heating_monthly = found_consumed_heating_monthly ? found_consumed_heating_monthly.building_monthly_metric.map((e) => e.value) : null;
        const consumed_cooling_monthly = found_consumed_cooling_monthly ? found_consumed_cooling_monthly.building_monthly_metric.map((e) => e.value) : null;
        const consumed_equipment_monthly = found_consumed_equipment_monthly
            ? found_consumed_equipment_monthly.building_monthly_metric.map((e) => e.value)
            : null;
        const consumed_lighting_monthly = found_consumed_lighting_monthly ? found_consumed_lighting_monthly.building_monthly_metric.map((e) => e.value) : null;
        const primary_heating_monthly = found_primary_heating_monthly ? found_primary_heating_monthly.building_monthly_metric.map((e) => e.value) : null;
        const primary_cooling_monthly = found_primary_cooling_monthly ? found_primary_cooling_monthly.building_monthly_metric.map((e) => e.value) : null;
        const primary_equipment_monthly = found_primary_equipment_monthly ? found_primary_equipment_monthly.building_monthly_metric.map((e) => e.value) : null;
        const primary_lighting_monthly = found_primary_lighting_monthly ? found_primary_lighting_monthly.building_monthly_metric.map((e) => e.value) : null;

        const demanded_monthly_data = [
            { name: 'Heating', data: demanded_heating_monthly, color: '#ab0d0d' },
            { name: 'Cooling', data: demanded_cooling_monthly, color: '#4671c7' },
            { name: 'Lighting', data: demanded_lighting_monthly, color: '#ffdc40' },
            { name: 'Equipment', data: demanded_equipment_monthly, color: '#4ba03e' },
        ];
        const primary_monthly_data = [
            { name: 'Heating', data: primary_heating_monthly, color: '#ab0d0d' },
            { name: 'Cooling', data: primary_cooling_monthly, color: '#4671c7' },
            { name: 'Lighting', data: primary_lighting_monthly, color: '#ffdc40' },
            { name: 'Equipment', data: primary_equipment_monthly, color: '#4ba03e' },
        ];

        const consumed_monthly_data = [
            { name: 'Heating', data: consumed_heating_monthly, color: '#ab0d0d' },
            { name: 'Cooling', data: consumed_cooling_monthly, color: '#4671c7' },
            { name: 'Lighting', data: consumed_lighting_monthly, color: '#ffdc40' },
            { name: 'Equipment', data: consumed_equipment_monthly, color: '#4ba03e' },
        ];

        // Usage metrics
        const usage_metrics = data?.building_usage_metrics;

        // Energy balances
        let energy_balances_data = [];

        for (let i = 0; i < usage_metrics.length; i++) {
            const usage_metric = usage_metrics[i].building_usage_metric;
            const usage_name = usage_metric.usage_name;

            // Energy balances
            const gain_system = usage_metric.system_sensible_hvac_zone_eq_and_other_sensible_air_heating;
            const gain_conduction = usage_metric.heat_gain_opaque_surface_conduction_and_other_heat_addition;
            const gain_inflitration = usage_metric.heat_gain_infiltration_heat_addition;
            const gain_windows = usage_metric.heat_gain_window_heat_addition;
            const gain_equipment = usage_metric.heat_gain_equipment_sensible_heat_addition;
            const gain_lights = usage_metric.heat_gain_lights_sensible_heat_addition;
            const gain_people = usage_metric.heat_gain_people_sensible_heat_addition;

            const loss_system = usage_metric.system_sensible_hvac_zone_eq_and_other_sensible_air_cooling;
            const loss_conduction = usage_metric.heat_loss_opaque_surface_conduction_and_other_heat_removal;
            const loss_infiltration = usage_metric.heat_loss_infiltration_heat_removal;
            const loss_windows = usage_metric.heat_loss_window_heat_removal;
            const loss_equipment = 0;
            const loss_lights = 0;
            const loss_people = 0;

            energy_balances_data.push({
                name: usage_name,
                data: [
                    {
                        name: 'Gains',
                        data: [gain_system, gain_conduction, gain_inflitration, gain_windows, gain_equipment, gain_lights, gain_people],
                    },
                    {
                        name: 'Losses',
                        data: [loss_system, loss_conduction, loss_infiltration, loss_windows, loss_equipment, loss_lights, loss_people],
                    },
                ],
            });
        }

        const values = {
            end_uses_demanded_cooling,
            end_uses_demanded_heating,
            end_uses_demanded_interior_equipment,
            end_uses_demanded_interior_lighting,
            end_uses_consumed_cooling,
            end_uses_consumed_heating,
            end_uses_consumed_interior_equipment,
            end_uses_consumed_interior_lighting,
            end_uses_primary_cooling,
            end_uses_primary_heating,
            end_uses_primary_interior_equipment,
            end_uses_primary_interior_lighting,
            utility_use_per_conditioned_floor_area_hvac_cooling,
            utility_use_per_conditioned_floor_area_hvac_heating,
            utility_use_per_conditioned_floor_area_other,
            utility_use_per_conditioned_floor_area_lighting,
            energy_balances_data,
            demanded_monthly_data,
            consumed_monthly_data,
            primary_monthly_data,
        };

        setBuildingValues(values);
    }, [props?.selectedFeature?.feature]);

    return buildingValues ? (
        <div className={classes.Charts}>
            <div className={classes.Chart}>
                <h3>Annual energy consumption analysis</h3>
                <ColumnChart
                    title={null}
                    alignLegend='center'
                    categories={['Heating', 'Cooling', 'Interior lighting', 'Interior equipment']}
                    yAxisTitle='kWh'
                    series={[
                        {
                            name: 'Energy demand',
                            data: [
                                buildingValues.end_uses_demanded_heating,
                                buildingValues.end_uses_demanded_cooling,
                                buildingValues.end_uses_demanded_interior_lighting,
                                buildingValues.end_uses_demanded_interior_equipment,
                            ],
                        },
                        {
                            name: 'Final energy',
                            data: [
                                buildingValues.end_uses_consumed_heating,
                                buildingValues.end_uses_consumed_cooling,
                                buildingValues.end_uses_consumed_interior_lighting,
                                buildingValues.end_uses_consumed_interior_equipment,
                            ],
                        },
                        {
                            name: 'Primary energy',
                            data: [
                                buildingValues.end_uses_primary_heating,
                                buildingValues.end_uses_primary_cooling,
                                buildingValues.end_uses_primary_interior_lighting,
                                buildingValues.end_uses_primary_interior_equipment,
                            ],
                        },
                    ]}
                />
            </div>

            <div className={classes.Chart}>
                <h3>Energy demand breakdown</h3>

                <ColumnChart
                    title={null}
                    alignLegend='center'
                    categories={['Heating', 'Cooling', 'Interior lighting', 'Other']}
                    yAxisTitle='kWh per sq. m.'
                    series={[
                        {
                            name: 'Energy demand',
                            data: [
                                buildingValues.utility_use_per_conditioned_floor_area_hvac_heating,
                                buildingValues.utility_use_per_conditioned_floor_area_hvac_cooling,
                                buildingValues.utility_use_per_conditioned_floor_area_lighting,
                                buildingValues.utility_use_per_conditioned_floor_area_other,
                            ],
                        },
                    ]}
                />
            </div>

            <div className={[classes.Chart, classes.TwoColumns].join(' ')}>
                <h3>Monthly energy demand breakdown</h3>

                <LineChart
                    title={null}
                    alignLegend='center'
                    categories={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                    series={buildingValues.demanded_monthly_data}
                    yAxisTitle='kWh'
                />
            </div>
            <div className={[classes.Chart, classes.TwoColumns].join(' ')}>
                <h3>Monthly final energy consumption</h3>

                <LineChart
                    title={null}
                    alignLegend='center'
                    categories={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                    series={buildingValues.consumed_monthly_data}
                    yAxisTitle='kWh'
                />
            </div>
            <div className={[classes.Chart, classes.TwoColumns].join(' ')}>
                <h3>Monthly primary energy</h3>

                <LineChart
                    title={null}
                    alignLegend='center'
                    categories={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                    series={buildingValues.primary_monthly_data}
                    yAxisTitle='kWh'
                />
            </div>

            {buildingValues.energy_balances_data.map((energy_balance_usage, index) => {
                return (
                    <div key={`energy_balance_${index}`} className={[classes.Chart, classes.TwoColumns].join(' ')}>
                        <h3>Building Energy Balance per usage - {energy_balance_usage.name}</h3>

                        <BarChart
                            title={null}
                            alignLegend='center'
                            isStacked
                            categories={['System HVAC zone', 'Surface conduction', 'Infiltration', 'Windows', 'Equipment', 'Lights', 'People']}
                            series={energy_balance_usage.data}
                            yAxisTitle='kWh'
                        />
                    </div>
                );
            })}
        </div>
    ) : null;
};

export default AllChartsEnergy;
