import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import L from 'leaflet';
import { MapContainer, TileLayer, FeatureGroup, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { latLngBounds } from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

import Badge from '../../features/UI/Badge/Badge';
import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import Widget from '../../features/Widget/Widget';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { myDataActions } from './myDataSlice';
import { getSelectedUserData } from './MyDataAsyncActions';
import classes from './MyDataView.module.css';
import { fNumMaxDec } from '../../utils/formats';

const MyDataView = () => {
    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const tileLayer = useSelector((state) => state.system.tileLayer);
    const tileLayerAttribution = useSelector((state) => state.system.tileLayerAttribution);
    const selectedUserData = useSelector((state) => state.myData.selectedUserData);

    const [bounds, setBounds] = useState(null);

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('My data view'));
        dispatch(getSelectedUserData(id, navigate));
        L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

        return () => {
            dispatch(myDataActions.setSelectedUserData(null));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch, id]);

    useEffect(() => {
        if (selectedUserData && selectedUserData.envelope) {
            const coords = selectedUserData.envelope.coordinates[0];
            let changeLatWithLon = [];
            changeLatWithLon = [
                [coords[0][1], coords[0][0]],
                [coords[1][1], coords[1][0]],
                [coords[2][1], coords[2][0]],
                [coords[3][1], coords[3][0]],
                [coords[4][1], coords[4][0]],
            ];

            let bounds = latLngBounds();
            bounds.extend(changeLatWithLon);

            setBounds(bounds);
        }
    }, [dispatch, selectedUserData]);

    return (
        <div className={classes.MyDataView}>
            <Breadcrumb link1='User' link2='/ My Data' link3='/ View My Data' />

            <div className={classes.ContentInner}>
                <div className={classes.Widgets}>
                    <div className={classes.Col13}>
                        <Widget>
                            <div className={classes.TitleRow}>
                                <h2>General Info</h2>
                            </div>

                            <div className={classes.WidgetContent}>
                                <div className={classes.InfoRow}>
                                    <span>Dataset name:</span>
                                    <span>{selectedUserData?.name}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Description:</span>
                                    <span>{selectedUserData?.description}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Date created:</span>
                                    <span>{selectedUserData?.date_created}</span>
                                </div>
                            </div>
                        </Widget>
                    </div>

                    <div className={classes.Col34}>
                        <Widget>
                            <div className={classes.TitleRowWithSubtitle}>
                                <h2>Projects</h2>
                                <span>The data is used in the following projects</span>
                            </div>

                            <div className={classes.WidgetContent}>
                                {selectedUserData?.projects.length === 0 ? (
                                    <div className={classes.NoData}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        >
                                            <circle cx='12' cy='12' r='10'></circle>
                                            <line x1='4.93' y1='4.93' x2='19.07' y2='19.07'></line>
                                        </svg>

                                        <span>The dataset is not used in any projects</span>
                                    </div>
                                ) : (
                                    <div className={classes.Projects}>
                                        {selectedUserData?.projects.map((project, index) => {
                                            return (
                                                <div key={index} className={classes.InfoRow}>
                                                    <span>{project.name}</span>
                                                    <div
                                                        className={classes.BadgeWrapper}
                                                        onClick={() => navigate(`/myprojects-view/${project.id}`)}
                                                        data-tip='View data'
                                                    >
                                                        <Badge
                                                            color='success'
                                                            size='small'
                                                            icon={
                                                                <svg
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    fill='none'
                                                                    stroke='currentColor'
                                                                    strokeWidth='2'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                >
                                                                    <circle cx='11' cy='11' r='8'></circle>
                                                                    <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                                                                </svg>
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </Widget>
                    </div>

                    <div className={classes.Col12}>
                        <Widget>
                            <div className={classes.TitleRow}>
                                <h2>Location</h2>
                            </div>

                            <div className={classes.WidgetContent}>
                                <div className={classes.InfoRow}>
                                    <span>Country</span>
                                    <span>{selectedUserData?.country}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>City</span>
                                    <span>{selectedUserData?.city}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Min latitude</span>
                                    <span>{fNumMaxDec(selectedUserData?.envelope?.coordinates[0][0][1], 5)}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Max latitude</span>
                                    <span>{fNumMaxDec(selectedUserData?.envelope?.coordinates[0][2][1], 5)}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Min longitude</span>
                                    <span>{fNumMaxDec(selectedUserData?.envelope?.coordinates[0][0][0], 5)}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Max longitude</span>
                                    <span>{fNumMaxDec(selectedUserData?.envelope?.coordinates[0][2][0], 5)}</span>
                                </div>
                            </div>
                        </Widget>
                    </div>

                    <div className={classes.Col24}>
                        <Widget noPadding>
                            <div className={classes.Location}>
                                {bounds ? (
                                    <MapContainer
                                        // center={[51.505, -0.09]}
                                        zoom={13}
                                        maxZoom={17}
                                        boundsOptions={{ padding: [50, 50] }}
                                        bounds={bounds}
                                        gestureHandling={true}
                                        gestureHandlingOptions={{ duration: 5000 }}
                                        // scrollWheelZoom={false}
                                    >
                                        {/* <TileLayer attribution={''} url={'http://{s}.tile.osm.org/{z}/{x}/{y}.png'} /> */}
                                        <TileLayer attribution={tileLayerAttribution} url={tileLayer} />

                                        <FeatureGroup>
                                            <GeoJSON data={selectedUserData?.envelope} style={{ color: 'red' }} />
                                        </FeatureGroup>
                                    </MapContainer>
                                ) : null}
                            </div>
                        </Widget>
                    </div>

                    <div className={classes.Col14}>
                        <Widget>
                            <div className={classes.TitleRow}>
                                <h2>Data Analysis</h2>
                            </div>

                            <div className={classes.WidgetContent}>
                                {/* Number of buildings */}
                                <div className={classes.InfoRow2}>
                                    <span>Number of buildings</span>
                                    <span>{selectedUserData?.buildings_num}</span>
                                </div>

                                {/* <div className={classes.InfoRow2}>
                                    <div className={classes.FirstCol}>
                                        <span>Buildings with no valid geometry</span>
                                        {selectedUserData && selectedUserData.buildings_num - selectedUserData.no_issues > 0 && (
                                            <span className={classes.Danger}>These buildings will not be included in the analysis</span>
                                        )}
                                    </div>
                                    <span>{selectedUserData ? selectedUserData.buildings_num - selectedUserData.no_issues : ''}</span>

                                    <div className={classes.AnalysisIcon}>
                                        {selectedUserData && selectedUserData.buildings_num - selectedUserData.no_issues === 0 ? (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Success}
                                            >
                                                <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path>
                                                <polyline points='22 4 12 14.01 9 11.01'></polyline>
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Danger}
                                            >
                                                <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
                                                <line x1='15' y1='9' x2='9' y2='15'></line>
                                                <line x1='9' y1='9' x2='15' y2='15'></line>
                                            </svg>
                                        )}
                                    </div>
                                </div> */}

                                {/* Height less than 3m */}
                                <div className={classes.InfoRow2}>
                                    <div className={classes.FirstCol}>
                                        <span>Buildings with height less than 3m</span>
                                        {selectedUserData?.small_height > 0 && (
                                            <span className={classes.Warning}>Caution: There are buildings with low height</span>
                                        )}
                                    </div>
                                    <span>{selectedUserData?.small_height}</span>

                                    <div className={classes.AnalysisIcon}>
                                        {selectedUserData?.small_height === 0 ? (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Success}
                                            >
                                                <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path>
                                                <polyline points='22 4 12 14.01 9 11.01'></polyline>
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Warning}
                                            >
                                                <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
                                                <line x1='12' y1='9' x2='12' y2='13'></line>
                                                <line x1='12' y1='17' x2='12.01' y2='17'></line>
                                            </svg>
                                        )}
                                    </div>
                                </div>

                                {/* No height */}
                                <div className={classes.InfoRow2}>
                                    <div className={classes.FirstCol}>
                                        <span>Buildings with no height</span>
                                        {selectedUserData?.no_height > 0 && (
                                            <span className={classes.Danger}>These buildings will not be included in the analysis</span>
                                        )}
                                    </div>
                                    <span>{selectedUserData?.no_height}</span>

                                    <div className={classes.AnalysisIcon}>
                                        {selectedUserData?.no_height === 0 ? (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Success}
                                            >
                                                <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path>
                                                <polyline points='22 4 12 14.01 9 11.01'></polyline>
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Danger}
                                            >
                                                <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
                                                <line x1='15' y1='9' x2='9' y2='15'></line>
                                                <line x1='9' y1='9' x2='15' y2='15'></line>
                                            </svg>
                                        )}
                                    </div>
                                </div>

                                {/* No use */}
                                <div className={classes.InfoRow2}>
                                    <div className={classes.FirstCol}>
                                        <span>Buildings with no use</span>
                                        {selectedUserData?.no_use > 0 && (
                                            <span className={classes.Danger}>These buildings will not be included in the analysis</span>
                                        )}
                                    </div>
                                    <span>{selectedUserData?.no_use}</span>

                                    <div className={classes.AnalysisIcon}>
                                        {selectedUserData?.no_use === 0 ? (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Success}
                                            >
                                                <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path>
                                                <polyline points='22 4 12 14.01 9 11.01'></polyline>
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Danger}
                                            >
                                                <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
                                                <line x1='15' y1='9' x2='9' y2='15'></line>
                                                <line x1='9' y1='9' x2='15' y2='15'></line>
                                            </svg>
                                        )}
                                    </div>
                                </div>

                                {/* No construction year */}
                                <div className={classes.InfoRow2}>
                                    <div className={classes.FirstCol}>
                                        <span>Buildings with no construction year</span>
                                        {selectedUserData?.no_year > 0 && (
                                            <span className={classes.Danger}>These buildings will not be included in the analysis</span>
                                        )}
                                    </div>
                                    <span>{selectedUserData?.no_year}</span>

                                    <div className={classes.AnalysisIcon}>
                                        {selectedUserData?.no_year === 0 ? (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Success}
                                            >
                                                <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path>
                                                <polyline points='22 4 12 14.01 9 11.01'></polyline>
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Danger}
                                            >
                                                <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
                                                <line x1='15' y1='9' x2='9' y2='15'></line>
                                                <line x1='9' y1='9' x2='15' y2='15'></line>
                                            </svg>
                                        )}
                                    </div>
                                </div>

                                {/* Results */}
                                <div className={classes.ResultRow}>
                                    <div onClick={() => navigate('/mydata-edit/' + id)}>
                                        <Badge
                                            color='warning'
                                            icon={
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='24'
                                                    height='24'
                                                    viewBox='0 0 24 24'
                                                    fill='none'
                                                    stroke='currentColor'
                                                    strokeWidth='2'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                >
                                                    <path d='M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z'></path>
                                                </svg>
                                            }
                                        />
                                    </div>

                                    <span>Buildings passed validation</span>
                                    <span>{selectedUserData ? selectedUserData.no_issues : ''}</span>
                                </div>
                            </div>

                            {/* <div className={classes.ButtonWrapper}>
                                <MyButton secondary label='Edit buildings' onClick={() => navigate('/mydata-edit/' + id)} />
                            </div> */}
                        </Widget>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyDataView;
