import axiosApi from '../../axios-api';
import axiosRoot from 'axios';

import { systemActions } from '../../features/systemSlice';
import { myScenariosActions } from './myScenariosSlice';
import { showError } from '../../utils/messageHandler';

export const getMyScenarios = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .get('getUserScenarios.php')
            .then((response) => {
                dispatch(myScenariosActions.setMyScenarios(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getSelectedUserScenario = (scenarioId, navigate) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('getSelectedUserScenario.php', { scenarioId: scenarioId })
            .then((response) => {
                dispatch(myScenariosActions.setSelectedScenario(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
                navigate('/myscenarios');
            });
    };
};

export const getScenarioComparison = (scenarioIdA, scenarioIdB) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        const requestOne = axiosApi.post('getSelectedUserScenario.php', { scenarioId: scenarioIdA });
        const requestTwo = axiosApi.post('getSelectedUserScenario.php', { scenarioId: scenarioIdB });
        const requestThree = axiosApi.post('getBuildingMapValues.php', { scenarioIdA: scenarioIdA, scenarioIdB: scenarioIdB });

        axiosRoot
            .all([requestOne, requestTwo, requestThree])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];
                    const responseThree = responses[2];

                    if (responseOne.status !== 200 || responseTwo.status !== 200 || responseThree.status !== 200) {
                        showError(null);
                    } else {
                        const compareData = {
                            scenarioA: responseOne.data,
                            scenarioB: responseTwo.data,
                        };

                        dispatch(myScenariosActions.setCompareData(compareData));
                        dispatch(myScenariosActions.setBuildingMapValues(responseThree.data));
                    }

                    dispatch(systemActions.setLoading(false));
                })
            )
            .catch((errors) => {
                showError(errors);
                dispatch(systemActions.setLoading(false));
            });
    };
};

// export const getBuildingMapValues = (scenarioAId, scenarioBId, tileGids) => {
//     return (dispatch) => {
//         dispatch(systemActions.setLoading(true));

//         axiosApi
//             .post('getBuildingMapValues.php', { scenarioAId: scenarioAId, scenarioBId: scenarioBId, tileGids: tileGids })
//             .then((response) => {
//                 console.log(response.data);
//                 // dispatch(myScenariosActions.setBuildingMapValues(response.data));
//                 dispatch(systemActions.setLoading(false));
//             })
//             .catch((error) => {
//                 showError(error);
//                 dispatch(systemActions.setLoading(false));
//             });
//     };
// };
