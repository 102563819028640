import { useNavigate } from 'react-router-dom';

import error_icon from '../../../assets/images/error.svg';
import MainButton from '../../../features/UI/MainButton/MainButton';
import classes from './Page404.module.css';

const Page404 = () => {
    const navigate = useNavigate();

    return (
        <div className={classes.Page404}>
            <div className={classes.Page404Inner}>
                <p className={classes.Title}>Ooops!</p>
                <p className={classes.Subtitle}>The page you requested was not found!</p>
                <img src={error_icon} alt='error icon' />
                <div className={classes.ButtonWrapper}>
                    <MainButton label='Go Back' color='action' onClick={() => navigate('/')} />
                </div>
            </div>
        </div>
    );
};

export default Page404;
