import classes from './MainTextarea.module.css';

const MainTextarea = (props) => {
    let classNames = [classes.MainTextareaWrapper];
    if (props.fullWidth) classNames.push(classes.FullWidth);
    else if (props.narrow) classNames.push(classes.Narrow);
    else if (props.veryNarrow) classNames.push(classes.VeryNarrow);

    return (
        <div className={classNames.join(' ')}>
            {props.label ? <label>{props.label}</label> : null}

            <textarea
                className={classes.MainTextarea}
                name={props.name}
                label={props.label}
                disabled={props.disabled}
                value={props.value}
                rows={props.rows}
                onChange={props.onChange}
            ></textarea>
        </div>
    );
};

export default MainTextarea;
