import React, { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { SnackbarProvider } from 'notistack';
import { Scrollbars } from 'react-custom-scrollbars-2';

import logo_black from '../../assets/images/repolis_logo_black.png';
import logo_white from '../../assets/images/repolis_logo_white.png';
import logo_icon_black from '../../assets/images/repolis_icon_black.png';
import logo_icon_white from '../../assets/images/repolis_icon_white.png';
import logo_icon_green from '../../assets/images/repolis_icon_green.png';
import classes from './Layout2.module.css';
import CloseSnackbarAction from '../../features/CloseSnackbarAction/CloseSnackbarAction';

const Layout2 = () => {
    const snackbarRef = useRef();
    const theme = useSelector((state) => state.layout.theme);

    return (
        <SnackbarProvider
            ref={snackbarRef}
            autoHideDuration={3000}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            action={(id) => <CloseSnackbarAction id={id} />}
        >
            <div className={classes.Layout2Wrapper}>
                <div className={classes.Layout2}>
                    <MediaQuery minWidth={700}>
                        <div className={classes.ColumnA}>
                            <img src={theme === 'dark' ? logo_icon_white : logo_icon_black} alt='Repolis icon' />

                            <h1>
                                Welcome to <br />
                                rePolis
                            </h1>
                            <span>Our goal is to develop the base for the most efficient energy saving interventions</span>
                        </div>
                    </MediaQuery>

                    <div className={classes.ScrollWrapper}>
                        <Scrollbars
                            className={classes.Scroller}
                            style={{ width: '100%', height: '100%' }}
                            renderThumbVertical={(props) => <div {...props} className='thumb-vertical' />}
                            renderThumbHorizontal={(props) => <div {...props} className='thumb-horizontal' />}
                        >
                            <div className={classes.ColumnB}>
                                <div className={classes.LogoWrapper}>
                                    <img src={theme === 'dark' ? logo_white : logo_black} alt='Repolis logo' />
                                </div>

                                <div className={classes.ColumnBContent}>
                                    <Outlet />
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </div>
        </SnackbarProvider>
    );
};

export default Layout2;
