import React, { useState, useEffect, useRef } from 'react';

import PieChart from '../../../features/Charts/PieChart';
import classes from './AllCharts.module.css';

const ScenarioChartsGeneral = (props) => {
    const [areas, setAreas] = useState({ conditionedPerc: null, uncoditionedPerc: null, usageArea: null });

    useEffect(() => {
        const conditionedArea = props.buildingValues.total_building_area;
        const unconditionedArea = props.buildingValues.total_unconditioned_area;
        const sum = unconditionedArea + conditionedArea;

        // Usage metrics
        let usages = [];
        // let totalUsageArea = props.buildingValues.total_building_area;
        let totalUsageArea = 0;

        for (let i = 0; i < props.buildingValues.usage_areas.length; i++) {
            const usage_area = props.buildingValues.usage_areas[i];
            const usageArea = usage_area.usage_area;

            totalUsageArea = totalUsageArea + usageArea;
        }

        for (let i = 0; i < props.buildingValues.usage_areas.length; i++) {
            const usage_area = props.buildingValues.usage_areas[i];
            const usageName = usage_area.usage_name;
            const usageArea = usage_area.usage_area;
            const usageAreaPerc = (usageArea / totalUsageArea) * 100;

            usages.push({ name: usageName, y: usageAreaPerc, color: usage_area.color });
        }

        const data = {
            conditionedPerc: (conditionedArea / sum) * 100,
            uncoditionedPerc: (unconditionedArea / sum) * 100,
            usages: usages,
        };

        setAreas(data);
    }, [props.buildingValues]);

    return (
        props.buildingValues && (
            <div className={classes.Charts}>
                <div className={classes.Chart}>
                    <h3>Building zone information</h3>
                    <PieChart
                        title={null}
                        alignLegend='center'
                        series={[
                            {
                                name: 'Percentage',
                                colorByPoint: true,
                                data: areas.usages,
                            },
                        ]}
                    />
                </div>

                <div className={classes.Chart}>
                    <h3>Building Area</h3>

                    <PieChart
                        title={null}
                        alignLegend='center'
                        series={[
                            {
                                name: 'Percentage',
                                colorByPoint: true,
                                data: [
                                    { name: 'Conditioned area', y: areas.conditionedPerc },
                                    { name: 'Unconditioned area', y: areas.uncoditionedPerc },
                                ],
                            },
                        ]}
                    />
                </div>
            </div>
        )
    );
};

export default ScenarioChartsGeneral;
