import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    analysisTypesData: [],
};

export const analysisTypesSlice = createSlice({
    name: 'analysisTypes',
    initialState,
    reducers: {
        setAnalysisTypesData: (state, action) => {
            state.analysisTypesData = action.payload;
        },
    },
});

export const analysisTypesActions = analysisTypesSlice.actions;

export default analysisTypesSlice;
