import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userInfo: null,
};

export const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
    },
});

export const userProfileActions = userProfileSlice.actions;

export default userProfileSlice;
