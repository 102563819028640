import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MapContainer, FeatureGroup, GeoJSON, TileLayer, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import { polygon } from '@turf/helpers';
import booleanIntersects from '@turf/boolean-intersects';

import Badge from '../../features/UI/Badge/Badge';
import MainInput from '../../features/UI/MainInput/MainInput';
import MainTextarea from '../../features/UI/MainTextarea/MainTextarea';
import MainButton from '../../features/UI/MainButton/MainButton';
import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import Widget from '../../features/Widget/Widget';
import Modal from '../../features/UI/Modal/Modal';
import MyAutocomplete from '../../features/UI/MyAutocomplete/MyAutocomplete';
import MyAutocompleteWeather from '../../features/UI/MyAutocomplete/MyAutocompleteWeather';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { myProjectsActions } from './myProjectsSlice';
import { getWeatherDataWithDistance, saveProject, getBuildingsGeoJson, getProjectInitData } from './MyProjectsAsyncActions';
import classes from './MyProjectsNew.module.css';
import { showMessage } from '../../utils/messageHandler';

const MyProjectsNew = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // -------------------
    const [formValidated, setFormValidated] = useState(false);
    const [map, setMap] = useState(null);
    const featureGroupRef = useRef(null);
    const editControlRef = useRef(null);

    const [isReady, setIsReady] = useState(false);
    const [stepsClasses, setStepsClasses] = useState({
        1: [classes.StepWrapper, classes.Active],
        2: [classes.StepWrapper, classes.Disabled],
        3: [classes.StepWrapper, classes.Disabled],
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [selectedUserData, setSelectedUserData] = useState(null);
    const [selectedWeatherData, setSelectedWeatherData] = useState(null);
    const [selectingStatus, setSelectingStatus] = useState('');
    const [areaSelection, setAreaSelection] = useState([]);

    const savedSelectedBuildings = useSelector((state) => state.myProjects.savedSelectedBuildings);
    const [selectedBuildings, setSelectedBuildings] = useState(savedSelectedBuildings);

    const tileLayer = useSelector((state) => state.system.tileLayer);
    const tileLayerAttribution = useSelector((state) => state.system.tileLayerAttribution);
    const userData = useSelector((state) => state.myProjects.userData);
    const weatherDataWithDistance = useSelector((state) => state.myProjects.weatherDataWithDistance);
    const savingProjectStatus = useSelector((state) => state.myProjects.savingProjectStatus);
    const loadingBuildingsStatus = useSelector((state) => state.myProjects.loadingBuildingsStatus);
    const buildingsGeoJson = useSelector((state) => state.myProjects.buildingsGeoJson);

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('My projects new'));
        dispatch(getProjectInitData());

        // This runs on component unmount
        return () => {
            dispatch(myProjectsActions.resetNewProject(''));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch]);

    useEffect(() => {
        let updateStepsClasses = {
            1: [classes.StepWrapper, classes.Active],
            2: [classes.StepWrapper, classes.Disabled],
            3: [classes.StepWrapper, classes.Disabled],
        };

        if (selectedUserData) {
            updateStepsClasses = {
                1: [classes.StepWrapper, classes.Active, classes.Valid],
                2: [classes.StepWrapper],
                3: [classes.StepWrapper, classes.Active],
            };

            dispatch(getWeatherDataWithDistance(selectedUserData.id));
        } else {
            dispatch(myProjectsActions.setWeatherDataWithDistance([]));
        }

        setStepsClasses(updateStepsClasses);
        // dispatch(myProjectsActions.setSelectedBuildings(null));
        setSelectedWeatherData(null);
    }, [selectedUserData]);

    useEffect(() => {
        let updateStepsClasses = { ...stepsClasses };

        if (selectedWeatherData) {
            updateStepsClasses[3] = [classes.StepWrapper, classes.Active, classes.Valid];
        } else {
            updateStepsClasses[3] = [classes.StepWrapper, classes.Active];
        }

        setStepsClasses(updateStepsClasses);
    }, [selectedWeatherData]);

    useEffect(() => {
        let updateStepsClasses = { ...stepsClasses };

        if (savedSelectedBuildings.length > 0) {
            updateStepsClasses[2] = [classes.StepWrapper, classes.Active, classes.Valid];
        } else {
            updateStepsClasses[2] = [classes.StepWrapper];
        }

        setStepsClasses(updateStepsClasses);
    }, [savedSelectedBuildings]);

    useEffect(() => {
        if (projectName.trim() && selectedUserData && selectedWeatherData) setIsReady(true);
        else setIsReady(false);
    }, [projectName, selectedUserData, selectedWeatherData]);

    useEffect(() => {
        if (savingProjectStatus === 'finished') navigate('/myprojects');
    }, [savingProjectStatus]);

    useEffect(() => {
        if (!map) return;
        if (!featureGroupRef.current) return;
        map.fitBounds(featureGroupRef.current.getBounds());
    }, [map]);

    useEffect(() => {
        let updateBuildings = [...selectedBuildings, ...areaSelection];
        setSelectedBuildings(updateBuildings);
    }, [areaSelection]);

    useEffect(() => {
        if (selectingStatus === 'draw') {
            editControlRef.current._toolbars.draw._modes.polygon.handler.enable();
        }
    }, [selectingStatus]);

    const toggleSelectedBuilding = (buildingId) => {
        if (selectingStatus !== 'polygons') return;
        let updateBuildings = [...selectedBuildings];
        if (updateBuildings.includes(buildingId)) {
            updateBuildings = updateBuildings.filter((b) => b !== buildingId);
        } else {
            updateBuildings.push(buildingId);
        }
        setSelectedBuildings(updateBuildings);
    };

    const getIntersectingPolygons = (e) => {
        const coords = e.layer.getLatLngs();
        let coordsNum = [];
        // let updateBuildings = [...selectedBuildings];
        let updateAreaSelection = [];

        // Change the coords from latlng to numbers
        coords[0].forEach(function (coordsPair) {
            coordsNum.push([coordsPair.lng, coordsPair.lat]);
        });
        coordsNum.push(coordsNum[0]); // add the first coordinate to close the polygon
        coordsNum = [coordsNum];

        const drawPolygon = polygon(coordsNum);

        buildingsGeoJson.forEach(function (building) {
            let buildingCoords = building.geometry.coordinates;
            const buildingPolygon = polygon(buildingCoords);

            const doesIntersect = booleanIntersects(drawPolygon, buildingPolygon);
            if (doesIntersect) {
                updateAreaSelection.push(building.id);
            }
        });

        // Remove layer before updating the state
        removeDrawLayers();

        // Update state
        setAreaSelection(updateAreaSelection);
        // setSelectedBuildings(updateBuildings);
        setSelectingStatus('');
    };

    const _onMounted = (e) => {
        editControlRef.current = e;
    };

    const clearAll = () => {
        setSelectedBuildings([]);
        removeDrawLayers();
    };

    const removeDrawLayers = () => {
        const layerContainer = editControlRef.current.options.edit.featureGroup;
        const layers = layerContainer._layers;
        const layer_ids = Object.keys(layers);
        layer_ids.forEach((id) => {
            const layer = layers[id];
            layerContainer.removeLayer(layer);
        });
    };

    const clearUserData = () => {
        setSelectedUserData(null);
        setSelectedBuildings([]);
    };

    const toggleSelectingStatus = (status) => {
        if (selectingStatus === status) setSelectingStatus('');
        else setSelectingStatus(status);
    };

    const onSaveProject = () => {
        setFormValidated(true);

        if (projectName.trim() === '') {
            showMessage('error', 'Please fill in all required fields');
        } else if (selectedUserData === null || selectedWeatherData === null) {
            showMessage('error', 'Please complete all the required steps');
        } else {
            dispatch(saveProject(projectName, projectDescription, selectedUserData, selectedWeatherData, selectedBuildings));
        }
    };

    const getGeojsonBuildings = () => {
        const id = parseInt(selectedUserData.id);
        const type = selectedUserData.group && selectedUserData.group === 'System data' ? 'system' : 'user';

        dispatch(getBuildingsGeoJson(id, type));
        setModalOpen(true);
    };

    return (
        <div className={classes.MyProjectsNew}>
            <Breadcrumb link1='User' link2='/ My projects' link3='/ New Project' />

            <div className={classes.ContentInner}>
                <div className={classes.ButtonWrapper}>
                    <MainButton
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                                <polyline points='17 21 17 13 7 13 7 21'></polyline>
                                <polyline points='7 3 7 8 15 8'></polyline>
                            </svg>
                        }
                        label='Save'
                        color='action'
                        onClick={onSaveProject}
                        //disabled={!isReady}
                        loading={savingProjectStatus === 'start'}
                    />
                </div>

                <div className={classes.Widgets}>
                    <Widget>
                        <div className={classes.TitleRowWithSubtitle}>
                            <h2>Project info</h2>
                            <span>Fill in a name for the weather dataset</span>
                        </div>

                        <div className={classes.WidgetContent}>
                            <div className={classes.FormRow}>
                                <label>Name *</label>
                                <MainInput
                                    type='text'
                                    fullWidth
                                    value={projectName}
                                    onChange={(e) => setProjectName(e.target.value)}
                                    validated={formValidated}
                                    isValid={projectName.trim() !== ''}
                                />
                            </div>
                            <div className={classes.FormRow}>
                                <label>Description</label>
                                <MainTextarea
                                    type='text'
                                    fullWidth
                                    value={projectDescription}
                                    rows={3}
                                    onChange={(e) => setProjectDescription(e.target.value)}
                                />
                            </div>
                        </div>
                    </Widget>

                    <Widget>
                        <div className={classes.TitleRowWithSubtitle}>
                            <h2>Steps</h2>
                            <span>Please complete the following steps to create a new project</span>
                        </div>
                        <div className={classes.WidgetContent}>
                            <div className={classes.StepsWrapper}>
                                {/* Building data */}
                                <div className={selectedUserData ? [classes.Step, classes.Complete].join(' ') : classes.Step}>
                                    <div className={classes.Col1}>
                                        <div className={classes.Circle}>
                                            <span>1</span>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <polyline points='20 6 9 17 4 12'></polyline>
                                            </svg>
                                        </div>
                                        <span></span>
                                    </div>
                                    <div className={classes.Col2}>
                                        <div className={classes.TitleWrapper}>
                                            <div className={classes.Title}>
                                                <h4>Select building data</h4>
                                                {selectedUserData && <span>{selectedUserData.name}</span>}
                                                {!selectedUserData && <span className={classes.Danger}>No selection</span>}
                                            </div>

                                            {!selectedUserData && (
                                                <MyAutocomplete
                                                    options={userData}
                                                    value={selectedUserData}
                                                    onChange={(value) => setSelectedUserData(value)}
                                                    fullWidth
                                                    groupBy='group'
                                                />
                                            )}
                                            {selectedUserData && (
                                                <div className={classes.EditPropertiesBtn} onClick={clearUserData}>
                                                    <Badge
                                                        color='warning'
                                                        icon={
                                                            <svg
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                width='24'
                                                                height='24'
                                                                viewBox='0 0 24 24'
                                                                fill='none'
                                                                stroke='currentColor'
                                                                strokeWidth='2'
                                                                strokeLinecap='round'
                                                                strokeLinejoin='round'
                                                            >
                                                                <path d='M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z'></path>
                                                            </svg>
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/* Building subselection */}
                                <div className={selectedBuildings.length > 0 ? [classes.Step, classes.Complete].join(' ') : classes.Step}>
                                    <div className={classes.Col1}>
                                        <div className={classes.Circle}>
                                            <span>2</span>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <polyline points='20 6 9 17 4 12'></polyline>
                                            </svg>
                                        </div>
                                        <div className={classes.IsOptional}>Optional</div>
                                        <span></span>
                                    </div>
                                    <div className={classes.Col2}>
                                        <div className={classes.TitleWrapper}>
                                            <div className={classes.Title}>
                                                <h4>Select subset of building data</h4>
                                                {selectedBuildings.length > 0 && <span>Selected {selectedBuildings.length} buildings</span>}
                                                {selectedBuildings.length === 0 && <span className={classes.Warning}>No selection</span>}
                                            </div>

                                            <div
                                                className={
                                                    selectedUserData ? classes.EditPropertiesBtn : [classes.EditPropertiesBtn, classes.Disabled].join(' ')
                                                }
                                                onClick={selectedUserData ? () => getGeojsonBuildings() : null}
                                            >
                                                <Badge
                                                    color='warning'
                                                    icon={
                                                        <svg
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                        >
                                                            <path d='M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z'></path>
                                                        </svg>
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Weather data */}
                                <div className={selectedWeatherData ? [classes.Step, classes.Complete].join(' ') : classes.Step}>
                                    <div className={classes.Col1}>
                                        <div className={classes.Circle}>
                                            <span>3</span>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <polyline points='20 6 9 17 4 12'></polyline>
                                            </svg>
                                        </div>
                                        {/* <span></span> */}
                                    </div>
                                    <div className={classes.Col2}>
                                        <div className={classes.TitleWrapper}>
                                            <div className={classes.Title}>
                                                <h4>Select weather dataset</h4>
                                                {selectedWeatherData && <span>{selectedWeatherData.label}</span>}
                                                {!selectedWeatherData && <span className={classes.Danger}>No selection</span>}
                                            </div>

                                            {!selectedWeatherData && (
                                                <MyAutocompleteWeather
                                                    options={weatherDataWithDistance}
                                                    value={selectedWeatherData}
                                                    onChange={(value) => setSelectedWeatherData(value)}
                                                    fullWidth
                                                />
                                            )}

                                            {selectedWeatherData && (
                                                <div className={classes.EditPropertiesBtn} onClick={() => setSelectedWeatherData(null)}>
                                                    <Badge
                                                        color='warning'
                                                        icon={
                                                            <svg
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                width='24'
                                                                height='24'
                                                                viewBox='0 0 24 24'
                                                                fill='none'
                                                                stroke='currentColor'
                                                                strokeWidth='2'
                                                                strokeLinecap='round'
                                                                strokeLinejoin='round'
                                                            >
                                                                <path d='M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z'></path>
                                                            </svg>
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/* Analysis type */}
                                {/* <div className={selectedAnalysisType ? [classes.Step, classes.Complete].join(' ') : classes.Step}>
                                    <div className={classes.Col1}>
                                        <div className={classes.Circle}>
                                            <span>4</span>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <polyline points='20 6 9 17 4 12'></polyline>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className={classes.Col2}>
                                        <div className={classes.TitleWrapper}>
                                            <div className={classes.Title}>
                                                <h4>Select analysis type</h4>
                                                {selectedAnalysisType && <span>{selectedAnalysisType.label}</span>}
                                                {!selectedAnalysisType && <span className={classes.Danger}>No selection</span>}
                                            </div>

                                            {!selectedAnalysisType && (
                                                <MyAutocomplete
                                                    options={analysisTypes}
                                                    value={selectedAnalysisType}
                                                    onChange={(value) => setSelectedAnalysisType(value)}
                                                    fullWidth
                                                />
                                            )}
                                            {selectedAnalysisType && (
                                                <div className={classes.EditPropertiesBtn} onClick={() => setSelectedAnalysisType(null)}>
                                                    <Badge
                                                        color='warning'
                                                        icon={
                                                            <svg
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                width='24'
                                                                height='24'
                                                                viewBox='0 0 24 24'
                                                                fill='none'
                                                                stroke='currentColor'
                                                                strokeWidth='2'
                                                                strokeLinecap='round'
                                                                strokeLinejoin='round'
                                                            >
                                                                <path d='M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z'></path>
                                                            </svg>
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </Widget>
                </div>
            </div>

            <Modal
                title='Select subset of data'
                loading={loadingBuildingsStatus === 'started'}
                show={modalOpen}
                onClose={() => {
                    setSelectedBuildings(savedSelectedBuildings);
                    removeDrawLayers();
                    setModalOpen(false);
                    setSelectingStatus('');
                }}
                buttons={
                    <MainButton
                        label='Set'
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <polyline points='20 6 9 17 4 12'></polyline>
                            </svg>
                        }
                        color='warning'
                        onClick={() => {
                            setSelectingStatus('');
                            dispatch(myProjectsActions.setSavedSelectedBuildings(selectedBuildings));
                            setModalOpen(false);
                        }}
                    />
                }
            >
                {modalOpen && loadingBuildingsStatus === 'finished' && (
                    <div className={classes.ModalMapOuter}>
                        <div className={classes.Controls}>
                            <div
                                className={selectingStatus === 'polygons' ? [classes.Control, classes.Active].join(' ') : classes.Control}
                                onClick={() => toggleSelectingStatus('polygons')}
                            >
                                <Badge
                                    size='large'
                                    color='success'
                                    label='Select buildings'
                                    icon={
                                        <svg xmlns='http://www.w3.org/2000/svg' width='42' height='42' viewBox='0 0 42 42'>
                                            <g transform='translate(-1.359 -1.484)'>
                                                <circle
                                                    cx='20'
                                                    cy='20'
                                                    r='20'
                                                    transform='translate(2.359 2.484)'
                                                    fill='none'
                                                    stroke='#00ab55'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    strokeWidth='3'
                                                />
                                                <line
                                                    x1='9'
                                                    transform='translate(33.359 22.484)'
                                                    fill='none'
                                                    stroke='#00ab55'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    strokeWidth='3'
                                                />
                                                <line
                                                    x1='8'
                                                    transform='translate(2.359 22.484)'
                                                    fill='none'
                                                    stroke='#00ab55'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    strokeWidth='3'
                                                />
                                                <line
                                                    y1='8'
                                                    transform='translate(22.359 2.484)'
                                                    fill='none'
                                                    stroke='#00ab55'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    strokeWidth='3'
                                                />
                                                <line
                                                    y1='9'
                                                    transform='translate(22.359 33.484)'
                                                    fill='none'
                                                    stroke='#00ab55'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    strokeWidth='3'
                                                />
                                            </g>
                                        </svg>
                                    }
                                />
                            </div>
                            <div
                                className={selectingStatus === 'draw' ? [classes.Control, classes.Active].join(' ') : classes.Control}
                                onClick={() => toggleSelectingStatus('draw')}
                            >
                                <Badge
                                    size='large'
                                    color='success'
                                    label='Select area'
                                    icon={
                                        <svg xmlns='http://www.w3.org/2000/svg' width='39.956' height='40' viewBox='0 0 39.956 40' fill='#00ab55'>
                                            <path d='M33.743,28.705a1.549,1.549,0,0,1-1.759-.927,29.338,29.338,0,0,0-1.7-2.858,1.219,1.219,0,0,1,.044-1.67,4.823,4.823,0,0,0,.5-5.484C29.7,16,30.269,14.855,31.212,13.5a16.108,16.108,0,0,0,1.016-1.743.922.922,0,0,1,1.156-.527,5.491,5.491,0,0,0,6.369-4.013,5.647,5.647,0,0,0-10.608-3.8,1.2,1.2,0,0,1-1.3.86q-7.8-.041-15.593,0a1.329,1.329,0,0,1-1.419-.889A5.652,5.652,0,0,0,2.728.83,5.4,5.4,0,0,0,.067,6.063a5.443,5.443,0,0,0,3.385,4.795c.6.276.911.518.9,1.27-.041,5.236-.029,10.478,0,15.711a1.126,1.126,0,0,1-.794,1.241A5.655,5.655,0,0,0,.089,35.113a5.744,5.744,0,0,0,4.763,4.81,5.7,5.7,0,0,0,6.033-3.464,1.114,1.114,0,0,1,1.235-.772c2.62.029,5.236,0,7.855,0s5.16.032,7.738-.019a1.354,1.354,0,0,1,1.473.953,5.308,5.308,0,0,0,5.191,3.3,5.309,5.309,0,0,0,5.017-3.2A5.561,5.561,0,0,0,33.743,28.705Zm.635-25.89a2.823,2.823,0,0,1,2.794,2.928,2.868,2.868,0,1,1-5.734-.165,2.772,2.772,0,0,1,2.931-2.762ZM2.791,5.622a2.908,2.908,0,0,1,2.924-2.8A2.82,2.82,0,0,1,8.573,5.765,2.924,2.924,0,0,1,5.681,8.6,2.975,2.975,0,0,1,2.791,5.622Zm2.8,31.52a2.874,2.874,0,1,1,2.983-2.761A2.874,2.874,0,0,1,5.592,37.142Zm23.344-4.677a.889.889,0,0,1-.892.422H11.984a.967.967,0,0,1-1.032-.73,5.36,5.36,0,0,0-3.108-3.1,1.183,1.183,0,0,1-.835-1.346q.029-7.735,0-15.466a1.277,1.277,0,0,1,.937-1.394A5.214,5.214,0,0,0,10.885,7.9,1.1,1.1,0,0,1,12.1,7.1c2.658.041,5.312.016,7.97.016,2.578,0,5.156.019,7.732,0,.657,0,.918.26,1.34.784,1.27,1.588.7,2.858-.318,4.172a5.547,5.547,0,0,0-.476.822,2.137,2.137,0,0,1-2.566,1.483,4.907,4.907,0,0,0-5.055,2.858,5.469,5.469,0,0,0,.362,6.055,5.179,5.179,0,0,0,5.376,2.223,1.077,1.077,0,0,1,1.34.635c.346.711.806,1.362,1.21,2.045,1.273,2.146,1.257,2.14-.083,4.267Zm-.318-12.491a2.915,2.915,0,1,1-2.913-2.819,2.915,2.915,0,0,1,2.913,2.819Zm5.633,17.168A2.874,2.874,0,1,1,37.156,34.3a2.874,2.874,0,0,1-2.905,2.842Z' />
                                        </svg>
                                    }
                                />
                            </div>
                            <div className={classes.Control} onClick={clearAll}>
                                <Badge
                                    size='large'
                                    color='danger'
                                    label='Clear all'
                                    icon={
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        >
                                            <polyline points='3 6 5 6 21 6'></polyline>
                                            <path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'></path>
                                            <line x1='10' y1='11' x2='10' y2='17'></line>
                                            <line x1='14' y1='11' x2='14' y2='17'></line>
                                        </svg>
                                    }
                                />
                            </div>
                        </div>

                        <div
                            className={
                                selectingStatus === 'polygons'
                                    ? [classes.ModalMapContainerCrosshair, classes.ModalMapContainer].join(' ')
                                    : classes.ModalMapContainer
                            }
                        >
                            <MapContainer
                                boundsOptions={{ padding: [10, 10] }}
                                center={[40, 22]}
                                zoom={13}
                                maxZoom={20}
                                whenReady={(map) => {
                                    if (buildingsGeoJson.length) setMap(map.target);
                                }}
                            >
                                <TileLayer attribution={tileLayerAttribution} url={tileLayer} />

                                <FeatureGroup>
                                    <EditControl
                                        position='topright'
                                        onMounted={_onMounted}
                                        onCreated={(e) => getIntersectingPolygons(e)}
                                        draw={{
                                            rectangle: false,
                                            polyline: false,
                                            marker: false,
                                            circle: false,
                                            circlemarker: false,
                                            polygon: {
                                                shapeOptions: { color: 'green' },
                                                allowIntersection: false,
                                                showArea: true,
                                            },
                                        }}
                                    />
                                </FeatureGroup>

                                <FeatureGroup ref={featureGroupRef}>
                                    {buildingsGeoJson.map((building) => {
                                        const isSelected = selectedBuildings.includes(building.id) ? true : false;

                                        return (
                                            <GeoJSON
                                                key={`${building.id}_${String(isSelected)}`}
                                                data={building}
                                                eventHandlers={{
                                                    click() {
                                                        toggleSelectedBuilding(building.id);
                                                    },
                                                }}
                                                style={isSelected ? { color: 'yellow' } : null}
                                            >
                                                {selectingStatus === '' && (
                                                    <Popup>
                                                        <div className={classes.Row}>
                                                            <span>Height:</span> {building.properties.height}
                                                        </div>
                                                        <div className={classes.Row}>
                                                            <span>Building type:</span> {building.properties.country_building_type}
                                                        </div>
                                                        <div className={classes.Row}>
                                                            <span>Construction year:</span> {building.properties.construction_year}
                                                        </div>
                                                    </Popup>
                                                )}
                                            </GeoJSON>
                                        );
                                    })}
                                </FeatureGroup>
                            </MapContainer>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default MyProjectsNew;
