import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import classes from './Home.module.css';
import Wavebox from '../../features/Wavebox/Wavebox';
import FancyList from '../../features/UI/FancyList/FancyList';
import MainButton from '../../features/UI/MainButton/MainButton';
import image1 from '../../assets/images/image5.jpg';
import image2 from '../../assets/images/image7.jpg';
import image3 from '../../assets/images/image6.jpg';
import image8 from '../../assets/images/image8.jpg';
import ImageWithList from '../../features/ImageWithList/ImageWithList';
import Banner from '../../features/Banner/Banner';

const Home = () => {
    const navigate = useNavigate();

    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        setAnimate(true);

        return () => {
            setAnimate(false);
        };
    }, []);

    return (
        <div className={animate ? [classes.Animate, classes.Home].join(' ') : classes.Home}>
            <Wavebox>
                <div className={classes.HeaderWrapper}>
                    <div className={classes.Header}>
                        <div className={classes.HeaderTitleCol}>
                            <div className={classes.HeaderTitle}>
                                {/* <h1>rePolis</h1> */}
                                <h1>Urban retrofitting strategies made easy and clear</h1>
                            </div>

                            <FancyList
                                items={[
                                    <span>integrated, cloud-based platform for the holistic assessment of urban buildings' retrofitting.</span>,
                                    <span>easy-to-use, fully automated platform that empowers the decarbonisation of urban building stocks.</span>,
                                    <span>assess single-building units, neighborhoods and even whole cities.</span>,
                                ]}
                                check
                                light
                            />

                            <div className={classes.HeaderButtons}>
                                <MainButton label='Get started' color='action' onClick={() => navigate('/pricing')} />
                                <MainButton
                                    icon={
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        >
                                            <path d='M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z'></path>
                                        </svg>
                                    }
                                    label='Platform'
                                    color='transparent'
                                    onClick={() => navigate('/dashboard')}
                                />
                            </div>
                        </div>

                        <div className={classes.HeaderImagesCol}>
                            <img className={classes.Image1} src={image1} alt='platform 1' />

                            <img className={classes.Image2} src={image2} alt='platform 2' />

                            <div className={classes.Image3Wrapper}>
                                <img className={classes.Image3} src={image3} alt='platform 3' />
                            </div>
                        </div>
                    </div>

                    <div className={classes.MouseScroll}>
                        <svg xmlns='http://www.w3.org/2000/svg' version='1.1' x='0px' y='0px' viewBox='0 0 50 50' aria-hidden='true' width='32' height='32'>
                            <path d='M25,0C16.4,0,9.4,7,9.4,15.6v18.8C9.4,43,16.4,50,25,50s15.6-7,15.6-15.6V15.6C40.6,7,33.6,0,25,0z M37.5,34.4 c0,6.9-5.6,12.5-12.5,12.5s-12.5-5.6-12.5-12.5V15.6c0-6.9,5.6-12.5,12.5-12.5s12.5,5.6,12.5,12.5V34.4z'></path>{' '}
                            <circle cx='25' cy='11.6' r='4'></circle>
                        </svg>
                    </div>
                </div>
            </Wavebox>

            <div className={classes.PageContent}>
                <div className={classes.WithMarginsWrapper}>
                    <div className={classes.WithMargins}>
                        <div className={classes.ColumnsGrid}>
                            <div className={classes.Column}>
                                <h3>Global approach</h3>
                                <span>Safely test your buildings anywhere in the world</span>
                            </div>
                            <div className={classes.Column}>
                                <h3>Integrated pan-European data</h3>
                                <span>Use our integrated official pan-European database</span>
                            </div>
                            <div className={classes.Column}>
                                <h3>Import your own data</h3>
                                <span>Use your own data using several different types</span>
                            </div>
                            <div className={classes.Column}>
                                <h3>We have your back</h3>
                                <span>Not sufficient in hand? Not sure where to find data? We'll do it for you. </span>
                            </div>
                            <div className={classes.Column}>
                                <h3>Easy and approved</h3>
                                <span>You don't have to be an energy expert to use the platform</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.Benefits}>
                    <div className={classes.WithMarginsWrapper}>
                        <div className={classes.WithMargins}>
                            <div className={classes.BoxTitle}>
                                <span>Why choose us</span>
                                <h2>YOUR BENEFITS AT A GLANCE</h2>
                            </div>

                            <div className={classes.ColumnsGrid}>
                                <div className={classes.ColumnFixed}>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='0.5'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <path d='M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z'></path>
                                    </svg>
                                    <h3>Cloud based</h3>
                                    <span>Fully cloud based and as fast as possible</span>
                                </div>
                                <div className={classes.ColumnFixed}>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='0.5'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z'></path>
                                    </svg>
                                    <h3>Reliable</h3>
                                    <span>Incorporates established BEM, GIS technologies and high-quality databases</span>
                                </div>
                                <div className={classes.ColumnFixed}>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='0.5'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <path d='M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z'></path>
                                    </svg>
                                    <h3>Easy to use</h3>
                                    <span>No expertise needed to use it</span>
                                </div>
                                <div className={classes.ColumnFixed}>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='0.5'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <circle cx='6' cy='6' r='3'></circle>
                                        <circle cx='6' cy='18' r='3'></circle>
                                        <line x1='20' y1='4' x2='8.12' y2='15.88'></line>
                                        <line x1='14.47' y1='14.48' x2='20' y2='20'></line>
                                        <line x1='8.12' y1='8.12' x2='12' y2='12'></line>
                                    </svg>
                                    <h3>Tailor-cut solutions</h3>
                                    <span>You can assess a variety of building typologies based on your own project needs</span>
                                </div>
                                <div className={classes.ColumnFixed}>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='0.5'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <polyline points='15 3 21 3 21 9'></polyline>
                                        <polyline points='9 21 3 21 3 15'></polyline>
                                        <line x1='21' y1='3' x2='14' y2='10'></line>
                                        <line x1='3' y1='21' x2='10' y2='14'></line>
                                    </svg>
                                    <h3>Project scalability</h3>
                                    <span>You can test single-buildings and whole cities</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.WithMarginsWrapper}>
                    <div className={classes.WithMargins}>
                        <ImageWithList image={image8} title='RETROFITTING URBAN BUILDINGS MADE EASY'>
                            <FancyList
                                items={[
                                    <span>
                                        <b>Building energy market analysis</b> - rePolis provides you with all needed data for your market nieche analysis by
                                        end-use, building category and fuel type
                                    </span>,
                                    <span>
                                        <b>Urban buildings retrofitting investments</b> - rePolis calculates energy, costs and carbon footprint improvents for
                                        you.
                                    </span>,
                                    <span>
                                        <b>Energy policy support</b> - rePolis enables you to securely assess investment scenarios based on their economic,
                                        energy and environmental feasibility.
                                    </span>,
                                    <span>
                                        <b>Custom retrofitting scenarios</b> - rePolis enables multi-variable analysis of multi-scale retrofitting scenarios.
                                    </span>,
                                ]}
                            />
                        </ImageWithList>
                    </div>
                </div>

                <Banner title='WANT TO GET FAMILIAR WITH REPOLIS?' subtitle='We offer a free tour through the platform' />
            </div>
        </div>
    );
};

export default Home;
