import classes from './Footer.module.css';

const Footer = () => {
    return (
        <div className={classes.Footer}>
            <span>Copyright &copy;2023 rePolis, All rights reserved.</span>
        </div>
    );
};

export default Footer;
