import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
    userProjects: [],
    listLoading: false,
    userData: [],
    // selectedBuildings: [],
    weatherDataWithDistance: [],
    savingProjectStatus: '',
    loadingBuildingsStatus: '',
    buildingsGeoJson: [],
    savedSelectedBuildings: [],
    selectedProject: null,
    selectedProjectScenarios: [],
    scenariosOptions: [],
    selectedScenario: null,
    analysisTypes: [],
    scenariosMetrics: {},
    scenarioFilteredMetrics: null,
    isFullScreen: false,
    viewLegend: true,
    colorBasedOn: 'Height',
    filterData: [],
    viewAllCharts: false,
    buildingCategories: [],
    useOptions: [],
};

export const myProjectsSlice = createSlice({
    name: 'myProjects',
    initialState,
    reducers: {
        resetNewProject: (state) => {
            state.listLoading = false;
            state.userData = [];
            // state.selectedBuildings = [];
            state.weatherDataWithDistance = [];
            state.savingProjectStatus = '';
            state.loadingBuildingsStatus = '';
            state.buildingsGeoJson = [];
            state.savedSelectedBuildings = [];
            state.selectedProject = null;
            state.selectedProjectScenarios = [];
            state.scenariosOptions = [];
            state.selectedScenario = null;
            state.scenariosMetrics = {};
            state.scenarioFilteredMetrics = null;
            state.isFullScreen = false;
            state.viewLegend = true;
            state.colorBasedOn = 'Height';
            state.filterData = [];
            state.viewAllCharts = false;
            state.buildingCategories = [];
            state.useOptions = [];
        },
        setSelectedProject: (state, action) => {
            state.selectedProject = action.payload?.project;
            state.selectedProjectScenarios = action.payload?.scenarios;
            state.selectedScenario = action.payload?.scenarios[0];
            state.scenariosOptions = action.payload?.scenariosOptions;
            state.buildingCategories = action.payload?.buildingCategories;
            state.useOptions = action.payload?.useOptions;
        },
        setUserProjects: (state, action) => {
            state.userProjects = action.payload;
        },
        setListLoading: (state, action) => {
            state.listLoading = action.payload;
        },
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
        // setSelectedBuildings: (state, action) => {
        //     state.selectedBuildings = action.payload;
        // },
        setWeatherDataWithDistance: (state, action) => {
            state.weatherDataWithDistance = action.payload;
        },
        setSavingProjectStatus: (state, action) => {
            state.savingProjectStatus = action.payload;
        },
        setLoadingBuildingsStatus: (state, action) => {
            state.loadingBuildingsStatus = action.payload;
        },
        setBuildingsGeoJson: (state, action) => {
            state.buildingsGeoJson = action.payload;
        },
        setSavedSelectedBuildings: (state, action) => {
            state.savedSelectedBuildings = action.payload;
        },
        setAnalysisTypes: (state, action) => {
            state.analysisTypes = action.payload;
        },
        setScenarioMetric: (state, action) => {
            state.scenariosMetrics[action.payload.simulationId] = action.payload.metrics;
        },
        setScenarioFilteredMetrics: (state, action) => {
            state.scenarioFilteredMetrics = action.payload;
        },
        setFullScreen: (state, action) => {
            state.isFullScreen = action.payload;
        },
        setViewLegend: (state, action) => {
            state.viewLegend = action.payload;
        },
        toggleViewLegend: (state, action) => {
            state.viewLegend = !current(state).viewLegend;
        },
        setSelectedScenario: (state, action) => {
            state.selectedScenario = action.payload;
        },
        setColorBasedOn: (state, action) => {
            state.colorBasedOn = action.payload;
        },
        updateFilterData: (state, action) => {
            state.filterData.push({ property: action.payload.property, comparison: action.payload.comparison, value: action.payload.value });
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload;
        },
        removeFilterByIndex: (state, action) => {
            state.filterData.splice(action.payload, 1);
        },
        setViewAllCharts: (state, action) => {
            state.viewAllCharts = action.payload;
        },
    },
});

export const myProjectsActions = myProjectsSlice.actions;

export default myProjectsSlice;
