import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const CloseSnackbarAction = (props) => {
    const { closeSnackbar } = useSnackbar();

    return <FontAwesomeIcon style={{ padding: '2px', cursor: 'pointer' }} icon={faTimes} onClick={() => closeSnackbar(props.id)} />;
};

export default CloseSnackbarAction;
