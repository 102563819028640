import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import L from 'leaflet';
import { MapContainer, TileLayer, FeatureGroup, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { GestureHandling } from 'leaflet-gesture-handling';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

import MyDropzone from '../../features/Dropzone/Dropzone';
import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import Widget from '../../features/Widget/Widget';
import MainButton from '../../features/UI/MainButton/MainButton';
import MainInput from '../../features/UI/MainInput/MainInput';
import MainTextarea from '../../features/UI/MainTextarea/MainTextarea';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { dropzoneActions } from '../../features/Dropzone/dropzoneSlice';
import { myWeatherFilesActions } from './myWeatherFilesSlice';
import classes from './MyWeatherFilesNew.module.css';
import { saveWeatherFile } from './myWeatherFilesAsyncActions';
import { showMessage } from '../../utils/messageHandler';
import { fNumMaxDec } from '../../utils/formats';

const MyWeatherFilesNew = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const mapRef = useRef(null);

    const [formValidated, setFormValidated] = useState(false);
    const [uploadedData, setUploadedData] = useState(null);

    const dataName = useSelector((state) => state.myWeatherFiles.dataName);
    const dataDescription = useSelector((state) => state.myWeatherFiles.dataDescription);
    const dataCoordinates = useSelector((state) => state.myWeatherFiles.dataCoordinates);
    const dataElevation = useSelector((state) => state.myWeatherFiles.dataElevation);
    const tileLayer = useSelector((state) => state.system.tileLayer);
    const tileLayerAttribution = useSelector((state) => state.system.tileLayerAttribution);
    const savingStatus = useSelector((state) => state.myWeatherFiles.savingStatus);
    const myFiles = useSelector((state) => state.dropzone.myFiles);

    const dataCoordinatesHandler = (value, property) => {
        let updatedCoordinates = { ...dataCoordinates };
        updatedCoordinates[property] = value;

        if (updatedCoordinates.lon && updatedCoordinates.lat) {
            mapRef?.current?.setView([updatedCoordinates.lat, updatedCoordinates.lon], 16);
        }

        dispatch(myWeatherFilesActions.setDataCoordinates(updatedCoordinates));
    };

    const markerDraggedHandler = (latlng) => {
        const lon = fNumMaxDec(latlng.lng, 5);
        const lat = fNumMaxDec(latlng.lat, 5);
        const updatedCoordinates = { lon: lon, lat: lat };

        dispatch(myWeatherFilesActions.setDataCoordinates(updatedCoordinates));
    };

    const dataElevationHandler = (value) => {
        dispatch(myWeatherFilesActions.setDataElevation(value));
    };

    const addMarker = () => {
        const mapCenter = mapRef.current.getCenter();
        markerDraggedHandler(mapCenter);
    };

    useEffect(() => {
        if (savingStatus === 'finished') navigate('/myweatherfiles');
    }, [savingStatus]);

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('Data'));
        L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

        // This runs on component unmount
        return () => {
            dispatch(dropzoneActions.reset());
            dispatch(myWeatherFilesActions.resetNewWeatherData());
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch]);

    const onSaveWeatherFile = () => {
        setFormValidated(true);

        if (dataName.trim() === '' || dataCoordinates.lon === '' || dataCoordinates.lat === '') {
            showMessage('error', 'Please fill in all required fields');
        } else if (!myFiles || myFiles.length === 0) {
            showMessage('error', 'Please add a weather file');
        } else {
            dispatch(myWeatherFilesActions.setSavingStatus('start'));
            dispatch(saveWeatherFile(uploadedData, dataCoordinates, dataName, dataDescription, dataElevation));
        }
    };

    return (
        <div className={classes.MyDataNew}>
            <Breadcrumb link1='User' link2='/ My Weather Files' link3='/ New Weather Dataset' />

            <div className={classes.ContentInner}>
                <div className={classes.ButtonWrapper}>
                    <MainButton
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                                <polyline points='17 21 17 13 7 13 7 21'></polyline>
                                <polyline points='7 3 7 8 15 8'></polyline>
                            </svg>
                        }
                        label='Save'
                        color='action'
                        onClick={onSaveWeatherFile}
                        loading={savingStatus === 'start'}
                    />
                </div>
                <div className={classes.Widgets}>
                    <Widget>
                        <div className={classes.TitleRowWithSubtitle}>
                            <h2>Weather dataset info</h2>
                            <span>Fill in a name for the weather dataset</span>
                        </div>

                        <div className={classes.WidgetContent}>
                            <div>
                                <div className={classes.FormRow}>
                                    <label>Name *</label>
                                    <MainInput
                                        type='text'
                                        fullWidth
                                        value={dataName}
                                        onChange={(e) => dispatch(myWeatherFilesActions.setDataName(e.target.value))}
                                        validated={formValidated}
                                        isValid={dataName.trim() !== ''}
                                    />
                                </div>
                                <div className={classes.FormRow}>
                                    <label>Description</label>
                                    <MainTextarea
                                        type='text'
                                        fullWidth
                                        value={dataDescription}
                                        rows={3}
                                        onChange={(e) => dispatch(myWeatherFilesActions.setDataDescription(e.target.value))}
                                    />
                                </div>
                            </div>
                        </div>
                    </Widget>

                    <Widget>
                        <div className={classes.TitleRowWithSubtitle}>
                            <h2>Location</h2>
                            <span>Input the longitude and latitude of the weather dataset / drag the marker in the map</span>
                        </div>

                        <div className={classes.WidgetContent}>
                            <div className={classes.MapToolbar}>
                                <MainInput
                                    type='number'
                                    label='Longitude *'
                                    fullWidth
                                    onChange={(e) => dataCoordinatesHandler(e.target.value, 'lon')}
                                    value={dataCoordinates.lon}
                                    validated={formValidated}
                                    isValid={dataCoordinates && dataCoordinates.lon !== ''}
                                />

                                <MainInput
                                    type='number'
                                    label='Latitude *'
                                    fullWidth
                                    onChange={(e) => dataCoordinatesHandler(e.target.value, 'lat')}
                                    value={dataCoordinates.lat}
                                    validated={formValidated}
                                    isValid={dataCoordinates && dataCoordinates.lat !== ''}
                                />

                                <MainInput
                                    type='number'
                                    label='Elevation'
                                    fullWidth
                                    onChange={(e) => dataElevationHandler(e.target.value)}
                                    value={dataElevation}
                                />

                                <div className={classes.AddMarkerButton}>
                                    <ReactTooltip effect='solid' />

                                    <div className={classes.AddMarker} onClick={addMarker} data-tip='Add marker on map'>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        >
                                            <path d='M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z'></path>
                                            <circle cx='12' cy='10' r='3'></circle>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.MapContainer}>
                                <MapContainer
                                    ref={mapRef}
                                    center={dataCoordinates.lat && dataCoordinates.lon ? [dataCoordinates?.lat, dataCoordinates?.lon] : [50, 16]}
                                    zoom={4}
                                    maxZoom={17}
                                    gestureHandling={true}
                                    gestureHandlingOptions={{ duration: 5000 }}
                                >
                                    <TileLayer attribution={tileLayerAttribution} url={tileLayer} />

                                    {dataCoordinates?.lat && dataCoordinates?.lon ? (
                                        <FeatureGroup>
                                            <Marker
                                                position={[dataCoordinates.lat, dataCoordinates.lon]}
                                                draggable={true}
                                                eventHandlers={{
                                                    dragend(e) {
                                                        const latlng = e.target.getLatLng();
                                                        markerDraggedHandler(latlng);
                                                    },
                                                }}
                                            ></Marker>
                                        </FeatureGroup>
                                    ) : null}
                                </MapContainer>
                            </div>
                        </div>
                    </Widget>

                    <Widget>
                        <div className={classes.TitleRowWithSubtitle}>
                            <h2>Upload file</h2>
                            <span>Please select a file to upload (only .epw files are accepted)</span>
                        </div>

                        <div className={classes.WidgetContent}>
                            <MyDropzone
                                label='Drag & drop your file or Browse'
                                acceptedFiles={{
                                    'application/data': ['.epw'],
                                }}
                                maxFiles={1}
                                maxSize={52428800}
                                // onCompleteUpload={(data) => dispatch(saveWeatherFile(data, dataCoordinates, dataName, dataDescription, dataElevation))}
                                onCompleteUpload={(data) => setUploadedData(data)}
                                onRemoveFile={() => dispatch(myWeatherFilesActions.setSavingStatus(''))}
                                uploadTo='weather_files'
                                // startUploading={savingStatus === 'start'}
                            />
                        </div>
                    </Widget>
                </div>
            </div>
        </div>
    );
};

export default MyWeatherFilesNew;
