import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Badge from '../../features/UI/Badge/Badge';
import Widget from '../../features/Widget/Widget';
import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import MyTable from '../../features/UI/MyTable/MyTable';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { getSystemData } from './systemDataAsyncActions';
import { systemDataActions } from './systemDataSlice';
import classes from './SystemData.module.css';
import { fNumSep } from '../../utils/formats';

const SystemData = () => {
    const dispatch = useDispatch();
    const systemData = useSelector((state) => state.systemData.systemData);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('System data'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getSystemData());

        return () => {
            dispatch(systemDataActions.setSystemData([]));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name', //simple recommended way to define a column
                header: 'Name',
                // muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
            },
            {
                accessorKey: 'country',
                header: 'Country',
            },
            {
                // accessorFn: (row) => row.age, //alternate way
                // id: 'age', //id required if you use accessorFn instead of accessorKey
                accessorKey: 'city',
                header: 'City',
                // Header: <i style={{ color: 'red' }}>Age</i>, //optional custom markup
            },
            {
                accessorKey: 'date_created',
                header: 'Date created',
                // Cell: ({ cell }) => cell.getValue(),
            },
            {
                accessorKey: 'description',
                header: 'Description',
            },
            {
                accessorKey: 'price',
                header: 'Price',
                Cell: ({ cell }) => '€ ' + fNumSep(cell.getValue()),
            },
            {
                accessorFn: (row) => row.isPurchased,
                id: 'isPurchased',
                header: 'Purchase',
                Cell: ({ cell }) => (
                    <div className={classes.TableActions}>
                        <ReactTooltip effect='solid' />

                        <div
                            className={
                                cell.getValue()
                                    ? [classes.TableBadge, classes.TableBadgeSuccess].join(' ')
                                    : [classes.TableBadge, classes.TableBadgeAction].join(' ')
                            }
                            onClick={cell.getValue() ? null : () => navigate('/purchase/' + cell.row.original.id)}
                        >
                            {cell.getValue() ? 'Purchased' : 'Purchase'}
                        </div>
                    </div>
                ),
                size: 40,
            },
            {
                accessorFn: (row) => row.id,
                id: 'id',
                header: 'Actions',
                Cell: ({ cell }) => (
                    <div className={classes.TableActions}>
                        <ReactTooltip effect='solid' />

                        <Link to={`/systemdata-view/${cell.getValue()}`} data-tip='View'>
                            <Badge
                                color='success'
                                size='small'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <circle cx='11' cy='11' r='8'></circle>
                                        <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                                    </svg>
                                }
                            />
                        </Link>
                    </div>
                ),
                size: 40,
            },
        ],
        []
    );

    return (
        <div className={classes.SystemData}>
            <Breadcrumb link1='System' link2='/ System Data' />

            <div className={classes.ContentInner}>
                <Widget>
                    <MyTable data={systemData} columns={columns} />
                </Widget>
            </div>
        </div>
    );
};

export default SystemData;
