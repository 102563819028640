import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import logo_black from '../../assets/images/repolis_logo_black.png';
import logo_white from '../../assets/images/repolis_logo_white.png';
import Navigation from './Navigation';
import NavigationMobile from './NavigationMobile';
import classes from './Topbar.module.css';
import MainButton from '../UI/MainButton/MainButton';

const Sidebar = () => {
    const navigate = useNavigate();

    const isScrolled = useSelector((state) => state.website.isScrolled);
    const isMobileWidth = useSelector((state) => state.website.isMobileWidth);
    const isMobile = useMediaQuery({ query: '(max-width: ' + isMobileWidth + 'px)' });

    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const toggleMenuOpen = () => {
        setMenuIsOpen((prev) => !prev);
    };

    const { pathname } = useLocation();
    let showBlack = false;
    if (pathname !== '/' && pathname !== '/home' && pathname !== '/contact') showBlack = true;

    return (
        <div className={showBlack ? [classes.Topbar, classes.Black].join(' ') : classes.Topbar}>
            <div className={classes.TopbarHeader}>
                <img className={classes.Logo} src={isScrolled || showBlack ? logo_black : logo_white} alt='Repolis logo' onClick={() => navigate('/')} />

                {isMobile ? (
                    <div className={menuIsOpen ? [classes.MobileHeaderWrapper, classes.MenuIsOpen].join(' ') : classes.MobileHeaderWrapper}>
                        <div className={classes.MenuButton} onClick={toggleMenuOpen}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <line x1='3' y1='12' x2='21' y2='12'></line>
                                <line x1='3' y1='6' x2='21' y2='6'></line>
                                <line x1='3' y1='18' x2='21' y2='18'></line>
                            </svg>
                        </div>

                        <div className={classes.Backdrop} onClick={() => setMenuIsOpen(false)}></div>
                        <div className={classes.MobileNav}>
                            <div className={classes.CloseButton} onClick={() => setMenuIsOpen(false)}>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <line x1='18' y1='6' x2='6' y2='18'></line>
                                    <line x1='6' y1='6' x2='18' y2='18'></line>
                                </svg>
                            </div>

                            <h2 className={classes.MenuHeading}>MENU</h2>
                            <NavigationMobile clicked={() => setMenuIsOpen(false)} />
                        </div>
                    </div>
                ) : (
                    <>
                        <Navigation />

                        <div className={classes.PlatformButton}>
                            <MainButton
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <path d='M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z'></path>
                                    </svg>
                                }
                                label='Platform'
                                color='action'
                                auto
                                onClick={() => navigate('/dashboard')}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Sidebar;
