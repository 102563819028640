import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useSnackbar } from 'notistack';
import L from 'leaflet';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SnackbarProvider } from 'notistack';

import Loader from '../UI/Loader/LoaderBar';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import Footer from './Footer';
import Backdrop from './Backdrop';
import classes from './Layout.module.css';
import { layoutActions } from '../../features/Layout/layoutSlice';
import CloseSnackbarAction from '../CloseSnackbarAction/CloseSnackbarAction';

// Fix marker not showing
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Layout = (props) => {
    const dispatch = useDispatch();
    const pageTitle = useSelector((state) => state.layout.pageTitle);
    const loading = useSelector((state) => state.system.loading);
    const systemMessage = useSelector((state) => state.system.systemMessage);
    const menuIsClosed = useSelector((state) => state.layout.menuIsClosed);
    const isMobile = useMediaQuery('(max-width:700px)');
    const snackbarRef = useRef();

    // Theme
    useEffect(() => {
        if (isMobile) dispatch(layoutActions.setMenuClosed());
    }, [dispatch]);

    // Messages
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if (systemMessage) {
            enqueueSnackbar(systemMessage.message, { variant: systemMessage.type });
        }
    }, [systemMessage, enqueueSnackbar]);

    const topBarLoginPages = ['Login', 'Activation', 'Reset password', 'Page404'];

    let contentWrapperClasses = [classes.ContentWrapper];

    if (topBarLoginPages.includes(pageTitle)) {
        contentWrapperClasses.push(classes.NoMargin);
    }

    let layoutClasses = [classes.Layout];
    if (menuIsClosed) layoutClasses.push('MenuIsClosed');
    if (isMobile) layoutClasses.push('IsMobile');
    if (loading) layoutClasses.push(classes.NoOverflow);

    return (
        <SnackbarProvider
            ref={snackbarRef}
            autoHideDuration={3000}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            action={(id) => <CloseSnackbarAction id={id} />}
        >
            <div className={layoutClasses.join(' ')}>
                {topBarLoginPages.includes(pageTitle) || pageTitle === '' ? null : <Sidebar />}
                {topBarLoginPages.includes(pageTitle) || pageTitle === '' ? null : <Backdrop />}

                <div className={contentWrapperClasses.join(' ')}>
                    <Loader show={loading} />
                    {topBarLoginPages.includes(pageTitle) || pageTitle === '' ? null : <Topbar />}

                    <div className={classes.ScrollWrapper} id='layout-content'>
                        <Scrollbars
                            className={classes.Scroller}
                            style={{ width: '100%', height: '100%' }}
                            renderThumbVertical={(props) => <div {...props} className='thumb-vertical' />}
                            renderThumbHorizontal={(props) => <div {...props} className='thumb-horizontal' />}
                        >
                            <div className={classes.Content}>
                                <Outlet />
                            </div>
                            {topBarLoginPages.includes(pageTitle) || pageTitle === '' ? null : <Footer />}
                        </Scrollbars>
                    </div>
                </div>
            </div>
        </SnackbarProvider>
    );
};

export default Layout;
