import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import MainInput from '../../features/UI/MainInput/MainInput';
import MainCheckbox from '../../features/UI/MainCheckbox/MainCheckboxControlled';
import MainButton from '../../features/UI/MainButton/MainButton';

import { loginActions } from './loginSlice';
import classes from './Login.module.css';
import { login } from './loginAsyncActions';

const LoginPart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loginUsername, setLoginUsername] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [rememberMe, toggleRememberMe] = useState(false);

    const loginLoading = useSelector((state) => state.login.loginLoading);

    const rememberMeHandler = () => {
        toggleRememberMe((prevRememberMe) => !prevRememberMe);
    };

    const checkLogin = (e) => {
        e.preventDefault();

        const loginInfo = { username: loginUsername, password: loginPassword, rememberMe: rememberMe };

        dispatch(login(loginInfo, navigate));
    };

    return (
        <div className={classes.Login}>
            <h2>Sign In</h2>
            <span>Enter your username and password to login</span>

            <form>
                <MainInput type='text' label='Username' name='username' fullWidth onChange={(e) => setLoginUsername(e.target.value)} />
                <div className={classes.VerticalSeparator}></div>

                <MainInput type='password' label='Password' name='password' fullWidth onChange={(e) => setLoginPassword(e.target.value)} />

                <div className={classes.Remember}>
                    <MainCheckbox label='Remember me' name='remember' checked={rememberMe} onChange={rememberMeHandler} />
                    <span className={classes.ForgotPassword} onClick={() => dispatch(loginActions.setShowing('forgot'))}>
                        Forgot password ?
                    </span>
                </div>

                <div className={classes.ButtonWrapper}>
                    <MainButton
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <path d='M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4'></path>
                                <polyline points='10 17 15 12 10 7'></polyline>
                                <line x1='15' y1='12' x2='3' y2='12'></line>
                            </svg>
                        }
                        label='SIGN IN'
                        color='action'
                        fullWidth
                        onClick={checkLogin}
                        loading={loginLoading}
                    />
                </div>

                <div className={classes.NoAccount}>
                    <span>Don't have an account ?</span>
                    <span className={classes.CreateAccount} onClick={() => dispatch(loginActions.setShowing('register'))}>
                        Sign Up
                    </span>
                </div>
            </form>
        </div>
    );
};

export default LoginPart;
