import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { MapContainer, FeatureGroup, GeoJSON, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStreetView } from '@fortawesome/free-solid-svg-icons';

import BuildingUsesSelection from '../../features/BuildingUsesSelection/BuildingUsesSelection';
import Modal from '../../features/UI/Modal/Modal';
import ModalConfirm from '../../features/UI/Modal/ModalConfirm';
import Badge from '../../features/UI/Badge/Badge';
import MainButton from '../../features/UI/MainButton/MainButton';
import MainInput from '../../features/UI/MainInput/MainInput';
import MainTextarea from '../../features/UI/MainTextarea/MainTextarea';
import MainSwitch from '../../features/UI/MainSwitch/MainSwitch';
import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import Widget from '../../features/Widget/Widget';
import MyTableServer from '../../features/UI/MyTable/MyTableServer';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { myDataActions } from './myDataSlice';
import { getBuildings, getEditData, updateDataInfo, updateBuilding, deleteBuilding } from './MyDataAsyncActions';
import classes from './MyDataEdit.module.css';
import { showMessage } from '../../utils/messageHandler';

const MyDataEdit = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [filtersVisible, setFiltersVisible] = useState(false);

    const toggleFilters = () => {
        setFiltersVisible((prevState) => !prevState);
    };
    const closeFilters = () => {
        setFiltersVisible(false);
    };

    const tileLayer = useSelector((state) => state.system.tileLayer);
    const tileLayerAttribution = useSelector((state) => state.system.tileLayerAttribution);
    const editBuildings = useSelector((state) => state.myData.editBuildings);
    const editBuildingsCount = useSelector((state) => state.myData.editBuildingsCount);
    const buildingUsesForCountry = useSelector((state) => state.myData.buildingUsesForCountry);
    const editDataName = useSelector((state) => state.myData.editDataName);
    const editDataCity = useSelector((state) => state.myData.editDataCity);
    const editDataDescription = useSelector((state) => state.myData.editDataDescription);

    const [formValidated, setFormValidated] = useState(false);
    const [nameValidated, setNameValidated] = useState(false);
    const [showModalSave, setShowModalSave] = useState(false);
    const [deleteBuildingId, setDeleteBuildingId] = useState(null);
    const [issuesChecked, setIssuesChecked] = useState({
        noIssue: true,
        heightLessThan3: true,
        noHeight: true,
        noUse: true,
        noYear: true,
    });
    const [tablePagination, setTablePagination] = useState(null);
    const [editingBuilding, setEditingBuilding] = useState(null);
    const [firstFloor, setFirstFloor] = useState(null);
    const [restFloors, setRestFloors] = useState(null);

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('My data edit'));
        dispatch(getEditData(id, navigate));

        return () => {
            clearEditingBuilding();
            dispatch(myDataActions.resetEditData());
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch, id]);

    const clearEditingBuilding = () => {
        setEditingBuilding(null);
        setFirstFloor(null);
        setRestFloors(null);
        setFormValidated(false);
    };

    useEffect(() => {
        if (editingBuilding && buildingUsesForCountry) {
            const editBuildingBTypeIds = editingBuilding.country_building_type_ids;

            // If there is a rest floor
            if (editBuildingBTypeIds?.includes('_')) {
                const ffId = editBuildingBTypeIds.split('_')[0];
                const ffFound = buildingUsesForCountry.find((b) => b.value === ffId);
                if (ffFound) setFirstFloor(ffFound);
                else setFirstFloor(null);

                const rfId = editBuildingBTypeIds.split('_')[1];
                const rfFound = buildingUsesForCountry.find((b) => b.value === rfId);
                if (rfFound) setRestFloors(rfFound);
                else setRestFloors(null);
            } else {
                const found = buildingUsesForCountry.find((b) => b.value === editingBuilding.country_building_type_ids);
                if (found) setFirstFloor(found);
                else setFirstFloor(null);
            }
        }
    }, [editingBuilding]);

    const getBounds = (cell) => {
        const coords = cell.row.original.envelope.coordinates[0];
        const bounds = [
            [coords[0][1], coords[0][0]],
            [coords[2][1], coords[2][0]],
        ];

        return bounds;
    };

    const updateIssuesChecked = (property) => {
        let updateIssues = { ...issuesChecked };
        updateIssues[property] = !updateIssues[property];
        setIssuesChecked(updateIssues);
    };

    const changedDataHandler = (property, value) => {
        let updateData = { ...editingBuilding };

        if (property === 'height' && value < 0) value = 0;

        updateData[property] = value;

        setEditingBuilding(updateData);
    };

    const changedBuildingTypeHandler = (value, floor) => {
        if (floor === 'first') {
            setFirstFloor(value);
        } else {
            setRestFloors(value);
        }
    };

    const saveDataInfo = () => {
        setNameValidated(true);

        if (editDataName?.trim() === '') {
            showMessage('error', 'Please fill in all required fields');
        } else {
            setShowModalSave(true);
        }
    };

    const openStreetView = (cellValues) => {
        let url = 'http://maps.google.com/maps?q=&layer=c&cbll=';
        url = url + cellValues.centroid_y + ',' + cellValues.centroid_x;

        window.open(url, '_blank');
    };

    const columns = [
        {
            accessorKey: 'construction_year',
            header: 'Construction year',
        },
        {
            // accessorKey: 'height',
            // header: 'Height (m)',
            accessorKey: 'height',
            header: 'Height (m)',
            Cell: ({ cell }) => (cell.getValue() ? Math.round(cell.getValue() * 100) / 100 : cell.getValue()),
        },
        {
            accessorKey: 'country_building_type',
            header: 'Building type',
        },
        {
            accessorKey: 'original_building_type',
            header: 'Imported building type',
        },
        {
            accessorFn: (row) => row.geom,
            id: 'geom',
            header: 'Polygon',
            Cell: ({ cell }) => {
                return (
                    <div className={classes.TableMapContainer}>
                        <MapContainer
                            key={JSON.stringify(cell.row.original.envelope.coordinates[0])}
                            boundsOptions={{ padding: [10, 10] }}
                            bounds={getBounds(cell)}
                            scrollWheelZoom={false}
                            zoomControl={false}
                            dragging={false}
                        >
                            <TileLayer attribution={tileLayerAttribution} url={tileLayer} />

                            <FeatureGroup>
                                <GeoJSON data={cell.getValue()} style={{ color: 'red' }} />
                            </FeatureGroup>
                        </MapContainer>
                    </div>
                );
            },
        },
        {
            accessorFn: (row) => row.id,
            id: 'id',
            header: 'Actions',
            Cell: ({ cell }) => (
                <div className={classes.TableActions}>
                    <ReactTooltip effect='solid' />

                    <div className={classes.TableAction} data-tip='Google street view' onClick={() => openStreetView(cell.row.original)}>
                        <Badge color='info' size='small' icon={<FontAwesomeIcon icon={faStreetView} />} />
                    </div>

                    <div className={classes.TableAction} data-tip='Edit' onClick={() => setEditingBuilding(cell.row.original)}>
                        <Badge
                            color='warning'
                            size='small'
                            icon={
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <path d='M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z'></path>
                                </svg>
                            }
                        />
                    </div>

                    <div className={classes.TableAction} data-tip='Delete' onClick={() => setDeleteBuildingId(cell.getValue())}>
                        <Badge
                            color='danger'
                            size='small'
                            icon={
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <polyline points='3 6 5 6 21 6'></polyline>
                                    <path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'></path>
                                    <line x1='10' y1='11' x2='10' y2='17'></line>
                                    <line x1='14' y1='11' x2='14' y2='17'></line>
                                </svg>
                            }
                        />
                    </div>
                </div>
            ),
            size: 40,
        },
    ];

    const onSaveNewDataset = () => {
        setFormValidated(true);

        if (editingBuilding?.construction_year?.trim() === '' || editingBuilding?.height?.trim() === '' || firstFloor === null) {
            showMessage('error', 'Please fill in all required fields');
        } else {
            // Update editing building with the first floor and rest floors
            let update = { ...editingBuilding };
            update.country_building_type = firstFloor.label;
            update.country_building_type_ids = firstFloor.value;

            if (restFloors) {
                update.country_building_type = firstFloor.label + '_' + restFloors.label;
                update.country_building_type_ids = firstFloor.value + '_' + restFloors.value;
            }

            dispatch(updateBuilding(update, tablePagination, id, issuesChecked));
            clearEditingBuilding();
        }
    };

    // Click outside
    const useOutsideClick = (callback) => {
        const ref = useRef();

        useEffect(() => {
            const handleClick = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    callback();
                }
            };

            document.addEventListener('click', handleClick);

            return () => {
                document.removeEventListener('click', handleClick);
            };
        }, [ref]);

        return ref;
    };

    const ref = useOutsideClick(closeFilters);

    return (
        <div className={classes.MyDataEdit}>
            <Breadcrumb link1='User' link2='/ My Data' link3={`/ Edit My Data (${editDataName})`} />

            <ModalConfirm
                type='save'
                title='Are you sure?'
                text='You will update the dataset.'
                show={showModalSave}
                onClose={() => setShowModalSave(false)}
                onAction={() => {
                    dispatch(updateDataInfo({ id: id, name: editDataName, city: editDataCity, description: editDataDescription }));
                    setShowModalSave(false);
                }}
            />

            <ModalConfirm
                type='delete'
                title='Are you sure?'
                text='You will delete the building.'
                show={deleteBuildingId}
                onClose={() => setDeleteBuildingId(null)}
                onAction={() => {
                    dispatch(deleteBuilding(deleteBuildingId, tablePagination, id, issuesChecked));
                    setDeleteBuildingId(null);
                }}
            />

            <Modal
                title='Edit building'
                size='small'
                show={editingBuilding}
                onClose={() => clearEditingBuilding()}
                buttons={
                    <MainButton
                        label='Save'
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                                <polyline points='17 21 17 13 7 13 7 21'></polyline>
                                <polyline points='7 3 7 8 15 8'></polyline>
                            </svg>
                        }
                        color='action'
                        onClick={() => onSaveNewDataset()}
                    />
                }
            >
                <form>
                    <div className={classes.FormRowWide}>
                        <label>Construction year *</label>
                        <MainInput
                            type='number'
                            fullWidth
                            value={editingBuilding ? editingBuilding.construction_year : ''}
                            onChange={(e) => changedDataHandler('construction_year', e.target.value)}
                            validated={formValidated}
                            isValid={editingBuilding?.construction_year?.trim() !== ''}
                        />
                    </div>
                    <div className={classes.FormRowWide}>
                        <label>Height *</label>
                        <MainInput
                            type='number'
                            fullWidth
                            value={editingBuilding ? editingBuilding.height : ''}
                            onChange={(e) => changedDataHandler('height', e.target.value)}
                            validated={formValidated}
                            isValid={editingBuilding?.height?.trim() !== ''}
                        />
                    </div>

                    {editingBuilding && buildingUsesForCountry ? (
                        <div className={classes.FormRowWide}>
                            <label>Building type *</label>
                            <BuildingUsesSelection
                                options={buildingUsesForCountry}
                                firstFloor={firstFloor}
                                restFloors={restFloors}
                                onChange={(value, floor) => changedBuildingTypeHandler(value, floor)}
                                showPerFloor={restFloors ? true : false}
                                validated={formValidated}
                                isValid={firstFloor ? true : false}
                            />
                        </div>
                    ) : null}
                </form>
            </Modal>

            <div className={classes.ContentInner}>
                <div className={classes.Widgets}>
                    <Widget>
                        <div className={classes.TitleRow}>
                            <div className={classes.TitleRowCol1}>
                                <h2>Edit Dataset</h2>
                                <span>You can change the name, description and city of the dataset</span>
                            </div>

                            <div className={classes.TitleRowCol2}>
                                <MainButton
                                    icon={
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        >
                                            <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                                            <polyline points='17 21 17 13 7 13 7 21'></polyline>
                                            <polyline points='7 3 7 8 15 8'></polyline>
                                        </svg>
                                    }
                                    type='circle'
                                    color='action'
                                    onClick={() => {
                                        saveDataInfo();
                                    }}
                                    // loading={loginLoading}
                                />
                            </div>
                        </div>

                        <div className={classes.WidgetContent}>
                            <form>
                                <div className={classes.FormRow}>
                                    <label>Name *</label>
                                    <MainInput
                                        type='text'
                                        fullWidth
                                        value={editDataName}
                                        onChange={(e) => dispatch(myDataActions.setEditDataName(e.target.value))}
                                        validated={nameValidated}
                                        isValid={editDataName.trim() !== ''}
                                    />
                                </div>

                                <div className={classes.FormRow}>
                                    <label>City</label>
                                    <MainInput
                                        type='text'
                                        fullWidth
                                        value={editDataCity}
                                        onChange={(e) => dispatch(myDataActions.setEditDataCity(e.target.value))}
                                    />
                                </div>

                                <div className={classes.FormRow}>
                                    <label>Description</label>
                                    <MainTextarea
                                        type='text'
                                        fullWidth
                                        value={editDataDescription}
                                        rows={3}
                                        onChange={(e) => dispatch(myDataActions.setEditDataDescription(e.target.value))}
                                    />
                                </div>
                            </form>
                        </div>
                    </Widget>

                    <Widget>
                        <div className={classes.TitleRowWithSubtitle}>
                            <h2>Dataset buildings</h2>
                            <span>You can update / delete individual buildings</span>
                        </div>

                        <div className={classes.WidgetContent}>
                            <MyTableServer
                                columns={columns}
                                data={editBuildings}
                                rowCount={editBuildingsCount}
                                onUpdateData={(pagination) => {
                                    setTablePagination(pagination);
                                    dispatch(getBuildings(pagination, id, issuesChecked));
                                }}
                                customFilters={issuesChecked}
                                buttons={
                                    <div className={classes.FilterWrapper}>
                                        <div ref={ref}>
                                            <div className={classes.Filter} onClick={toggleFilters}>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='24'
                                                    height='24'
                                                    viewBox='0 0 24 24'
                                                    fill='none'
                                                    stroke='currentColor'
                                                    strokeWidth='2'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                >
                                                    <circle cx='12' cy='12' r='1'></circle>
                                                    <circle cx='12' cy='5' r='1'></circle>
                                                    <circle cx='12' cy='19' r='1'></circle>
                                                </svg>
                                                <span>Filters</span>
                                            </div>
                                            <div className={filtersVisible ? [classes.FilterContent, classes.Show].join(' ') : classes.FilterContent}>
                                                <h4>Filter visible items</h4>
                                                <MainSwitch
                                                    name='No issue'
                                                    label='No issue'
                                                    checked={issuesChecked.noIssue}
                                                    onChange={() => updateIssuesChecked('noIssue')}
                                                />
                                                <MainSwitch
                                                    name='Height less than 3m'
                                                    label='Height less than 3m'
                                                    checked={issuesChecked.heightLessThan3}
                                                    onChange={() => updateIssuesChecked('heightLessThan3')}
                                                />
                                                <MainSwitch
                                                    name='No height'
                                                    label='No height'
                                                    checked={issuesChecked.noHeight}
                                                    onChange={() => updateIssuesChecked('noHeight')}
                                                />
                                                <MainSwitch
                                                    name='No building use'
                                                    label='No building use'
                                                    checked={issuesChecked.noUse}
                                                    onChange={() => updateIssuesChecked('noUse')}
                                                />
                                                <MainSwitch
                                                    name='No year'
                                                    label='No year'
                                                    checked={issuesChecked.noYear}
                                                    onChange={() => updateIssuesChecked('noYear')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    </Widget>
                </div>
            </div>
        </div>
    );
};

export default MyDataEdit;
