export function getAccessToken() {
    const accessToken = localStorage.getItem('accessToken');
    return accessToken;
}

export function getRefreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    return refreshToken;
}

export function setTokens(accessToken, refreshToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
}

export function setAccessToken(accessToken) {
    localStorage.setItem('accessToken', accessToken);
}

export function removeTokens() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
}
