import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MainInput from '../../features/UI/MainInput/MainInput';
import MainCheckbox from '../../features/UI/MainCheckbox/MainCheckboxControlled';
import MainButton from '../../features/UI/MainButton/MainButton';
import { loginActions } from './loginSlice';
import { register } from './loginAsyncActions';
import classes from './Login.module.css';
import { showMessage } from '../../utils/messageHandler';

const RegisterPart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [registerUsername, setRegisterUsername] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [registerPasswordConfirm, setRegisterPasswordConfirm] = useState('');
    const [readTerms, setReadTerms] = useState(false);

    const loginLoading = useSelector((state) => state.login.loginLoading);
    const registerSuccess = useSelector((state) => state.login.registerSuccess);

    const toggleReadTerms = () => {
        setReadTerms((prevReadTerms) => !prevReadTerms);
    };

    const registerHandler = (e) => {
        e.preventDefault();
        const isComplete = checkRegister();

        if (isComplete) {
            dispatch(register({ username: registerUsername, password: registerPassword, email: registerEmail }));
        }
    };

    const checkRegister = () => {
        let isComplete = true;
        let error = '';
        if (registerUsername.trim() === '' || registerEmail.trim() === '' || registerPassword.trim() === '' || registerPasswordConfirm.trim() === '')
            error = 'Please complete all fields';
        else if (registerEmail.includes('@') === false) error = 'Please provide a valid email';
        else if (registerPassword.trim() !== registerPasswordConfirm.trim()) error = 'The password confirm does not match with password';
        else if (readTerms === false) error = 'Please accept the terms and conditions';

        if (error) {
            isComplete = false;
            showMessage('error', error);
        }

        return isComplete;
    };

    const resetState = useCallback(() => {
        setRegisterUsername('');
        setRegisterEmail('');
        setRegisterPassword('');
        setRegisterPasswordConfirm('');
        setReadTerms(false);
    }, []);

    useEffect(() => {
        if (registerSuccess) {
            resetState();
            dispatch(loginActions.setShowing('login'));
            dispatch(loginActions.setRegisterSuccess(false));
        }
    }, [registerSuccess, dispatch, resetState]);

    return (
        <div className={classes.Login}>
            <h2>Create an account</h2>
            <span>Enter your email and password to register</span>

            <form autoComplete='new-password'>
                <MainInput type='text' label='Username *' name='username' fullWidth onChange={(e) => setRegisterUsername(e.target.value)} autoComplete='off' />
                <div className={classes.VerticalSeparator}></div>

                <MainInput type='email' label='Email *' name='email' fullWidth onChange={(e) => setRegisterEmail(e.target.value)} autoComplete='off' />
                <div className={classes.VerticalSeparator}></div>

                <MainInput type='password' label='Password *' name='password' fullWidth onChange={(e) => setRegisterPassword(e.target.value)} />
                <div className={classes.VerticalSeparator}></div>

                <MainInput
                    type='password'
                    label='Password confirm *'
                    name='confirm_password'
                    fullWidth
                    onChange={(e) => setRegisterPasswordConfirm(e.target.value)}
                    autoComplete='off'
                />

                <div className={classes.Remember}>
                    <MainCheckbox label='I agree with the' name='agree_terms' checked={readTerms} onChange={toggleReadTerms} />
                    <span className={classes.TermsLink} onClick={() => navigate('/terms')}>
                        Terms and Conditions
                    </span>
                </div>

                <div className={classes.ButtonWrapper}>
                    <MainButton
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <path d='M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4'></path>
                                <polyline points='10 17 15 12 10 7'></polyline>
                                <line x1='15' y1='12' x2='3' y2='12'></line>
                            </svg>
                        }
                        label='REGISTER'
                        color='action'
                        fullWidth
                        onClick={registerHandler}
                        loading={loginLoading}
                    />
                </div>

                <div className={classes.NoAccount}>
                    <span>Already have an account?</span>
                    <span className={classes.CreateAccount} onClick={() => dispatch(loginActions.setShowing('login'))}>
                        Sign In
                    </span>
                </div>
            </form>
        </div>
    );
};

export default RegisterPart;
