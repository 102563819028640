import { Link } from 'react-router-dom';

import Badge from '../Badge/Badge';
import classes from './SimpleTable.module.css';

const SimpleTable = (props) => {
    return (
        <table className={classes.SimpleTable}>
            <thead className={classes.TableHead}>
                <tr>
                    {props.columns.map((column, index) => {
                        if (!column.hidden) {
                            return <th key={`col_${index}`}>{column.label}</th>;
                        }
                    })}
                    <th className={classes.Th}>View</th>
                </tr>
            </thead>

            <tbody className={classes.TableBody}>
                {props.data.map((row, rowIndex) => {
                    return (
                        <tr key={`row_${rowIndex}`}>
                            {props.columns.map((column, colIndex) => {
                                if (!column.hidden) {
                                    return <td key={`rowcol_${colIndex}`}>{row[column.property]}</td>;
                                }
                            })}

                            <td>
                                <Link to={`/${props.action.view}/${row[props.action.property]}`} data-tip='View'>
                                    <Badge
                                        color='success'
                                        size='small'
                                        icon={
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <circle cx='11' cy='11' r='8'></circle>
                                                <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                                            </svg>
                                        }
                                    />
                                </Link>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default SimpleTable;
