import classes from './Wavebox.module.css';

const Wavebox = (props) => {
    return (
        <div className={classes.Wavebox2}>
            <div className={classes.Wave2}></div>
            <div className={classes.ChildrenWrapper}>{props.children}</div>
        </div>
    );
};

export default Wavebox;
