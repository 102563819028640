import axiosApi from '../../axios-api';

import { dropzoneActions } from './dropzoneSlice';

export const deleteUpload = (uploadsDir) => {
    return (dispatch) => {
        // Delete .part files of the user from the server
        axiosApi
            .post('uploadCancel.php', uploadsDir)
            .then((response) => {})
            .catch((error) => {
                dispatch(dropzoneActions.setUploadStatus('generalError'));
            });
    };
};
