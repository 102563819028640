import React, { useState, useEffect } from 'react';

import ColumnChart from '../../../features/Charts/ColumnChart';
import classes from './AllCharts.module.css';

const AllChartsEmissions = (props) => {
    const [buildingValues, setBuildingValues] = useState(null);

    useEffect(() => {
        const selectedFeature = props.selectedFeature.feature;
        if (!selectedFeature) return;

        const data = JSON.parse(selectedFeature.getProperty('building_data'));
        if (!data) return;

        // Building metrics
        const building_metric = data.building_metrics.building_metric;

        const end_uses_co2_cooling = building_metric?.end_uses_co2_cooling;
        const end_uses_co2_heating = building_metric?.end_uses_co2_heating;
        const end_uses_co2_interior_equipment = building_metric?.end_uses_co2_interior_equipment;
        const end_uses_co2_interior_lighting = building_metric?.end_uses_co2_interior_lighting;

        // Monthly building metrics
        const building_metric_monthly = data.building_metrics.building_monthly_metric;

        const found_co2_heating_monthly = building_metric_monthly.find((x) => x.metric_type_id === 15);
        const found_co2_cooling_monthly = building_metric_monthly.find((x) => x.metric_type_id === 16);
        const found_co2_lighting_monthly = building_metric_monthly.find((x) => x.metric_type_id === 14);
        const found_co2_equipment_monthly = building_metric_monthly.find((x) => x.metric_type_id === 13);
        // Get only the value in an array
        const co2_heating_monthly = found_co2_heating_monthly ? found_co2_heating_monthly.building_monthly_metric.map((e) => e.value) : null;
        const co2_cooling_monthly = found_co2_cooling_monthly ? found_co2_cooling_monthly.building_monthly_metric.map((e) => e.value) : null;
        const co2_lighting_monthly = found_co2_lighting_monthly ? found_co2_lighting_monthly.building_monthly_metric.map((e) => e.value) : null;
        const co2_equipment_monthly = found_co2_equipment_monthly ? found_co2_equipment_monthly.building_monthly_metric.map((e) => e.value) : null;

        const co2_monthly_data = [
            {
                name: 'Heating',
                data: co2_heating_monthly,
                color: '#ab0d0d',
            },
            {
                name: 'Cooling',
                data: co2_cooling_monthly,
                color: '#4671c7',
            },
            {
                name: 'Lighting',
                data: co2_lighting_monthly,
                color: '#ffdc40',
            },
            {
                name: 'Equipment',
                data: co2_equipment_monthly,
                color: '#4ba03e',
            },
        ];

        // Usage metrics
        const usage_metrics = data?.building_usage_metrics;

        const co2_monthly_data_usages = [];
        for (let i = 0; i < usage_metrics.length; i++) {
            const building_usage = usage_metrics[i];
            const usage_name = building_usage.building_usage_metric.usage_name;
            const building_usage_monthly_metric = building_usage.building_usage_monthly_metric;

            const found_co2_heating_usage_monthly = building_usage_monthly_metric.find((x) => x.metric_type_id === 15);
            const found_co2_cooling_usage_monthly = building_usage_monthly_metric.find((x) => x.metric_type_id === 16);
            const found_co2_lighting_usage_monthly = building_usage_monthly_metric.find((x) => x.metric_type_id === 14);
            const found_co2_equipment_usage_monthly = building_usage_monthly_metric.find((x) => x.metric_type_id === 13);
            // Get only the value in an array
            const co2_heating_usage_monthly = found_co2_heating_usage_monthly
                ? found_co2_heating_usage_monthly.building_usage_monthly_metric.map((e) => e.value)
                : null;
            const co2_cooling_usage_monthly = found_co2_cooling_usage_monthly
                ? found_co2_cooling_usage_monthly.building_usage_monthly_metric.map((e) => e.value)
                : null;
            const co2_lighting_usage_monthly = found_co2_lighting_usage_monthly
                ? found_co2_lighting_usage_monthly.building_usage_monthly_metric.map((e) => e.value)
                : null;
            const co2_equipment_usage_monthly = found_co2_equipment_usage_monthly
                ? found_co2_equipment_usage_monthly.building_usage_monthly_metric.map((e) => e.value)
                : null;

            const co2_monthly_data_usage = {
                name: usage_name,
                data: [
                    {
                        name: 'Heating',
                        data: co2_heating_usage_monthly,
                        color: '#ab0d0d',
                    },
                    {
                        name: 'Cooling',
                        data: co2_cooling_usage_monthly,
                        color: '#4671c7',
                    },
                    {
                        name: 'Lighting',
                        data: co2_lighting_usage_monthly,
                        color: '#ffdc40',
                    },
                    {
                        name: 'Equipment',
                        data: co2_equipment_usage_monthly,
                        color: '#4ba03e',
                    },
                ],
            };

            co2_monthly_data_usages.push(co2_monthly_data_usage);
        }

        const values = {
            end_uses_co2_cooling,
            end_uses_co2_heating,
            end_uses_co2_interior_equipment,
            end_uses_co2_interior_lighting,
            co2_monthly_data,
            co2_monthly_data_usages,
        };

        setBuildingValues(values);
    }, [props?.selectedFeature?.feature]);

    return (
        <div className={classes.Charts}>
            <div className={[classes.Chart, classes.TwoColumns].join(' ')}>
                <h3>
                    Co<sub>2</sub> emissions per end use
                </h3>
                {buildingValues && (
                    <ColumnChart
                        title={null}
                        alignLegend='center'
                        categories={['Heating', 'Cooling', 'Interior lighting', 'Interior equipment']}
                        yAxisTitle='kgCO2eq'
                        series={[
                            {
                                name: 'Emissions',
                                data: [
                                    buildingValues.end_uses_co2_heating,
                                    buildingValues.end_uses_co2_cooling,
                                    buildingValues.end_uses_co2_interior_lighting,
                                    buildingValues.end_uses_co2_interior_equipment,
                                ],
                            },
                        ]}
                    />
                )}
            </div>

            <div className={[classes.Chart, classes.TwoColumns].join(' ')}>
                <h3>
                    Montlhy Emissions Co<sub>2</sub>
                </h3>
                {buildingValues && (
                    <ColumnChart
                        title={null}
                        isStacked
                        alignLegend='center'
                        yAxisTitle='kgCO2eq'
                        categories={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                        series={buildingValues.co2_monthly_data}
                    />
                )}
            </div>

            {buildingValues &&
                buildingValues.co2_monthly_data_usages.map((co2_monthly_data_usage, index) => {
                    return (
                        <div key={index} className={[classes.Chart, classes.TwoColumns].join(' ')}>
                            <h3>
                                Montlhy Emissions CO<sub>2</sub> - {co2_monthly_data_usage.name}
                            </h3>

                            <ColumnChart
                                title={null}
                                isStacked
                                alignLegend='center'
                                categories={[
                                    'January',
                                    'February',
                                    'March',
                                    'April',
                                    'May',
                                    'June',
                                    'July',
                                    'August',
                                    'September',
                                    'October',
                                    'November',
                                    'December',
                                ]}
                                series={co2_monthly_data_usage.data}
                                yAxisTitle='kgCO2eq'
                            />
                        </div>
                    );
                })}
        </div>
    );
};

export default AllChartsEmissions;
