import axiosApi from '../../axios-api';

import { systemActions } from '../../features/systemSlice';
import { loginActions } from '../Login/loginSlice';
import { userProfileActions } from './userProfileSlice';
import { showError, showMessage } from '../../utils/messageHandler';

export const getUserInfo = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .get('getUserInfo.php')
            .then((response) => {
                dispatch(userProfileActions.setUserInfo(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const updateUserPassword = (activePassword, newPassword, navigate) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('updateUserPassword.php', { activePassword: activePassword, newPassword: newPassword })
            .then((response) => {
                showMessage('info', 'Login with your new credentials');
                dispatch(loginActions.logout());
                navigate('/login');
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const updateUser = (payload) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('updateUser.php', payload)
            .then((response) => {
                dispatch(getUserInfo());
                showMessage('success', 'Your profile has been updated');
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};
