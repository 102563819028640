import { NavLink } from 'react-router-dom';

import classes from './NavigationMobile.module.css';

const NavigationMobile = (props) => {
    return (
        <ul className={classes.Navigation}>
            <NavLink to='/' className={({ isActive }) => (isActive ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink)} onClick={props.clicked}>
                <span className={classes.ItemTitle}>Home</span>
            </NavLink>

            <NavLink
                to='/about'
                className={({ isActive }) => (isActive ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink)}
                onClick={props.clicked}
            >
                <span className={classes.ItemTitle}>About</span>
            </NavLink>

            <NavLink
                to='repolis'
                className={({ isActive }) => (isActive ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink)}
                onClick={props.clicked}
            >
                <span className={classes.ItemTitle}>rePolis</span>
            </NavLink>

            <NavLink
                to='clients'
                className={({ isActive }) => (isActive ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink)}
                onClick={props.clicked}
            >
                <span className={classes.ItemTitle}>Clients</span>
            </NavLink>

            <NavLink
                to='pricing'
                className={({ isActive }) => (isActive ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink)}
                onClick={props.clicked}
            >
                <span className={classes.ItemTitle}>Pricing</span>
            </NavLink>

            <NavLink
                to='contact'
                className={({ isActive }) => (isActive ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink)}
                onClick={props.clicked}
            >
                <span className={classes.ItemTitle}>Contact</span>
            </NavLink>

            <NavLink
                to='dashboard'
                className={({ isActive }) => (isActive ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink)}
                onClick={props.clicked}
            >
                <div className={classes.Flexed}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <path d='M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z'></path>
                    </svg>
                    <span className={classes.ItemTitle}>Platform</span>
                </div>
            </NavLink>
        </ul>
    );
};

export default NavigationMobile;
