import classes from './CheckoutForm.module.css';
import SimpleHeader from '../../features/SimpleHeader/SimpleHeader';
import MainButton from '../../features/UI/MainButton/MainButton';
import { useNavigate } from 'react-router-dom';

const CancelCheckout = () => {
    const navigate = useNavigate();

    return (
        <div>
            <SimpleHeader title='Order canceled' />

            <div className={classes.WithMarginsWrapper}>
                <div className={classes.WithMargins}>
                    <div className={classes.CancelCheckout}>
                        <p>You canceled your order!</p>
                        <MainButton label='Select another plan' color='accent' onClick={() => navigate('/pricing')} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CancelCheckout;
