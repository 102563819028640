import React from 'react';

import classes from './Loader2.module.css';

const Loader2 = (props) => {
    let loaderClasses = [classes.LoaderWrapper];
    if (props.show) {
        loaderClasses.push(classes.Show);
    }

    return (
        <div className={loaderClasses.join(' ')}>
            <div className={classes.Loader}>
                <div className={classes.SvgWrapper}>
                    <svg width='68' height='66' viewBox='0 0 68 66' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <g id='art'>
                            <mask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='68' height='66'>
                                <rect id='mask' width='68' height='66' fill='#C4C4C4' />
                            </mask>
                            <g mask='url(#mask0)'>
                                <g>
                                    <rect x='2' y='2' width='64' height='64' />
                                    <g className={classes.cloud_left}>
                                        <path
                                            id='cloud_left_2'
                                            d='M8 30C8 30.0422 7.99951 30.0844 7.99866 30.1264C8.31873 30.0439 8.65417 30 9 30C11.2091 30 13 31.7909 13 34C13 36.2091 11.2091 38 9 38H-4.5C-6.98523 38 -9 35.9853 -9 33.5C-9 31.0147 -6.98523 29 -4.5 29C-4.30457 29 -4.11194 29.0125 -3.9231 29.0367C-3.46216 26.1809 -0.98584 24 2 24C5.31372 24 8 26.6863 8 30Z'
                                            fill='#F1FAEE'
                                        />
                                    </g>
                                    <g className={classes.cloud_right}>
                                        <path
                                            d='M77 10C77 10.0422 76.9995 10.0844 76.9987 10.1264C77.3187 10.0439 77.6542 10 78 10C80.2091 10 82 11.7909 82 14C82 16.2091 80.2091 18 78 18H64.5C62.0148 18 60 15.9853 60 13.5C60 11.0147 62.0148 9 64.5 9C64.6954 9 64.8881 9.01248 65.0769 9.03665C65.5378 6.18091 68.0142 4 71 4C74.3137 4 77 6.68628 77 10Z'
                                            fill='#F1FAEE'
                                        />
                                    </g>
                                    <g className={classes.building_top}>
                                        <path d='M16 17H52V47H16V17Z' fill='#c9e2e7' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M15 17C15 16.4477 15.4477 16 16 16H52C52.5523 16 53 16.4477 53 17V47C53 47.5523 52.5523 48 52 48H16C15.4477 48 15 47.5523 15 47V17ZM17 18V46H51V18H17Z'
                                            fill='#1D3557'
                                        />
                                        <path d='M14 13H54V17H14V13Z' fill='#0e80ac' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M13 13C13 12.4477 13.4477 12 14 12H54C54.5523 12 55 12.4477 55 13V17C55 17.5523 54.5523 18 54 18H14C13.4477 18 13 17.5523 13 17V13ZM15 14V16H53V14H15Z'
                                            fill='#1D3557'
                                        />
                                    </g>
                                    <g className={classes.balcony_01}>
                                        <path d='M20 21H26V27H20V21Z' fill='#F1FAEE' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M19 21C19 20.4477 19.4477 20 20 20H26C26.5523 20 27 20.4477 27 21V27C27 27.5523 26.5523 28 26 28H20C19.4477 28 19 27.5523 19 27V21ZM21 22V26H25V22H21Z'
                                            fill='#1D3557'
                                        />
                                        <path d='M19 27H27V30H19V27Z' fill='#557981' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M18 27C18 26.4477 18.4477 26 19 26H27C27.5523 26 28 26.4477 28 27V30C28 30.5523 27.5523 31 27 31H19C18.4477 31 18 30.5523 18 30V27ZM20 28V29H26V28H20Z'
                                            fill='#1D3557'
                                        />
                                    </g>
                                    <g className={classes.balcony_04}>
                                        <path d='M20 34H26V40H20V34Z' fill='#F1FAEE' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M19 34C19 33.4477 19.4477 33 20 33H26C26.5523 33 27 33.4477 27 34V40C27 40.5523 26.5523 41 26 41H20C19.4477 41 19 40.5523 19 40V34ZM21 35V39H25V35H21Z'
                                            fill='#1D3557'
                                        />
                                        <path d='M19 40H27V43H19V40Z' fill='#557981' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M18 40C18 39.4477 18.4477 39 19 39H27C27.5523 39 28 39.4477 28 40V43C28 43.5523 27.5523 44 27 44H19C18.4477 44 18 43.5523 18 43V40ZM20 41V42H26V41H20Z'
                                            fill='#1D3557'
                                        />
                                    </g>
                                    <g className={classes.balcony_02}>
                                        <path d='M31 21H37V27H31V21Z' fill='#F1FAEE' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M30 21C30 20.4477 30.4477 20 31 20H37C37.5523 20 38 20.4477 38 21V27C38 27.5523 37.5523 28 37 28H31C30.4477 28 30 27.5523 30 27V21ZM32 22V26H36V22H32Z'
                                            fill='#1D3557'
                                        />
                                        <path d='M30 27H38V30H30V27Z' fill='#557981' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M29 27C29 26.4477 29.4477 26 30 26H38C38.5523 26 39 26.4477 39 27V30C39 30.5523 38.5523 31 38 31H30C29.4477 31 29 30.5523 29 30V27ZM31 28V29H37V28H31Z'
                                            fill='#1D3557'
                                        />
                                    </g>
                                    <g className={classes.balcony_05}>
                                        <path d='M31 34H37V40H31V34Z' fill='#F1FAEE' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M30 34C30 33.4477 30.4477 33 31 33H37C37.5523 33 38 33.4477 38 34V40C38 40.5523 37.5523 41 37 41H31C30.4477 41 30 40.5523 30 40V34ZM32 35V39H36V35H32Z'
                                            fill='#1D3557'
                                        />
                                        <path d='M30 40H38V43H30V40Z' fill='#557981' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M29 40C29 39.4477 29.4477 39 30 39H38C38.5523 39 39 39.4477 39 40V43C39 43.5523 38.5523 44 38 44H30C29.4477 44 29 43.5523 29 43V40ZM31 41V42H37V41H31Z'
                                            fill='#1D3557'
                                        />
                                    </g>
                                    <g className={classes.balcony_03}>
                                        <path d='M42 21H48V27H42V21Z' fill='#F1FAEE' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M41 21C41 20.4477 41.4477 20 42 20H48C48.5523 20 49 20.4477 49 21V27C49 27.5523 48.5523 28 48 28H42C41.4477 28 41 27.5523 41 27V21ZM43 22V26H47V22H43Z'
                                            fill='#1D3557'
                                        />
                                        <path d='M41 27H49V30H41V27Z' fill='#557981' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M40 27C40 26.4477 40.4477 26 41 26H49C49.5523 26 50 26.4477 50 27V30C50 30.5523 49.5523 31 49 31H41C40.4477 31 40 30.5523 40 30V27ZM42 28V29H48V28H42Z'
                                            fill='#1D3557'
                                        />
                                    </g>
                                    <g className={classes.balcony_06}>
                                        <path d='M42 34H48V40H42V34Z' fill='#F1FAEE' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M41 34C41 33.4477 41.4477 33 42 33H48C48.5523 33 49 33.4477 49 34V40C49 40.5523 48.5523 41 48 41H42C41.4477 41 41 40.5523 41 40V34ZM43 35V39H47V35H43Z'
                                            fill='#1D3557'
                                        />
                                        <path d='M41 40H49V43H41V40Z' fill='#557981' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M40 40C40 39.4477 40.4477 39 41 39H49C49.5523 39 50 39.4477 50 40V43C50 43.5523 49.5523 44 49 44H41C40.4477 44 40 43.5523 40 43V40ZM42 41V42H48V41H42Z'
                                            fill='#1D3557'
                                        />
                                    </g>
                                    <g className={classes.big_tree}>
                                        <path
                                            d='M56 42C56 39.7909 57.7909 38 60 38C62.2091 38 64 39.7909 64 42V54C64 56.2091 62.2091 58 60 58C57.7909 58 56 56.2091 56 54V42Z'
                                            fill='#89c627'
                                        />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M55 42C55 39.2386 57.2386 37 60 37C62.7614 37 65 39.2386 65 42V54C65 56.7614 62.7614 59 60 59C57.2386 59 55 56.7614 55 54V42ZM60 39C58.3431 39 57 40.3431 57 42V54C57 55.6569 58.3431 57 60 57C61.6569 57 63 55.6569 63 54V42C63 40.3431 61.6569 39 60 39Z'
                                            fill='#89c627'
                                        />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M60 48C60.5523 48 61 48.4477 61 49L61 64C61 64.5523 60.5523 65 60 65C59.4477 65 59 64.5523 59 64L59 49C59 48.4477 59.4477 48 60 48Z'
                                            fill='#1D3557'
                                        />
                                    </g>
                                    <g className={classes.small_tree}>
                                        <path
                                            d='M4 48C4 45.7909 5.79086 44 8 44V44C10.2091 44 12 45.7909 12 48V54C12 56.2091 10.2091 58 8 58V58C5.79086 58 4 56.2091 4 54V48Z'
                                            fill='#89c627'
                                        />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M3 48C3 45.2386 5.23858 43 8 43C10.7614 43 13 45.2386 13 48V54C13 56.7614 10.7614 59 8 59C5.23858 59 3 56.7614 3 54V48ZM8 45C6.34315 45 5 46.3431 5 48V54C5 55.6569 6.34315 57 8 57C9.65685 57 11 55.6569 11 54V48C11 46.3431 9.65685 45 8 45Z'
                                            fill='#89c627'
                                        />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M8 51C8.55228 51 9 51.4477 9 52L9 64C9 64.5523 8.55228 65 8 65C7.44771 65 7 64.5523 7 64L7 52C7 51.4477 7.44772 51 8 51Z'
                                            fill='#1D3557'
                                        />
                                    </g>
                                    <g className={classes.building_bottom}>
                                        <path d='M15 47H53V51H15V47Z' fill='#0e80ac' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M14 47C14 46.4477 14.4477 46 15 46H53C53.5523 46 54 46.4477 54 47V51C54 51.5523 53.5523 52 53 52H15C14.4477 52 14 51.5523 14 51V47ZM16 48V50H52V48H16Z'
                                            fill='#1D3557'
                                        />
                                        <path d='M16 51H52V65H16V51Z' fill='#c9e2e7' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M15 51C15 50.4477 15.4477 50 16 50H52C52.5523 50 53 50.4477 53 51V65C53 65.5523 52.5523 66 52 66H16C15.4477 66 15 65.5523 15 65V51ZM17 52V64H51V52H17Z'
                                            fill='#1D3557'
                                        />
                                    </g>
                                    <g className={classes.window_01}>
                                        <path d='M20 55H24V61H20V55Z' fill='#F1FAEE' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M19 55C19 54.4477 19.4477 54 20 54H24C24.5523 54 25 54.4477 25 55V61C25 61.5523 24.5523 62 24 62H20C19.4477 62 19 61.5523 19 61V55ZM21 56V60H23V56H21Z'
                                            fill='#1D3557'
                                        />
                                    </g>
                                    <g className={classes.window_02}>
                                        <path d='M44 55H48V61H44V55Z' fill='#F1FAEE' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M43 55C43 54.4477 43.4477 54 44 54H48C48.5523 54 49 54.4477 49 55V61C49 61.5523 48.5523 62 48 62H44C43.4477 62 43 61.5523 43 61V55ZM45 56V60H47V56H45Z'
                                            fill='#1D3557'
                                        />
                                    </g>
                                    <g className={classes.door}>
                                        <path d='M28 55H34V65H28V55Z' fill='#557981' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M27 55C27 54.4477 27.4477 54 28 54H34C34.5523 54 35 54.4477 35 55V65C35 65.5523 34.5523 66 34 66H28C27.4477 66 27 65.5523 27 65V55ZM29 56V64H33V56H29Z'
                                            fill='#1D3557'
                                        />
                                        <path d='M34 55H40V65H34V55Z' fill='#557981' />
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M33 55C33 54.4477 33.4477 54 34 54H40C40.5523 54 41 54.4477 41 55V65C41 65.5523 40.5523 66 40 66H34C33.4477 66 33 65.5523 33 65V55ZM35 56V64H39V56H35Z'
                                            fill='#1D3557'
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>

                <div className={classes.Message}>
                    {props.title && <h3>{props.title}</h3>}
                    {props.subtitle && <span>{props.subtitle}</span>}

                    {!props.title && !props.children && <h3>Loading, please wait</h3>}

                    {!props.title && props.children && props.children}
                </div>
            </div>
        </div>
    );
};

export default Loader2;
