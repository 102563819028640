import { useNavigate } from 'react-router-dom';

import classes from './CheckoutForm.module.css';
import SimpleHeader from '../../features/SimpleHeader/SimpleHeader';
import MainButton from '../../features/UI/MainButton/MainButton';

const SuccessCheckout = () => {
    const navigate = useNavigate();

    return (
        <div>
            <SimpleHeader title='Success checkout' />

            <div className={classes.WithMarginsWrapper}>
                <div className={classes.WithMargins}>
                    <div className={classes.SuccessCheckout}>
                        <p>Thank you for your order!</p>
                        <p>An email will be sent to you with directions on how to login to the platform.</p>

                        <MainButton
                            icon={
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <path d='M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z'></path>
                                </svg>
                            }
                            label='Platform'
                            color='accent'
                            onClick={() => navigate('/dashboard')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessCheckout;
