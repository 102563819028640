import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    searchData: null,
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSearchData: (state, action) => {
            state.searchData = action.payload;
        },
    },
});

export const searchActions = searchSlice.actions;

export default searchSlice;
