import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Viewer, Scene, Globe, Cesium3DTileset, Clock, ScreenSpaceEventHandler, ScreenSpaceEvent, ImageryLayer, AmbientOcclusion } from 'resium';
import { Cartesian2, ScreenSpaceEventType, JulianDate, BingMapsImageryProvider, BingMapsStyle, Ion, Cesium3DTileStyle, Color, defined } from 'cesium';

import CompareBuildingPopup from './CompareBuildingPopup';
import CompareMapFilters from './CompareMapFilters';
import classes from './CompareMap.module.css';

const CONFIG = require('../../../reactConfig.json');

Ion.defaultAccessToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwZTEwYzU4Zi0zYjZlLTQ2YTItODIzYS04ZmI1MmM2MDU5ZWIiLCJpZCI6MzkxMDEsImlhdCI6MTYwNzE2MzY0NX0.jcCjSwZLLqMgXHb5Ses_1WGuDM__F8B89lMhh0i7X30';

const CompareMap = (props) => {
    const viewerRef = React.createRef();
    const [extentTileset, setExtentTileset] = useState(null);
    const [zoomedToExtent, setZoomedToExtent] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState({
        feature: null,
        originalColor: '',
        position: null,
    });

    const theme = useSelector((state) => state.layout.theme);
    const buildingMapValues = useSelector((state) => state.myScenarios.buildingMapValues);
    const compareMapProperty = useSelector((state) => state.myScenarios.compareMapProperty);
    const isFullScreen = useSelector((state) => state.myScenarios.isFullScreen);
    const viewLegend = useSelector((state) => state.myScenarios.viewLegend);
    const selectedProject = useSelector((state) => state.myScenarios.selectedProject);
    const selectedScenarios = useSelector((state) => state.myScenarios.selectedScenarios);

    useEffect(() => {
        setSelectedFeature({
            feature: null,
            originalColor: '',
            position: null,
        });
    }, [selectedProject, selectedScenarios, compareMapProperty]);

    const imageryProvider = useMemo(() => {
        return new BingMapsImageryProvider({
            url: 'https://dev.virtualearth.net',
            key: 'AsCCUmkUKRlk_m7oiOJbuUHPL5M342CU6SwB0vMUdBwyJRQofZX-TCrjV0ueEvrT', //'get-yours-at-https://www.bingmapsportal.com/',
            mapStyle: theme === 'light' ? BingMapsStyle.CANVAS_LIGHT : BingMapsStyle.CANVAS_DARK,
        });
    }, [theme]);

    useEffect(() => {
        if (!viewerRef) return;
        if (!extentTileset) return;
        if (zoomedToExtent) return;
        viewerRef.current.cesiumElement.zoomTo(extentTileset);
        setZoomedToExtent(true);
    }, [extentTileset]);

    const zoomToDataset = (tileset) => {
        if (!tileset) return;
        viewerRef.current.cesiumElement.zoomTo(tileset);
    };

    const colorsArr = useMemo(() => {
        return ['#08968c', '#51bc8a', '#9fc888', '#d5d5d5', '#e7e674', '#e7b574', '#b16356'];
    }, []);

    const getColor = (value) => {
        let color = colorsArr[0];

        if (value > 100) color = colorsArr[0];
        else if (value <= 100 && value > 50) color = colorsArr[1];
        else if (value <= 50 && value > 0) color = colorsArr[2];
        else if (value === 0) color = colorsArr[3];
        else if (value < 0 && value >= -50) color = colorsArr[4];
        else if (value < 50 && value >= -100) color = colorsArr[5];
        else if (value < -100) color = colorsArr[6];

        return color;
    };

    const buildingStyle = useMemo(() => {
        return new Cesium3DTileStyle({
            color: {
                evaluateColor: function (feature) {
                    const noDataColor = '#646464';
                    const noChangeColor = '#d5d5d5';

                    if (!buildingMapValues) return Color.fromCssColorString(noDataColor);

                    const gid = JSON.parse(feature.getProperty('gid'));

                    if (!buildingMapValues[gid].scenarioA) return Color.fromCssColorString(noDataColor);
                    if (!buildingMapValues[gid].scenarioB) return Color.fromCssColorString(noDataColor);

                    const scenarioAValue = buildingMapValues[gid]?.scenarioA[compareMapProperty.name];
                    const scenarioBValue = buildingMapValues[gid]?.scenarioB[compareMapProperty.name];

                    if (scenarioAValue === null || scenarioBValue === null || parseFloat(scenarioAValue) === 0) return Color.fromCssColorString(noDataColor);

                    const difference = parseFloat(scenarioBValue) - parseFloat(scenarioAValue);
                    if (!difference) return Color.fromCssColorString(noChangeColor);

                    let differencePercentage = difference;
                    if (!compareMapProperty.isPercentage) differencePercentage = (difference / parseFloat(scenarioAValue)) * 100;

                    const color = getColor(differencePercentage);

                    return Color.fromCssColorString(color);
                },
            },
        });
    }, [compareMapProperty, buildingMapValues]);

    const legendItems = useMemo(() => {
        return (
            <>
                <div className={classes.LegendItems}>
                    <div className={classes.LegendRow}>
                        <div className={classes.LegendColor} style={{ backgroundColor: colorsArr[0] }}></div>
                        <span>{'>'} 100</span>
                    </div>
                    <div className={classes.LegendRow}>
                        <div className={classes.LegendColor} style={{ backgroundColor: colorsArr[1] }}></div>
                        <span>100 - 50</span>
                    </div>
                    <div className={classes.LegendRow}>
                        <div className={classes.LegendColor} style={{ backgroundColor: colorsArr[2] }}></div>
                        <span>50 - 0</span>
                    </div>
                    <div className={classes.LegendRow}>
                        <div className={classes.LegendColor} style={{ backgroundColor: colorsArr[3] }}></div>
                        <span>0</span>
                    </div>
                    <div className={classes.LegendRow}>
                        <div className={classes.LegendColor} style={{ backgroundColor: colorsArr[4] }}></div>
                        <span>0 - -50</span>
                    </div>
                    <div className={classes.LegendRow}>
                        <div className={classes.LegendColor} style={{ backgroundColor: colorsArr[5] }}></div>
                        <span>-50 - -100</span>
                    </div>
                    <div className={classes.LegendRow}>
                        <div className={classes.LegendColor} style={{ backgroundColor: colorsArr[6] }}></div>
                        <span>{'<'} -100</span>
                    </div>
                    <div className={classes.LegendRow}>
                        <div className={classes.LegendColor} style={{ backgroundColor: '#646464' }}></div>
                        <span>No data</span>
                    </div>
                </div>
            </>
        );
    }, []);

    const onMapClick = (evt) => {
        const viewer = viewerRef.current.cesiumElement;

        let selected = { ...selectedFeature };

        // If a feature was previously selected, undo the highlight
        if (selected.feature) {
            selected.feature.color = selected.originalColor;
            selected.feature = null;
        }

        const pickedFeature = viewer.scene.pick(evt.position);

        if (!defined(pickedFeature)) {
            setSelectedFeature({
                feature: null,
                originalColor: '',
                position: null,
            });
            return;
        }

        if (pickedFeature) {
            const originalColor = pickedFeature.color;
            selected.feature = pickedFeature;
            selected.originalColor = originalColor;
            selected.position = evt.position;
            pickedFeature.color = Color.WHITE.withAlpha(1.0);

            setSelectedFeature(selected);
        }
    };

    const clearSelected = () => {
        let selected = { ...selectedFeature };

        // If a feature was previously selected, undo the highlight
        if (selected.feature) {
            selected.feature.color = selected.originalColor;
            selected.feature = null;
        }

        setSelectedFeature({
            feature: null,
            originalColor: '',
            position: null,
        });
    };

    return (
        <div className={isFullScreen ? [classes.ViewerWrapper, classes.FullScreen].join(' ') : classes.ViewerWrapper}>
            <CompareMapFilters onZoomToBounds={() => zoomToDataset(extentTileset)} />

            <div className={classes.Viewer}>
                <div className={selectedFeature.feature ? [classes.CustomPopup, classes.Show].join(' ') : classes.CustomPopup}>
                    <CompareBuildingPopup selectedFeature={selectedFeature} onClose={clearSelected} />
                </div>

                <div className={viewLegend ? [classes.LegendWrapper, classes.Show].join(' ') : classes.LegendWrapper}>
                    <div className={classes.Legend}>
                        <h4>Legend</h4>
                        <span className={classes.LegendTitle}>
                            {compareMapProperty.label} (<span dangerouslySetInnerHTML={{ __html: compareMapProperty.unit }} />)
                        </span>
                        {legendItems}
                    </div>
                </div>

                <Viewer
                    ref={viewerRef}
                    full
                    timeline={false}
                    animation={false}
                    vrButton={false}
                    sceneModePicker={false}
                    homeButton={false}
                    infoBox={false}
                    baseLayerPicker={false}
                    scene3DOnly={true}
                    imageryProvider={false}
                >
                    <Clock currentTime={JulianDate.fromIso8601('2021-06-23T14:00')} />

                    <ScreenSpaceEventHandler>
                        <ScreenSpaceEvent action={onMapClick} type={ScreenSpaceEventType.LEFT_CLICK} />
                    </ScreenSpaceEventHandler>

                    <Scene>
                        <Globe depthTestAgainstTerrain={true} />
                        <ImageryLayer imageryProvider={imageryProvider} />
                        <AmbientOcclusion intensity={0} />

                        <Cesium3DTileset
                            url={`${CONFIG.data}/tiles/scenarios/${props.data.scenarioA.tiles_folder}/tileset.json`}
                            style={buildingStyle}
                            imageBasedLightingFactor={new Cartesian2(1.0, 1.0)}
                            onReady={(tileset) => {
                                setExtentTileset(tileset);
                            }}
                        />
                    </Scene>
                </Viewer>
            </div>
        </div>
    );
};

export default CompareMap;
