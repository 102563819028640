import classes from './MainInput.module.css';

const MainInput = (props) => {
    let classNames = [classes.MainInputWrapper];
    if (props.fullWidth) classNames.push(classes.FullWidth);
    else if (props.narrow) classNames.push(classes.Narrow);
    else if (props.veryNarrow) classNames.push(classes.VeryNarrow);

    if (props.validated && props.isValid) classNames.push(classes.IsValid);
    else if (props.validated && props.isValid === false) classNames.push(classes.IsInvalid);

    return (
        <div className={classNames.join(' ')}>
            {props.label ? <label>{props.label}</label> : null}

            <input
                className={classes.MainInput}
                name={props.name}
                type={props.type}
                label={props.label}
                disabled={props.disabled}
                value={props.value}
                onChange={props.onChange}
            />
        </div>
    );
};

export default MainInput;
