import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import MyAutocomplete from '../UI/MyAutocomplete/MyAutocomplete';
import Badge from '../UI/Badge/Badge';
import classes from './BuildingUsesSelection.module.css';

const BuildingUsesSelection = (props) => {
    const [isPerFloor, setIsPerFloor] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        if (props.showPerFloor) setIsPerFloor(true);
    }, [props.showPerFloor]);

    useEffect(() => {
        setIsValid(props.isValid);
        setValidated(props.validated);
    }, [props.validated, props.isValid]);

    const togglePerFloorHandler = () => {
        // If changed to not per floor, then delete the rest of floors
        if (isPerFloor) {
            props.onChange(null, 'rest');
        }

        setIsPerFloor((prev) => !prev);
    };

    return (
        <div className={classes.BuildingUsesSelection}>
            <ReactTooltip effect='solid' />

            <div className={classes.SelectWithLabel}>
                {isPerFloor ? (
                    <div className={classes.SelectCouple}>
                        <div>
                            <label>Ground floor</label>
                            <MyAutocomplete
                                options={props.options}
                                value={props.firstFloor ? props.firstFloor : null}
                                onChange={(value) => props.onChange(value, 'first')}
                                fullWidth
                                groupBy='category'
                                validated={validated}
                                isValid={isValid}
                            />
                        </div>

                        <div>
                            <label>Rest of floors</label>
                            <MyAutocomplete
                                options={props.options}
                                value={props.restFloors ? props.restFloors : null}
                                onChange={(value) => props.onChange(value, 'rest')}
                                fullWidth
                                groupBy='category'
                            />
                        </div>
                    </div>
                ) : (
                    <div>
                        <MyAutocomplete
                            options={props.options}
                            value={props.firstFloor ? props.firstFloor : null}
                            onChange={(value) => props.onChange(value, 'first')}
                            fullWidth
                            groupBy='category'
                            validated={validated}
                            isValid={isValid}
                        />
                    </div>
                )}

                <div className={classes.BuildingIcon}>
                    <div
                        className={isPerFloor ? [classes.Floors, classes.DifferentFirstFloor].join(' ') : classes.Floors}
                        onClick={togglePerFloorHandler}
                        data-tip='Different first floor'
                    >
                        <Badge
                            color='warning'
                            icon={
                                <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'>
                                    <g>
                                        <path
                                            d='M11,1,1,6l10,5L21,6Z'
                                            fill='#fff'
                                            stroke='#010101'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='2'
                                        />
                                        <path
                                            d='M21,11v5L11,21,1,16V11l10,5,10-5L11,16'
                                            fill='#fff'
                                            stroke='#010101'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='2'
                                        />
                                        <path
                                            d='M21,6,11,11,1,6v4.81l10,4.85,10-4.83Z'
                                            fill='#fff'
                                            stroke='#010101'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='2'
                                        />
                                    </g>
                                </svg>
                            }
                        ></Badge>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuildingUsesSelection;
