import classes from './ImageWithList.module.css';

const ImageWithList = (props) => {
    return (
        <div className={classes.ImageWithList}>
            <div className={classes.ImageWrapper}>
                <img src={props.image} alt='Platform view' />
            </div>
            <div className={classes.RightColumn}>
                <h2>{props.title}</h2>
                {props.children}
            </div>
        </div>
    );
};

export default ImageWithList;
