import classes from './LoaderBar.module.css';

const LoaderBar = (props) => {
    let loaderClasses = [classes.LoaderWrapper];
    if (props.show) {
        loaderClasses.push(classes.Show);
    }

    return (
        <div className={loaderClasses.join(' ')}>
            <div className={classes.Loader}>
                <div className={classes.LoadingBox}>
                    <div></div>
                </div>

                <div className={classes.Message}>
                    {props.title && <h3>{props.title}</h3>}
                    {props.subtitle && <span>{props.subtitle}</span>}

                    {!props.title && !props.children && <h3>Loading, please wait</h3>}

                    {!props.title && props.children && props.children}
                </div>
            </div>
        </div>
    );
};

export default LoaderBar;
