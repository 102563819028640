import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import MainButton from '../../features/UI/MainButton/MainButton';
import MainInput from '../../features/UI/MainInput/MainInput';
import MainTextarea from '../../features/UI/MainTextarea/MainTextarea';
import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import Widget from '../../features/Widget/Widget';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { myProjectsActions } from './myProjectsSlice';
import { getSelectedProject, updateProject } from './MyProjectsAsyncActions';
import classes from './MyProjectsEdit.module.css';
import { showMessage } from '../../utils/messageHandler';
import ModalConfirm from '../../features/UI/Modal/ModalConfirm';

const MyProjectsEdit = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectedData = useSelector((state) => state.myProjects.selectedProject);

    const [editData, setEditData] = useState(null);
    const [formValidated, setFormValidated] = useState(false);
    const [showModalSave, setShowModalSave] = useState(false);

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('My weather files edit'));
        dispatch(getSelectedProject(id, navigate));

        return () => {
            dispatch(myProjectsActions.resetNewProject());
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch, id]);

    useEffect(() => {
        setEditData(selectedData);
    }, [selectedData]);

    const saveDataInfo = () => {
        setFormValidated(true);

        if (editData?.name.trim() === '') {
            showMessage('error', 'Please fill in all required fields');
        } else {
            setShowModalSave(true);
        }
    };

    const updateEditData = (property, value) => {
        let update = { ...editData };
        update[property] = value;
        setEditData(update);
    };

    return (
        <div className={classes.MyProjectsEdit}>
            <Breadcrumb link1='User' link2='/ My projects' link3={`/ Edit project (${selectedData?.name})`} />

            <ModalConfirm
                type='save'
                title='Are you sure?'
                text='You will update the project.'
                show={showModalSave}
                onClose={() => setShowModalSave(false)}
                onAction={() => {
                    dispatch(updateProject(editData));
                    setShowModalSave(false);
                }}
            />

            <div className={classes.ContentInner}>
                <div className={classes.Widgets}>
                    <Widget>
                        <div className={classes.TitleRow}>
                            <div className={classes.TitleRowCol1}>
                                <h2>Edit weather dataset</h2>
                                <span>You can change the name and description of the weather dataset</span>
                            </div>

                            <div className={classes.TitleRowCol2}>
                                <MainButton
                                    icon={
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        >
                                            <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                                            <polyline points='17 21 17 13 7 13 7 21'></polyline>
                                            <polyline points='7 3 7 8 15 8'></polyline>
                                        </svg>
                                    }
                                    type='circle'
                                    color='action'
                                    onClick={() => {
                                        saveDataInfo();
                                    }}
                                    //loading={loginLoading}
                                />
                            </div>
                        </div>

                        <div className={classes.WidgetContent}>
                            {editData ? (
                                <form>
                                    <div className={classes.FormRow}>
                                        <label>Name *</label>
                                        <MainInput
                                            type='text'
                                            fullWidth
                                            value={editData?.name}
                                            onChange={(e) => updateEditData('name', e.target.value)}
                                            validated={formValidated}
                                            isValid={editData?.name.trim() !== ''}
                                        />
                                    </div>

                                    <div className={classes.FormRow}>
                                        <label>Description</label>
                                        <MainTextarea
                                            type='text'
                                            fullWidth
                                            value={editData?.description}
                                            rows={3}
                                            onChange={(e) => updateEditData('description', e.target.value)}
                                        />
                                    </div>
                                </form>
                            ) : null}
                        </div>
                    </Widget>
                </div>
            </div>
        </div>
    );
};

export default MyProjectsEdit;
