import { useSelector } from 'react-redux';

import classes from './MapFilters.module.css';

const MapFiltersScenarioInfo = () => {
    const selectedProject = useSelector((state) => state.myProjects.selectedProject);
    const selectedScenario = useSelector((state) => state.myProjects.selectedScenario);
    const scenariosMetrics = useSelector((state) => state.myProjects.scenariosMetrics);

    return (
        <div className={classes.ResultsWrapper}>
            <div className={classes.ResultCol}>
                <div className={classes.ResultColTitle}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <circle cx='12' cy='12' r='10'></circle>
                        <line x1='12' y1='16' x2='12' y2='12'></line>
                        <line x1='12' y1='8' x2='12.01' y2='8'></line>
                    </svg>
                    <h4>Info</h4>
                </div>

                <div className={classes.ResultRows}>
                    <div className={classes.ResultRow}>
                        <span>Num of buildings:</span>
                        <span>{scenariosMetrics[selectedScenario.id]?.buildings_num}</span>
                    </div>
                    <div className={classes.ResultRow}>
                        <span>Simulated area:</span>
                        <span>{scenariosMetrics[selectedScenario.id]?.total_building_area.toFixed(2)}</span>
                        <span>
                            m<sup>2</sup>
                        </span>
                    </div>
                    <div className={classes.ResultRow}>
                        <span>Hours simulated:</span>
                        <span>{scenariosMetrics[selectedScenario.id]?.general_hours_simulated}</span>
                    </div>
                    {/* <div className={classes.ResultRow}>
                            <span>Centroid latitude:</span>
                            <span>{scenariosMetrics[selectedScenario.id]?.general_latitude}</span>
                        </div>
                        <div className={classes.ResultRow}>
                            <span>Centroid longitude:</span>
                            <span>{scenariosMetrics[selectedScenario.id]?.general_longitude}</span>
                        </div>
                        <div className={classes.ResultRow}>
                            <span>Centroid elevation:</span>
                            <span>{scenariosMetrics[selectedScenario.id]?.general_elevation}</span>
                            <span>m</span>
                        </div> */}
                </div>
            </div>

            <div className={classes.ResultCol}>
                <div className={classes.ResultColTitle}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <polygon points='13 2 3 14 12 14 11 22 21 10 12 10 13 2'></polygon>
                    </svg>
                    <h4>Energy / Emissions</h4>
                </div>

                <div className={classes.ResultRows}>
                    <div className={classes.ResultRow}>
                        <span>Primary energy:</span>
                        <span>{scenariosMetrics[selectedScenario.id]?.total_primary_energy_per_m2.toFixed(2)}</span>
                        <span>
                            kWh/m<sup>2</sup>
                        </span>
                    </div>
                    <div className={classes.ResultRow}>
                        <span>Consumed energy:</span>
                        <span>{scenariosMetrics[selectedScenario.id]?.total_consumed_energy_per_m2.toFixed(2)}</span>
                        <span>
                            kWh/m<sup>2</sup>
                        </span>
                    </div>
                    <div className={classes.ResultRow}>
                        <span>Carbon emissions:</span>
                        <span>{scenariosMetrics[selectedScenario.id]?.total_co2_per_m2.toFixed(2)}</span>
                        <span>kgCO2eq</span>
                    </div>
                </div>
            </div>

            <div className={classes.ResultCol}>
                <div className={classes.ResultColTitle}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <line x1='8' y1='19' x2='8' y2='21'></line>
                        <line x1='8' y1='13' x2='8' y2='15'></line>
                        <line x1='16' y1='19' x2='16' y2='21'></line>
                        <line x1='16' y1='13' x2='16' y2='15'></line>
                        <line x1='12' y1='21' x2='12' y2='23'></line>
                        <line x1='12' y1='15' x2='12' y2='17'></line>
                        <path d='M20 16.58A5 5 0 0 0 18 7h-1.26A8 8 0 1 0 4 15.25'></path>
                    </svg>
                    <h4>Weather station</h4>
                </div>

                <div className={classes.ResultRows}>
                    <div className={classes.ResultRow}>
                        <span>Name:</span>
                        <span>{selectedProject?.weather_name}</span>
                    </div>
                    <div className={classes.ResultRow}>
                        <span>Latitude:</span>
                        <span>{selectedProject?.weather_latitude}</span>
                    </div>
                    <div className={classes.ResultRow}>
                        <span>Longitude:</span>
                        <span>{selectedProject?.weather_longitude}</span>
                    </div>
                    <div className={classes.ResultRow}>
                        <span>Elevation:</span>
                        <span>{selectedProject?.weather_elevation}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapFiltersScenarioInfo;
