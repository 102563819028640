import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import highchartsAccessibility from 'highcharts/modules/accessibility';

import classes from './Charts.module.css';

highchartsAccessibility(Highcharts);

const PieChart = (props) => {
    const chartRef = useRef(null);

    const [chartOptions, setChartOptions] = useState({
        chart: {
            backgroundColor: 'rgba(255,255,255,0)', // Disable background color
            plotBackgroundColor: null,
            plotBorderWidth: null,
            // plotShadow: false,
            type: 'pie',
            style: {
                fontFamily: 'Nunito, sans-serif',
            },
            events: {
                load: function () {
                    const chart = this;
                    const legend = chart.legend;
                    setTimeout(() => {
                        const newHeight = chart.chartHeight + legend.legendHeight;
                        chart.setSize(undefined, newHeight);
                    }, 400);
                },
            },
        },
        title: {
            text: props.title,
        },
        subtitle: {
            text: props.subtitle ? props.subtitle : '',
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        accessibility: {
            point: {
                valueSuffix: '%',
            },
        },
        legend: {
            enabled: props.hideLegend ? false : true,
            align: props.alignLegend ? props.alignLegend : 'left',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            itemStyle: { color: '#515365', fontSize: '1.4rem', fontWeight: 'regular', fontFamily: 'Nunito, sans-serif' },
            itemMarginBottom: 5,
            labelFormatter: function () {
                if (this.y !== 0 && !this.y) return '';
                return this.name + ' (' + this.y.toFixed(2) + '%)';
            },
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                borderWidth: 0,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                },
                size: '100%',
                showInLegend: true,
                colors: ['#50B432', '#ED561B', '#DDDF00', '#24CBE5'],
            },
        },
        series: props.series,
    });

    useEffect(() => {
        setChartOptions({
            series: props.series,
        });
    }, [props.series]);

    const onResize = useCallback(() => {
        const chart = chartRef.current.chart;
        const wrapperWidth = ref?.current?.offsetWidth;
        chart.setSize(wrapperWidth, void 0, false);
    }, []);

    const { width, height, ref } = useResizeDetector({
        handleHeight: false,
        refreshMode: 'debounce',
        refreshRate: 150,
        onResize,
    });

    return (
        <div className={classes.MainChart} ref={ref}>
            <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} />
        </div>
    );
};

export default PieChart;
