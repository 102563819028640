import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import useMediaQuery from '@mui/material/useMediaQuery';

import Search from '../Search/Search';
import { loginActions } from '../../pages/Login/loginSlice';
import classes from './Topbar.module.css';
import { layoutActions } from '../Layout/layoutSlice';

const Topbar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.login.user);
    const theme = useSelector((state) => state.layout.theme);
    const loading = useSelector((state) => state.system.loading);

    const isMobile = useMediaQuery('(max-width:700px)');

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisible((prevState) => !prevState);
    };
    const closeDropdown = () => {
        setDropdownVisible(false);
    };

    const logout = () => {
        dispatch(loginActions.logout());
    };

    // Click outside
    const useOutsideClick = (callback) => {
        const ref = useRef();

        useEffect(() => {
            const handleClick = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    callback();
                }
            };

            document.addEventListener('click', handleClick);

            return () => {
                document.removeEventListener('click', handleClick);
            };
        }, [ref]);

        return ref;
    };

    const ref = useOutsideClick(closeDropdown);

    return (
        <div className={loading ? [classes.TopbarWrapper, classes.Loading].join(' ') : classes.TopbarWrapper}>
            <div className={classes.Topbar}>
                <header className={classes.Header}>
                    <MediaQuery maxWidth={700}>
                        <div className={classes.MobileMenuButton} onClick={() => dispatch(layoutActions.toggleMenuClosed())}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <line x1='3' y1='12' x2='21' y2='12'></line>
                                <line x1='3' y1='6' x2='21' y2='6'></line>
                                <line x1='3' y1='18' x2='21' y2='18'></line>
                            </svg>
                        </div>
                    </MediaQuery>

                    <Search />

                    <div className={isMobile ? [classes.IsMobile, classes.HeaderNav].join(' ') : classes.HeaderNav}>
                        <div className={classes.ThemeButtons}>
                            {theme === 'dark' && (
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className={classes.Moon}
                                    onClick={() => dispatch(layoutActions.setTheme('light'))}
                                >
                                    <path d='M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z'></path>
                                </svg>
                            )}

                            {theme === 'light' && (
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className={classes.Sun}
                                    onClick={() => dispatch(layoutActions.setTheme('dark'))}
                                >
                                    <circle cx='12' cy='12' r='5'></circle>
                                    <line x1='12' y1='1' x2='12' y2='3'></line>
                                    <line x1='12' y1='21' x2='12' y2='23'></line>
                                    <line x1='4.22' y1='4.22' x2='5.64' y2='5.64'></line>
                                    <line x1='18.36' y1='18.36' x2='19.78' y2='19.78'></line>
                                    <line x1='1' y1='12' x2='3' y2='12'></line>
                                    <line x1='21' y1='12' x2='23' y2='12'></line>
                                    <line x1='4.22' y1='19.78' x2='5.64' y2='18.36'></line>
                                    <line x1='18.36' y1='5.64' x2='19.78' y2='4.22'></line>
                                </svg>
                            )}
                        </div>

                        <div className={classes.MoreIconWrapper} ref={ref} onClick={toggleDropdown}>
                            <div className={classes.Avatar}>{user?.username?.slice(0, 1).toUpperCase()}</div>

                            <div className={dropdownVisible ? [classes.DropdownMenu, classes.Show].join(' ') : classes.DropdownMenu}>
                                <div className={classes.UserProfile}>
                                    <h5>{user && user.username}</h5>
                                    <span>{user && user.role}</span>
                                </div>
                                <div className={classes.UserLinks}>
                                    <div className={classes.UserLink} onClick={() => navigate('/user-profile')}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        >
                                            <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2'></path>
                                            <circle cx='12' cy='7' r='4'></circle>
                                        </svg>
                                        <span>Profile</span>
                                    </div>

                                    <div className={classes.UserLink}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        >
                                            <polyline points='22 12 16 12 14 15 10 15 8 12 2 12'></polyline>
                                            <path d='M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z'></path>
                                        </svg>
                                        <span>Messages</span>
                                    </div>

                                    <div className={classes.UserLink} onClick={() => logout()}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        >
                                            <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4'></path>
                                            <polyline points='16 17 21 12 16 7'></polyline>
                                            <line x1='21' y1='12' x2='9' y2='12'></line>
                                        </svg>
                                        <span>Sign out</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </div>
    );
};

export default Topbar;
