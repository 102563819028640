import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import classes from './NavigationItem.module.css';

const NavigationItem = (props) => {
    const menuIsClosed = useSelector((state) => state.layout.menuIsClosed);

    let attachedClasses = [classes.NavigationItem];

    if (props.disabled) {
        attachedClasses.push(classes.Disabled);
    }
    if (props.isBold) {
        attachedClasses.push(classes.IsBold);
    }
    if (props.isTall) {
        attachedClasses.push(classes.IsTall);
    }
    if (props.isOpaque) {
        attachedClasses.push(classes.IsOpaque);
    }

    const { pathname } = useLocation();
    const slashIndex = pathname.lastIndexOf('/');

    let pathnameNoParams = pathname;
    if (slashIndex > 0) pathnameNoParams = pathname.slice(0, slashIndex);

    return (
        <li className={attachedClasses.join(' ')}>
            {props.link ? (
                <NavLink
                    to={props.link}
                    exact={props.exact}
                    className={({ isActive }) => (isActive || props.location.includes(pathnameNoParams) ? classes.active : classes.NavLink)}
                >
                    {props.icon ? <div className={classes.ItemIconWrapper}>{props.icon}</div> : null}
                    <span className={menuIsClosed ? [classes.ItemTitle, classes.Hide].join(' ') : classes.ItemTitle}>{props.title}</span>
                </NavLink>
            ) : (
                <a className={classes.NavLink} href={props.outsideLink} rel='noopener noreferrer' target='_blank'>
                    <div className={classes.ItemIconWrapper}>{props.icon}</div>
                    <span className={menuIsClosed ? [classes.ItemTitle, classes.Hide].join(' ') : classes.ItemTitle}>{props.title}</span>
                </a>
            )}
        </li>
    );
};

export default NavigationItem;
