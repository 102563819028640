import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useMediaQuery } from 'react-responsive';

import Banner from '../../features/Banner/Banner';
import SimpleHeader from '../../features/SimpleHeader/SimpleHeader';
import classes from './About.module.css';
import ImageWithList from '../../features/ImageWithList/ImageWithList';
import image5 from '../../assets/images/image5.jpg';
import FancyList from '../../features/UI/FancyList/FancyList';
import TeamCard from '../../features/TeamCard/TeamCard';
import team_member_theodoridou from '../../assets/images/team_member_theodoridou.png';
import team_member_christodoulou from '../../assets/images/team_member_christodoulou.png';
import team_member_gialas from '../../assets/images/team_member_gialas.png';
import team_member_kapanidis from '../../assets/images/team_member_kapanidis.png';
import team_member_mallinis from '../../assets/images/team_member_mallinis.png';
import team_member_mermigas from '../../assets/images/team_member_mermigas.png';
import team_member_pippis from '../../assets/images/team_member_pippis.png';
import team_member_saropoulou from '../../assets/images/team_member_saropoulou.png';
import team_member_siopis from '../../assets/images/team_member_siopis.png';

const About = () => {
    const isNarrow = useMediaQuery({ query: '(max-width: 1000px)' });

    return (
        <div className={classes.About}>
            <SimpleHeader title='About' subtitle='together we can re-invent our cities' />

            <div className={classes.WithMarginsWrapper}>
                <div className={classes.WithMargins}>
                    <ImageWithList image={image5} title='WORKING WITH REPOLIS'>
                        <FancyList
                            items={[
                                <span>Get robust and safe results on buildings' energy performance and carbon footprint</span>,
                                <span>Run real-time dynamic energy simulation</span>,
                                <span>Understand your city's building stock</span>,
                                <span>Design and choose your retrofitting actions</span>,
                                <span>Measure your decarbonisation</span>,
                            ]}
                        />
                    </ImageWithList>
                </div>
            </div>

            <div className={classes.Team}>
                <div className={classes.WithMarginsWrapper}>
                    <div className={classes.WithMargins}>
                        <div className={classes.BoxTitle}>
                            <h2>MEET OUR TEAM</h2>
                        </div>

                        <div className={classes.TeamSlider}>
                            <CarouselProvider
                                className={classes.CarouselContainer}
                                naturalSlideWidth={250}
                                naturalSlideHeight={380}
                                visibleSlides={isNarrow ? 1 : 2}
                                totalSlides={9}
                            >
                                <div className={classes.CarouselInner}>
                                    <Slider className={classes.Slider}>
                                        <Slide index={0} className={classes.CarouselSlide}>
                                            <TeamCard
                                                name='Dr. Ifigeneia Theodoridou'
                                                position='Founder + Head Innovation + Product Design'
                                                image={team_member_theodoridou}
                                                description='Ifigeneia is an Architect Engineer (TU Darmstadt, 2006) and holds a PhD from the TU Darmstadt regarding large-scale urban retrofitting measures. She is a certified nZEB trainer and Energy Auditor (Energieberater – TUD, 2009).'
                                            />
                                        </Slide>
                                        <Slide index={1} className={classes.CarouselSlide}>
                                            <TeamCard
                                                name='Dr. Georgios Mallinis'
                                                position='R&D + Innovation'
                                                image={team_member_mallinis}
                                                description='Giorgos is an Assistant Professor at the Aristotle University of Thessaloniki. He specializes in Remote Sensing and GIS, with key fields of expertise in geospatial analysis and ecosystem services assessment and monitoring.  Over the past years, he has managed 34 co-funded research projects.'
                                            />
                                        </Slide>
                                        <Slide index={2} className={classes.CarouselSlide}>
                                            <TeamCard
                                                name='Dimitris Gialas'
                                                position='Software development'
                                                image={team_member_gialas}
                                                description='Dimitris holds a Bachelor of Science in Engineering Degree, in Electronic and Communication Engineering (KTH, Stockholm). He is well-versed in Java programming with an experience in developing applications tailored for the finance and the engineering sector. He specializes in developing processing systems and integration solutions.'
                                            />
                                        </Slide>
                                        <Slide index={3} className={classes.CarouselSlide}>
                                            <TeamCard
                                                name='Ioannis Kapanidis'
                                                position='Front end development - GIS expert'
                                                image={team_member_kapanidis}
                                                description='Giannis has extensive experience in desktop GIS and web GIS, web design and development, software programming, as well as database development and management. He specializes in the development of systems for spatial analysis and visualization. He holds a degree in Environmental studies and an MSc in GIS.'
                                            />
                                        </Slide>
                                        <Slide index={4} className={classes.CarouselSlide}>
                                            <TeamCard
                                                name='Giannis Pippis'
                                                position='Product Design + Research'
                                                image={team_member_pippis}
                                                description='Giannis is a graduate of Production and Management Engineering (DUTH) and holds an MSc in Energy Building Design (IHU) & Economic Studies (DUTH). He has considerable experience in the areas of mechanical processes and energy analysis in the built environment (MEP, HVAC, R.E.S.).'
                                            />
                                        </Slide>
                                        <Slide index={5} className={classes.CarouselSlide}>
                                            <TeamCard
                                                name='Andreas Mermigas'
                                                position='R&D + Sustainability Expert'
                                                image={team_member_mermigas}
                                                description='Andreas is an Architect Engineer (TU Darmstadt, 2007). He is a highly experience sustainability and energy efficiency consultant. He holds the LEED AP BD+C (U.S. Green Building Council) title.'
                                            />
                                        </Slide>
                                        <Slide index={6} className={classes.CarouselSlide}>
                                            <TeamCard
                                                name='Katerina Christodoulou'
                                                position='Research + Graphic Design'
                                                image={team_member_christodoulou}
                                                description='Katerina holds a Diploma in Architecture Engineering (AUTH) and a Master’s Degree in Graphic Arts – Multimedia. She has extensive experience in Architectural Design on Building Projects.'
                                            />
                                        </Slide>
                                        <Slide index={7} className={classes.CarouselSlide}>
                                            <TeamCard
                                                name='Angelos Siopis'
                                                position='Business Development + Market Analysis'
                                                image={team_member_siopis}
                                                description='Angelos has a long-time experience in the wealth management sector, as an advisor of UHNWI & HNWI clients and investors. He is managing mergers and multi-million acquisitions projects. He holds a Degree in Accounting & Finance and an M.Sc. in Finance (University of Liverpool).'
                                            />
                                        </Slide>
                                        <Slide index={8} className={classes.CarouselSlide}>
                                            <TeamCard
                                                name='Sofia Saropoulou'
                                                position='Branding + Marketing'
                                                image={team_member_saropoulou}
                                                description='Sofia holds a master’s degree in Marketing from VU in Amsterdam, NL, and a master’s degree in e-Business and Digital Marketing from IHU in Thessaloniki, Greece. Her working experience is focusing on digital marketing, branding, social media and content creation.'
                                            />
                                        </Slide>
                                    </Slider>
                                    <ButtonBack className={classes.ButtonBack}>
                                        <div>
                                            <span>{'<'}</span>
                                        </div>
                                    </ButtonBack>
                                    <ButtonNext className={classes.ButtonNext}>
                                        <div>
                                            <span>{'>'}</span>
                                        </div>
                                    </ButtonNext>
                                </div>
                            </CarouselProvider>
                        </div>
                    </div>
                </div>
            </div>

            <Banner title='WANT TO GET FAMILIAR WITH REPOLIS?' subtitle='We offer a free tour through the platform' />
        </div>
    );
};

export default About;
