import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';

import Topbar from './Topbar';
import Footer from './Footer';
import classes from './LayoutSite.module.css';
import { websiteActions } from '../../pages/websiteSlice';

const LayoutSite = () => {
    const dispatch = useDispatch();

    const isScrolled = useSelector((state) => state.website.isScrolled);

    const handleScroll = () => {
        if (document.querySelector('#layout-content > div > div').scrollTop > 0) {
            dispatch(websiteActions.setIsScrolled(true));
        } else {
            dispatch(websiteActions.setIsScrolled(false));
        }
    };

    useEffect(() => {
        document.querySelector('#layout-content > div > div').removeEventListener('scroll', handleScroll);

        document.querySelector('#layout-content > div > div').addEventListener('scroll', handleScroll, { passive: true });
    }, []);

    return (
        <div className={isScrolled ? [classes.LayoutSite, 'isScrolled'].join(' ') : classes.LayoutSite}>
            <div className={classes.ContentWrapper}>
                <Topbar />

                <div className={classes.ScrollWrapper} id='layout-content'>
                    <Scrollbars
                        className={classes.Scroller}
                        style={{ width: '100%', height: '100%' }}
                        renderThumbVertical={(props) => <div {...props} className='thumb-vertical' />}
                        renderThumbHorizontal={(props) => <div {...props} className='thumb-horizontal' />}
                    >
                        <div className={classes.Content}>
                            <div className={classes.ContentInner}>
                                <Outlet />
                            </div>
                        </div>

                        <Footer />
                    </Scrollbars>
                </div>
            </div>
        </div>
    );
};

export default LayoutSite;
