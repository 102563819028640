import { NavLink, useNavigate } from 'react-router-dom';

import classes from './Footer.module.css';
import MainButton from '../UI/MainButton/MainButton';
import espa_logo from '../../assets/images/espa_logo.jpg';
import logo_white from '../../assets/images/repolis_logo_white.png';

const Footer = () => {
    const navigate = useNavigate();

    return (
        <div className={classes.FooterWrapper}>
            <div className={classes.Footer}>
                <div className={classes.TopPart}>
                    <div className={classes.Column}>
                        <img className={classes.Logo} src={logo_white} alt='Repolis logo' onClick={() => navigate('/')} />
                        <p>
                            No maps with precise façade properties available for your whole study area? Don’t worry. rePolis can combine simple 2D data maps
                            with building databases and move securely from a 2D to a 3D “ready for simulation model” for multiple urban scales.
                        </p>
                        <MainButton label='Read more' color='action' onClick={() => navigate('/about')} />
                    </div>

                    <div className={classes.Column}>
                        <h2>FUNDING</h2>
                        <p>
                            The rePolis project was co‐financed by the European Regional Development Fund of the European Union and Greek national funds through
                            the Operational Program Competitiveness, Entrepreneurship and Innovation, under the call RESEARCH – CREATE - INNOVATE (project
                            code:Τ2EDK-04301)
                        </p>
                        <div className={classes.Logos}>
                            <img src={espa_logo} alt='ESPA logo' />
                        </div>
                    </div>

                    <div className={classes.Column}>
                        <h2>LINKS</h2>
                        <ul>
                            <div className={classes.FooterLink}>
                                <div>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <polyline points='9 18 15 12 9 6'></polyline>
                                    </svg>
                                </div>
                                <NavLink to='contact' className={({ isActive }) => (isActive ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink)}>
                                    <span className={classes.ItemTitle}>Contact</span>
                                </NavLink>
                            </div>
                            <div className={classes.FooterLink}>
                                <div>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <polyline points='9 18 15 12 9 6'></polyline>
                                    </svg>
                                </div>
                                <NavLink to='pricing' className={({ isActive }) => (isActive ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink)}>
                                    <span className={classes.ItemTitle}>Pricing</span>
                                </NavLink>
                            </div>
                            <div className={classes.FooterLink}>
                                <div>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <polyline points='9 18 15 12 9 6'></polyline>
                                    </svg>
                                </div>
                                <NavLink to='about' className={({ isActive }) => (isActive ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink)}>
                                    <span className={classes.ItemTitle}>About</span>
                                </NavLink>
                            </div>
                            <div className={classes.FooterLink}>
                                <div>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <polyline points='9 18 15 12 9 6'></polyline>
                                    </svg>
                                </div>
                                <NavLink to='repolis' className={({ isActive }) => (isActive ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink)}>
                                    <span className={classes.ItemTitle}>rePolis</span>
                                </NavLink>
                            </div>
                        </ul>
                    </div>
                </div>

                <div className={classes.BottomPart}>
                    <span>Copyright &copy;2023 rePolis, All rights reserved.</span>
                </div>
            </div>
        </div>
    );
};

export default Footer;
