import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    percentUploaded: 0,
    uploadStatus: '',
    myFiles: [],
    fileError: null,
};

export const dropzoneSlice = createSlice({
    name: 'dropzone',
    initialState,
    reducers: {
        reset: (state) => {
            state.percentUploaded = 0;
            state.uploadStatus = '';
            state.fileError = null;
            state.myFiles = [];
        },
        setPercentUploaded: (state, action) => {
            state.percentUploaded = action.payload;
        },
        setUploadStatus: (state, action) => {
            state.uploadStatus = action.payload;
        },
        setMyFiles: (state, action) => {
            state.myFiles = action.payload;
        },
        setFileError: (state, action) => {
            state.fileError = action.payload;
        },
    },
});

export const dropzoneActions = dropzoneSlice.actions;

export default dropzoneSlice;
