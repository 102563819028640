import classes from './FancyList.module.css';

const FancyList = (props) => {
    return (
        <ul className={props.light ? [classes.FancyList, classes.Light].join(' ') : classes.FancyList}>
            {props.items.map((item, index) => {
                return (
                    <li key={index}>
                        <div className={classes.Circle}>
                            {props.check ? (
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <polyline points='20 6 9 17 4 12'></polyline>
                                </svg>
                            ) : (
                                index + 1
                            )}
                        </div>
                        {item}
                    </li>
                );
            })}
        </ul>
    );
};

export default FancyList;
