import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';

import classes from './MyAutocomplete.module.css';

const MyAutocompleteWeather = (props) => {
    let elementClasses = [classes.FormControl];

    if (props.fullWidth) elementClasses.push(classes.FullWidth);
    else if (props.narrow) elementClasses.push(classes.Narrow);
    else if (props.veryNarrow) elementClasses.push(classes.VeryNarrow);

    if (props.disabled) elementClasses.push(classes.Disabled);
    if (!props.label) elementClasses.push(classes.NoLabel);

    return (
        <FormControl className={elementClasses.join(' ')} style={props.style}>
            <Autocomplete
                options={props.options}
                autoHighlight
                getOptionLabel={(option) => option.label}
                value={props.value}
                defaultValue=''
                groupBy={(option) => option.group}
                onChange={(event, newValue) => {
                    props.onChange(newValue);
                }}
                renderOption={(props, option) => {
                    const period = option.period ? '(' + option.period + ')' : '';

                    return (
                        <li {...props} key={option.value}>
                            {option.label} {period} <span>-</span> <span>{option.distance} (m)</span>
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </FormControl>
    );
};

export default MyAutocompleteWeather;
