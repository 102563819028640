import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import Page404 from './features/Page404';
import Page500 from './features/Page500';

const ErrorPage = () => {
    const error = useRouteError();

    useEffect(() => {
        const theme = localStorage.getItem('theme');
        if (theme) {
            document.body.setAttribute('data-theme', theme);
        }
    }, []);

    let page = <Page404 />;

    if (error.status !== 404) {
        page = <Page500 />;
    }

    return page;
};

export default ErrorPage;
