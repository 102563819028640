import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import InitApp from './features/InitApp/InitApp';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import Login, { loader as loginLoader } from './pages/Login/Login';
import Layout from './features/Layout/Layout';
import Layout2 from './features/Layout/Layout2';
import Activation from './pages/Login/Activation';
import ResetPassword from './pages/Login/ResetPassword';
import PrivateRoute from './features/PrivateRoute/PrivateRoute';

import Dashboard from './pages/Dashboard/Dashboard';
import UserProfile from './pages/UserProfile/UserProfile';
import SystemData from './pages/SystemData/SystemData';
import SystemDataView from './pages/SystemData/SystemDataView';
import WeatherData from './pages/WeatherData/WeatherData';
import WeatherDataView from './pages/WeatherData/WeatherDataView';
import MyData from './pages/MyData/MyData';
import MyDataView from './pages/MyData/MyDataView';
import MyDataNew from './pages/MyData/MyDataNew';
import MyDataEdit from './pages/MyData/MyDataEdit';
import MyProjects from './pages/MyProjects/MyProjects';
import MyProjectsNew from './pages/MyProjects/MyProjectsNew';
import MyProjectsView from './pages/MyProjects/MyProjectsView';
import MyProjectsEdit from './pages/MyProjects/MyProjectsEdit';
import MyWeatherFiles from './pages/MyWeatherFiles/MyWeatherFiles';
import MyWeatherFilesNew from './pages/MyWeatherFiles/MyWeatherFilesNew';
import MyWeatherFilesView from './pages/MyWeatherFiles/MyWeatherFilesView';
import MyWeatherFilesEdit from './pages/MyWeatherFiles/MyWeatherFilesEdit';
import MyScenarios from './pages/MyScenarios/MyScenarios';
import MyScenariosView from './pages/MyScenarios/MyScenariosView';
import AnalysisTypes from './pages/AnalysisTypes/AnalysisTypes';
import CompareScenarios from './pages/CompareScenarios/CompareScenarios';
import Purchase from './pages/Purchase/Purchase';
import SuccessPurchase from './pages/Purchase/SuccessCheckout';
import CancelPurchase from './pages/Purchase/CancelCheckout';

// Site
import LayoutSite from './website/features/Layout/LayoutSite';
import Home from './website/pages/Home/Home';
import About from './website/pages/About/About';
import Repolis from './website/pages/Repolis/Repolis';
import Clients from './website/pages/Clients/Clients';
import Pricing from './website/pages/Pricing/Pricing';
import Contact from './website/pages/Contact/Contact';
import Order from './website/pages/Order/Order';
import CancelCheckout from './website/pages/Order/CancelCheckout';
import SuccessCheckout from './website/pages/Order/SuccessCheckout';

function App() {
    const router = createBrowserRouter([
        {
            element: <InitApp />,
            errorElement: <ErrorPage />,
            children: [
                {
                    path: '/',
                    element: <LayoutSite />,
                    children: [
                        {
                            index: true,
                            element: <Home />,
                        },
                        {
                            path: '/home',
                            element: <Home />,
                        },
                        {
                            path: '/about',
                            element: <About />,
                        },
                        {
                            path: '/repolis',
                            element: <Repolis />,
                        },
                        {
                            path: '/clients',
                            element: <Clients />,
                        },
                        {
                            path: '/pricing',
                            element: <Pricing />,
                        },
                        {
                            path: '/contact',
                            element: <Contact />,
                        },
                        {
                            path: '/order/:id',
                            element: <Order />,
                        },
                        {
                            path: '/success_payment',
                            element: <SuccessCheckout />,
                        },
                        {
                            path: '/cancel_payment',
                            element: <CancelCheckout />,
                        },
                    ],
                },
                {
                    // path: '/',
                    element: <Layout />,
                    children: [
                        {
                            // index: true,
                            element: (
                                <PrivateRoute roleId={20}>
                                    <Dashboard />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/dashboard',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <Dashboard />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/user-profile',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <UserProfile />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/myprojects',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <MyProjects />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/myprojects-new',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <MyProjectsNew />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/myprojects-view/:id',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <MyProjectsView />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/myprojects-edit/:id',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <MyProjectsEdit />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/myweatherfiles',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <MyWeatherFiles />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/myweatherfiles-new',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <MyWeatherFilesNew />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/myweatherfiles-view/:id',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <MyWeatherFilesView />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/myweatherfiles-edit/:id',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <MyWeatherFilesEdit />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/mydata',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <MyData />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/mydata-view/:id',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <MyDataView />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/mydata-edit/:id',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <MyDataEdit />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/mydata-new',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <MyDataNew />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/systemdata',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <SystemData />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/systemdata-view/:id',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <SystemDataView />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/weatherdata',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <WeatherData />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/weatherdata-view/:id',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <WeatherDataView />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/myscenarios',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <MyScenarios />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/myscenarios-view/:id',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <MyScenariosView />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/analysistypes',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <AnalysisTypes />
                                </PrivateRoute>
                            ),
                        },
                        // {
                        //     path: '/compare',
                        //     element: (
                        //         <PrivateRoute roleId={20}>
                        //             <CompareScenarios />
                        //         </PrivateRoute>
                        //     ),
                        // },
                        {
                            path: '/purchase/:id',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <Purchase />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/success_purchase',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <SuccessPurchase />
                                </PrivateRoute>
                            ),
                        },
                        {
                            path: '/cancel_purchase',
                            element: (
                                <PrivateRoute roleId={20}>
                                    <CancelPurchase />
                                </PrivateRoute>
                            ),
                        },
                    ],
                },
                {
                    element: <Layout2 />,
                    children: [
                        {
                            path: '/login',
                            element: <Login />,
                            loader: loginLoader,
                        },
                        {
                            path: '/activation/:id',
                            element: <Activation />,
                        },
                        {
                            path: '/reset-password/:id',
                            element: <ResetPassword />,
                        },
                    ],
                },
            ],
        },
    ]);

    return <RouterProvider router={router} />;
}

export default App;
