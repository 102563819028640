import axiosApi from '../../axios-api';

import { systemActions } from '../../features/systemSlice';
import { myWeatherFilesActions } from './myWeatherFilesSlice';
import { showError, showMessage } from '../../utils/messageHandler';

export const getMyWeatherData = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .get('getMyWeatherData.php')
            .then((response) => {
                dispatch(myWeatherFilesActions.setMyWeatherData(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getSelectedWeatherData = (weatherDataId, navigate) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('getSelectedWeatherData.php', { weatherDataId: weatherDataId })
            .then((response) => {
                dispatch(myWeatherFilesActions.setSelectedWeatherData(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
                navigate('/myweatherfiles');
            });
    };
};

export const saveWeatherFile = (data, dataCoordinates, dataName, dataDescription, dataElevation) => {
    return (dispatch) => {
        dispatch(myWeatherFilesActions.setSavingStatus('start'));
        const payload = { ...data, ...dataCoordinates, dataName, dataDescription, dataElevation };

        axiosApi
            .post('saveWeatherFile.php', payload)
            .then((response) => {
                showMessage('success', 'The data were saved');
                dispatch(myWeatherFilesActions.setSavingStatus('finished'));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setSavingStatus('error'));
            });
    };
};

export const deleteWeatherFile = (dataId) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('deleteWeatherFile.php', dataId)
            .then((response) => {
                showMessage('success', 'The dataset was deleted');
                dispatch(getMyWeatherData());
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const updateWeatherFile = (payload) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));
        //dispatch(myWeatherFilesActions.setSavingStatus('start'));

        axiosApi
            .post('updateWeatherFile.php', payload)
            .then((response) => {
                showMessage('success', 'The data were saved');
                // dispatch(myWeatherFilesActions.setSavingStatus('finished'));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};
