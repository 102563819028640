import classes from './Wavebox.module.css';

const Wavebox = (props) => {
    return (
        <div className={classes.Wavebox}>
            <div className={classes.Wave}></div>
            {props.children}
        </div>
    );
};

export default Wavebox;
