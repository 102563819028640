import { useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CheckoutForm from './CheckoutForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51NI9fiE6ddmX1q2ZDUGfFWjxcpGOcF4Wi8giW0aqQdKheeDPSbSFcMsef9qwZRLvEx9yz14VC8EEUvO9yVdCIR7x00RUo3X5ZI');

const Order = () => {
    const { id } = useParams();

    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm productId={id} />
        </Elements>
    );
};

export default Order;
