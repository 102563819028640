import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SimpleHeader from '../../features/SimpleHeader/SimpleHeader';
import classes from './Pricing.module.css';
import PricingCard from './PricingCard';
import Banner from '../../features/Banner/Banner';
import { getPlans } from '../websiteAsyncActions';

const Pricing = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const plans = useSelector((state) => state.website.plans);

    useEffect(() => {
        dispatch(getPlans());
    }, []);

    return (
        <div className={classes.Pricing}>
            <SimpleHeader title='Pricing' subtitle='empowering the decarbonisation of urban building stocks' />

            <div className={classes.WithMarginsWrapper}>
                <div className={classes.WithMargins}>
                    {plans.length > 0 && (
                        <div className={classes.PricingCardsList}>
                            <PricingCard
                                title={plans[0].name.toUpperCase()}
                                subtitle={`up to ${plans[0].buildings.toLocaleString('en-US')} buildings`}
                                // price={plans[0].price.toLocaleString('en-US')}
                                price='Contact us'
                                benefits={[true, true, false, true, true, true, false, false, false, false, false]}
                                // buttonLabel='Order'
                                // onButtonAction={() => navigate('/order/1')}
                                buttonLabel='Contact'
                                onButtonAction={() => navigate('/contact')}
                            />
                            <PricingCard
                                title={plans[1].name.toUpperCase()}
                                subtitle={`up to ${plans[1].buildings.toLocaleString('en-US')} buildings`}
                                // price={plans[1].price.toLocaleString('en-US')}
                                price='Contact us'
                                benefits={[true, true, false, true, true, true, false, false, false, false, false]}
                                // buttonLabel='Order'
                                // onButtonAction={() => navigate('/order/2')}
                                buttonLabel='Contact'
                                onButtonAction={() => navigate('/contact')}
                            />
                            <PricingCard
                                title={plans[2].name.toUpperCase()}
                                subtitle={`up to ${plans[2].buildings.toLocaleString('en-US')} buildings`}
                                // price={plans[2].price.toLocaleString('en-US')}
                                price='Contact us'
                                benefits={[true, true, true, true, true, true, false, false, false, false, false]}
                                // buttonLabel='Order'
                                // onButtonAction={() => navigate('/order/3')}
                                buttonLabel='Contact'
                                onButtonAction={() => navigate('/contact')}
                            />
                            <PricingCard
                                title={plans[3].name.toUpperCase()}
                                subtitle={`> ${plans[3].buildings.toLocaleString('en-US')} buildings`}
                                price='Contact us'
                                benefits={[true, true, true, true, true, true, true, true, true, true, true]}
                                buttonLabel='Contact'
                                onButtonAction={() => navigate('/contact')}
                            />
                        </div>
                    )}
                </div>
            </div>

            <Banner title='WANT TO GIVE TO TAKE A LOOK FIRST?' subtitle='We offer a free tour through the platform' />
        </div>
    );
};

export default Pricing;
