import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userData: [],
    uploadedFileDetails: null,
    dataName: '',
    dataDescription: '',
    dataCountry: null,
    dataCity: '',

    createTempDbStatus: '',
    importDataStatus: '',
    getFileFieldsStatus: '',
    fileFields: null,
    getBuildingUsesStatus: '',
    buildingUses: [],
    buildingUsesForCountry: [],
    savingStatus: '',

    selectedUserData: null,
    editBuildings: [],
    editBuildingsCount: 0,
    editBuildingsDatasetName: '',
    editDataName: '',
    editDataCity: '',
    editDataDescription: '',
};

export const myDataSlice = createSlice({
    name: 'myData',
    initialState,
    reducers: {
        resetNewData: (state) => {
            state.uploadedFileDetails = null;
            state.createTempDbStatus = '';
            state.importDataStatus = '';
            state.getFileFieldsStatus = '';
            state.fileFields = null;
            state.getBuildingUsesStatus = '';
            state.buildingUses = [];
            state.buildingUsesForCountry = [];
            state.savingStatus = '';
        },
        resetEditData: (state) => {
            state.editBuildings = [];
            state.editBuildingsCount = 0;
            state.editBuildingsDatasetName = '';
            state.editDataName = '';
            state.editDataCity = '';
            state.editDataDescription = '';
        },
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
        setUploadedFileDetails: (state, action) => {
            state.uploadedFileDetails = action.payload;
        },
        setDataName: (state, action) => {
            state.dataName = action.payload;
        },
        setDataDescription: (state, action) => {
            state.dataDescription = action.payload;
        },
        setDataCountry: (state, action) => {
            state.dataCountry = action.payload;
        },
        setDataCity: (state, action) => {
            state.dataCity = action.payload;
        },
        setCreateTempDbStatus: (state, action) => {
            state.createTempDbStatus = action.payload;
        },
        setImportDataStatus: (state, action) => {
            state.importDataStatus = action.payload;
        },
        setGetFileFieldsStatus: (state, action) => {
            state.getFileFieldsStatus = action.payload;
        },
        setFileFields: (state, action) => {
            state.fileFields = action.payload;
        },
        setGetBuildingUsesStatus: (state, action) => {
            state.getBuildingUsesStatus = action.payload;
        },
        setBuildingUses: (state, action) => {
            state.buildingUses = action.payload.buildingUses;
            state.buildingUsesForCountry = action.payload.buildingUsesForCountry;
        },
        setBuildingUsesForCountry: (state, action) => {
            state.buildingUsesForCountry = action.payload;
        },
        setSavingStatus: (state, action) => {
            state.savingStatus = action.payload;
        },
        setSelectedUserData: (state, action) => {
            state.selectedUserData = action.payload;
        },

        setEditBuildings: (state, action) => {
            state.editBuildings = action.payload.data;
            state.editBuildingsCount = action.payload.count;
            state.editBuildingsDatasetName = action.payload.name;
        },
        setEditDataInfo: (state, action) => {
            state.editDataName = action.payload.name;
            state.editDataCity = action.payload.city;
            state.editDataDescription = action.payload.description;
        },
        setEditDataName: (state, action) => {
            state.editDataName = action.payload;
        },
        setEditDataCity: (state, action) => {
            state.editDataCity = action.payload;
        },
        setEditDataDescription: (state, action) => {
            state.editDataDescription = action.payload;
        },
    },
});

export const myDataActions = myDataSlice.actions;

export default myDataSlice;
