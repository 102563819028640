import axiosApi from '../../axios-api';
import axiosRoot from 'axios';

import { systemActions } from '../../features/systemSlice';
import { myDataActions } from './myDataSlice';
import { myTableServerActions } from '../../features/UI/MyTable/myTableServerSlice';
import { dropzoneActions } from '../../features/Dropzone/dropzoneSlice';
import { showError, showMessage } from '../../utils/messageHandler';

// Get data
export const getUserData = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .get('getUserData.php')
            .then((response) => {
                dispatch(myDataActions.setUserData(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getSelectedUserData = (userDataId, navigate) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('getSelectedUserData.php', { userDataId: userDataId })
            .then((response) => {
                dispatch(myDataActions.setSelectedUserData(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
                navigate('/mydata');
            });
    };
};

// New data
// export const createTempDb = (uploadedFileDetails) => {
//     return (dispatch) => {
//         dispatch(myDataActions.setCreateTempDbStatus('start'));

//         axiosApi
//             .post('createTempDb.php', { dbName: uploadedFileDetails.dbName })
//             .then((response) => {
//                 dispatch(myDataActions.setCreateTempDbStatus('finished'));
//                 dispatch(importToTempDb(uploadedFileDetails));
//             })
//             .catch((error) => {
//                 showError(error);
//                 dispatch(myDataActions.setCreateTempDbStatus('error'));
//             });
//     };
// };

// export const importToTempDb = (uploadedFileDetails) => {
//     return (dispatch) => {
//         dispatch(myDataActions.setImportDataStatus('start'));

//         axiosApi
//             .post('importToTempDb.php', uploadedFileDetails)
//             .then((response) => {
//                 dispatch(myDataActions.setImportDataStatus('finished'));
//                 dispatch(getFileFields(uploadedFileDetails));
//             })
//             .catch((error) => {
//                 showError(error);
//                 dispatch(myDataActions.setImportDataStatus('error'));
//             });
//     };
// };

export const getFileFields = (uploadedFileDetails) => {
    return (dispatch) => {
        dispatch(myDataActions.setGetFileFieldsStatus('start'));
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('getFileFields.php', uploadedFileDetails)
            .then((response) => {
                dispatch(myDataActions.setFileFields(response.data));
                dispatch(myDataActions.setGetFileFieldsStatus('finished'));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(myDataActions.setGetFileFieldsStatus('error'));
                dispatch(dropzoneActions.setUploadStatus('generalError'));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getBuildingUses = (uploadedFileDetails, fieldsAssign, dataCountry) => {
    return (dispatch) => {
        dispatch(myDataActions.setGetBuildingUsesStatus('start'));

        const payload = { ...uploadedFileDetails, buildingType: fieldsAssign.buildingType.label, dataCountry: dataCountry };

        axiosApi
            .post('getBuildingUses.php', payload)
            .then((response) => {
                dispatch(myDataActions.setBuildingUses(response.data));
                dispatch(myDataActions.setGetBuildingUsesStatus('finished'));
            })
            .catch((error) => {
                showError(error);
                dispatch(myDataActions.setGetBuildingUsesStatus('error'));
            });
    };
};

export const getEditData = (uploadId, navigate) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        const requestOne = axiosApi.post('getDataInfo.php', uploadId);
        const requestTwo = axiosApi.post('getBuildingsUsesWithUploadId.php', uploadId);

        axiosRoot
            .all([requestOne, requestTwo])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];

                    if (responseOne.status !== 200 || responseTwo.status !== 200) {
                        showError(null);
                    } else {
                        dispatch(myDataActions.setEditDataInfo(responseOne.data));
                        dispatch(myDataActions.setBuildingUsesForCountry(responseTwo.data));
                    }

                    dispatch(systemActions.setLoading(false));
                })
            )
            .catch((errors) => {
                showError(errors);
                dispatch(systemActions.setLoading(false));
                navigate('/mydata');
            });
    };
};

export const saveDataFile = (payload) => {
    return (dispatch) => {
        dispatch(myDataActions.setSavingStatus('start'));

        axiosApi
            .post('saveDataFile.php', payload)
            .then((response) => {
                showMessage('success', 'The data were saved');
                dispatch(myDataActions.setSavingStatus('finished'));
            })
            .catch((error) => {
                showError(error);
                dispatch(myDataActions.setSavingStatus('error'));
            });
    };
};

// Delete data
export const deleteUserData = (dataId) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('deleteUserData.php', dataId)
            .then((response) => {
                showMessage('success', 'The dataset was deleted');
                dispatch(getUserData());
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};

// Data edit
export const getBuildings = (pagination, uploadId, issuesChecked) => {
    return (dispatch) => {
        const payload = { ...pagination, uploadId, issuesChecked };
        dispatch(myTableServerActions.setTableIsRefetching(true));

        axiosApi
            .post('getBuildings.php', payload)
            .then((response) => {
                dispatch(myDataActions.setEditBuildings(response.data));
                dispatch(myTableServerActions.setTableIsRefetching(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(myTableServerActions.setTableIsRefetching(false));
            });
    };
};

export const updateDataInfo = (payload) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('updateDataInfo.php', payload)
            .then((response) => {
                showMessage('success', 'The data were updated');
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const updateBuilding = (buildingData, tablePagination, id, issuesChecked) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('updateBuilding.php', buildingData)
            .then((response) => {
                showMessage('success', 'The data were updated');
                dispatch(systemActions.setLoading(false));
                dispatch(getBuildings(tablePagination, id, issuesChecked));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
        // }
    };
};

export const deleteBuilding = (buildingId, tablePagination, id, issuesChecked) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('deleteBuilding.php', buildingId)
            .then((response) => {
                showMessage('success', 'The building was deleted');
                dispatch(systemActions.setLoading(false));
                dispatch(getBuildings(tablePagination, id, issuesChecked));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};
