import { useNavigate } from 'react-router-dom';

import classes from './Banner.module.css';
import MainButton from '../UI/MainButton/MainButton';
import Wavebox2 from '../Wavebox/Wavebox2';

const Banner = (props) => {
    const navigate = useNavigate();

    return (
        <Wavebox2>
            <div className={classes.Banner}>
                <div className={classes.Title}>
                    <span>{props.title}</span>
                    <span>{props.subtitle}</span>
                </div>
                <MainButton
                    icon={
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        >
                            <line x1='22' y1='2' x2='11' y2='13'></line>
                            <polygon points='22 2 15 22 11 13 2 9 22 2'></polygon>
                        </svg>
                    }
                    label='Contact us'
                    onClick={() => navigate('/contact')}
                    color='action'
                />
            </div>
        </Wavebox2>
    );
};

export default Banner;
