import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
    myScenariosData: [],
    selectedScenario: null,
    compareData: null,
    selectedProject: null,
    selectedScenarios: [null, null],
    buildingMapValues: null,
    compareMapProperty: {
        group: 'End use breakdown',
        name: 'space_heating',
        label: 'Space heating',
        unit: '%',
        value: 'space_heating',
        isPercentage: true,
    },
    viewLegend: true,
    isFullScreen: false,
    compareResultsItems: [
        {
            group: 'End use breakdown',
            name: 'space_heating',
            label: 'Space heating',
            unit: '%',
            value: 'space_heating',
            isPercentage: true,
        },
        {
            group: 'End use breakdown',
            name: 'space_cooling',
            label: 'Space cooling',
            unit: '%',
            value: 'space_cooling',
            isPercentage: true,
        },
        {
            group: 'End use breakdown',
            name: 'space_interior_lighting',
            label: 'Interior lighting',
            unit: '%',
            value: 'space_interior_lighting',
            isPercentage: true,
        },
        {
            group: 'End use breakdown',
            name: 'space_receptacle_equipment',
            label: 'Receptacle equipment',
            unit: '%',
            value: 'space_receptacle_equipment',
            isPercentage: true,
        },
        {
            group: 'Annual energy consumption (Energy demand)',
            name: 'end_uses_demanded_heating',
            label: 'Heating',
            unit: 'kWh',
            value: 'end_uses_demanded_heating',
            isPercentage: false,
        },
        {
            group: 'Annual energy consumption (Energy demand)',
            name: 'end_uses_demanded_cooling',
            label: 'Cooling',
            unit: 'kWh',
            value: 'end_uses_demanded_cooling',
            isPercentage: false,
        },
        {
            group: 'Annual energy consumption (Energy demand)',
            name: 'end_uses_demanded_interior_lighting',
            label: 'Interior lighting',
            unit: 'kWh',
            value: 'end_uses_demanded_interior_lighting',
            isPercentage: false,
        },
        {
            group: 'Annual energy consumption (Energy demand)',
            name: 'end_uses_demanded_interior_equipment',
            label: 'Interior equipment',
            unit: 'kWh',
            value: 'end_uses_demanded_interior_equipment',
            isPercentage: false,
        },
        {
            group: 'Annual energy consumption (Energy consumption)',
            name: 'end_uses_consumed_heating',
            label: 'Heating',
            unit: 'kWh',
            value: 'end_uses_consumed_heating',
            isPercentage: false,
        },
        {
            group: 'Annual energy consumption (Energy consumption)',
            name: 'end_uses_consumed_cooling',
            label: 'Cooling',
            unit: 'kWh',
            value: 'end_uses_consumed_cooling',
            isPercentage: false,
        },
        {
            group: 'Annual energy consumption (Energy consumption)',
            name: 'end_uses_consumed_interior_lighting',
            label: 'Interior lighting',
            unit: 'kWh',
            value: 'end_uses_consumed_interior_lighting',
            isPercentage: false,
        },
        {
            group: 'Annual energy consumption (Energy consumption)',
            name: 'end_uses_consumed_interior_equipment',
            label: 'Interior equipment',
            unit: 'kWh',
            value: 'end_uses_consumed_interior_equipment',
            isPercentage: false,
        },
        {
            group: 'Annual energy consumption (Primary energy)',
            name: 'end_uses_primary_heating',
            label: 'Heating',
            unit: 'kWh',
            value: 'end_uses_primary_heating',
            isPercentage: false,
        },
        {
            group: 'Annual energy consumption (Primary energy)',
            name: 'end_uses_primary_cooling',
            label: 'Cooling',
            unit: 'kWh',
            value: 'end_uses_primary_cooling',
            isPercentage: false,
        },
        {
            group: 'Annual energy consumption (Primary energy)',
            name: 'end_uses_primary_interior_lighting',
            label: 'Interior lighting',
            unit: 'kWh',
            value: 'end_uses_primary_interior_lighting',
            isPercentage: false,
        },
        {
            group: 'Annual energy consumption (Primary energy)',
            name: 'end_uses_primary_interior_equipment',
            label: 'Interior equipment',
            unit: 'kWh',
            value: 'end_uses_primary_interior_equipment',
            isPercentage: false,
        },
        {
            group: 'Energy demand breadkdown',
            name: 'utility_use_per_conditioned_floor_area_hvac_heating',
            label: 'Heating',
            unit: 'kWh',
            value: 'utility_use_per_conditioned_floor_area_hvac_heating',
            isPercentage: false,
        },
        {
            group: 'Energy demand breadkdown',
            name: 'utility_use_per_conditioned_floor_area_hvac_cooling',
            label: 'Cooling',
            unit: 'kWh',
            value: 'utility_use_per_conditioned_floor_area_hvac_cooling',
            isPercentage: false,
        },
        {
            group: 'Energy demand breadkdown',
            name: 'utility_use_per_conditioned_floor_area_lighting',
            label: 'Interior lighting',
            unit: 'kWh',
            value: 'utility_use_per_conditioned_floor_area_lighting',
            isPercentage: false,
        },
        {
            group: 'Energy demand breadkdown',
            name: 'utility_use_per_conditioned_floor_area_other',
            label: 'Other',
            unit: 'kWh',
            value: 'utility_use_per_conditioned_floor_area_other',
            isPercentage: false,
        },
        {
            group: 'CO2 emissions per end use',
            name: 'end_uses_co2_heating',
            label: 'Heating',
            unit: 'kgCO<sub>2</sub>eq',
            value: 'end_uses_co2_heating',
            isPercentage: false,
        },
        {
            group: 'CO2 emissions per end use',
            name: 'end_uses_co2_cooling',
            label: 'Cooling',
            unit: 'kgCO<sub>2</sub>eq',
            value: 'end_uses_co2_cooling',
            isPercentage: false,
        },
        {
            group: 'CO2 emissions per end use',
            name: 'end_uses_co2_interior_lighting',
            label: 'Interior lighting',
            unit: 'kgCO<sub>2</sub>eq',
            value: 'end_uses_co2_interior_lighting',
            isPercentage: false,
        },
        {
            group: 'CO2 emissions per end use',
            name: 'end_uses_co2_interior_equipment',
            label: 'Interior equipment',
            unit: 'kgCO<sub>2</sub>eq',
            value: 'end_uses_co2_interior_equipment',
            isPercentage: false,
        },
    ],
};

export const myScenariosSlice = createSlice({
    name: 'myScenarios',
    initialState,
    reducers: {
        reset: (state, action) => {
            state.myScenariosData = [];
            state.selectedScenario = null;
            state.compareData = null;
            state.selectedProject = null;
            state.selectedScenarios = [null, null];
            state.buildingMapValues = null;
            state.compareMapProperty = {
                group: 'End use breakdown',
                name: 'space_heating',
                label: 'Space heating',
                unit: '%',
                value: 'space_heating',
                isPercentage: true,
            };
            state.viewLegend = true;
            state.isFullScreen = false;
        },
        setMyScenarios: (state, action) => {
            state.myScenariosData = action.payload;
        },
        setSelectedScenario: (state, action) => {
            state.selectedScenario = action.payload;
        },
        setCompareData: (state, action) => {
            state.compareData = action.payload;
        },
        setSelectedProject: (state, action) => {
            state.selectedProject = action.payload;
        },
        setSelectedScenarios: (state, action) => {
            state.selectedScenarios = action.payload;
        },
        setBuildingMapValues: (state, action) => {
            state.buildingMapValues = action.payload;
        },
        setCompareMapProperty: (state, action) => {
            state.compareMapProperty = action.payload;
        },
        toggleViewLegend: (state, action) => {
            state.viewLegend = !current(state).viewLegend;
        },
        setFullScreen: (state, action) => {
            state.isFullScreen = action.payload;
        },
    },
});

export const myScenariosActions = myScenariosSlice.actions;

export default myScenariosSlice;
