import axiosApi from '../../axios-api';

import { systemActions } from '../../features/systemSlice';
import { dashboardActions } from './dashboardSlice';
import { showError } from '../../utils/messageHandler';

// Get data
export const getDashboardData = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .get('getDashboardData.php')
            .then((response) => {
                dispatch(dashboardActions.setDashboardData(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};
