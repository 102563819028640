import classes from './MainSelect.module.css';

const MainSelectControlled = (props) => {
    let classNames = [classes.MainSelectWrapper];
    if (props.fullWidth) classNames.push(classes.FullWidth);
    else if (props.narrow) classNames.push(classes.Narrow);
    else if (props.veryNarrow) classNames.push(classes.VeryNarrow);

    if (props.validated && props.isValid) classNames.push(classes.IsValid);
    else if (props.validated && props.isValid === false) classNames.push(classes.IsInvalid);

    return (
        <div className={classNames.join(' ')}>
            {props.label ? <label>{props.label}</label> : null}

            <select className={classes.MainSelect} name={props.name} disabled={props.disabled} value={props.value} onChange={props.onChange}>
                {props.options.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default MainSelectControlled;
