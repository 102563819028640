import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import HighlightedText from '../../features/HighlightedText/HighlightedText';
import Badge from '../../features/UI/Badge/Badge';
import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import Widget from '../../features/Widget/Widget';
import MyTable from '../../features/UI/MyTable/MyTable';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { getWeatherData } from './weatherDataAsyncActions';
import { weatherDataActions } from './weatherDataSlice';
import classes from './WeatherData.module.css';

const WeatherData = () => {
    const dispatch = useDispatch();
    const allWeatherData = useSelector((state) => state.weatherData.allWeatherData);
    // const controller = new AbortController();

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('System data'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getWeatherData());

        return () => {
            dispatch(weatherDataActions.setWeatherData([]));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Name',
            },
            {
                accessorKey: 'operation_period',
                header: 'Period of record',
            },
            // {
            //     accessorKey: 'years_num',
            //     header: 'Number of years',
            // },
            {
                accessorKey: 'lon',
                header: 'Longitude',
            },
            {
                accessorKey: 'lat',
                header: 'Latitude',
            },
            {
                accessorKey: 'elevation',
                header: 'Elevation (m)',
            },
            {
                accessorFn: (row) => row.id,
                id: 'id',
                header: 'Actions',
                Cell: ({ cell }) => (
                    <div className={classes.TableActions}>
                        <ReactTooltip effect='solid' />

                        <Link to={`/weatherdata-view/${cell.getValue()}`} data-tip='View'>
                            <Badge
                                color='success'
                                size='small'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <circle cx='11' cy='11' r='8'></circle>
                                        <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                                    </svg>
                                }
                            />
                        </Link>
                    </div>
                ),
                size: 40,
            },
        ],
        []
    );

    return (
        <div className={classes.WeatherData}>
            <Breadcrumb link1='System' link2='/ Weather stations' />

            <div className={classes.ContentInner}>
                <div className={classes.HighlightTextWrapper}>
                    <HighlightedText type='info' text='All of the following weather stations can be used by the user in the project creation process' />
                </div>

                <Widget>
                    <MyTable data={allWeatherData} columns={columns} />
                </Widget>
            </div>
        </div>
    );
};

export default WeatherData;
