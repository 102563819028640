import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isScrolled: false,
    isMobileWidth: 800,
    sendingEmail: false,
    emailMessage: '',
    plans: [],
    checkoutLoading: false,
};

export const websiteSlice = createSlice({
    name: 'website',
    initialState,
    reducers: {
        setIsScrolled: (state, action) => {
            state.isScrolled = action.payload;
        },
        setSendingEmail: (state, action) => {
            state.sendingEmail = action.payload;
        },
        setEmailMessage: (state, action) => {
            state.emailMessage = action.payload;
        },
        setPlans: (state, action) => {
            state.plans = action.payload;
        },
        setCheckoutLoading: (state, action) => {
            state.checkoutLoading = action.payload;
        },
    },
});

export const websiteActions = websiteSlice.actions;

export default websiteSlice;
