import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Widget from '../../features/Widget/Widget';
import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { getAnalysisTypes } from './analysisTypesAsyncActions';
import { analysisTypesActions } from './analysisTypesSlice';
import classes from './AnalysisTypes.module.css';

const AnalysisTypes = () => {
    const dispatch = useDispatch();

    const analysisTypesData = useSelector((state) => state.analysisTypes.analysisTypesData);

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('Analysis types'));
        dispatch(getAnalysisTypes());

        return () => {
            dispatch(analysisTypesActions.setAnalysisTypesData([]));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch]);

    return (
        <div className={classes.AnalysisTypes}>
            <Breadcrumb link1='System' link2='/ Analysis types' />

            <div className={classes.ContentInner}>
                <div className={classes.Widgets}>
                    {analysisTypesData.map((analysisType, index) => {
                        return (
                            <Widget key={`analysisType${index}`}>
                                <div className={classes.TitleRowWithSubtitle}>
                                    <h2>{analysisType.title}</h2>
                                    <span>{analysisType.subtitle}</span>
                                </div>

                                <div className={classes.WidgetContent}>
                                    <div className={classes.Illustration} dangerouslySetInnerHTML={{ __html: analysisType.description }} />
                                    <div className={classes.Illustration} dangerouslySetInnerHTML={{ __html: analysisType.illustration }} />
                                </div>
                            </Widget>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default AnalysisTypes;
