import axiosApi from '../../axios-api';

import { searchActions } from './searchSlice';
import { showError } from '../../utils/messageHandler';

// Get data
export const getSearchData = () => {
    return (dispatch) => {
        axiosApi
            .get('getSearchData.php')
            .then((response) => {
                dispatch(searchActions.setSearchData(response.data));
            })
            .catch((error) => {
                showError(error);
            });
    };
};
