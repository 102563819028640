import { createSlice } from '@reduxjs/toolkit';

import { removeTokens } from '../../utils/auth';

const initialState = {
    isAuthenticated: false,
    showing: 'login',
    user: null,
    activationMessage: null,
    resetMessage: null,
    loginLoading: false,
    registerSuccess: false,
    resetPasswordSuccess: false,
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setUserInfo: (state, action) => {
            state.user = action.payload;
            state.isAuthenticated = true;
        },
        setShowing: (state, action) => {
            state.showing = action.payload;
        },
        setActivationMessage: (state, action) => {
            state.activationMessage = action.payload;
        },
        setResetMessage: (state, action) => {
            state.resetMessage = action.payload;
        },
        setLoginLoading: (state, action) => {
            state.loginLoading = action.payload;
        },
        setRegisterSuccess: (state, action) => {
            state.registerSuccess = action.payload;
        },
        setResetPasswordSuccess: (state, action) => {
            state.resetPasswordSuccess = action.payload;
        },
        authSuccess: (state, action) => {
            state.user = action.payload;
            state.isAuthenticated = true;
        },

        logout: (state) => {
            removeTokens();
            if (window.updateAccessTokenTimer) clearTimeout(window.updateAccessTokenTimer);

            state.isAuthenticated = false;
            state.showing = 'login';
            state.user = null;
            state.activationMessage = null;
            state.resetMessage = null;
            state.loginLoading = false;
            state.registerSuccess = false;
            state.resetPasswordSuccess = false;
        },
    },
});

export const loginActions = loginSlice.actions;

export default loginSlice;
