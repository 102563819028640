import axiosApi from '../../axios-api';

import { systemActions } from '../../features/systemSlice';
import { analysisTypesActions } from './analysisTypesSlice';
import { showError } from '../../utils/messageHandler';

export const getAnalysisTypes = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .get('getAnalysisTypes.php')
            .then((response) => {
                dispatch(analysisTypesActions.setAnalysisTypesData(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};
