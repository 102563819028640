import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import L from 'leaflet';
import { MapContainer, TileLayer, FeatureGroup, CircleMarker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { GestureHandling } from 'leaflet-gesture-handling';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import Widget from '../../features/Widget/Widget';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { weatherDataActions } from './weatherDataSlice';
import { getWeatherStation } from './weatherDataAsyncActions';
import classes from './WeatherDataView.module.css';
import Badge from '../../features/UI/Badge/Badge';

const WeatherDataView = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const tileLayer = useSelector((state) => state.system.tileLayer);
    const tileLayerAttribution = useSelector((state) => state.system.tileLayerAttribution);
    const weatherStation = useSelector((state) => state.weatherData.weatherStation);

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('Weather data view'));
        dispatch(getWeatherStation(id, navigate));
        L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

        return () => {
            dispatch(weatherDataActions.setWeatherStation(null));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch, id]);

    return (
        <div className={classes.WeatherDataView}>
            <Breadcrumb link1='System' link2='/ Weather Stations' link3='/ View Weather Station' />

            <div className={classes.ContentInner}>
                <div className={classes.Widgets}>
                    <div className={classes.Col13}>
                        <Widget>
                            <div className={classes.TitleRow}>
                                <h2>General Info</h2>
                            </div>

                            <div className={classes.WidgetContent}>
                                <div className={classes.InfoRow}>
                                    <span>Name</span>
                                    <span>{weatherStation?.name}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Period of record</span>
                                    <span>{weatherStation?.operation_period}</span>
                                </div>
                                {/* <div className={classes.InfoRow}>
                                    <span>Number of years</span>
                                    <span>{weatherStation?.years_num}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>WMO</span>
                                    <span>{weatherStation?.wmo}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Time zone</span>
                                    <span>{weatherStation?.timezone}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Climate zone</span>
                                    <span>{weatherStation?.climate_zone}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Heating</span>
                                    <span>{weatherStation?.heating}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Cooling</span>
                                    <span>{weatherStation?.cooling}</span>
                                </div> */}
                                <div className={classes.InfoRow}>
                                    <span>Codes</span>
                                    <span>{weatherStation?.codes}</span>
                                </div>
                            </div>
                        </Widget>
                    </div>

                    <div className={classes.Col34}>
                        <Widget>
                            <div className={classes.TitleRowWithSubtitle}>
                                <h2>Projects</h2>
                                <span>The data is used in the following projects</span>
                            </div>

                            <div className={classes.WidgetContent}>
                                {weatherStation?.projects.length === 0 ? (
                                    <div className={classes.NoData}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        >
                                            <circle cx='12' cy='12' r='10'></circle>
                                            <line x1='4.93' y1='4.93' x2='19.07' y2='19.07'></line>
                                        </svg>

                                        <span>The dataset is not used in any projects</span>
                                    </div>
                                ) : (
                                    <div className={classes.Projects}>
                                        {weatherStation?.projects.map((project, index) => {
                                            return (
                                                <div key={index} className={classes.InfoRow}>
                                                    <span>{project.name}</span>
                                                    <div
                                                        className={classes.BadgeWrapper}
                                                        onClick={() => navigate(`/myprojects-view/${project.id}`)}
                                                        data-tip='View data'
                                                    >
                                                        <Badge
                                                            color='success'
                                                            size='small'
                                                            icon={
                                                                <svg
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    fill='none'
                                                                    stroke='currentColor'
                                                                    strokeWidth='2'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                >
                                                                    <circle cx='11' cy='11' r='8'></circle>
                                                                    <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                                                                </svg>
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </Widget>
                    </div>

                    <div className={classes.Col12}>
                        <Widget>
                            <div className={classes.TitleRow}>
                                <h2>Location</h2>
                            </div>

                            <div className={classes.WidgetContent}>
                                <div className={classes.InfoRow}>
                                    <span>Latitude</span>
                                    <span>{weatherStation?.lat}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Longitude</span>
                                    <span>{weatherStation?.lon}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Elevation (m)</span>
                                    <span>{weatherStation?.elevation}</span>
                                </div>
                            </div>
                        </Widget>
                    </div>

                    <div className={classes.Col24}>
                        <Widget noPadding>
                            <div className={classes.Location}>
                                {weatherStation ? (
                                    <MapContainer
                                        center={[weatherStation?.lat, weatherStation?.lon]}
                                        zoom={14}
                                        maxZoom={17}
                                        gestureHandling={true}
                                        gestureHandlingOptions={{ duration: 5000 }}
                                    >
                                        <TileLayer attribution={tileLayerAttribution} url={tileLayer} />

                                        <FeatureGroup>
                                            <CircleMarker
                                                center={{
                                                    lat: weatherStation?.lat,
                                                    lng: weatherStation?.lon,
                                                }}
                                                fillColor='red'
                                                fillOpacity={0.5}
                                                color='red'
                                                opacity={1}
                                                radius={10}
                                            ></CircleMarker>
                                            {/* <GeoJSON data={weatherStation?.geom} /> */}
                                        </FeatureGroup>
                                    </MapContainer>
                                ) : null}
                            </div>
                        </Widget>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WeatherDataView;
