import axiosApi from '../../axios-api';

import { websiteActions } from './websiteSlice';

// Get data
export const sendContactEmail = (payload) => {
    return (dispatch) => {
        dispatch(websiteActions.setSendingEmail(true));
        dispatch(websiteActions.setEmailMessage(''));
        axiosApi
            .post('sendContactEmail.php', payload)
            .then((response) => {
                dispatch(websiteActions.setEmailMessage('Your submission was successful.'));
                dispatch(websiteActions.setSendingEmail(false));
            })
            .catch((error) => {
                dispatch(websiteActions.setEmailMessage('Error! Something went wrong.'));
                dispatch(websiteActions.setSendingEmail(false));
            });
    };
};

export const checkout = (productId) => {
    return (dispatch) => {
        dispatch(websiteActions.setCheckoutLoading(true));

        axiosApi
            .post('checkout.php', productId)
            .then((response) => {
                dispatch(websiteActions.setCheckoutLoading(false));
                let url = response.data;
                url = url.replace(/\s+/g, ''); // remove weird space after https in url

                window.location.href = url;
            })
            .catch((error) => {
                dispatch(websiteActions.setCheckoutLoading(false));
            });
    };
};

export const getPlans = () => {
    return (dispatch) => {
        axiosApi
            .get('getPlans.php')
            .then((response) => {
                dispatch(websiteActions.setPlans(response.data));
            })
            .catch((error) => {});
    };
};
