import { NavLink, useLocation } from 'react-router-dom';

import classes from './Navigation.module.css';

const Navigation = () => {
    const { pathname } = useLocation();

    return (
        <ul className={classes.Navigation}>
            <NavLink
                to='/'
                className={({ isActive }) => (isActive || pathname.includes('home') ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink)}
            >
                <span className={classes.ItemTitle}>Home</span>
            </NavLink>

            <NavLink to='/about' className={({ isActive }) => (isActive ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink)}>
                <span className={classes.ItemTitle}>About</span>
            </NavLink>

            <NavLink to='repolis' className={({ isActive }) => (isActive ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink)}>
                <span className={classes.ItemTitle}>rePolis</span>
            </NavLink>

            <NavLink to='clients' className={({ isActive }) => (isActive ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink)}>
                <span className={classes.ItemTitle}>Clients</span>
            </NavLink>

            <NavLink to='pricing' className={({ isActive }) => (isActive ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink)}>
                <span className={classes.ItemTitle}>Pricing</span>
            </NavLink>

            <NavLink to='contact' className={({ isActive }) => (isActive ? [classes.NavLink, classes.Active].join(' ') : classes.NavLink)}>
                <span className={classes.ItemTitle}>Contact</span>
            </NavLink>
        </ul>
    );
};

export default Navigation;
