export function fNum2Dec(num) {
    if (num === null || num === undefined) return '';

    let formatted = parseFloat(num).toFixed(2);
    return formatted;
}

export function fNumMaxDec(num, dec) {
    if (num === null || num === undefined) return '';

    let formatted = +parseFloat(num).toFixed(dec);
    return formatted;
}

export function fNumSep(num) {
    if (!num) return '';
    if (typeof num === 'string') num = parseFloat(num);

    let formatted = num.toLocaleString();
    return formatted;
}
