import axiosApi from '../../axios-api';

import { systemActions } from '../../features/systemSlice';
import { weatherDataActions } from './weatherDataSlice';
import { showError } from '../../utils/messageHandler';

export const getWeatherData = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .get('getWeatherData.php')
            .then((response) => {
                dispatch(weatherDataActions.setWeatherData(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getWeatherStation = (weatherStationId, navigate) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('getWeatherStation.php', { weatherStationId: weatherStationId })
            .then((response) => {
                dispatch(weatherDataActions.setWeatherStation(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
                navigate('/weatherdata');
            });
    };
};
