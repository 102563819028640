import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

import classes from './Backdrop.module.css';
import { layoutActions } from '../../features/Layout/layoutSlice';

const Backdrop = () => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width:700px)');

    const toggleMenuHandler = () => {
        dispatch(layoutActions.toggleMenuClosed());
    };

    const menuIsClosed = useSelector((state) => state.layout.menuIsClosed);

    return <div className={!menuIsClosed && isMobile ? [classes.Backdrop, classes.Show].join(' ') : classes.Backdrop} onClick={toggleMenuHandler}></div>;
};

export default Backdrop;
