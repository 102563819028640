import axiosApi from '../../axios-api';
import jwt from 'jwt-decode';

import { systemActions } from '../../features/systemSlice';
import { loginActions } from './loginSlice';
import { getAccessToken, getRefreshToken } from '../../utils/auth';
import { showError, showMessage } from '../../utils/messageHandler';

export const register = (registerInfo) => {
    return (dispatch) => {
        dispatch(loginActions.setLoginLoading(true));

        axiosApi
            .post('register.php', registerInfo)
            .then((response) => {
                dispatch(loginActions.setLoginLoading(false));
                dispatch(loginActions.setRegisterSuccess(true));
                showMessage('success', 'You are now registered. Please activate your account');
            })
            .catch((error) => {
                showError(error);
                dispatch(loginActions.setLoginLoading(false));
            });
    };
};

export const activation = (activationKey) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('accountActivation.php', { activationKey: activationKey })
            .then((response) => {
                dispatch(loginActions.setActivationMessage({ message: response.data, type: 'success' }));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again.';
                if (error?.response?.data?.message) message = error.response.data.message;
                if (error.code !== 'ERR_CANCELED') dispatch(loginActions.setActivationMessage({ message: message, type: 'error' }));
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const sendResetPasswordEmail = (email) => {
    return (dispatch) => {
        dispatch(loginActions.setLoginLoading(true));

        axiosApi
            .post('sendResetPasswordEmail.php', { email: email })
            .then((response) => {
                showMessage('success', response.data);
                // dispatch(loginActions.setActivationMessage({ message: response.data, type: 'success' }));
                dispatch(loginActions.setLoginLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(loginActions.setLoginLoading(false));
            });
    };
};

export const getResetKeyExists = (resetKey, navigate) => {
    return (dispatch) => {
        dispatch(loginActions.setLoginLoading(true));

        axiosApi
            .post('getResetKeyExists.php', { resetKey: resetKey })
            .then((response) => {
                dispatch(loginActions.setLoginLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(loginActions.setLoginLoading(false));
                navigate('/login');
            });
    };
};

export const resetPassword = (resetKey, newPassword) => {
    return (dispatch) => {
        dispatch(loginActions.setLoginLoading(true));

        axiosApi
            .post('resetPassword.php', { resetKey: resetKey, newPassword: newPassword })
            .then((response) => {
                showMessage('success', response.data);
                // dispatch(loginActions.setResetMessage({ message: response.data, type: 'success' }));
                dispatch(loginActions.setResetPasswordSuccess(true));
                dispatch(loginActions.setLoginLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(loginActions.setLoginLoading(false));
            });
    };
};

export const login = (loginInfo, navigate) => {
    return async (dispatch) => {
        dispatch(loginActions.setLoginLoading(true));

        try {
            const response = await axiosApi.post('checkLogin.php', loginInfo);

            if (response.data.token !== undefined && response.data.refreshToken !== undefined) {
                localStorage.setItem('accessToken', response.data.token);
                localStorage.setItem('refreshToken', response.data.refreshToken);

                const user = jwt(response.data.token);
                dispatch(loginActions.authSuccess(user));

                navigate('/dashboard');
            }

            dispatch(loginActions.setLoginLoading(false));
        } catch (error) {
            showError(error);
            dispatch(loginActions.setLoginLoading(false));
        }
    };
};

export const setAccessTokenTimer = () => {
    return (dispatch) => {
        const token = getAccessToken();
        const tokenInfo = jwt(token);
        const now = new Date();
        const expirationDate = new Date(tokenInfo.exp * 1000);
        const tokenDuration = expirationDate.getTime() - now.getTime() - 3000; // Subtract 3 seconds to be sure that the token has not expired

        if (tokenDuration < 0) dispatch(loginActions.logout());

        if (window.updateAccessTokenTimer) clearTimeout(window.updateAccessTokenTimer);
        window.updateAccessTokenTimer = setTimeout(() => {
            dispatch(updateAccessToken());
        }, tokenDuration);
    };
};

export const updateAccessToken = () => {
    return async (dispatch) => {
        try {
            const refreshToken = getRefreshToken();
            if (!refreshToken) dispatch(loginActions.logout());

            const response = await axiosApi.post('tokensRefresh.php', { refreshToken: refreshToken });

            if (response.data.token !== undefined) {
                localStorage.setItem('accessToken', response.data.token);
                dispatch(setAccessTokenTimer());
            } else {
                throw Error();
            }
        } catch (error) {
            dispatch(loginActions.logout());
        }
    };
};
