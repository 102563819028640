import SimpleHeader from '../../features/SimpleHeader/SimpleHeader';
import classes from './Clients.module.css';
import Client from './Client';
import Banner from '../../features/Banner/Banner';

const Clients = () => {
    return (
        <div className={classes.Clients}>
            <SimpleHeader
                title='Clients'
                subtitle="rePolis is designed to serve urban buildings' retrofitting stakeholders from the private and public sector."
            />

            <div className={classes.WithMarginsWrapper}>
                <div className={classes.WithMargins}>
                    <h3>Our clients consist of:</h3>
                    <ul className={classes.ClientsList}>
                        <Client name='ESCOs' />
                        <Client name='REITs' />
                        <Client name="The energy efficiency and smart cities' software industry" />
                        <Client name='Cities and local authorities' />
                        <Client name='Energy Communities' />
                        <Client name='Energy regulators' />
                        <Client name='Researchers' />
                        <Client name='Consultants' />
                    </ul>
                </div>
            </div>

            <Banner title='CURIOUS FOR MORE?' subtitle='We offer a free tour through the platform' />
        </div>
    );
};

export default Clients;
