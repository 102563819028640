import axiosApi from '../../axios-api';

import { systemActions } from '../../features/systemSlice';
import { systemDataActions } from './systemDataSlice';
import { showError } from '../../utils/messageHandler';

export const getSystemData = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .get('getSystemData.php')
            .then((response) => {
                dispatch(systemDataActions.setSystemData(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getSelectedSystemData = (systemDataId, navigate) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('getSelectedSystemData.php', { systemDataId: systemDataId })
            .then((response) => {
                dispatch(systemDataActions.setSelectedSystemData(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
                navigate('/systemdata');
            });
    };
};

export const checkout = (productId) => {
    return (dispatch) => {
        dispatch(systemDataActions.setCheckoutLoading(true));

        axiosApi
            .post('checkoutProduct.php', productId)
            .then((response) => {
                dispatch(systemDataActions.setCheckoutLoading(false));
                let url = response.data;
                url = url.replace(/\s+/g, ''); // remove weird space after https in url

                window.location.href = url;
            })
            .catch((error) => {
                showError(error);
                dispatch(systemDataActions.setCheckoutLoading(false));
            });
    };
};
