import SimpleHeader from '../../features/SimpleHeader/SimpleHeader';
import classes from './Repolis.module.css';
import ImageWithList from '../../features/ImageWithList/ImageWithList';
import image8 from '../../assets/images/image8.jpg';
import FancyList from '../../features/UI/FancyList/FancyList';
import Banner from '../../features/Banner/Banner';

const Repolis = () => {
    return (
        <div className={classes.Repolis}>
            <SimpleHeader title='rePolis' subtitle='empowering the decarbonisation of urban building stocks' />

            <div className={classes.WithMarginsWrapper}>
                <div className={classes.WithMargins}>
                    <ImageWithList image={image8} title='MAIN FEATURES'>
                        <FancyList
                            items={[
                                <span>Fully cloud-based platform</span>,
                                <span>Access to reliable building data</span>,
                                <span>Able to work with low LOD levels</span>,
                                <span>2d maps compatibility</span>,
                                <span>Scalability up to 500.000 buildings</span>,
                                <span>Dynamic energy simulation per building unit</span>,
                                <span>Interactive results </span>,
                                <span>Retrofitting measures compatible with your national EPBD standards</span>,
                            ]}
                            check
                        />
                    </ImageWithList>
                </div>
            </div>

            <Banner title='CURIOUS FOR MORE?' subtitle='We offer a free tour through the platform' />
        </div>
    );
};

export default Repolis;
