import React, { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import AllChartsEnergy from './AllChartsEnergy';
import AllChartsGeneral from './AllChartsGeneral';
import AllChartsEmissions from './AllChartsEmissions';
import classes from './AllCharts.module.css';

const AllCharts = (props) => {
    const [showTab, setShowTab] = useState('general');

    return (
        <div className={classes.AllCharts}>
            <div className={classes.Tabs}>
                <div className={showTab === 'general' ? [classes.Tab, classes.Active].join(' ') : classes.Tab} onClick={() => setShowTab('general')}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <circle cx='12' cy='12' r='10'></circle>
                        <line x1='12' y1='16' x2='12' y2='12'></line>
                        <line x1='12' y1='8' x2='12.01' y2='8'></line>
                    </svg>
                    <span>General</span>
                </div>

                <div className={showTab === 'energy' ? [classes.Tab, classes.Active].join(' ') : classes.Tab} onClick={() => setShowTab('energy')}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <polygon points='13 2 3 14 12 14 11 22 21 10 12 10 13 2'></polygon>
                    </svg>
                    <span>Energy</span>
                </div>

                <div className={showTab === 'emissions' ? [classes.Tab, classes.Active].join(' ') : classes.Tab} onClick={() => setShowTab('emissions')}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <path d='M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z'></path>
                    </svg>
                    <span>Emissions</span>
                </div>
            </div>

            <div className={classes.TabContent}>
                <Scrollbars
                    style={{ width: '100%', height: '100%' }}
                    renderThumbVertical={(props) => <div {...props} className='thumb-vertical' />}
                    renderThumbHorizontal={(props) => <div {...props} className='thumb-horizontal' />}
                >
                    {showTab === 'general' && <AllChartsGeneral selectedFeature={props.selectedFeature} />}
                    {showTab === 'energy' && <AllChartsEnergy selectedFeature={props.selectedFeature} />}
                    {showTab === 'emissions' && <AllChartsEmissions selectedFeature={props.selectedFeature} />}
                </Scrollbars>
            </div>
        </div>
    );
};

export default AllCharts;
