import axiosApi from '../../axios-api';

import { systemActions } from '../../features/systemSlice';
import { myProjectsActions } from './myProjectsSlice';
import { showError, showMessage } from '../../utils/messageHandler';

export const getUserProjects = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .get('getUserProjects.php')
            .then((response) => {
                dispatch(myProjectsActions.setUserProjects(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getProjectInitData = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .get('getAnalysisTypes.php')
            .then((response) => {
                dispatch(myProjectsActions.setAnalysisTypes(response.data));
                dispatch(getUserData());
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getUserData = () => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .get('getUserData.php')
            .then((response) => {
                dispatch(myProjectsActions.setUserData(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getWeatherDataWithDistance = (projectId) => {
    return (dispatch) => {
        dispatch(myProjectsActions.setListLoading(true));

        axiosApi
            .post('getWeatherDataWithDistance.php', projectId)
            .then((response) => {
                dispatch(myProjectsActions.setWeatherDataWithDistance(response.data));
                dispatch(myProjectsActions.setListLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(myProjectsActions.setListLoading(false));
            });
    };
};

export const saveProject = (projectName, projectDescription, selectedUserData, selectedWeatherData, selectedBuildings) => {
    return (dispatch) => {
        dispatch(myProjectsActions.setSavingProjectStatus('started'));

        const dataId = selectedUserData.value;
        const weatherId = selectedWeatherData.value.substring(1); // remove the first character, which indicates if it is user defined or system
        const firstChar = selectedWeatherData.value.substring(0, 1);
        let weatherType = 'user';
        if (firstChar === 's') weatherType = 'system';
        let sBuildings = [...selectedBuildings];
        if (selectedBuildings.length === 0) sBuildings = null;

        const payload = { projectName, projectDescription, dataId, weatherId, weatherType, selectedBuildings: sBuildings };

        axiosApi
            .post('saveProject.php', payload)
            .then((response) => {
                showMessage('success', 'The project was saved');
                dispatch(myProjectsActions.setSavingProjectStatus('finished'));
            })
            .catch((error) => {
                showError(error);
                dispatch(myProjectsActions.setSavingProjectStatus('error'));
            });
    };
};

export const deleteProject = (projectId) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('deleteProject.php', projectId)
            .then((response) => {
                showMessage('success', 'The project was deleted');
                dispatch(getUserProjects());
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};

// Buildings
export const getBuildingsGeoJson = (id, type = 'user') => {
    return (dispatch) => {
        dispatch(myProjectsActions.setLoadingBuildingsStatus('started'));

        axiosApi
            .post('getBuildingsGeoJson.php', { id: id, type: type })
            .then((response) => {
                dispatch(myProjectsActions.setBuildingsGeoJson(response.data));
                dispatch(myProjectsActions.setLoadingBuildingsStatus('finished'));
            })
            .catch((error) => {
                showError(error);
                dispatch(myProjectsActions.setLoadingBuildingsStatus('error'));
            });
    };
};

export const getSelectedProject = (projectId, navigate) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('getSelectedProject.php', { projectId: projectId })
            .then((response) => {
                dispatch(myProjectsActions.setSelectedProject(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
                navigate('/myprojects');
            });
    };
};

export const updateProject = (payload) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('updateProject.php', payload)
            .then((response) => {
                showMessage('success', 'The data were saved');
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};

//Scenarios
export const getScenarioMetrics = (simulationId, projectId) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('getScenarioMetrics.php', { simulationId: simulationId, projectId: projectId })
            .then((response) => {
                dispatch(myProjectsActions.setScenarioMetric({ simulationId: simulationId, metrics: response.data }));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};

export const getScenarioFilteredMetrics = (simulationId, filters) => {
    return (dispatch) => {
        dispatch(systemActions.setLoading(true));

        axiosApi
            .post('getScenarioFilteredMetrics.php', { simulationId: simulationId, filters: filters })
            .then((response) => {
                dispatch(myProjectsActions.setScenarioFilteredMetrics(response.data));
                dispatch(systemActions.setLoading(false));
            })
            .catch((error) => {
                showError(error);
                dispatch(systemActions.setLoading(false));
            });
    };
};
