import classes from './TeamCard.module.css';

const TeamCard = (props) => {
    return (
        <div className={classes.TeamCard}>
            <h3>{props.name}</h3>
            <span>{props.position}</span>
            <img src={props.image} alt='Team member' />
            <p>{props.description}</p>
        </div>
    );
};

export default TeamCard;
