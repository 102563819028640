import React from 'react';

import NavigationItem from './NavigationItem/NavigationItem';
import classes from './Navigation.module.css';

const Navigation = () => {
    return (
        <ul className={classes.Navigation}>
            <NavigationItem
                icon={
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <path d='M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z'></path>
                        <polyline points='9 22 9 12 15 12 15 22'></polyline>
                    </svg>
                }
                title='Dashboard'
                link='/dashboard'
                location={['/', '/dashboard']}
                isTall
                isOpaque
            />

            <NavigationItem
                icon={
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <circle cx='12' cy='12' r='10'></circle>
                        <line x1='2' y1='12' x2='22' y2='12'></line>
                        <path d='M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z'></path>
                    </svg>
                }
                title='rePolis website'
                link='/'
                // outsideLink='https://google.com'
                location={[]}
            />

            {/* Group title */}
            <div className={classes.GroupTitleWrapper}>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                >
                    <line x1='5' y1='12' x2='19' y2='12'></line>
                </svg>
                <span className={classes.GroupTitle}>USER</span>
            </div>

            <NavigationItem
                icon={
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2'></path>
                        <circle cx='12' cy='7' r='4'></circle>
                    </svg>
                }
                title='Profile'
                link='/user-profile'
                location={['/user-profile']}
            ></NavigationItem>

            <NavigationItem
                icon={
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <ellipse cx='12' cy='5' rx='9' ry='3'></ellipse>
                        <path d='M21 12c0 1.66-4 3-9 3s-9-1.34-9-3'></path>
                        <path d='M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5'></path>
                    </svg>
                }
                title='My data'
                link='/mydata'
                location={['/mydata', '/mydata-new', '/mydata-view', '/mydata-edit']}
            ></NavigationItem>

            <NavigationItem
                icon={
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <line x1='8' y1='19' x2='8' y2='21'></line>
                        <line x1='8' y1='13' x2='8' y2='15'></line>
                        <line x1='16' y1='19' x2='16' y2='21'></line>
                        <line x1='16' y1='13' x2='16' y2='15'></line>
                        <line x1='12' y1='21' x2='12' y2='23'></line>
                        <line x1='12' y1='15' x2='12' y2='17'></line>
                        <path d='M20 16.58A5 5 0 0 0 18 7h-1.26A8 8 0 1 0 4 15.25'></path>
                    </svg>
                }
                title='My weather files'
                link='/myweatherfiles'
                location={['/myweatherfiles', '/myweatherfiles-new', '/myweatherfiles-view', '/myweatherfiles-edit']}
            ></NavigationItem>

            <NavigationItem
                icon={
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <line x1='8' y1='6' x2='21' y2='6'></line>
                        <line x1='8' y1='12' x2='21' y2='12'></line>
                        <line x1='8' y1='18' x2='21' y2='18'></line>
                        <line x1='3' y1='6' x2='3.01' y2='6'></line>
                        <line x1='3' y1='12' x2='3.01' y2='12'></line>
                        <line x1='3' y1='18' x2='3.01' y2='18'></line>
                    </svg>
                }
                title='My projects'
                link='/myprojects'
                location={['/myprojects', '/myprojects-new', '/myprojects-view', '/myprojects-edit']}
            ></NavigationItem>

            <NavigationItem
                icon={
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <polyline points='21 8 21 21 3 21 3 8'></polyline>
                        <rect x='1' y='3' width='22' height='5'></rect>
                        <line x1='10' y1='12' x2='14' y2='12'></line>
                    </svg>
                }
                title='My scenarios'
                link='/myscenarios'
                location={['/myscenarios', '/myscenarios-view', '/compare']}
            ></NavigationItem>

            {/* Group title */}
            <div className={classes.GroupTitleWrapper}>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                >
                    <line x1='5' y1='12' x2='19' y2='12'></line>
                </svg>

                <span className={classes.GroupTitle}>SYSTEM</span>
            </div>

            <NavigationItem
                icon={
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <ellipse cx='12' cy='5' rx='9' ry='3'></ellipse>
                        <path d='M21 12c0 1.66-4 3-9 3s-9-1.34-9-3'></path>
                        <path d='M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5'></path>
                    </svg>
                }
                title='System data'
                link='/systemdata'
                location={['/systemdata', '/systemdata-view', '/purchase']}
            ></NavigationItem>

            <NavigationItem
                icon={
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <line x1='8' y1='19' x2='8' y2='21'></line>
                        <line x1='8' y1='13' x2='8' y2='15'></line>
                        <line x1='16' y1='19' x2='16' y2='21'></line>
                        <line x1='16' y1='13' x2='16' y2='15'></line>
                        <line x1='12' y1='21' x2='12' y2='23'></line>
                        <line x1='12' y1='15' x2='12' y2='17'></line>
                        <path d='M20 16.58A5 5 0 0 0 18 7h-1.26A8 8 0 1 0 4 15.25'></path>
                    </svg>
                }
                title='Weather stations'
                link='/weatherdata'
                location={['/weatherdata', '/weatherdata-view']}
            ></NavigationItem>

            <NavigationItem
                icon={
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <circle cx='12' cy='12' r='3'></circle>
                        <path d='M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z'></path>
                    </svg>
                }
                title='Analysis types'
                link='/analysistypes'
                location={['/analysistypes']}
            ></NavigationItem>
        </ul>
    );
};

export default Navigation;
