import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { MapContainer, FeatureGroup, GeoJSON, TileLayer, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import ReactTooltip from 'react-tooltip';

import ProjectMap from './features/ProjectMap';
import MainButton from '../../features/UI/MainButton/MainButton';
import Modal from '../../features/UI/Modal/Modal';
import Badge from '../../features/UI/Badge/Badge';
import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import Widget from '../../features/Widget/Widget';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { myProjectsActions } from './myProjectsSlice';
import { myScenariosActions } from '../MyScenarios/myScenariosSlice';
import { getSelectedProject, getBuildingsGeoJson } from './MyProjectsAsyncActions';
import classes from './MyProjectsView.module.css';

const MyProjectsView = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const featureGroupRef = useRef(null);

    const isFullScreen = useSelector((state) => state.myProjects.isFullScreen);
    const selectedProject = useSelector((state) => state.myProjects.selectedProject);
    const selectedScenario = useSelector((state) => state.myProjects.selectedScenario);
    const selectedProjectScenarios = useSelector((state) => state.myProjects.selectedProjectScenarios);
    const loadingBuildingsStatus = useSelector((state) => state.myProjects.loadingBuildingsStatus);
    const tileLayer = useSelector((state) => state.system.tileLayer);
    const tileLayerAttribution = useSelector((state) => state.system.tileLayerAttribution);
    const buildingsGeoJson = useSelector((state) => state.myProjects.buildingsGeoJson);

    const [map, setMap] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('My projects view'));
        dispatch(getSelectedProject(id, navigate));

        return () => {
            dispatch(myProjectsActions.setSelectedProject(null));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch, id]);

    useEffect(() => {
        if (!map) return;
        if (!featureGroupRef.current) return;
        map.fitBounds(featureGroupRef.current.getBounds());
    }, [map]);

    const showSelectedBuildings = () => {
        dispatch(getBuildingsGeoJson(selectedProject.data_id));
        setModalOpen(true);
    };

    return (
        <div className={isFullScreen ? [classes.MyProjectsView, classes.MapFullScreen].join(' ') : classes.MyProjectsView}>
            <Breadcrumb link1='User' link2='/ My projects' link3='/ View project' />

            <div className={classes.ContentInner}>
                <div className={classes.WidgetsOuter}>
                    <div className={classes.Widgets}>
                        <Widget>
                            <div className={classes.TitleRow}>
                                <h2>General Info</h2>
                            </div>

                            <div className={classes.WidgetContent}>
                                <div className={classes.InfoRow}>
                                    <span>Name</span>
                                    <span>{selectedProject?.name}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Description</span>
                                    <span>{selectedProject?.description}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Date created</span>
                                    <span>{selectedProject?.date_created}</span>
                                </div>
                            </div>
                        </Widget>

                        <Widget>
                            <div className={classes.TitleRow}>
                                <h2>Data</h2>
                            </div>

                            <div className={classes.WidgetContent}>
                                <ReactTooltip effect='solid' />

                                <div className={classes.InfoRow}>
                                    <span>Building dataset</span>
                                    <span>{selectedProject?.data_name}</span>
                                    <div
                                        className={classes.BadgeWrapper}
                                        onClick={() => navigate(`/mydata-view/${selectedProject?.data_id}`)}
                                        data-tip='View data'
                                    >
                                        <Badge
                                            color='success'
                                            size='small'
                                            icon={
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='24'
                                                    height='24'
                                                    viewBox='0 0 24 24'
                                                    fill='none'
                                                    stroke='currentColor'
                                                    strokeWidth='2'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                >
                                                    <circle cx='11' cy='11' r='8'></circle>
                                                    <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </div>

                                <div className={classes.InfoRow}>
                                    <span>Subset</span>
                                    {selectedProject?.buildings_subselection === null && <span>No subset</span>}
                                    {selectedProject?.buildings_subselection && (
                                        <>
                                            <span>{selectedProject.buildings_subselection.split(',').length} buildings selected</span>
                                            <div className={classes.BadgeWrapper} onClick={showSelectedBuildings} data-tip='View subset'>
                                                <Badge
                                                    color='success'
                                                    size='small'
                                                    icon={
                                                        <svg
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                        >
                                                            <circle cx='11' cy='11' r='8'></circle>
                                                            <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                                                        </svg>
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>

                                <div className={classes.InfoRow}>
                                    <span>Weather dataset</span>
                                    <span>{selectedProject?.weather_name}</span>
                                    <div
                                        className={classes.BadgeWrapper}
                                        onClick={() => {
                                            selectedProject.weather_type === 'user'
                                                ? navigate(`/myweatherfiles-view/${selectedProject?.weather_id}`)
                                                : navigate(`/weatherdata-view/${selectedProject?.weather_id}`);
                                        }}
                                        data-tip='View weather data'
                                    >
                                        <Badge
                                            color='success'
                                            size='small'
                                            icon={
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='24'
                                                    height='24'
                                                    viewBox='0 0 24 24'
                                                    fill='none'
                                                    stroke='currentColor'
                                                    strokeWidth='2'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                >
                                                    <circle cx='11' cy='11' r='8'></circle>
                                                    <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </Widget>

                        <Widget noPadding>
                            <div className={classes.ScenariosBackground}>
                                <div className={classes.ScenariosInner}>
                                    <div className={classes.ScenariosTitleRow}>
                                        <h2>Scenarios</h2>
                                    </div>

                                    <div className={classes.WidgetContent}>
                                        {selectedProjectScenarios?.map((projectScenario, index) => {
                                            return (
                                                <div key={index} className={classes.ScenarioInfoRow}>
                                                    <div className={classes.ScenarioName}>
                                                        <span>{projectScenario.analysis_name}</span>
                                                        {projectScenario.id === 0 && <span className={classes.NotRunMsg}>You have not run this scenario</span>}
                                                    </div>

                                                    <div className={classes.ScenarioButtonWrapper}>
                                                        <ReactTooltip effect='solid' />
                                                        {projectScenario.id > 0 ? (
                                                            <div
                                                                className={classes.BadgeWrapper}
                                                                onClick={() => navigate(`/myscenarios-view/${projectScenario.id}`)}
                                                                data-tip='View scenario'
                                                            >
                                                                <Badge
                                                                    color='white'
                                                                    size='small'
                                                                    icon={
                                                                        <svg
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            width='24'
                                                                            height='24'
                                                                            viewBox='0 0 24 24'
                                                                            fill='none'
                                                                            stroke='currentColor'
                                                                            strokeWidth='2'
                                                                            strokeLinecap='round'
                                                                            strokeLinejoin='round'
                                                                        >
                                                                            <circle cx='11' cy='11' r='8'></circle>
                                                                            <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                                                                        </svg>
                                                                    }
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={classes.BadgeWrapper}
                                                                onClick={() => console.log(projectScenario.analysis_type_id)}
                                                                data-tip='Run scenario'
                                                            >
                                                                <Badge
                                                                    color='white'
                                                                    size='small'
                                                                    icon={
                                                                        <svg
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            width='24'
                                                                            height='24'
                                                                            viewBox='0 0 24 24'
                                                                            fill='none'
                                                                            stroke='currentColor'
                                                                            strokeWidth='2'
                                                                            strokeLinecap='round'
                                                                            strokeLinejoin='round'
                                                                        >
                                                                            <polygon points='5 3 19 12 5 21 5 3'></polygon>
                                                                        </svg>
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        {/* 
                                        <div className={classes.CompareButtonWrapper}>
                                            <MainButton
                                                label='Compare'
                                                icon={
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                    >
                                                        <polyline points='23 18 13.5 8.5 8.5 13.5 1 6'></polyline>
                                                        <polyline points='17 18 23 18 23 12'></polyline>
                                                    </svg>
                                                }
                                                color='white'
                                                onClick={() => {
                                                    dispatch(
                                                        myScenariosActions.setSelectedProject({ value: selectedProject.name, label: selectedProject.name })
                                                    );
                                                    navigate('/compare');
                                                }}
                                            />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </Widget>
                    </div>

                    <div className={classes.MapWrapper}>
                        <Widget noPadding>{selectedScenario && <ProjectMap projectId={id} />}</Widget>
                    </div>
                </div>
            </div>

            <Modal
                title='Selected subset of data'
                loading={loadingBuildingsStatus === 'started'}
                show={modalOpen}
                onClose={() => setModalOpen(false)}
                buttons={
                    <MainButton
                        label='Close'
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <line x1='18' y1='6' x2='6' y2='18'></line>
                                <line x1='6' y1='6' x2='18' y2='18'></line>
                            </svg>
                        }
                        color='grey'
                        onClick={() => setModalOpen(false)}
                    />
                }
            >
                {modalOpen && loadingBuildingsStatus === 'finished' && (
                    <div className={classes.ModalMapOuter}>
                        <div className={classes.ModalMapContainer}>
                            <MapContainer
                                boundsOptions={{ padding: [10, 10] }}
                                center={[40, 22]}
                                zoom={13}
                                maxZoom={20}
                                whenReady={(map) => {
                                    if (buildingsGeoJson.length) setMap(map.target);
                                }}
                            >
                                <TileLayer attribution={tileLayerAttribution} url={tileLayer} />

                                <FeatureGroup ref={featureGroupRef}>
                                    {buildingsGeoJson.map((building) => {
                                        const isSelected = selectedProject.buildings_subselection.split(',').includes(building.id.toString()) ? true : false;

                                        return (
                                            <GeoJSON
                                                key={`${building.id}_${String(isSelected)}`}
                                                data={building}
                                                style={isSelected ? { color: 'yellow' } : null}
                                            >
                                                <Popup>
                                                    <div className={classes.Row}>
                                                        <span>Height:</span> {building.properties.height}
                                                    </div>
                                                    <div className={classes.Row}>
                                                        <span>Building type:</span> {building.properties.country_building_type}
                                                    </div>
                                                    <div className={classes.Row}>
                                                        <span>Construction year:</span> {building.properties.construction_year}
                                                    </div>
                                                </Popup>
                                            </GeoJSON>
                                        );
                                    })}
                                </FeatureGroup>
                            </MapContainer>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default MyProjectsView;
