import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Badge from '../../features/UI/Badge/Badge';
import MainButton from '../../features/UI/MainButton/MainButton';
import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import Widget from '../../features/Widget/Widget';
import MyTable from '../../features/UI/MyTable/MyTable';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { myProjectsActions } from './myProjectsSlice';
import { getUserProjects, deleteProject } from './MyProjectsAsyncActions';
import classes from './MyProjects.module.css';
import ModalConfirm from '../../features/UI/Modal/ModalConfirm';

const MyProjects = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userProjects = useSelector((state) => state.myProjects.userProjects);
    const [deleteProjectId, setDeleteProjectId] = useState(null);

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('My projects'));
        dispatch(getUserProjects());

        return () => {
            dispatch(myProjectsActions.setUserProjects([]));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Name',
            },
            {
                accessorKey: 'date_created',
                header: 'Date created',
            },
            {
                accessorKey: 'description',
                header: 'Description',
            },
            {
                accessorFn: (row) => row.id,
                id: 'id',
                header: 'Actions',
                Cell: ({ cell }) => (
                    <div className={classes.TableActions}>
                        <ReactTooltip effect='solid' />

                        <Link to={`/myprojects-view/${cell.getValue()}`} data-tip='View'>
                            <Badge
                                color='success'
                                size='small'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <circle cx='11' cy='11' r='8'></circle>
                                        <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                                    </svg>
                                }
                            />
                        </Link>

                        <Link to={`/myprojects-edit/${cell.getValue()}`} data-tip='Edit'>
                            <Badge
                                color='warning'
                                size='small'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <path d='M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z'></path>
                                    </svg>
                                }
                            />
                        </Link>

                        <div className={classes.DeleteProjectData} aria-label='Delete' onClick={() => setDeleteProjectId(cell.getValue())} data-tip='Delete'>
                            <Badge
                                color='danger'
                                size='small'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <polyline points='3 6 5 6 21 6'></polyline>
                                        <path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'></path>
                                        <line x1='10' y1='11' x2='10' y2='17'></line>
                                        <line x1='14' y1='11' x2='14' y2='17'></line>
                                    </svg>
                                }
                            />
                        </div>
                    </div>
                ),
                size: 40,
            },
        ],
        []
    );

    return (
        <div className={classes.MyProjects}>
            <Breadcrumb link1='User' link2='/ My Projects' />

            <ModalConfirm
                type='delete'
                title='Are you sure?'
                text='The project will be deleted.'
                show={deleteProjectId}
                onClose={() => setDeleteProjectId(null)}
                onAction={() => {
                    dispatch(deleteProject(deleteProjectId));
                    setDeleteProjectId(null);
                }}
            />

            <div className={classes.ContentInner}>
                <Widget>
                    <MyTable
                        data={userProjects}
                        columns={columns}
                        buttons={
                            <div className={classes.AddButton}>
                                <MainButton
                                    icon={
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        >
                                            <line x1='12' y1='5' x2='12' y2='19'></line>
                                            <line x1='5' y1='12' x2='19' y2='12'></line>
                                        </svg>
                                    }
                                    label='Add new'
                                    color='success'
                                    fullWidth
                                    onClick={() => navigate('/myprojects-new')}
                                />
                            </div>
                        }
                    />
                </Widget>
            </div>
        </div>
    );
};

export default MyProjects;
