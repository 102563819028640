import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import L from 'leaflet';
import { MapContainer, TileLayer, FeatureGroup, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { latLngBounds } from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import Widget from '../../features/Widget/Widget';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { systemDataActions } from './systemDataSlice';
import { getSelectedSystemData } from './systemDataAsyncActions';
import classes from './SystemDataView.module.css';
import Badge from '../../features/UI/Badge/Badge';
import { fNumMaxDec } from '../../utils/formats';

const SystemDataView = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const tileLayer = useSelector((state) => state.system.tileLayer);
    const tileLayerAttribution = useSelector((state) => state.system.tileLayerAttribution);
    const selectedSystemData = useSelector((state) => state.systemData.selectedSystemData);

    const [bounds, setBounds] = useState(null);

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('System data view'));
        dispatch(getSelectedSystemData(id, navigate));
        L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

        return () => {
            dispatch(systemDataActions.setSelectedSystemData(null));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch, id]);

    useEffect(() => {
        if (selectedSystemData && selectedSystemData.envelope) {
            const coords = selectedSystemData.envelope.coordinates[0];
            let changeLatWithLon = [];
            changeLatWithLon = [
                [coords[0][1], coords[0][0]],
                [coords[1][1], coords[1][0]],
                [coords[2][1], coords[2][0]],
                [coords[3][1], coords[3][0]],
                [coords[4][1], coords[4][0]],
            ];

            let bounds = latLngBounds();
            bounds.extend(changeLatWithLon);

            setBounds(bounds);
        }
    }, [dispatch, selectedSystemData]);

    return (
        <div className={classes.SystemDataView}>
            <Breadcrumb link1='System' link2='/ System Data' link3='/ View System Data' />

            <div className={classes.ContentInner}>
                <div className={classes.Widgets}>
                    <div className={classes.Col13}>
                        <Widget>
                            <div className={classes.TitleRow}>
                                <h2>General Info</h2>
                            </div>

                            <div className={classes.WidgetContent}>
                                <div className={classes.InfoRow}>
                                    <span>Dataset Name</span>
                                    <span>{selectedSystemData?.name}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Description</span>
                                    <span>{selectedSystemData?.description}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Date created</span>
                                    <span>{selectedSystemData?.date_created}</span>
                                </div>
                            </div>
                        </Widget>
                    </div>

                    <div className={classes.Col34}>
                        {selectedSystemData?.isPurchased ? (
                            <Widget>
                                <div className={classes.TitleRowWithSubtitle}>
                                    <h2>Projects</h2>
                                    <span>The data is used in the following projects</span>
                                </div>

                                <div className={classes.WidgetContent}>
                                    {selectedSystemData?.projects.length === 0 ? (
                                        <div className={classes.NoData}>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <circle cx='12' cy='12' r='10'></circle>
                                                <line x1='4.93' y1='4.93' x2='19.07' y2='19.07'></line>
                                            </svg>

                                            <span>The dataset is not used in any projects</span>
                                        </div>
                                    ) : (
                                        <div className={classes.Projects}>
                                            {selectedSystemData?.projects.map((project, index) => {
                                                return (
                                                    <div key={index} className={classes.InfoRow}>
                                                        <span>{project.name}</span>
                                                        <div
                                                            className={classes.BadgeWrapper}
                                                            onClick={() => navigate(`/myprojects-view/${project.id}`)}
                                                            data-tip='View data'
                                                        >
                                                            <Badge
                                                                color='success'
                                                                size='small'
                                                                icon={
                                                                    <svg
                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                        width='24'
                                                                        height='24'
                                                                        viewBox='0 0 24 24'
                                                                        fill='none'
                                                                        stroke='currentColor'
                                                                        strokeWidth='2'
                                                                        strokeLinecap='round'
                                                                        strokeLinejoin='round'
                                                                    >
                                                                        <circle cx='11' cy='11' r='8'></circle>
                                                                        <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                                                                    </svg>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </Widget>
                        ) : (
                            <Widget noPadding>
                                <div className={classes.BlueBackground}>
                                    <div className={classes.BlueBackgroundInner}>
                                        <div className={classes.BlueBackgroundTitleRow}>
                                            <h2>Purchase</h2>
                                            <span>You cannot use this dataset</span>
                                        </div>

                                        <div className={classes.BlueBackgroundInfoRow}>
                                            <div className={classes.BlueBackgroundName}>
                                                <span>Purchase the Dataset</span>
                                            </div>

                                            <div className={classes.BlueBackgroundButtonWrapper}>
                                                <div
                                                    className={classes.BadgeWrapper}
                                                    onClick={() => console.log(selectedSystemData?.id)}
                                                    data-tip='Run scenario'
                                                >
                                                    <Badge
                                                        color='white'
                                                        size='small'
                                                        icon={
                                                            <svg
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                width='24'
                                                                height='24'
                                                                viewBox='0 0 24 24'
                                                                fill='none'
                                                                stroke='currentColor'
                                                                strokeWidth='2'
                                                                strokeLinecap='round'
                                                                strokeLinejoin='round'
                                                            >
                                                                <circle cx='9' cy='21' r='1'></circle>
                                                                <circle cx='20' cy='21' r='1'></circle>
                                                                <path d='M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6'></path>
                                                            </svg>
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Widget>
                        )}
                    </div>

                    <div className={classes.Col12}>
                        <Widget>
                            <div className={classes.TitleRow}>
                                <h2>Location</h2>
                            </div>

                            <div className={classes.WidgetContent}>
                                <div className={classes.InfoRow}>
                                    <span>Country</span>
                                    <span>{selectedSystemData?.country}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>City</span>
                                    <span>{selectedSystemData?.city}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Min latitude</span>
                                    <span>{fNumMaxDec(selectedSystemData?.envelope?.coordinates[0][0][1], 5)}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Max latitude</span>
                                    <span>{fNumMaxDec(selectedSystemData?.envelope?.coordinates[0][2][1], 5)}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Min longitude</span>
                                    <span>{fNumMaxDec(selectedSystemData?.envelope?.coordinates[0][0][0], 5)}</span>
                                </div>
                                <div className={classes.InfoRow}>
                                    <span>Max longitude</span>
                                    <span>{fNumMaxDec(selectedSystemData?.envelope?.coordinates[0][2][0], 5)}</span>
                                </div>
                            </div>
                        </Widget>
                    </div>

                    <div className={classes.Col24}>
                        <Widget noPadding>
                            <div className={classes.Location}>
                                {bounds ? (
                                    <MapContainer
                                        // center={[51.505, -0.09]}
                                        zoom={13}
                                        maxZoom={17}
                                        boundsOptions={{ padding: [50, 50] }}
                                        bounds={bounds}
                                        gestureHandling={true}
                                        gestureHandlingOptions={{ duration: 5000 }}
                                        // scrollWheelZoom={false}
                                    >
                                        {/* <TileLayer attribution={''} url={'http://{s}.tile.osm.org/{z}/{x}/{y}.png'} /> */}
                                        <TileLayer attribution={tileLayerAttribution} url={tileLayer} />

                                        <FeatureGroup>
                                            <GeoJSON data={selectedSystemData?.envelope} style={{ color: 'red' }} />
                                        </FeatureGroup>
                                    </MapContainer>
                                ) : null}
                            </div>
                        </Widget>
                    </div>

                    <div className={classes.Col14}>
                        <Widget>
                            <div className={classes.TitleRow}>
                                <h2>Data Analysis</h2>
                            </div>

                            <div className={classes.WidgetContent}>
                                {/* Number of buildings */}
                                <div className={classes.InfoRow2}>
                                    <span>Number of buildings</span>
                                    <span>{selectedSystemData?.buildings_num}</span>
                                </div>

                                {/* Height less than 3m */}
                                <div className={classes.InfoRow2}>
                                    <div className={classes.FirstCol}>
                                        <span>Buildings with height less than 3m</span>
                                        {selectedSystemData?.small_height > 0 && (
                                            <span className={classes.Warning}>Caution: There are buildings with low height</span>
                                        )}
                                    </div>
                                    <span>{selectedSystemData?.small_height}</span>

                                    <div className={classes.AnalysisIcon}>
                                        {selectedSystemData?.small_height === 0 ? (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Success}
                                            >
                                                <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path>
                                                <polyline points='22 4 12 14.01 9 11.01'></polyline>
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Warning}
                                            >
                                                <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
                                                <line x1='12' y1='9' x2='12' y2='13'></line>
                                                <line x1='12' y1='17' x2='12.01' y2='17'></line>
                                            </svg>
                                        )}
                                    </div>
                                </div>

                                {/* No height */}
                                <div className={classes.InfoRow2}>
                                    <div className={classes.FirstCol}>
                                        <span>Buildings with no height</span>
                                        {selectedSystemData?.no_height > 0 && (
                                            <span className={classes.Danger}>These buildings will not be included in the analysis</span>
                                        )}
                                    </div>
                                    <span>{selectedSystemData?.no_height}</span>

                                    <div className={classes.AnalysisIcon}>
                                        {selectedSystemData?.no_height === 0 ? (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Success}
                                            >
                                                <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path>
                                                <polyline points='22 4 12 14.01 9 11.01'></polyline>
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Danger}
                                            >
                                                <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
                                                <line x1='15' y1='9' x2='9' y2='15'></line>
                                                <line x1='9' y1='9' x2='15' y2='15'></line>
                                            </svg>
                                        )}
                                    </div>
                                </div>

                                {/* No use */}
                                <div className={classes.InfoRow2}>
                                    <div className={classes.FirstCol}>
                                        <span>Buildings with no use</span>
                                        {selectedSystemData?.no_use > 0 && (
                                            <span className={classes.Danger}>These buildings will not be included in the analysis</span>
                                        )}
                                    </div>
                                    <span>{selectedSystemData?.no_use}</span>

                                    <div className={classes.AnalysisIcon}>
                                        {selectedSystemData?.no_use === 0 ? (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Success}
                                            >
                                                <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path>
                                                <polyline points='22 4 12 14.01 9 11.01'></polyline>
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Danger}
                                            >
                                                <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
                                                <line x1='15' y1='9' x2='9' y2='15'></line>
                                                <line x1='9' y1='9' x2='15' y2='15'></line>
                                            </svg>
                                        )}
                                    </div>
                                </div>

                                {/* No construction year */}
                                <div className={classes.InfoRow2}>
                                    <div className={classes.FirstCol}>
                                        <span>Buildings with no construction year</span>
                                        {selectedSystemData?.no_year > 0 && (
                                            <span className={classes.Danger}>These buildings will not be included in the analysis</span>
                                        )}
                                    </div>
                                    <span>{selectedSystemData?.no_year}</span>

                                    <div className={classes.AnalysisIcon}>
                                        {selectedSystemData?.no_year === 0 ? (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Success}
                                            >
                                                <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path>
                                                <polyline points='22 4 12 14.01 9 11.01'></polyline>
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className={classes.Danger}
                                            >
                                                <polygon points='7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2'></polygon>
                                                <line x1='15' y1='9' x2='9' y2='15'></line>
                                                <line x1='9' y1='9' x2='15' y2='15'></line>
                                            </svg>
                                        )}
                                    </div>
                                </div>

                                {/* Results */}
                                <div className={classes.ResultRow}>
                                    <span>Buildings passed validation</span>
                                    <span>{selectedSystemData?.no_issues}</span>
                                </div>
                            </div>
                        </Widget>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SystemDataView;
