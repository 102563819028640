import { configureStore } from '@reduxjs/toolkit';

import layoutSlice from '../features/Layout/layoutSlice';
import systemSlice from '../features/systemSlice';
import loginSlice from '../pages/Login/loginSlice';
// import mapSlice from '../pages/Map/mapSlice';
import dropzoneSlice from '../features/Dropzone/dropzoneSlice';
import myDataSlice from '../pages/MyData/myDataSlice';
import myTableServerSlice from '../features/UI/MyTable/myTableServerSlice';
import userProfileSlice from '../pages/UserProfile/userProfileSlice';
import systemDataSlice from '../pages/SystemData/systemDataSlice';
import weatherDataSlice from '../pages/WeatherData/weatherDataSlice';
import myWeatherFilesSlice from '../pages/MyWeatherFiles/myWeatherFilesSlice';
import myProjectsSlice from '../pages/MyProjects/myProjectsSlice';
import dashboardSlice from '../pages/Dashboard/dashboardSlice';
import searchSlice from '../features/Search/searchSlice';
import analysisTypesSlice from '../pages/AnalysisTypes/analysisTypesSlice';
import myScenariosSlice from '../pages/MyScenarios/myScenariosSlice';
import websiteSlice from '../website/pages/websiteSlice';

export const store = configureStore({
    reducer: {
        layout: layoutSlice.reducer,
        system: systemSlice.reducer,
        // map: mapSlice.reducer,
        dropzone: dropzoneSlice.reducer,
        myData: myDataSlice.reducer,
        login: loginSlice.reducer,
        userProfile: userProfileSlice.reducer,
        systemData: systemDataSlice.reducer,
        weatherData: weatherDataSlice.reducer,
        myWeatherFiles: myWeatherFilesSlice.reducer,
        myProjects: myProjectsSlice.reducer,
        myTableServer: myTableServerSlice.reducer,
        dashboard: dashboardSlice.reducer,
        search: searchSlice.reducer,
        analysisTypes: analysisTypesSlice.reducer,
        myScenarios: myScenariosSlice.reducer,
        website: websiteSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
