import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faCheck } from '@fortawesome/free-solid-svg-icons';

import MainButton from '../../features/UI/MainButton/MainButton';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { loginActions } from './loginSlice';
import { activation } from './loginAsyncActions';
import classes from './Activation.module.css';

const Activation = () => {
    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const activationMessage = useSelector((state) => state.login.activationMessage);

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('Activation'));
        dispatch(activation(id));

        // This runs on component unmount
        return () => {
            dispatch(loginActions.setActivationMessage(null));
        };
    }, [dispatch, id]);

    return (
        <div className={classes.ActivationWrapper}>
            {activationMessage && activationMessage.type === 'error' && (
                <div className={classes.ErrorActivation}>
                    <div className={classes.IconWrapper}>
                        <FontAwesomeIcon icon={faExclamation} />
                    </div>

                    <h3>{activationMessage.message}</h3>

                    <div className={classes.ButtonWrapper}>
                        <MainButton label='Goto login' color='action' onClick={() => navigate('/login')} />
                    </div>
                </div>
            )}

            {activationMessage && activationMessage.type === 'success' && (
                <div className={classes.SuccessActivation}>
                    <div className={classes.IconWrapper}>
                        <FontAwesomeIcon icon={faCheck} />
                    </div>

                    <h3>{activationMessage.message}</h3>

                    <div className={classes.ButtonWrapper}>
                        <MainButton label='Goto login' color='action' onClick={() => navigate('/login')} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Activation;
