import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import Widget from '../../features/Widget/Widget';
import MainButton from '../../features/UI/MainButton/MainButton';
import MainInput from '../../features/UI/MainInput/MainInput';
import MainSwitch from '../../features/UI/MainSwitch/MainSwitch';
import Badge from '../../features/UI/Badge/Badge';

import classes from './UserProfile.module.css';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { userProfileActions } from './userProfileSlice';
import { getUserInfo, updateUserPassword, updateUser } from './userProfileAsyncActions';
import { showMessage } from '../../utils/messageHandler';
import { fNumSep } from '../../utils/formats';

const { Entropy } = require('entropy-string');

const CONFIG = require('../../reactConfig.json');

const UserProfile = () => {
    const navigate = useNavigate();
    const minPasswordLength = 8;

    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userProfile.userInfo);

    const [updatedUser, setUpdatedUser] = useState(null);
    const [passwords, setPasswords] = useState({ activePassword: '', newPassword: '', confirmNewPassword: '' });
    const [showPasswords, setShowPasswords] = useState(false);
    const [passwordFormValidated, setPasswordFormValidated] = useState(false);
    const [userFormValidated, setUserFormValidated] = useState(false);

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('User profile'));
        setUpdatedUser(userInfo);
    }, [dispatch, userInfo]);

    useEffect(() => {
        dispatch(getUserInfo());

        return () => {
            dispatch(userProfileActions.setUserInfo(null));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch]);

    const updateUserProperty = (property, value) => {
        let uUser = { ...updatedUser };
        uUser[property] = value;

        setUpdatedUser(uUser);
    };

    const handleUpdateUser = () => {
        setUserFormValidated(true);

        if (updatedUser.email.trim() === '') showMessage('error', 'The email cannot be blank');
        else {
            dispatch(updateUser(updatedUser));
        }
    };

    const handleUpdatePassword = () => {
        setPasswordFormValidated(true);

        if (passwords.activePassword.trim() === '' || passwords.newPassword.trim() === '' || passwords.confirmNewPassword.trim() === '') {
            showMessage('error', 'Please fill in all required fields');
        } else if (passwords.confirmNewPassword.trim() !== passwords.newPassword.trim()) {
            showMessage('error', 'The password confirmation does not match the password');
        } else if (passwords.newPassword.trim().length < minPasswordLength) {
            showMessage('error', 'The password must be at least ' + minPasswordLength + ' characters long');
        } else {
            dispatch(updateUserPassword(passwords.activePassword, passwords.newPassword, navigate));

            setPasswords({ activePassword: '', newPassword: '', confirmNewPassword: '' });
            setPasswordFormValidated(false);
        }
    };

    const handleSetPasswords = (property, value) => {
        let tempPasswords = { ...passwords };
        tempPasswords[property] = value.trim();

        setPasswords(tempPasswords);
    };

    const showPasswordsHandler = () => {
        setShowPasswords((prevState) => !prevState);
    };

    const generateRandomPassword = () => {
        // Creates a random string
        let entropy;
        let charset;

        charset = '234689bdfghmnpqrBDFGHJLMNR!@#_)(';
        // const specialCharsArr = ["!","@","#","_",")","("];

        const bits = minPasswordLength * 5; // The first number is the minimum characters necessary

        entropy = new Entropy({ charset: charset, bits: bits });
        const string = entropy.string();

        let tempPasswords = { ...passwords };
        tempPasswords.newPassword = string;
        tempPasswords.confirmNewPassword = string;

        setPasswords(tempPasswords);
    };

    const openInvoice = (invoice) => {
        let a = document.createElement('a');
        a.href = CONFIG.data + '/invoices/' + userInfo.id + '/' + invoice;
        a.target = '_blank';
        a.rel = 'noopener noreferrer';
        a.click();
    };

    return (
        <div className={classes.UserProfile}>
            <Breadcrumb link1='User' link2='/ Profile' />

            {userInfo && (
                <div className={classes.ContentInner}>
                    <div className={classes.Widgets}>
                        <Widget>
                            <div className={classes.TitleRow}>
                                <h2>Profile</h2>
                                <MainButton
                                    icon={
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        >
                                            <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                                            <polyline points='17 21 17 13 7 13 7 21'></polyline>
                                            <polyline points='7 3 7 8 15 8'></polyline>
                                        </svg>
                                    }
                                    type='circle'
                                    color='action'
                                    onClick={handleUpdateUser}
                                />
                            </div>

                            <div className={classes.WidgetContent}>
                                <form>
                                    <div className={classes.FormRow}>
                                        <label>Username</label>
                                        <MainInput type='text' fullWidth value={userInfo ? userInfo.username : ''} disabled />
                                    </div>

                                    <div className={classes.FormRow}>
                                        <label>Email</label>
                                        <MainInput
                                            type='text'
                                            fullWidth
                                            value={updatedUser ? updatedUser.email : ''}
                                            onChange={(e) => updateUserProperty('email', e.target.value)}
                                            validated={userFormValidated}
                                            isValid={updatedUser && updatedUser.email !== ''}
                                        />
                                    </div>

                                    <div className={classes.FormRow}>
                                        <label>Registration date</label>
                                        <MainInput type='text' fullWidth value={userInfo ? userInfo.registration_date : ''} disabled />
                                    </div>
                                </form>
                            </div>
                        </Widget>

                        <Widget>
                            <div className={classes.TitleRow}>
                                <div className={classes.TitleRowCol1}>
                                    <h2>Change Password</h2>
                                    <span>The password must have at least 8 characters</span>
                                </div>

                                <div className={classes.TitleRowCol2}>
                                    <MainButton
                                        icon={
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                                                <polyline points='17 21 17 13 7 13 7 21'></polyline>
                                                <polyline points='7 3 7 8 15 8'></polyline>
                                            </svg>
                                        }
                                        type='circle'
                                        color='action'
                                        onClick={handleUpdatePassword}
                                    />
                                </div>
                            </div>

                            <div className={classes.WidgetContent}>
                                <div className={classes.ButtonsWrapper}>
                                    <MainSwitch name='Show passwords' label='Show passwords' checked={showPasswords} onChange={showPasswordsHandler} />

                                    <div className={classes.RandomPasswordWrapper}>
                                        <MainButton
                                            icon={
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='24'
                                                    height='24'
                                                    viewBox='0 0 24 24'
                                                    fill='none'
                                                    stroke='currentColor'
                                                    strokeWidth='2'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                >
                                                    <path d='M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4'></path>
                                                </svg>
                                            }
                                            type='circle'
                                            color='success'
                                            onClick={generateRandomPassword}
                                        />
                                        <label>Generate random password</label>
                                    </div>
                                </div>

                                <form>
                                    <div className={classes.FormRow}>
                                        <label>Current password</label>
                                        <MainInput
                                            type={showPasswords ? 'text' : 'password'}
                                            fullWidth
                                            value={passwords.activePassword}
                                            onChange={(e) => handleSetPasswords('activePassword', e.target.value)}
                                            validated={passwordFormValidated}
                                            isValid={passwords.activePassword !== ''}
                                        />
                                    </div>

                                    <div className={classes.FormRow}>
                                        <label>New password</label>
                                        <MainInput
                                            type={showPasswords ? 'text' : 'password'}
                                            fullWidth
                                            value={passwords.newPassword}
                                            onChange={(e) => handleSetPasswords('newPassword', e.target.value)}
                                            validated={passwordFormValidated}
                                            isValid={passwords.newPassword !== '' && passwords.newPassword.length >= minPasswordLength}
                                        />
                                    </div>

                                    <div className={classes.FormRow}>
                                        <label>Confirm password</label>
                                        <MainInput
                                            type={showPasswords ? 'text' : 'password'}
                                            fullWidth
                                            value={passwords.confirmNewPassword}
                                            onChange={(e) => handleSetPasswords('confirmNewPassword', e.target.value)}
                                            validated={passwordFormValidated}
                                            isValid={
                                                passwords.confirmNewPassword !== '' &&
                                                passwords.confirmNewPassword.length >= minPasswordLength &&
                                                passwords.confirmNewPassword === passwords.newPassword
                                            }
                                        />
                                    </div>
                                </form>
                            </div>
                        </Widget>

                        <Widget>
                            <div className={[classes.TitleRow, classes.FlexStart].join(' ')}>
                                <h2>{userInfo.plan.plan_name} plan</h2>

                                <div className={classes.RenewWrapper}>
                                    <MainButton
                                        label='Renew now'
                                        color='info'
                                        // onClick={forgotEmailHandler}
                                        // loading={loginLoading}
                                    />

                                    <div className={classes.DaysLeft}>
                                        <span>{fNumSep(userInfo.plan.plan_buildings - userInfo.plan.processed)} buildings left</span>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.WidgetContent}>
                                <div className={classes.PlanWrapper}>
                                    <div className={classes.PlanItem}>
                                        <Badge
                                            color='info'
                                            type='circle'
                                            icon={
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='24'
                                                    height='24'
                                                    viewBox='0 0 24 24'
                                                    fill='none'
                                                    stroke='currentColor'
                                                    strokeWidth='2'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                >
                                                    <path d='M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z'></path>
                                                    <polyline points='9 22 9 12 15 12 15 22'></polyline>
                                                </svg>
                                            }
                                        />
                                        <div className={classes.PlanBarWrapper}>
                                            <div className={classes.PlanText}>
                                                <span>{fNumSep(userInfo.plan.plan_buildings)} buildings</span>
                                                <span>
                                                    {((userInfo.plan.plan_buildings - userInfo.plan.processed) / userInfo.plan.plan_buildings) * 100}% available
                                                </span>
                                            </div>

                                            <div className={classes.Bar}>
                                                <div
                                                    style={{
                                                        width:
                                                            ((userInfo.plan.plan_buildings - userInfo.plan.processed) / userInfo.plan.plan_buildings) * 100 +
                                                            '%',
                                                    }}
                                                    className={classes.ProgressInfo}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Widget>

                        <Widget>
                            <div className={classes.TitleRow}>
                                <h2>Payments</h2>
                            </div>

                            <div className={classes.WidgetContent}>
                                {userInfo.payments.map((payment, index) => {
                                    return (
                                        <div key={index} className={classes.Plan}>
                                            <div className={classes.PlanDateWrapper}>
                                                <span className={classes.PlanDate}>{payment.payment_date}</span>
                                                <span className={classes.PlanName}>{payment.plan_name} Plan</span>
                                            </div>
                                            <div className={classes.PlanValue}>€ {fNumSep(payment.price)}</div>

                                            <MainButton
                                                icon={
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                    >
                                                        <path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4'></path>
                                                        <polyline points='7 10 12 15 17 10'></polyline>
                                                        <line x1='12' y1='15' x2='12' y2='3'></line>
                                                    </svg>
                                                }
                                                type='circle'
                                                color='success'
                                                onClick={() => openInvoice(payment.invoice)}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </Widget>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserProfile;
