import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allWeatherData: [],
    weatherStation: null,
};

export const weatherDataSlice = createSlice({
    name: 'weatherData',
    initialState,
    reducers: {
        setWeatherData: (state, action) => {
            state.allWeatherData = action.payload;
        },
        setWeatherStation: (state, action) => {
            state.weatherStation = action.payload;
        },
    },
});

export const weatherDataActions = weatherDataSlice.actions;

export default weatherDataSlice;
