import React, { useState, useEffect } from 'react';

import PieChart from '../../../features/Charts/PieChart';
import classes from './AllCharts.module.css';

const AllChartsGeneral = (props) => {
    const [buildingValues, setBuildingValues] = useState(null);

    useEffect(() => {
        const selectedFeature = props.selectedFeature.feature;
        if (!selectedFeature) return;

        const data = JSON.parse(selectedFeature.getProperty('building_data'));
        if (!data) return;

        // Building metrics
        const building_metric = data.building_metrics.building_metric;

        const totalArea = building_metric?.building_area_total_building_area;
        const netConditionedArea = building_metric?.building_area_net_conditioned_building_area;
        const unconditionedArea = building_metric?.building_area_unconditioned_building_area;
        const netConditionedAreaPerc = (netConditionedArea / totalArea) * 100;
        const unconditionedAreaPerc = (unconditionedArea / totalArea) * 100;

        // Usage metrics
        const usageMetrics = data?.building_usage_metrics;

        let usages = [];
        let totalUsageArea = building_metric.zone_information_total_area;

        for (let i = 0; i < usageMetrics.length; i++) {
            const usageMetric = usageMetrics[i].building_usage_metric;
            const usageName = usageMetric.usage_name;
            const usageArea = usageMetric.usage_area;
            const usageAreaPerc = (usageArea / totalUsageArea) * 100;

            usages.push({ name: usageName, y: usageAreaPerc });
        }

        const values = {
            netConditionedAreaPerc,
            unconditionedAreaPerc,
            usages,
        };

        setBuildingValues(values);
    }, [props?.selectedFeature?.feature]);

    return (
        <div className={classes.Charts}>
            <div className={classes.Chart}>
                <h3>Building zone information</h3>
                {buildingValues && (
                    <PieChart
                        title={null}
                        alignLegend='center'
                        series={[
                            {
                                name: 'Percentage',
                                colorByPoint: true,
                                data: buildingValues.usages,
                            },
                        ]}
                    />
                )}
            </div>

            <div className={classes.Chart}>
                <h3>Building Area</h3>
                {buildingValues && (
                    <PieChart
                        title={null}
                        alignLegend='center'
                        series={[
                            {
                                name: 'Percentage',
                                colorByPoint: true,
                                data: [
                                    { name: 'Conditioned area', y: buildingValues.netConditionedAreaPerc },
                                    { name: 'Unconditioned area', y: buildingValues.unconditionedAreaPerc },
                                ],
                            },
                        ]}
                    />
                )}
            </div>
        </div>
    );
};

export default AllChartsGeneral;
