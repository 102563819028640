import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';

import MainSelect from '../../../features/UI/MainSelect/MainSelectControlled';
import MainCheckbox from '../../../features/UI/MainCheckbox/MainCheckboxControlled';
import MainButton from '../../../features/UI/MainButton/MainButton';
import classes from './MapFilters.module.css';
import Modal from '../../../features/UI/Modal/Modal';
import MapFiltersScenarioInfo from './MapFiltersScenarioInfo';
import ScenarioStats from './ScenarioStats';
import MapFiltersFilter from './MapFiltersFilter';
import { myProjectsActions } from '../myProjectsSlice';

const MapFilters = (props) => {
    const dispatch = useDispatch();

    const [viewFiltersMenu, setViewFiltersMenu] = useState(false);
    const [viewFiltersItem, setViewFiltersItem] = useState(null);
    const [showViewFiltersModal, setShowViewFiltersModal] = useState(false);

    const isFullScreen = useSelector((state) => state.myProjects.isFullScreen);
    const viewLegend = useSelector((state) => state.myProjects.viewLegend);
    const scenariosOptions = useSelector((state) => state.myProjects.scenariosOptions);
    const selectedProjectScenarios = useSelector((state) => state.myProjects.selectedProjectScenarios);
    const selectedScenario = useSelector((state) => state.myProjects.selectedScenario);
    const colorBasedOn = useSelector((state) => state.myProjects.colorBasedOn);
    const filterData = useSelector((state) => state.myProjects.filterData);

    const updateScenario = (value) => {
        const found = selectedProjectScenarios.find((s) => s.analysis_name === value);
        if (found) dispatch(myProjectsActions.setSelectedScenario(found));
    };

    const closeDropdown = () => {
        setViewFiltersMenu(false);
    };

    // Click outside
    const useOutsideClick = (callback) => {
        const ref = useRef();

        useEffect(() => {
            const handleClick = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    callback();
                }
            };

            document.addEventListener('click', handleClick);

            return () => {
                document.removeEventListener('click', handleClick);
            };
        }, [ref]);

        return ref;
    };

    const ref = useOutsideClick(closeDropdown);

    return (
        <div className={isFullScreen ? [classes.MapFilters, classes.FullScreen].join(' ') : classes.MapFilters}>
            <div className={classes.WithTriangle}>
                <div className={classes.FirstRow}>
                    <div className={classes.FiltersMenuWrapper} ref={ref}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            onClick={() => setViewFiltersMenu((prev) => !prev)}
                        >
                            <line x1='3' y1='12' x2='21' y2='12'></line>
                            <line x1='3' y1='6' x2='21' y2='6'></line>
                            <line x1='3' y1='18' x2='21' y2='18'></line>
                        </svg>

                        <div className={viewFiltersMenu ? [classes.FiltersMenu, classes.Show].join(' ') : classes.FiltersMenu}>
                            <div
                                className={classes.FiltersMenuItem}
                                onClick={() => {
                                    setViewFiltersMenu(false);
                                    props.onZoomToBounds();
                                }}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <circle cx='11' cy='11' r='8'></circle>
                                    <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                                    <line x1='11' y1='8' x2='11' y2='14'></line>
                                    <line x1='8' y1='11' x2='14' y2='11'></line>
                                </svg>
                                <span>Zoom to extent</span>
                            </div>

                            <div
                                className={classes.FiltersMenuItem}
                                onClick={() => {
                                    setViewFiltersItem('Filter data');
                                    setShowViewFiltersModal(true);
                                    setViewFiltersMenu(false);
                                }}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <polygon points='22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3'></polygon>
                                </svg>
                                <span>Filter data</span>
                            </div>
                            <div
                                className={classes.FiltersMenuItem}
                                onClick={() => {
                                    setViewFiltersItem('Scenario info');
                                    setShowViewFiltersModal(true);
                                    setViewFiltersMenu(false);
                                }}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <circle cx='12' cy='12' r='10'></circle>
                                    <line x1='12' y1='16' x2='12' y2='12'></line>
                                    <line x1='12' y1='8' x2='12.01' y2='8'></line>
                                </svg>
                                <span>Scenario info</span>
                            </div>
                            <div
                                className={classes.FiltersMenuItem}
                                onClick={() => {
                                    setViewFiltersItem('Scenario analysis');
                                    setShowViewFiltersModal(true);
                                    setViewFiltersMenu(false);
                                }}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <path d='M21.21 15.89A10 10 0 1 1 8 2.83'></path>
                                    <path d='M22 12A10 10 0 0 0 12 2v10z'></path>
                                </svg>
                                <span>{filterData.length > 0 ? 'Scenario analysis (selection)' : 'Scenario analysis'}</span>
                            </div>
                        </div>
                    </div>

                    <Scrollbars
                        className={classes.Scroller}
                        style={{ width: '100%', height: '100%' }}
                        renderThumbVertical={(props) => <div {...props} className='thumb-vertical' />}
                        renderThumbHorizontal={(props) => <div {...props} className='thumb-horizontal' />}
                    >
                        <div className={classes.LeftPart}>
                            <div className={classes.GroupFlex}>
                                <span>Scenario</span>
                                <MainSelect
                                    options={scenariosOptions}
                                    value={selectedScenario.analysis_name}
                                    onChange={(e) => updateScenario(e.target.value)}
                                    narrow
                                />
                            </div>

                            <div className={classes.GroupFlex}>
                                <span>Color buildings</span>
                                <MainSelect
                                    options={['Height', 'Construction year', 'Building use', 'Building area', 'Carbon footprint', 'Primary energy']}
                                    value={colorBasedOn}
                                    onChange={(e) => dispatch(myProjectsActions.setColorBasedOn(e.target.value))}
                                    narrow
                                />
                            </div>

                            <div className={classes.GroupFlex}>
                                <MainCheckbox
                                    label='View legend'
                                    name='view_legend'
                                    checked={viewLegend}
                                    onChange={() => dispatch(myProjectsActions.toggleViewLegend())}
                                />
                            </div>
                        </div>
                    </Scrollbars>
                    <div className={classes.FullScreenButtons}>
                        <div className={classes.Maximize}>
                            <MainButton
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <path d='M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3'></path>
                                    </svg>
                                }
                                onClick={() => dispatch(myProjectsActions.setFullScreen(true))}
                                type='circle'
                                color='action'
                            />
                        </div>

                        <div className={classes.Minimize}>
                            <MainButton
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <path d='M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3'></path>
                                    </svg>
                                }
                                onClick={() => dispatch(myProjectsActions.setFullScreen(false))}
                                type='circle'
                                color='action'
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                title={viewFiltersItem === 'Scenario analysis' && filterData.length > 0 ? 'Scenario analysis (selection)' : viewFiltersItem}
                show={showViewFiltersModal}
                size={viewFiltersItem === 'Scenario analysis' ? null : 'small'}
                onClose={() => {
                    setShowViewFiltersModal(false);
                    setViewFiltersItem('Scenario info');
                }}
                buttons={
                    <MainButton
                        label='Close'
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <line x1='18' y1='6' x2='6' y2='18'></line>
                                <line x1='6' y1='6' x2='18' y2='18'></line>
                            </svg>
                        }
                        color='grey'
                        onClick={() => {
                            setShowViewFiltersModal(false);
                            setViewFiltersItem('Scenario info');
                        }}
                    />
                }
                noPadding={viewFiltersItem === 'Scenario analysis' ? true : false}
            >
                {viewFiltersItem === 'Scenario info' && <MapFiltersScenarioInfo />}
                {viewFiltersItem === 'Scenario analysis' && <ScenarioStats />}
                {viewFiltersItem === 'Filter data' && <MapFiltersFilter />}
            </Modal>
        </div>
    );
};

export default MapFilters;
