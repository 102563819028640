import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    myWeatherData: [],
    selectedWeatherData: null,
    dataName: '',
    dataDescription: '',
    dataElevation: '',
    dataCoordinates: { lon: '', lat: '' },
    savingStatus: '',
};

export const myWeatherFilesSlice = createSlice({
    name: 'myWeatherFiles',
    initialState,
    reducers: {
        resetNewWeatherData: (state) => {
            state.dataName = '';
            state.dataDescription = '';
            state.dataElevation = '';
            state.dataCoordinates = { lon: '', lat: '' };
            state.savingStatus = '';
            state.selectedWeatherData = null;
        },
        setMyWeatherData: (state, action) => {
            state.myWeatherData = action.payload;
        },
        setSelectedWeatherData: (state, action) => {
            state.selectedWeatherData = action.payload;
        },
        setDataName: (state, action) => {
            state.dataName = action.payload;
        },
        setDataDescription: (state, action) => {
            state.dataDescription = action.payload;
        },
        setDataElevation: (state, action) => {
            state.dataElevation = action.payload;
        },
        setDataCoordinates: (state, action) => {
            state.dataCoordinates = action.payload;
        },
        setSavingStatus: (state, action) => {
            state.savingStatus = action.payload;
        },
    },
});

export const myWeatherFilesActions = myWeatherFilesSlice.actions;

export default myWeatherFilesSlice;
