import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';

import MainButton from '../../../features/UI/MainButton/MainButton';
import PieChart from '../../../features/Charts/PieChart';
import classes from './BuildingPopup.module.css';
import { myProjectsActions } from '../myProjectsSlice';
import { fNumMaxDec } from '../../../utils/formats';

const BuildingPopup = (props) => {
    const dispatch = useDispatch();

    const [buildingValues, setBuildingValues] = useState(null);

    useEffect(() => {
        const selectedFeature = props.selectedFeature.feature;
        if (!selectedFeature) return;

        const data = JSON.parse(selectedFeature.getProperty('building_data'));
        if (!data) return;

        const building_metric = data.building_metrics.building_metric;
        const area = building_metric?.building_area_net_conditioned_building_area;
        const height = building_metric?.height;
        const constructionYear = building_metric?.construction_year;
        const primaryEnergy = building_metric?.end_uses_primary_per_m2.toFixed(2);
        const consumedEnergy = building_metric?.end_uses_consumed_per_m2.toFixed(2);
        const emissionsCo2 = building_metric?.end_uses_co2_per_m2.toFixed(2);

        const spaceCooling = building_metric?.eap_218_end_use_percentage_space_cooling;
        const spaceHeating = building_metric?.eap_218_end_use_percentage_space_heating;
        const interiorLighting = building_metric?.eap_218_end_use_percentage_interior_lighting;
        const receptacleEquipment = building_metric?.eap_218_end_use_percentage_receptacle_equipment;

        const usageMetrics = data?.building_usage_metrics;
        let usageName = '';
        let energyLosses = 0;

        for (let i = 0; i < usageMetrics.length; i++) {
            const usageMetric = usageMetrics[i].building_usage_metric;
            usageName = usageName + usageMetric.usage_name + ' | ';

            energyLosses =
                energyLosses +
                (usageMetric.heat_loss_window_heat_removal +
                    usageMetric.heat_loss_infiltration_heat_removal +
                    usageMetric.heat_loss_opaque_surface_conduction_and_other_heat_removal) /
                    usageMetric.usage_area;
        }
        usageName = usageName.endsWith(' | ') ? usageName.slice(0, -3) : usageName;
        energyLosses = Math.abs(energyLosses).toFixed(2);

        const values = {
            area,
            height,
            constructionYear,
            primaryEnergy,
            consumedEnergy,
            emissionsCo2,
            spaceCooling,
            spaceHeating,
            interiorLighting,
            receptacleEquipment,
            usageName,
            energyLosses,
        };

        setBuildingValues(values);
    }, [props?.selectedFeature?.feature]);

    return (
        <Scrollbars
            style={{ width: '100%', height: '100%' }}
            renderThumbVertical={(props) => <div {...props} className='thumb-vertical' />}
            renderThumbHorizontal={(props) => <div {...props} className='thumb-horizontal' />}
        >
            <div className={classes.BuildingPopupInner}>
                <div className={classes.TopPart}>
                    <div className={classes.UsagesWrapper}>
                        <div className={classes.Usages}>{buildingValues?.usageName}</div>
                        <div className={classes.CloseButton} onClick={props.onClose}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <line x1='18' y1='6' x2='6' y2='18'></line>
                                <line x1='6' y1='6' x2='18' y2='18'></line>
                            </svg>
                        </div>
                    </div>

                    <div className={classes.BasicInfo}>
                        <div className={classes.BasicInfoCol}>
                            <div>
                                <span className={classes.RowValue}>{buildingValues?.area ? fNumMaxDec(buildingValues.area, 2) : 0}</span>
                                <span>
                                    m<sup>2</sup>
                                </span>
                            </div>
                            <span>Conditioned Area</span>
                        </div>
                        <div className={classes.BasicInfoCol}>
                            <div>
                                <span className={classes.RowValue}>{buildingValues?.height ? fNumMaxDec(buildingValues.height, 2) : 0}</span>
                                <span>m</span>
                            </div>
                            <span>Height</span>
                        </div>
                        <div className={classes.BasicInfoCol}>
                            <div>
                                <span className={classes.RowValue}>{buildingValues?.constructionYear}</span>
                            </div>
                            <span>Construction Year</span>
                        </div>
                    </div>
                </div>

                <div className={classes.MiddlePart}>
                    <div className={classes.InfoRow}>
                        <span>{buildingValues?.primaryEnergy}</span>
                        <span>
                            kWh/m<sup>2</sup>
                        </span>
                        <span>Primary Energy</span>
                    </div>
                    <div className={classes.InfoRow}>
                        <span>{buildingValues?.consumedEnergy}</span>
                        <span>
                            kWh/m<sup>2</sup>
                        </span>
                        <span>Energy Consumption</span>
                    </div>

                    <div className={classes.InfoRow}>
                        <span>{buildingValues?.emissionsCo2}</span>
                        <span>kgCO2eq</span>
                        <span>
                            Emissions CO<sub>2</sub>
                        </span>
                    </div>
                    <div className={classes.InfoRow}>
                        <span>{buildingValues?.energyLosses}</span>
                        <span>
                            kWh/m<sup>2</sup>
                        </span>
                        <span>Energy Losses</span>
                    </div>

                    <div className={classes.ChartsButtonWrapper}>
                        <MainButton
                            icon={
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <path d='M21.21 15.89A10 10 0 1 1 8 2.83'></path>
                                    <path d='M22 12A10 10 0 0 0 12 2v10z'></path>
                                </svg>
                            }
                            label='Building analysis'
                            onClick={() => dispatch(myProjectsActions.setViewAllCharts(true))}
                            color='action'
                        />
                    </div>
                </div>

                <div className={classes.BottomPart}>
                    <PieChart
                        title={null}
                        series={[
                            {
                                name: 'Percentage',
                                colorByPoint: true,
                                data: [
                                    { name: 'Space heating', y: buildingValues?.spaceHeating, color: '#ab0d0d' },
                                    { name: 'Space cooling', y: buildingValues?.spaceCooling, color: '#4671c7' },
                                    { name: 'Interior lighting', y: buildingValues?.interiorLighting, color: '#ffdc40' },
                                    { name: 'Receptacle equipment', y: buildingValues?.receptacleEquipment, color: '#4ba03e' },
                                ],
                            },
                        ]}
                    />
                </div>
            </div>
        </Scrollbars>
    );
};

export default BuildingPopup;
