import { useEffect, useState } from 'react';

import ColumnChart from '../../../features/Charts/ColumnChart';
import MainSelect from '../../../features/UI/MainSelect/MainSelectControlled';
import classes from './AllCharts.module.css';

const ScenarioChartsEmissions = (props) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [tabValues, setTabValues] = useState(null);
    const [usages, setUsages] = useState([]);

    useEffect(() => {
        if (!props.buildingValues) return;
        const usageValues = props.buildingValues.co2_monthly_per_usage;
        const usageArr = [];

        for (let i = 0; i < usageValues.length; i++) {
            usageArr.push(usageValues[i].name);
        }

        setUsages(usageArr);
    }, [props.buildingValues]);

    useEffect(() => {
        if (!props.buildingValues) return;

        const currentTabValues = props.buildingValues.co2_monthly_per_usage[currentTab];

        setTabValues(currentTabValues);
    }, [currentTab, props.buildingValues]);

    const updateTabFromName = (name) => {
        const foundIndex = props.buildingValues.co2_monthly_per_usage.findIndex((u) => u.name === name);

        setCurrentTab(foundIndex);
    };

    return (
        props.buildingValues && (
            <div className={classes.Charts}>
                <div className={[classes.Chart, classes.TwoColumns].join(' ')}>
                    <h3>
                        Emissions Co<sub>2</sub>
                    </h3>

                    <ColumnChart
                        title={null}
                        alignLegend='center'
                        categories={['Heating CO2', 'Cooling CO2', 'Interior lighting CO2', 'Interior equipment CO2']}
                        yAxisTitle='kgCO2eq / m2'
                        series={[
                            {
                                name: 'Emissions',
                                data: [
                                    props.buildingValues.end_uses_co2_heating,
                                    props.buildingValues.end_uses_co2_cooling,
                                    props.buildingValues.end_uses_co2_interior_lighting,
                                    props.buildingValues.end_uses_co2_interior_equipment,
                                ],
                            },
                        ]}
                    />
                </div>

                <div className={[classes.Chart, classes.TwoColumns].join(' ')}>
                    <h3>
                        Montlhy Emissions Co<sub>2</sub>
                    </h3>

                    <ColumnChart
                        title={null}
                        isStacked
                        alignLegend='center'
                        yAxisTitle='kgCO2eq'
                        categories={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                        series={props.buildingValues.co2_monthly_data}
                    />
                </div>

                {tabValues && (
                    <div className={classes.ChartWithTabs}>
                        <ul className={classes.ChartTabs}>
                            {props.buildingValues.co2_monthly_per_usage.map((usage, index) => {
                                return (
                                    <li className={currentTab == index ? classes.ActiveTab : null} key={index} onClick={() => setCurrentTab(index)}>
                                        {usage.name}
                                    </li>
                                );
                            })}
                        </ul>

                        <div className={[classes.Chart, classes.TwoColumns].join(' ')}>
                            <h3 className={classes.SimpleTitle}>
                                Montlhy Emissions CO<sub>2</sub> - {tabValues.name}
                            </h3>

                            <div className={classes.TitleWithSelect}>
                                <h3>
                                    Montlhy Emissions CO<sub>2</sub> -{' '}
                                </h3>
                                <MainSelect
                                    options={usages}
                                    value={props.buildingValues.co2_monthly_per_usage[currentTab].name}
                                    onChange={(e) => updateTabFromName(e.target.value)}
                                    narrow
                                />
                            </div>

                            <ColumnChart
                                title={null}
                                isStacked
                                alignLegend='center'
                                categories={[
                                    'January',
                                    'February',
                                    'March',
                                    'April',
                                    'May',
                                    'June',
                                    'July',
                                    'August',
                                    'September',
                                    'October',
                                    'November',
                                    'December',
                                ]}
                                series={tabValues.data}
                                yAxisTitle='kgCO2eq'
                            />
                        </div>
                    </div>
                )}
            </div>
        )
    );
};

export default ScenarioChartsEmissions;
