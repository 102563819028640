import { useNavigate } from 'react-router-dom';

import MainButton from '../UI/MainButton/MainButton';
import classes from './NoDataIcon.module.css';

const NoDataIcon = (props) => {
    const navigate = useNavigate();

    return (
        <div className={classes.NoDataIcon}>
            {/* <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                className='NoDataSvg'
            >
                <circle cx='12' cy='12' r='10'></circle>
                <line x1='4.93' y1='4.93' x2='19.07' y2='19.07'></line>
            </svg> */}

            <span>{props.message}</span>

            {props.link && (
                <div className={classes.ButtonWrapper}>
                    <MainButton
                        icon={
                            props.icon ? (
                                props.icon
                            ) : (
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <line x1='12' y1='5' x2='12' y2='19'></line>
                                    <line x1='5' y1='12' x2='19' y2='12'></line>
                                </svg>
                            )
                        }
                        label={props.label ? props.label : 'Add'}
                        color='success'
                        onClick={() => navigate(props.link)}
                    />
                </div>
            )}
        </div>
    );
};

export default NoDataIcon;
