import React, { useCallback, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import axiosApi from '../../axios-api';
import { CancelToken, isCancel } from 'axios';

import { deleteUpload } from './dropzoneAsyncActions';
import { dropzoneActions } from './dropzoneSlice';
import classes from './Dropzone.module.css';
import { showMessage } from '../../utils/messageHandler';
import ModalConfirm from '../UI/Modal/ModalConfirm';

const MyDropzone = (props) => {
    const dispatch = useDispatch();

    const [showModalClear, setShowModalClear] = useState(false);

    const percentUploaded = useSelector((state) => state.dropzone.percentUploaded);
    const uploadStatus = useSelector((state) => state.dropzone.uploadStatus);
    const myFiles = useSelector((state) => state.dropzone.myFiles);
    const fileError = useSelector((state) => state.dropzone.fileError);

    const cancelFileUpload = useRef(null);

    const uploadFile = (fileToUpload) => {
        dispatch(dropzoneActions.setUploadStatus('uploading'));

        let formData = new FormData();
        formData.append('file', fileToUpload);

        const fileNameAppend = new Blob([''], { type: 'text/plain' });
        formData.append('fileName', fileNameAppend, fileToUpload.name);

        const uploadDirAppend = new Blob([''], { type: 'text/plain' });
        formData.append('uploadsDir', uploadDirAppend, props.uploadTo);

        const config = {
            onUploadProgress: function (progressEvent) {
                const uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                dispatch(dropzoneActions.setPercentUploaded(uploadProgress));
            },
            cancelToken: new CancelToken((cancel) => (cancelFileUpload.current = cancel)),
        };

        // Upload the file to server
        axiosApi
            .post('upload.php', formData, config)
            .then((response) => {
                dispatch(dropzoneActions.setUploadStatus('uploaded'));
                if (props.onCompleteUpload) {
                    props.onCompleteUpload(response.data);
                }
            })
            .catch((error) => {
                if (isCancel(error)) {
                    showMessage('info', error.message);
                } else {
                    dispatch(dropzoneActions.setUploadStatus('generalError'));
                }
            });
    };

    const cancelUpload = () => {
        if (cancelFileUpload.current) {
            cancelFileUpload.current('The upload was canceled');
            dispatch(deleteUpload(props.uploadTo));
        }
    };

    const onDrop = useCallback(
        (acceptedFiles, fileRejections) => {
            if (uploadStatus === 'uploading') return;
            if (fileRejections.length) {
                const rejection = fileRejections[0];
                const errors = rejection.errors;

                const firstErrorCode = errors[0].code;
                let firstError;
                if (firstErrorCode === 'file-invalid-type')
                    firstError = { text: 'Wrong file type', subText: 'Allowed file types are .zip, .xml, .gml and .json' };
                if (firstErrorCode === 'file-too-large') firstError = { text: 'File is too large', subText: 'Maximum file size is 50 MB' };
                if (firstErrorCode === 'too-many-files') firstError = { text: 'Too many files', subText: 'Only one file may be selected' };

                const firstName = rejection.file.name;
                const firstSize = rejection.file.size;
                const formattedFiles = [{ name: firstName, size: firstSize }];

                dispatch(dropzoneActions.setFileError(firstError));
                dispatch(dropzoneActions.setMyFiles(formattedFiles));
                dispatch(dropzoneActions.setUploadStatus('error'));
            } else {
                //const theseFiles = [...acceptedFiles];
                dispatch(dropzoneActions.setMyFiles([...acceptedFiles]));
                dispatch(dropzoneActions.setUploadStatus('ready'));
                //uploadFile(theseFiles[0]);
            }
        },
        [dispatch, uploadFile, uploadStatus]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: props.maxFiles,
        accept: props.acceptedFiles,
        maxSize: props.maxSize,
    });

    const removeFile = () => {
        dispatch(dropzoneActions.reset());
        if (props.onRemoveFile) props.onRemoveFile();
    };

    const files = myFiles.map((file, i) => (
        <div className={classes.FileItem} key={file.name}>
            <span className={classes.FileName}>{file.name}</span>
            <span className={classes.FileSize}>
                {file.size < 1000000 ? Math.round((file.size / 1024) * 100) / 100 + 'KB' : Math.round((file.size / 1048576) * 100) / 100 + 'MB'}{' '}
            </span>
        </div>
    ));

    let classNames = [classes.DropzoneWrapper];
    if (uploadStatus === 'ready') classNames.push(classes.Ready);
    if (uploadStatus === 'uploading') classNames.push(classes.Uploading);
    else if (uploadStatus === 'uploaded') classNames.push(classes.Uploaded);
    else if (uploadStatus === 'error' || uploadStatus === 'generalError') classNames.push(classes.Error);

    return (
        <section className={classNames.join(' ')}>
            <ModalConfirm
                type='clear'
                title='Are you sure?'
                text='The data will be cleared.'
                show={showModalClear}
                onClose={() => setShowModalClear(false)}
                onAction={() => {
                    removeFile();
                    setShowModalClear(false);
                }}
            />

            <div
                {...getRootProps({
                    className: classes.Dropzone,
                    onClick: (event) => {
                        if (uploadStatus === 'uploading') event.stopPropagation();
                    },
                })}
            >
                <input {...getInputProps()} />
                {uploadStatus === '' && <span className={classes.Label}>{props.label}</span>}

                {uploadStatus === 'ready' && myFiles.length > 0 && (
                    <div className={classes.DropzoneInner}>
                        <div
                            className={classes.ClearButtonWrapperLeft}
                            onClick={(e) => {
                                e.stopPropagation();
                                removeFile(e, files[0]);
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                        {files}
                        <div className={classes.ProgressWrapper}>
                            <div className={classes.ProgressText}>
                                <span>Upload</span>
                                <span className={classes.ClearText}>Click button to upload the file</span>
                            </div>
                            <div
                                className={classes.ClearButtonWrapperRight}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    uploadFile(myFiles[0]);
                                }}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4'></path>
                                    <polyline points='17 8 12 3 7 8'></polyline>
                                    <line x1='12' y1='3' x2='12' y2='15'></line>
                                </svg>
                            </div>
                        </div>
                    </div>
                )}

                {uploadStatus === 'uploading' && (
                    <div className={classes.DropzoneInner}>
                        {files}
                        <div className={classes.ProgressWrapper}>
                            <div className={classes.ProgressText}>
                                <span>Uploading {percentUploaded}%</span>
                                <span className={classes.ClearText}>Click button to cancel</span>
                            </div>

                            <div
                                className={classes.ClearButtonWrapperRight}
                                onClick={(e) => {
                                    cancelUpload();
                                    removeFile(e, files[0]);
                                }}
                            >
                                <div className={classes.UploadingAnimationWrapper}>
                                    <CircularProgressbar
                                        value={percentUploaded}
                                        strokeWidth={6}
                                        styles={buildStyles({
                                            // How long animation takes to go from one percentage to another, in seconds
                                            pathTransitionDuration: 0.2,

                                            // Colors
                                            pathColor: `rgba(255, 255, 255, ${percentUploaded / 100})`,
                                            trailColor: '#333333',
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {uploadStatus === 'uploaded' && (
                    <div className={classes.DropzoneInner}>
                        {files}
                        <div className={classes.ProgressWrapper}>
                            <div className={classes.ProgressText}>
                                <span>Upload complete</span>
                                <span className={classes.ClearText}>Click button to clear</span>
                            </div>

                            <div
                                className={classes.ClearButtonWrapperRight}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowModalClear(true);
                                    // setShowConfirm(true);
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </div>
                        </div>
                    </div>
                )}

                {uploadStatus === 'error' && (
                    <div className={classes.DropzoneInner}>
                        <div
                            className={classes.ClearButtonWrapperLeft}
                            onClick={(e) => {
                                e.stopPropagation();
                                removeFile(e, files[0]);
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                        {files}
                        <div className={classes.ProgressWrapper}>
                            <div className={classes.ProgressText}>
                                <span>{fileError.text}</span>
                                <span className={classes.ClearText}>{fileError.subText}</span>
                            </div>
                        </div>
                    </div>
                )}
                {uploadStatus === 'generalError' && (
                    <div className={classes.DropzoneInner}>
                        <div
                            className={classes.ClearButtonWrapperLeft}
                            onClick={(e) => {
                                e.stopPropagation();
                                removeFile(e, files[0]);
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                        {files}
                        <div className={classes.ProgressWrapper}>
                            <div className={classes.ProgressText}>
                                <span>Something went wrong</span>
                                <span className={classes.ClearText}>Please try again</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default MyDropzone;
