import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import classes from './CheckoutForm.module.css';
import MainButton from '../../features/UI/MainButton/MainButton';
import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import Widget from '../../features/Widget/Widget';
import { layoutActions } from '../../features/Layout/layoutSlice';

const SuccessCheckout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('Success purchase'));
    }, [dispatch]);

    return (
        <div className={classes.CheckoutForm}>
            <Breadcrumb link1='System data' link2='/ Purchase' />

            <div className={classes.ContentInner}>
                <Widget>
                    <div className={classes.SuccessCheckout}>
                        <h3>Thank you for your order!</h3>
                        <p>The product you purchased is now available.</p>

                        <MainButton
                            icon={
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <ellipse cx='12' cy='5' rx='9' ry='3'></ellipse>
                                    <path d='M21 12c0 1.66-4 3-9 3s-9-1.34-9-3'></path>
                                    <path d='M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5'></path>
                                </svg>
                            }
                            label='System data'
                            color='action'
                            onClick={() => navigate('/systemdata')}
                        />
                    </div>
                </Widget>
            </div>
        </div>
    );
};

export default SuccessCheckout;
