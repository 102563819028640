import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MainSelect from '../../../features/UI/MainSelect/MainSelectControlled';
import MyAutocomplete from '../../../features/UI/MyAutocomplete/MyAutocomplete';
import Badge from '../../../features/UI/Badge/Badge';
import MainInput from '../../../features/UI/MainInput/MainInput';
import classes from './MapFilters.module.css';
import { myProjectsActions } from '../myProjectsSlice';

const MapFiltersFilter = () => {
    const dispatch = useDispatch();

    const [heightFilterComparison, setHeightFilterComparison] = useState('=');
    const [heightFilterValue, setHeightFilterValue] = useState(0);
    const [yearFilterComparison, setYearFilterComparison] = useState('=');
    const [yearFilterValue, setYearFilterValue] = useState(0);
    const [areaFilterComparison, setAreaFilterComparison] = useState('=');
    const [areaFilterValue, setAreaFilterValue] = useState(0);
    const [useFilterValue, setUseFilterValue] = useState(null);
    const filterData = useSelector((state) => state.myProjects.filterData);
    const useOptions = useSelector((state) => state.myProjects.useOptions);

    useEffect(() => {
        if (!filterData.length) {
            dispatch(myProjectsActions.setScenarioFilteredMetrics(null));
        }
    }, [filterData]);

    const propertyLabels = useMemo(() => {
        return {
            height: 'Height',
            year: 'Construction year',
            area: 'Building area',
            use: 'Building use',
        };
    }, []);

    const addFilter = (property) => {
        let comparison;
        let value;

        if (property === 'height') {
            comparison = heightFilterComparison;
            value = heightFilterValue;
        } else if (property === 'year') {
            comparison = yearFilterComparison;
            value = yearFilterValue;
        } else if (property === 'area') {
            comparison = areaFilterComparison;
            value = areaFilterValue;
        } else if (property === 'use') {
            comparison = '=';
            value = useFilterValue ? useFilterValue.label : '';
        }

        //Check if exists
        const found = filterData.find((f) => f.property === property && f.comparison === comparison && f.value === value);
        if (!found) {
            dispatch(myProjectsActions.updateFilterData({ property: property, comparison: comparison, value: value }));
        }

        // Reset filters
        setHeightFilterComparison('=');
        setHeightFilterValue(0);
        setYearFilterComparison('=');
        setYearFilterValue(0);
        setAreaFilterComparison('=');
        setAreaFilterValue(0);
        setUseFilterValue(null);
    };

    return (
        <div>
            <div className={classes.ResultCol}>
                <div className={classes.ResultColTitle}>
                    <div className={classes.ResetFilters} onClick={() => dispatch(myProjectsActions.setFilterData([]))}>
                        <Badge
                            color='warning'
                            icon={
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <polyline points='23 4 23 10 17 10'></polyline>
                                    <polyline points='1 20 1 14 7 14'></polyline>
                                    <path d='M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15'></path>
                                </svg>
                            }
                        ></Badge>
                    </div>
                </div>
                <div className={classes.Filters}>
                    <div className={classes.FilterRow}>
                        <span>Height:</span>
                        <MainSelect
                            options={['=', '>', '>=', '<', '<=']}
                            value={heightFilterComparison}
                            onChange={(e) => setHeightFilterComparison(e.target.value)}
                            veryNarrow
                        />
                        <MainInput type='number' label='' onChange={(e) => setHeightFilterValue(e.target.value)} value={heightFilterValue} narrow />
                        <div className={classes.FilterButtonWrapper} onClick={() => addFilter('height')}>
                            <Badge
                                color='success'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <line x1='12' y1='5' x2='12' y2='19'></line>
                                        <line x1='5' y1='12' x2='19' y2='12'></line>
                                    </svg>
                                }
                            ></Badge>
                        </div>
                    </div>

                    <div className={classes.FilterRow}>
                        <span>Construction year:</span>
                        <MainSelect
                            options={['=', '>', '>=', '<', '<=']}
                            value={yearFilterComparison}
                            onChange={(e) => setYearFilterComparison(e.target.value)}
                            veryNarrow
                        />
                        <MainInput type='number' label='' onChange={(e) => setYearFilterValue(e.target.value)} value={yearFilterValue} narrow />
                        <div className={classes.FilterButtonWrapper} onClick={() => addFilter('year')}>
                            <Badge
                                color='success'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <line x1='12' y1='5' x2='12' y2='19'></line>
                                        <line x1='5' y1='12' x2='19' y2='12'></line>
                                    </svg>
                                }
                            ></Badge>
                        </div>
                    </div>

                    <div className={classes.FilterRow}>
                        <span>Building area:</span>
                        <MainSelect
                            options={['=', '>', '>=', '<', '<=']}
                            value={areaFilterComparison}
                            onChange={(e) => setAreaFilterComparison(e.target.value)}
                            veryNarrow
                        />
                        <MainInput type='number' label='' onChange={(e) => setAreaFilterValue(e.target.value)} value={areaFilterValue} narrow />
                        <div className={classes.FilterButtonWrapper} onClick={() => addFilter('area')}>
                            <Badge
                                color='success'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <line x1='12' y1='5' x2='12' y2='19'></line>
                                        <line x1='5' y1='12' x2='19' y2='12'></line>
                                    </svg>
                                }
                            ></Badge>
                        </div>
                    </div>

                    <div className={classes.FilterRow}>
                        <span>Building use:</span>
                        <div className={classes.UseSelectWrapper}>
                            {/* <MainSelect options={useOptions} value={useFilterValue} onChange={(e) => setUseFilterValue(e.target.value)} /> */}

                            <MyAutocomplete options={useOptions} value={useFilterValue} onChange={(value) => setUseFilterValue(value)} fullWidth />
                        </div>

                        <div className={classes.FilterButtonWrapper} onClick={() => addFilter('use')}>
                            <Badge
                                color='success'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <line x1='12' y1='5' x2='12' y2='19'></line>
                                        <line x1='5' y1='12' x2='19' y2='12'></line>
                                    </svg>
                                }
                            ></Badge>
                        </div>
                    </div>
                </div>

                <div className={filterData.length > 0 ? [classes.FiltersApplied, classes.WithFilters].join(' ') : classes.FiltersApplied}>
                    {filterData.map((f, index) => {
                        return (
                            <div className={classes.FilterApplied} key={index}>
                                <span>{propertyLabels[f.property]}</span>
                                <span>{f.comparison}</span>
                                <span>{f.value}</span>
                                <div className={classes.FilterButtonWrapper} onClick={() => dispatch(myProjectsActions.removeFilterByIndex(index))}>
                                    <Badge
                                        color='danger'
                                        icon={
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <polyline points='3 6 5 6 21 6'></polyline>
                                                <path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'></path>
                                                <line x1='10' y1='11' x2='10' y2='17'></line>
                                                <line x1='14' y1='11' x2='14' y2='17'></line>
                                            </svg>
                                        }
                                    ></Badge>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default MapFiltersFilter;
