import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import Widget from '../../features/Widget/Widget';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { myScenariosActions } from './myScenariosSlice';
import { getSelectedUserScenario } from './myScenariosAsyncActions';
import classes from './MyScenariosView.module.css';
import { fNum2Dec } from '../../utils/formats';

const MyScenariosView = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectedScenario = useSelector((state) => state.myScenarios.selectedScenario);

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('My scenarios data view'));
        dispatch(getSelectedUserScenario(id, navigate));

        return () => {
            dispatch(myScenariosActions.setSelectedScenario(null));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch, id]);

    return (
        <div className={classes.MyScenariosView}>
            <Breadcrumb link1='User' link2='/ My scenarios' link3='/ View scenario' />

            <div className={classes.ContentInner}>
                <div className={classes.Widgets}>
                    <Widget>
                        <div className={classes.TitleRow}>
                            <h2>Scenario Info</h2>
                        </div>

                        <div className={classes.WidgetContent}>
                            <div className={classes.InfoRow}>
                                <span>Scenario type</span>
                                <span>{selectedScenario?.analysis_name}</span>
                            </div>
                            <div className={classes.InfoRow}>
                                <span>Project name</span>
                                <span>{selectedScenario?.project_name}</span>
                            </div>
                            <div className={classes.InfoRow}>
                                <span>Project description</span>
                                <span>{selectedScenario?.project_description}</span>
                            </div>
                            <div className={classes.InfoRow}>
                                <span>Date created</span>
                                <span>{selectedScenario?.date_created}</span>
                            </div>
                        </div>
                    </Widget>

                    <Widget>
                        <div className={classes.TitleRow}>
                            <h2>Sum values</h2>
                        </div>

                        <div className={classes.WidgetContent}>
                            <div className={classes.InfoRowWithSubrow}>
                                <span>End use breakdown</span>
                                <div className={classes.SubInfoRow}>
                                    <span>Space heating</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.space_heating)}
                                        <em>%</em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Space cooling</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.space_cooling)}
                                        <em>%</em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Interior lighting</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.space_interior_lighting)}
                                        <em>%</em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Receptacle equipment</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.space_receptacle_equipment)}
                                        <em>%</em>
                                    </span>
                                </div>
                            </div>

                            <div className={classes.InfoRowWithSubrow}>
                                <span>Annual energy consumption (Energy demand)</span>
                                <div className={classes.SubInfoRow}>
                                    <span>Heating</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.end_uses_demanded_heating)}
                                        <em>kWh</em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Cooling</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.end_uses_demanded_cooling)}
                                        <em>kWh</em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Interior lighting</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.end_uses_demanded_interior_lighting)}
                                        <em>kWh</em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Interior equipment</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.end_uses_demanded_interior_equipment)}
                                        <em>kWh</em>
                                    </span>
                                </div>
                            </div>

                            <div className={classes.InfoRowWithSubrow}>
                                <span>Annual energy consumption (Energy consumption)</span>
                                <div className={classes.SubInfoRow}>
                                    <span>Heating</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.end_uses_consumed_heating)}
                                        <em>kWh</em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Cooling</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.end_uses_consumed_cooling)}
                                        <em>kWh</em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Interior lighting</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.end_uses_consumed_interior_lighting)}
                                        <em>kWh</em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Interior equipment</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.end_uses_consumed_interior_equipment)}
                                        <em>kWh</em>
                                    </span>
                                </div>
                            </div>

                            <div className={classes.InfoRowWithSubrow}>
                                <span>Annual energy consumption (Primary energy)</span>
                                <div className={classes.SubInfoRow}>
                                    <span>Heating</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.end_uses_primary_heating)}
                                        <em>kWh</em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Cooling</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.end_uses_primary_cooling)}
                                        <em>kWh</em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Interior lighting</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.end_uses_primary_interior_lighting)}
                                        <em>kWh</em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Interior equipment</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.end_uses_primary_interior_equipment)}
                                        <em>kWh</em>
                                    </span>
                                </div>
                            </div>

                            <div className={classes.InfoRowWithSubrow}>
                                <span>Energy demand breadkdown</span>
                                <div className={classes.SubInfoRow}>
                                    <span>Heating</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.utility_use_per_conditioned_floor_area_hvac_heating)}
                                        <em>kWh</em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Cooling</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.utility_use_per_conditioned_floor_area_hvac_cooling)}
                                        <em>kWh</em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Interior lighting</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.utility_use_per_conditioned_floor_area_lighting)}
                                        <em>kWh</em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Other</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.utility_use_per_conditioned_floor_area_other)}
                                        <em>kWh</em>
                                    </span>
                                </div>
                            </div>

                            <div className={classes.InfoRowWithSubrow}>
                                <span>
                                    CO<sub>2</sub> emissions per end use
                                </span>
                                <div className={classes.SubInfoRow}>
                                    <span>Heating</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.end_uses_co2_heating)}
                                        <em>
                                            kgCO<sub>2</sub>eq
                                        </em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Cooling</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.end_uses_co2_cooling)}
                                        <em>
                                            kgCO<sub>2</sub>eq
                                        </em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Interior lighting</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.end_uses_co2_interior_lighting)}
                                        <em>
                                            kgCO<sub>2</sub>eq
                                        </em>
                                    </span>
                                </div>
                                <div className={classes.SubInfoRow}>
                                    <span>Interior equipment</span>
                                    <span>
                                        {fNum2Dec(selectedScenario?.sum_values?.end_uses_co2_interior_equipment)}
                                        <em>
                                            kgCO<sub>2</sub>eq
                                        </em>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Widget>
                </div>
            </div>
        </div>
    );
};

export default MyScenariosView;
