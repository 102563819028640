import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import jwt from 'jwt-decode';

import axiosApi from '../../axios-api';
import { loginActions } from '../../pages/Login/loginSlice';
import { layoutActions } from '../Layout/layoutSlice';
import { getAccessToken } from '../../utils/auth';
import { setAccessTokenTimer } from '../../pages/Login/loginAsyncActions';
import Loader from '../UI/Loader/Loader2';

const InitApp = () => {
    const dispatch = useDispatch();
    const [dataLoaded, setDataLoaded] = useState(false);

    const user = useSelector((state) => state.login.user);

    // Loads once on start. Check if access token exists or it has expired.
    useEffect(() => {
        let theme = localStorage.getItem('theme');
        if (!theme) theme = 'light';

        dispatch(layoutActions.setTheme(theme));
        document.body.setAttribute('data-theme', theme);

        const token = getAccessToken();
        if (!token) {
            setDataLoaded(true);
            return;
        }

        axiosApi
            .get('tokensCheck.php')
            .then((res) => {
                if (res.data.isValid === false) {
                    throw Error('Forbidden');
                } else {
                    const tokenInfo = jwt(token);
                    dispatch(loginActions.setUserInfo(tokenInfo));
                    setDataLoaded(true);
                }
            })
            .catch((error) => {
                dispatch(loginActions.logout());
                setDataLoaded(true);
            });
    }, []);

    useEffect(() => {
        if (!user) return;

        dispatch(setAccessTokenTimer());
    }, [user]);

    return dataLoaded ? <Outlet /> : <Loader show={true} />;
};

export default InitApp;
