import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    systemData: [],
    selectedSystemData: null,
    checkoutLoading: false,
};

export const systemDataSlice = createSlice({
    name: 'systemData',
    initialState,
    reducers: {
        setSystemData: (state, action) => {
            state.systemData = action.payload;
        },
        setSelectedSystemData: (state, action) => {
            state.selectedSystemData = action.payload;
        },
        setCheckoutLoading: (state, action) => {
            state.checkoutLoading = action.payload;
        },
    },
});

export const systemDataActions = systemDataSlice.actions;

export default systemDataSlice;
