import classes from './Breadcrumb.module.css';

const Breadcrumb = (props) => {
    return (
        <div className={classes.Breadcrumb}>
            {props.link1 && <span>{props.link1}</span>}
            {props.link2 && <span>{props.link2}</span>}
            {props.link3 && <span>{props.link3}</span>}
        </div>
    );
};

export default Breadcrumb;
