import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classes from './Contact.module.css';
import Wavebox from '../../features/Wavebox/Wavebox';
import MainButton from '../../features/UI/MainButton/MainButton';
import MainInput from '../../features/UI/MainInput/MainInput';
import MainTextarea from '../../features/UI/MainTextarea/MainTextarea';
import { websiteActions } from '../websiteSlice';
import { sendContactEmail } from '../websiteAsyncActions';

const Contact = () => {
    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [formValidated, setFormValidated] = useState(false);
    const [bot, setBot] = useState('');

    const sendingEmail = useSelector((state) => state.website.sendingEmail);
    const emailMessage = useSelector((state) => state.website.emailMessage);

    const reset = () => {
        setName('');
        setEmail('');
        setMessage('');
        setFormValidated(false);
        setBot('');
    };

    const onSendContactEmail = (e) => {
        e.preventDefault();

        setFormValidated(true);

        if (bot) {
            return;
        }

        if (name.trim !== '' && email.includes('@') === true && email.split('@')[1].length > 0 && email.trim() !== '' && message.trim() !== '') {
            const payload = {
                name,
                email,
                message,
            };
            dispatch(sendContactEmail(payload));

            reset();
        } else {
            dispatch(websiteActions.setEmailMessage('Error! You must fill in all the necessary fields'));
        }
    };

    useEffect(() => {
        return () => {
            dispatch(websiteActions.setSendingEmail(false));
            dispatch(websiteActions.setEmailMessage(''));
        };
    }, []);

    return (
        <div className={classes.Contact}>
            <Wavebox>
                <div className={classes.ColumnsWrapper}>
                    <div className={classes.Columns}>
                        <div className={classes.ColumnLeft}>
                            <div className={classes.ContactItem}>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='1'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <path d='M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z'></path>
                                    <circle cx='12' cy='10' r='3'></circle>
                                </svg>
                                <h3>Thessaloniki, Greece</h3>
                                <span>Themistokli Sofouli 57, 55131</span>
                            </div>

                            <div className={classes.ContactItem}>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='1'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <path d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z'></path>
                                </svg>
                                <h3>+30 2314 021 677</h3>
                                <span>Call us: Mon - Fri 9:00 - 17:00</span>
                            </div>

                            <div className={classes.ContactItem}>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='1'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                >
                                    <path d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z'></path>
                                    <polyline points='22,6 12,13 2,6'></polyline>
                                </svg>
                                <h3>info@re-polis.com</h3>
                                <span>Send us an email</span>
                            </div>
                        </div>

                        <div className={classes.ColumnRight}>
                            <h2>CONTACT US</h2>
                            <span>Got any questions? Want a free tour of the platform? Fill out the form below.</span>

                            <form>
                                <input name='bot-field' placeholder='do not fill this' type='hidden' onChange={(e) => setBot(e.target.value)} />

                                <div className={classes.TwoItems}>
                                    <MainInput
                                        type='text'
                                        fullWidth
                                        label='Name *'
                                        onChange={(e) => setName(e.target.value)}
                                        value={name}
                                        validated={formValidated}
                                        isValid={name.trim() !== ''}
                                    />
                                    <MainInput
                                        type='email'
                                        fullWidth
                                        label='Email *'
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                        validated={formValidated}
                                        isValid={email.includes('@') === true && email.split('@')[1].length > 0 && email.trim() !== ''}
                                    />
                                </div>

                                <MainTextarea
                                    type='text'
                                    fullWidth
                                    label='Message *'
                                    value={message}
                                    rows={4}
                                    onChange={(e) => setMessage(e.target.value)}
                                    validated={formValidated}
                                    isValid={message.trim() !== ''}
                                />

                                <div className={classes.ButtonWrapper}>
                                    <MainButton
                                        icon={
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <line x1='22' y1='2' x2='11' y2='13'></line>
                                                <polygon points='22 2 15 22 11 13 2 9 22 2'></polygon>
                                            </svg>
                                        }
                                        label='SEND'
                                        color='action'
                                        fullWidth
                                        loading={sendingEmail}
                                        onClick={(e) => onSendContactEmail(e)}
                                    />

                                    {emailMessage && (
                                        <div
                                            className={
                                                emailMessage.includes('Error')
                                                    ? [classes.Message, classes.Danger].join(' ')
                                                    : [classes.Message, classes.Success].join(' ')
                                            }
                                        >
                                            <div className={classes.MessageIcon}></div>
                                            <span>{emailMessage}</span>
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Wavebox>
        </div>
    );
};

export default Contact;
