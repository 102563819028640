import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Badge from '../../features/UI/Badge/Badge';
import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import Widget from '../../features/Widget/Widget';
import MyTable from '../../features/UI/MyTable/MyTable';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { getMyScenarios } from './myScenariosAsyncActions';
import { myScenariosActions } from './myScenariosSlice';
import classes from './MyScenarios.module.css';
import MainButton from '../../features/UI/MainButton/MainButton';

const MyScenarios = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const myScenariosData = useSelector((state) => state.myScenarios.myScenariosData);

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('My scenarios'));
        dispatch(getMyScenarios());

        return () => {
            dispatch(myScenariosActions.setMyScenarios([]));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'project_name',
                header: 'Project name',
            },
            {
                accessorKey: 'analysis_name',
                header: 'Analysis type',
            },
            {
                accessorKey: 'project_description',
                header: 'Project description',
            },
            {
                accessorFn: (row) => row.id,
                id: 'id',
                header: 'Actions',
                Cell: ({ cell }) => (
                    <div className={classes.TableActions}>
                        <ReactTooltip effect='solid' />

                        <Link to={`/myscenarios-view/${cell.getValue()}`} data-tip='View'>
                            <Badge
                                color='success'
                                size='small'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <circle cx='11' cy='11' r='8'></circle>
                                        <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
                                    </svg>
                                }
                            />
                        </Link>
                    </div>
                ),
                size: 40,
            },
        ],
        []
    );

    return (
        <div className={classes.MyScenarios}>
            <Breadcrumb link1='User' link2='/ My scenarios' />

            <div className={classes.ContentInner}>
                <Widget>
                    <MyTable
                        data={myScenariosData}
                        columns={columns}
                        // buttons={
                        //     <div className={classes.AddButton}>
                        //         <MainButton
                        //             icon={
                        //                 <svg
                        //                     xmlns='http://www.w3.org/2000/svg'
                        //                     width='24'
                        //                     height='24'
                        //                     viewBox='0 0 24 24'
                        //                     fill='none'
                        //                     stroke='currentColor'
                        //                     strokeWidth='2'
                        //                     strokeLinecap='round'
                        //                     strokeLinejoin='round'
                        //                 >
                        //                     <polyline points='23 18 13.5 8.5 8.5 13.5 1 6'></polyline>
                        //                     <polyline points='17 18 23 18 23 12'></polyline>
                        //                 </svg>
                        //             }
                        //             label='Compare'
                        //             color='success'
                        //             fullWidth
                        //             onClick={() => navigate('/compare')}
                        //         />
                        //     </div>
                        // }
                    />
                </Widget>
            </div>
        </div>
    );
};

export default MyScenarios;
