import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

import classes from './MyAutocomplete.module.css';

const MyAutocompleteCountries = (props) => {
    let elementClasses = [classes.FormControl];

    if (props.fullWidth) elementClasses.push(classes.FullWidth);
    else if (props.narrow) elementClasses.push(classes.Narrow);
    else if (props.veryNarrow) elementClasses.push(classes.VeryNarrow);

    if (props.disabled) elementClasses.push(classes.Disabled);

    if (props.validated && props.isValid) elementClasses.push(classes.IsValid);
    else if (props.validated && props.isValid === false) elementClasses.push(classes.IsInvalid);

    return (
        <FormControl className={elementClasses.join(' ')}>
            <Autocomplete
                options={props.options}
                autoHighlight
                getOptionLabel={(option) => option.label}
                value={props.value}
                defaultValue=''
                disableClearable={props.disableClearable}
                onChange={(event, newValue) => {
                    props.onChange(newValue);
                }}
                renderOption={(props, option) => (
                    <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                            loading='lazy'
                            width='20'
                            src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                            alt=''
                        />
                        {option.label} ({option.value})
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </FormControl>
    );
};

export default MyAutocompleteCountries;
