import { enqueueSnackbar } from 'notistack';

export function showMessage(type, message) {
    enqueueSnackbar(message, { variant: type });
}

export function showError(error) {
    if (error.code === 'ERR_CANCELED') return;

    let message = 'Something went wrong. Please try again.';
    if (error?.response?.data?.message) message = error.response.data.message;
    else if (error?.message) message = error.message;

    enqueueSnackbar(message, { variant: 'error' });
}
