import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SimpleTable from '../../features/UI/MyTable/SimpleTable';
import NoDataIcon from '../../features/NoDataIcon/NoDataIcon';
import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import Widget from '../../features/Widget/Widget';
import Badge from '../../features/UI/Badge/Badge';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { dashboardActions } from './dashboardSlice';
import { getDashboardData } from './dashboardAsyncActions';
import classes from './Dashboard.module.css';

const Dashboard = () => {
    const dispatch = useDispatch();

    const dashboardData = useSelector((state) => state.dashboard.dashboardData);

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('Dashboard'));
        dispatch(getDashboardData());

        return () => {
            dispatch(dashboardActions.setDashboardData(null));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch]);

    return (
        <div className={classes.Dashboard}>
            <Breadcrumb link1='Main' link2='/ Home' />

            <div className={classes.ContentInner}>
                <div className={classes.Widgets}>
                    <Widget>
                        <div className={classes.TitleRow}>
                            <Badge
                                color='warning'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <ellipse cx='12' cy='5' rx='9' ry='3'></ellipse>
                                        <path d='M21 12c0 1.66-4 3-9 3s-9-1.34-9-3'></path>
                                        <path d='M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5'></path>
                                    </svg>
                                }
                            />

                            <div className={classes.Title}>
                                <h2>{dashboardData?.datasets.length} Datasets</h2>
                                <span>in my data</span>
                            </div>
                        </div>

                        <div className={classes.WidgetContent}>
                            {dashboardData && dashboardData.datasets.length === 0 && (
                                <NoDataIcon message='You have not created any datasets' link='/mydata-new' />
                            )}
                            {dashboardData && dashboardData.datasets.length > 0 && (
                                <SimpleTable
                                    data={dashboardData?.datasets}
                                    columns={[
                                        { property: 'id', label: 'ID', hidden: true },
                                        { property: 'name', label: 'Name' },
                                        { property: 'description', label: 'Description' },
                                        { property: 'date_created', label: 'Date created' },
                                    ]}
                                    action={{ property: 'id', view: 'mydata-view' }}
                                />
                            )}
                        </div>
                    </Widget>

                    <Widget>
                        <div className={classes.TitleRow}>
                            <Badge
                                color='success'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <line x1='8' y1='19' x2='8' y2='21'></line>
                                        <line x1='8' y1='13' x2='8' y2='15'></line>
                                        <line x1='16' y1='19' x2='16' y2='21'></line>
                                        <line x1='16' y1='13' x2='16' y2='15'></line>
                                        <line x1='12' y1='21' x2='12' y2='23'></line>
                                        <line x1='12' y1='15' x2='12' y2='17'></line>
                                        <path d='M20 16.58A5 5 0 0 0 18 7h-1.26A8 8 0 1 0 4 15.25'></path>
                                    </svg>
                                }
                            />

                            <div className={classes.Title}>
                                <h2>{dashboardData?.weatherData.length} Weather file</h2>
                                <span>imported</span>
                            </div>
                        </div>

                        <div className={classes.WidgetContent}>
                            {dashboardData && dashboardData.weatherData.length === 0 && (
                                <NoDataIcon message='You have not added any weather files' link='/myweatherfiles-new' />
                            )}
                            {dashboardData && dashboardData.weatherData.length > 0 && (
                                <SimpleTable
                                    data={dashboardData?.weatherData}
                                    columns={[
                                        { property: 'id', label: 'ID', hidden: true },
                                        { property: 'name', label: 'Name' },
                                        { property: 'description', label: 'Description' },
                                        { property: 'date_created', label: 'Date created' },
                                    ]}
                                    action={{ property: 'id', view: 'myweatherfiles-view' }}
                                />
                            )}
                        </div>
                    </Widget>

                    <Widget>
                        <div className={classes.TitleRow}>
                            <Badge
                                color='info'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <line x1='8' y1='6' x2='21' y2='6'></line>
                                        <line x1='8' y1='12' x2='21' y2='12'></line>
                                        <line x1='8' y1='18' x2='21' y2='18'></line>
                                        <line x1='3' y1='6' x2='3.01' y2='6'></line>
                                        <line x1='3' y1='12' x2='3.01' y2='12'></line>
                                        <line x1='3' y1='18' x2='3.01' y2='18'></line>
                                    </svg>
                                }
                            />

                            <div className={classes.Title}>
                                <h2>{dashboardData?.projects.length} Projects</h2>
                                <span>created</span>
                            </div>
                        </div>

                        <div className={classes.WidgetContent}>
                            {dashboardData && dashboardData.projects.length === 0 && (
                                <NoDataIcon message='You have not created any projects' link='/myprojects-new' />
                            )}
                            {dashboardData && dashboardData.projects.length > 0 && (
                                <SimpleTable
                                    data={dashboardData?.projects}
                                    columns={[
                                        { property: 'id', label: 'ID', hidden: true },
                                        { property: 'name', label: 'Name' },
                                        { property: 'description', label: 'Description' },
                                        { property: 'date_created', label: 'Date created' },
                                    ]}
                                    action={{ property: 'id', view: 'myprojects-view' }}
                                />
                            )}
                        </div>
                    </Widget>

                    <Widget>
                        <div className={classes.TitleRow}>
                            <Badge
                                color='danger'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <polyline points='21 8 21 21 3 21 3 8'></polyline>
                                        <rect x='1' y='3' width='22' height='5'></rect>
                                        <line x1='10' y1='12' x2='14' y2='12'></line>
                                    </svg>
                                }
                            />

                            <div className={classes.Title}>
                                <h2>{dashboardData?.scenarios.length} Scenarios</h2>
                                <span>created</span>
                            </div>
                        </div>

                        <div className={classes.WidgetContent}>
                            {dashboardData && dashboardData.scenarios.length === 0 && <NoDataIcon message='You have not run any scenarios' />}
                            {dashboardData && dashboardData.scenarios.length > 0 && (
                                <SimpleTable
                                    data={dashboardData?.scenarios}
                                    columns={[
                                        { property: 'id', label: 'ID', hidden: true },
                                        { property: 'analysis_type', label: 'Analysis type' },
                                        { property: 'project_name', label: 'Project name' },
                                        { property: 'date_created', label: 'Date created' },
                                    ]}
                                    action={{ property: 'id', view: 'myscenarios-view' }}
                                />
                            )}
                        </div>
                    </Widget>

                    <Widget>
                        <div className={classes.TitleRow}>
                            <Badge
                                color='warning'
                                icon={
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <circle cx='9' cy='21' r='1'></circle>
                                        <circle cx='20' cy='21' r='1'></circle>
                                        <path d='M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6'></path>
                                    </svg>
                                }
                            />

                            <div className={classes.Title}>
                                <h2>{dashboardData?.systemDatasets.length} System datasets</h2>
                                <span>purchased</span>
                            </div>
                        </div>

                        <div className={classes.WidgetContent}>
                            {dashboardData && dashboardData.systemDatasets.length === 0 && (
                                <NoDataIcon
                                    message='You have not purchased any system datasets'
                                    link='/systemdata'
                                    label='Purchase'
                                    icon={
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        >
                                            <circle cx='9' cy='21' r='1'></circle>
                                            <circle cx='20' cy='21' r='1'></circle>
                                            <path d='M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6'></path>
                                        </svg>
                                    }
                                />
                            )}
                            {dashboardData && dashboardData.systemDatasets.length > 0 && (
                                <SimpleTable
                                    data={dashboardData?.systemDatasets}
                                    columns={[
                                        { property: 'id', label: 'ID', hidden: true },
                                        { property: 'name', label: 'Name' },
                                        { property: 'country', label: 'Country' },
                                        { property: 'date_purchased', label: 'Date purchased' },
                                    ]}
                                    action={{ property: 'id', view: 'systemdata-view' }}
                                />
                            )}
                        </div>
                    </Widget>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
