import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useNavigate } from 'react-router-dom';

import logo_black from '../../assets/images/repolis_logo_black.png';
import logo_white from '../../assets/images/repolis_logo_white.png';
import icon_black from '../../assets/images/repolis_icon_black.png';
import icon_white from '../../assets/images/repolis_icon_white.png';
import Navigation from '../Navigation/Navigation';
import classes from './Sidebar.module.css';
import { layoutActions } from '../../features/Layout/layoutSlice';

const Sidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const menuIsClosed = useSelector((state) => state.layout.menuIsClosed);
    const theme = useSelector((state) => state.layout.theme);

    return (
        <div className={menuIsClosed ? [classes.Sidebar, 'IsClosed'].join(' ') : classes.Sidebar}>
            <div className={classes.SidebarHeader}>
                <img className={classes.LogoIcon} src={theme === 'dark' ? icon_white : icon_black} alt='Repolis icon' onClick={() => navigate('/')} />
                <img className={classes.Logo} src={theme === 'dark' ? logo_white : logo_black} alt='Repolis logo' onClick={() => navigate('/')} />

                <div className={classes.CloseButton} onClick={() => dispatch(layoutActions.toggleMenuClosed())}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <polyline points='11 17 6 12 11 7'></polyline>
                        <polyline points='18 17 13 12 18 7'></polyline>
                    </svg>
                </div>
            </div>

            <div className={classes.ShadowBottom}></div>

            <div className={classes.NavigationOuter}>
                <Scrollbars
                    style={{ width: '100%', height: '100%' }}
                    renderThumbVertical={(props) => <div {...props} className='thumb-vertical' />}
                    renderThumbHorizontal={(props) => <div {...props} className='thumb-horizontal' />}
                >
                    <Navigation />
                </Scrollbars>
            </div>
        </div>
    );
};

export default Sidebar;
