import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import countryList from 'react-select-country-list';
import ReactTooltip from 'react-tooltip';

import BuildingUsesSelection from '../../features/BuildingUsesSelection/BuildingUsesSelection';
import Badge from '../../features/UI/Badge/Badge';
import MyDropzone from '../../features/Dropzone/Dropzone';
import Widget from '../../features/Widget/Widget';
import Breadcrumb from '../../features/Breadcrumb/Breadcrumb';
import Modal from '../../features/UI/Modal/Modal';
import MainButton from '../../features/UI/MainButton/MainButton';
import MainInput from '../../features/UI/MainInput/MainInput';
import MainTextarea from '../../features/UI/MainTextarea/MainTextarea';
import MyAutocompleteCountries from '../../features/UI/MyAutocomplete/MyAutocompleteCountries';
import MyAutocomplete from '../../features/UI/MyAutocomplete/MyAutocomplete';
import { layoutActions } from '../../features/Layout/layoutSlice';
import { dropzoneActions } from '../../features/Dropzone/dropzoneSlice';
import { myDataActions } from './myDataSlice';
import { systemActions } from '../../features/systemSlice';
import classes from './MyDataNew.module.css';
import { getBuildingUses, saveDataFile, getFileFields } from '../../pages/MyData/MyDataAsyncActions';
import { showMessage } from '../../utils/messageHandler';

const MyDataNew = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const savingStatus = useSelector((state) => state.myData.savingStatus);

    const uploadedFileDetails = useSelector((state) => state.myData.uploadedFileDetails);
    const fileFields = useSelector((state) => state.myData.fileFields);
    const uploadStatus = useSelector((state) => state.dropzone.uploadStatus);
    const getFileFieldsStatus = useSelector((state) => state.myData.getFileFieldsStatus);
    const buildingUses = useSelector((state) => state.myData.buildingUses);
    const buildingUsesForCountry = useSelector((state) => state.myData.buildingUsesForCountry);

    const [formValidated, setFormValidated] = useState(false);
    const [tempFieldsAssign, setTempFieldsAssign] = useState({ height: null, buildingType: null, constructionYear: null });
    const [tempBuildingUsesAssign, setTempBuildingUsesAssign] = useState({});
    const [restFloorsUsesAssign, setRestFloorsUsesAssign] = useState({});
    const [showEditPropertiesModal, setShowEditPropertiesModal] = useState(false);
    const [showUsesModal, setShowUsesModal] = useState(false);
    const [step2Valid, setStep2Valid] = useState(false);
    const [step3Valid, setStep3Valid] = useState(false);
    const [getHeightFrom3d, setGetHeightFrom3d] = useState(false);

    const initNewDataset = {
        //fileDetails: null,
        fieldsAssign: { height: null, buildingType: null, constructionYear: null },
        buildingUsesAssign: {},
        dataName: '',
        dataDescription: '',
        dataCity: '',
        dataCountry: null,
    };
    const [newDataset, setNewDataset] = useState(initNewDataset);

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('Data'));

        // This runs on component unmount
        return () => {
            clear();

            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, [dispatch]);

    const updateDataset = (property, value) => {
        let updated = { ...newDataset };
        updated[property] = value;
        setNewDataset(updated);
    };

    const clear = () => {
        dispatch(dropzoneActions.reset());
        dispatch(myDataActions.resetNewData());

        let updateNewDataset = { ...newDataset };
        updateNewDataset.fieldsAssign = initNewDataset.fieldsAssign;
        updateNewDataset.buildingUsesAssign = initNewDataset.buildingUsesAssign;

        setTempFieldsAssign(initNewDataset.fieldsAssign);
        setTempBuildingUsesAssign(initNewDataset.buildingUsesAssign);
        setNewDataset(updateNewDataset);
        setRestFloorsUsesAssign({});
    };

    useEffect(() => {
        if (uploadStatus === 'uploaded' && uploadedFileDetails && getFileFieldsStatus === '') {
            dispatch(getFileFields(uploadedFileDetails));
        }
    }, [uploadStatus, dispatch, uploadedFileDetails, getFileFieldsStatus]);

    // --------------------------------------
    // Fields assign
    // Open fields assign
    const openFieldsAssign = () => {
        setTempFieldsAssign(newDataset.fieldsAssign);
        setShowEditPropertiesModal(true);
    };

    // if all properties are assigned then it is valid
    useEffect(() => {
        if (newDataset.fieldsAssign.height && newDataset.fieldsAssign.buildingType && newDataset.fieldsAssign.constructionYear) {
            setStep2Valid(true);
            dispatch(getBuildingUses(uploadedFileDetails, newDataset.fieldsAssign, newDataset.dataCountry.label));
        } else {
            setStep2Valid(false);
        }
    }, [newDataset.fieldsAssign, dispatch]);

    // Save fields assign
    const saveFieldsAssign = () => {
        let update = { ...newDataset };
        update.fieldsAssign = tempFieldsAssign;
        setNewDataset(update);
        setShowEditPropertiesModal(false);
    };

    // Fields assign handler
    const fieldsAssignHandler = (property, value) => {
        let updatedFieldsAssign = { ...tempFieldsAssign };
        updatedFieldsAssign[property] = value;

        // If building type clear all building assigns
        if (property === 'buildingType') {
            setTempBuildingUsesAssign(initNewDataset.buildingUsesAssign);
            setRestFloorsUsesAssign({});
        }

        setTempFieldsAssign(updatedFieldsAssign);
    };

    // On change height from 3d
    const getHeightFrom3dHandler = () => {
        let updatedFieldsAssign = { ...tempFieldsAssign };

        if (getHeightFrom3d) {
            updatedFieldsAssign.height = null;
            setGetHeightFrom3d(false);
        } else {
            updatedFieldsAssign.height = { label: '__3dHeight', value: '__3dHeight' };
            setGetHeightFrom3d(true);
        }

        setTempFieldsAssign(updatedFieldsAssign);
    };

    // Get remaining options for drop down menus
    const getOptions = (property) => {
        let options = fileFields ? [...fileFields.fieldOptions] : [];

        let toRemoveArr = [];
        for (const [key, value] of Object.entries(newDataset.fieldsAssign)) {
            if (key !== property && value) toRemoveArr.push(value);
        }

        const toRemove = new Set(toRemoveArr);
        const filteredOptions = options.filter((x) => !toRemove.has(x));

        return filteredOptions;
    };
    // --------------------------------------

    // --------------------------------------
    // Building uses assign
    // Open building uses assign
    const openBuildingUsesAssign = () => {
        setTempBuildingUsesAssign(newDataset.buildingUsesAssign);
        setShowUsesModal(true);
    };

    // Try to match initially the building uses
    useEffect(() => {
        let updateUsesAssign = {};

        for (let buildingUse of buildingUses) {
            const lowerCaseBuildingUse = typeof buildingUse === 'string' ? buildingUse?.toLowerCase() : buildingUse;

            const found = buildingUsesForCountry.find((u) =>
                typeof u.label === 'string' ? u.label.toLowerCase() === lowerCaseBuildingUse : u.label === lowerCaseBuildingUse
            );
            if (found) updateUsesAssign[buildingUse] = found;
        }

        let update = { ...newDataset };
        update.buildingUsesAssign = updateUsesAssign;
        setNewDataset(update);
    }, [buildingUses, buildingUsesForCountry]);

    // If all building uses have been assigned, step 3 is valid
    useEffect(() => {
        if (
            buildingUses.length > 0 &&
            Object.keys(newDataset.buildingUsesAssign).length > 0 &&
            Object.keys(newDataset.buildingUsesAssign).length === buildingUses.length
        ) {
            setStep3Valid(true);
        } else {
            setStep3Valid(false);
        }
    }, [buildingUses, newDataset.buildingUsesAssign]);

    // Save building uses assign
    const saveBuildingUsesAssign = () => {
        let update = { ...newDataset };
        update.buildingUsesAssign = tempBuildingUsesAssign;
        setNewDataset(update);
        setShowUsesModal(false);
    };

    // Building uses assign handler
    const assignBuildingUse = (buildingUse, value, floor) => {
        let updatedBuildingUsesAssign = { ...tempBuildingUsesAssign };
        let updateRestFloorsUsesAssign = { ...restFloorsUsesAssign };

        if (floor === 'rest') {
            if (!value && updateRestFloorsUsesAssign[buildingUse]) delete updateRestFloorsUsesAssign[buildingUse];
            else updateRestFloorsUsesAssign[buildingUse] = value;

            setRestFloorsUsesAssign(updateRestFloorsUsesAssign);
        } else {
            if (value) updatedBuildingUsesAssign[buildingUse] = value;
            else delete updatedBuildingUsesAssign[buildingUse];

            setTempBuildingUsesAssign(updatedBuildingUsesAssign);
        }
    };
    // --------------------------------------

    const onSaveNewDataset = () => {
        setFormValidated(true);

        if (newDataset.dataName.trim() === '') {
            showMessage('error', 'Please fill in all required fields');
        } else if (!uploadedFileDetails) {
            showMessage('error', 'Please upload a file');
        } else if (
            newDataset.fieldsAssign.height === null ||
            newDataset.fieldsAssign.buildingType === null ||
            newDataset.fieldsAssign.constructionYear === null
        ) {
            showMessage('error', 'Please assign height, building type and construction year fields');
        } else {
            let updatedBuildingUsesAssign = { ...newDataset.buildingUsesAssign };

            for (const [key, buildingRest] of Object.entries(restFloorsUsesAssign)) {
                const newValue = updatedBuildingUsesAssign[key].value + '_' + buildingRest.value;
                const newLabel = updatedBuildingUsesAssign[key].label + '_' + buildingRest.label;
                const newObj = { value: newValue, label: newLabel };
                updatedBuildingUsesAssign[key] = newObj;
            }

            const payload = {
                ...uploadedFileDetails,
                ...fileFields,
                fieldsAssign: newDataset.fieldsAssign,
                buildingUsesAssign: updatedBuildingUsesAssign,
                dataName: newDataset.dataName,
                dataDescription: newDataset.dataDescription,
                dataCity: newDataset.dataCity,
                dataCountry: newDataset.dataCountry.label,
            };

            //analyzeCityJsonFile();
            dispatch(saveDataFile(payload));
        }
    };

    useEffect(() => {
        if (savingStatus === 'start') {
            dispatch(systemActions.setLoading(true));
        } else if (savingStatus === 'error') {
            dispatch(systemActions.setLoading(false));
        } else if (savingStatus === 'finished') {
            dispatch(systemActions.setLoading(false));
            navigate('/mydata');
        }
    }, [savingStatus, navigate]);

    return (
        <div className={savingStatus === 'start' ? [classes.MyDataNew, classes.LockInteraction].join(' ') : classes.MyDataNew}>
            <Breadcrumb link1='User' link2='/ My data' link3='/ New dataset' />

            <div className={classes.ContentInner}>
                <div className={classes.ButtonWrapper}>
                    <MainButton
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                                <polyline points='17 21 17 13 7 13 7 21'></polyline>
                                <polyline points='7 3 7 8 15 8'></polyline>
                            </svg>
                        }
                        label='Save'
                        color='action'
                        onClick={onSaveNewDataset}
                        loading={savingStatus === 'start'}
                    />
                </div>

                <div className={classes.Widgets}>
                    <Widget>
                        <div className={classes.TitleRowWithSubtitle}>
                            <h2>Dataset info</h2>
                            <span>Fill in the name and country of the dataset</span>
                        </div>

                        <div className={classes.WidgetContent}>
                            <form>
                                <div className={classes.FormRow}>
                                    <label>Name *</label>
                                    <MainInput
                                        type='text'
                                        fullWidth
                                        value={newDataset.dataName}
                                        onChange={(e) => updateDataset('dataName', e.target.value)}
                                        validated={formValidated}
                                        isValid={newDataset.dataName.trim() !== ''}
                                    />
                                </div>
                                <div className={classes.FormRow}>
                                    <label>Country *</label>
                                    <MyAutocompleteCountries
                                        fullWidth
                                        options={countryList().data}
                                        value={newDataset.dataCountry}
                                        onChange={(country) => updateDataset('dataCountry', country)}
                                        disableClearable
                                        validated={formValidated}
                                        isValid={newDataset.dataCountry ? true : false}
                                    />
                                </div>
                                <div className={classes.FormRow}>
                                    <label>City</label>
                                    <MainInput type='text' fullWidth value={newDataset.dataCity} onChange={(e) => updateDataset('dataCity', e.target.value)} />
                                </div>
                                <div className={classes.FormRow}>
                                    <label>Description</label>
                                    <MainTextarea
                                        type='text'
                                        fullWidth
                                        value={newDataset.dataDescription}
                                        rows={3}
                                        onChange={(e) => updateDataset('dataDescription', e.target.value)}
                                    />
                                </div>
                            </form>
                        </div>
                    </Widget>

                    <Widget
                        showCurtain={newDataset.dataName.trim() === '' || newDataset.dataCountry === null}
                        curtainMessage='Fill in the name and country of the dataset first'
                    >
                        <div className={classes.TitleRowWithSubtitle}>
                            <h2>Steps</h2>
                            <span>Please complete the following steps to create a new dataset</span>
                        </div>

                        <div className={classes.WidgetContent}>
                            <div className={classes.StepsWrapper}>
                                <div
                                    className={
                                        uploadedFileDetails && uploadStatus !== 'error' && uploadStatus !== 'generalError'
                                            ? [classes.Step, classes.Complete].join(' ')
                                            : classes.Step
                                    }
                                >
                                    <div className={classes.Col1}>
                                        <div className={classes.Circle}>
                                            <span>1</span>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <polyline points='20 6 9 17 4 12'></polyline>
                                            </svg>
                                        </div>

                                        <span></span>
                                    </div>
                                    <div className={classes.Col2}>
                                        <div className={classes.Title}>
                                            <h4>Upload file</h4>
                                            <span>Please select a file to upload</span>
                                        </div>
                                        <MyDropzone
                                            label='Drag & drop your file or Browse'
                                            acceptedFiles={{
                                                'application/zip': ['.zip'],
                                                'application/xml': ['.xml', '.cityGml', '.gml'],
                                                'application/json': ['.cityJson', '.json'],
                                            }}
                                            maxFiles={1}
                                            maxSize={52428800}
                                            onCompleteUpload={(data) => dispatch(myDataActions.setUploadedFileDetails(data))}
                                            onRemoveFile={() => clear()}
                                            uploadTo='uploads'
                                        />
                                    </div>
                                </div>

                                <div className={step2Valid ? [classes.Step, classes.Complete].join(' ') : classes.Step}>
                                    <div className={classes.Col1}>
                                        <div className={classes.Circle}>
                                            <span>2</span>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <polyline points='20 6 9 17 4 12'></polyline>
                                            </svg>
                                        </div>
                                        <span></span>
                                    </div>
                                    <div className={classes.Col2}>
                                        <div className={classes.TitleWrapper}>
                                            <div className={classes.Title}>
                                                <h4>Select fields for height, building type and construction year</h4>
                                                {step2Valid && <span className={classes.Success}>All fields are selected</span>}
                                                {!step2Valid && <span className={classes.Danger}>The fields are not selected</span>}
                                            </div>
                                            <div
                                                className={
                                                    uploadedFileDetails && uploadStatus !== 'error' && uploadStatus !== 'generalError'
                                                        ? classes.EditPropertiesBtn
                                                        : [classes.EditPropertiesBtn, classes.Disabled].join(' ')
                                                }
                                                onClick={() =>
                                                    uploadedFileDetails && uploadStatus !== 'error' && uploadStatus !== 'generalError'
                                                        ? openFieldsAssign()
                                                        : null
                                                }
                                            >
                                                <Badge
                                                    color='warning'
                                                    icon={
                                                        <svg
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                        >
                                                            <path d='M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z'></path>
                                                        </svg>
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={step3Valid ? [classes.Step, classes.Complete].join(' ') : classes.Step}>
                                    <div className={classes.Col1}>
                                        <div className={classes.Circle}>
                                            <span>3</span>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <polyline points='20 6 9 17 4 12'></polyline>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className={classes.Col2}>
                                        <div className={classes.TitleWrapper}>
                                            <div className={classes.Title}>
                                                <h4>Match the building uses in your file with the building uses provided</h4>
                                                {Object.keys(newDataset.buildingUsesAssign).length === 0 && (
                                                    <span className={classes.Warning}>No matches were found</span>
                                                )}
                                                {Object.keys(newDataset.buildingUsesAssign).length > 0 && (
                                                    <span className={classes.Success}>
                                                        Found {Object.keys(newDataset.buildingUsesAssign).length} matches out of {buildingUses.length}
                                                    </span>
                                                )}
                                            </div>
                                            <div
                                                className={step2Valid ? classes.EditPropertiesBtn : [classes.EditPropertiesBtn, classes.Disabled].join(' ')}
                                                onClick={() => (step2Valid ? openBuildingUsesAssign() : null)}
                                            >
                                                <Badge
                                                    color='warning'
                                                    icon={
                                                        <svg
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                        >
                                                            <path d='M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z'></path>
                                                        </svg>
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Widget>
                </div>
            </div>

            <Modal
                title='Select fields for height, building type and construction year'
                subtitle='All fields is necessary to be present in your file'
                size='small'
                show={showEditPropertiesModal}
                onClose={() => setShowEditPropertiesModal(false)}
                buttons={
                    <MainButton
                        label='Set'
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <polyline points='20 6 9 17 4 12'></polyline>
                            </svg>
                        }
                        color='warning'
                        onClick={saveFieldsAssign}
                    />
                }
            >
                <form>
                    <div className={classes.FormRow}>
                        <label>Select height *</label>
                        {uploadedFileDetails && (uploadedFileDetails.ext === 'gml' || uploadedFileDetails.ext === 'json') ? (
                            <div className={classes.GetHeightsFrom3dWrapper}>
                                <ReactTooltip effect='solid' />

                                {getHeightFrom3d ? (
                                    <span>Get heights from 3D objects</span>
                                ) : (
                                    <MyAutocomplete
                                        options={getOptions('height')}
                                        value={tempFieldsAssign.height}
                                        onChange={(value) => fieldsAssignHandler('height', value)}
                                        disableClearable
                                        fullWidth
                                    />
                                )}

                                <div
                                    className={getHeightFrom3d ? [classes.GetHeightsFrom3d, classes.Object3d].join(' ') : classes.Object3d}
                                    onClick={getHeightFrom3dHandler}
                                    data-tip='Get height from 3D'
                                >
                                    <Badge
                                        color='warning'
                                        icon={
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            >
                                                <path d='M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z'></path>
                                                <polyline points='3.27 6.96 12 12.01 20.73 6.96'></polyline>
                                                <line x1='12' y1='22.08' x2='12' y2='12'></line>
                                            </svg>
                                        }
                                    ></Badge>
                                </div>
                            </div>
                        ) : (
                            <MyAutocomplete
                                options={getOptions('height')}
                                value={tempFieldsAssign.height}
                                onChange={(value) => fieldsAssignHandler('height', value)}
                                disableClearable
                                fullWidth
                            />
                        )}
                    </div>
                    <div className={classes.FormRow}>
                        <label>Select building type *</label>
                        <MyAutocomplete
                            options={getOptions('buildingType')}
                            value={tempFieldsAssign.buildingType}
                            onChange={(value) => fieldsAssignHandler('buildingType', value)}
                            disableClearable
                            fullWidth
                        />
                    </div>
                    <div className={classes.FormRow}>
                        <label>Select construction year *</label>
                        <MyAutocomplete
                            options={getOptions('constructionYear')}
                            value={tempFieldsAssign.constructionYear}
                            onChange={(value) => fieldsAssignHandler('constructionYear', value)}
                            disableClearable
                            fullWidth
                        />
                    </div>
                </form>
            </Modal>

            <Modal
                title='Match the building uses in your file with the building uses provided'
                subtitle='You can edit the properties of individual buildings after the import'
                size='small'
                show={showUsesModal}
                onClose={() => setShowUsesModal(false)}
                buttons={
                    <MainButton
                        label='Set'
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <polyline points='20 6 9 17 4 12'></polyline>
                            </svg>
                        }
                        color='warning'
                        onClick={saveBuildingUsesAssign}
                    />
                }
            >
                <div className={classes.FieldSelections}>
                    <ReactTooltip effect='solid' />

                    {buildingUses.map((buildingUseName, index) => {
                        return (
                            <div key={index} className={classes.Row}>
                                <span>{buildingUseName}</span>

                                <BuildingUsesSelection
                                    options={buildingUsesForCountry}
                                    // buildingUseName={buildingUseName}
                                    firstFloor={tempBuildingUsesAssign[buildingUseName] ? tempBuildingUsesAssign[buildingUseName] : null}
                                    restFloors={restFloorsUsesAssign[buildingUseName] ? restFloorsUsesAssign[buildingUseName] : null}
                                    onChange={(value, floor) => assignBuildingUse(buildingUseName, value, floor)}
                                />
                            </div>
                        );
                    })}
                </div>
            </Modal>
        </div>
    );
};

export default MyDataNew;
