import React, { useMemo } from 'react';

import classes from './Modal.module.css';
import MainButton from '../MainButton/MainButton';

const Modal = (props) => {
    let elClasses = [classes.ModalWrapper, classes.ModalConfirm];

    if (props.show) elClasses.push(classes.Show);
    if (props.type === 'delete') elClasses.push(classes.ModalDelete);
    else if (props.type === 'save') elClasses.push(classes.ModalSave);
    else if (props.type === 'clear') elClasses.push(classes.ModalClear);

    const actionIcon = useMemo(() => {
        if (props.type === 'delete')
            return (
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                >
                    <polyline points='3 6 5 6 21 6'></polyline>
                    <path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'></path>
                    <line x1='10' y1='11' x2='10' y2='17'></line>
                    <line x1='14' y1='11' x2='14' y2='17'></line>
                </svg>
            );
        else if (props.type === 'save')
            return (
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                >
                    <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                    <polyline points='17 21 17 13 7 13 7 21'></polyline>
                    <polyline points='7 3 7 8 15 8'></polyline>
                </svg>
            );
        else return null;
    }, [props.type]);

    const actionLabel = useMemo(() => {
        if (props.type === 'delete') return 'Delete';
        else if (props.type === 'save') return 'Save';
        else if (props.type === 'clear') return 'Clear';
        else return 'Save';
    }, [props.type]);

    return (
        <div className={elClasses.join(' ')}>
            <div className={classes.Backdrop}></div>
            <div className={classes.Modal}>
                <div className={classes.ModalInner}>
                    <div className={classes.ModalHeader}>
                        <div className={classes.ModalIcon}>
                            <span>!</span>
                        </div>

                        <h3>{props.title}</h3>
                    </div>

                    <div className={classes.ModalContent}>{props.text}</div>

                    <div className={classes.ModalFooter}>
                        <MainButton label='Cancel' color={props.type === 'delete' ? 'danger' : 'action'} outline onClick={props.onClose} />
                        <MainButton icon={actionIcon} label={actionLabel} color={props.type === 'delete' ? 'danger' : 'action'} onClick={props.onAction} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
