import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    tileLayer: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    tileLayerAttribution: '',
};

export const systemSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
});

export const systemActions = systemSlice.actions;

export default systemSlice;
