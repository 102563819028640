import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import { getAccessToken } from '../../utils/auth';
import LoginPart from './LoginPart';
import RegisterPart from './RegisterPart';
import ForgotPart from './ForgotPart';
import { layoutActions } from '../../features/Layout/layoutSlice';
import classes from './Login.module.css';
import CloseSnackbarAction from '../../features/CloseSnackbarAction/CloseSnackbarAction';

const Login = () => {
    const dispatch = useDispatch();
    const showing = useSelector((state) => state.login.showing);
    const snackbarRef = useRef();

    useEffect(() => {
        dispatch(layoutActions.setPageTitle('Login'));
    }, [dispatch]);

    return (
        <SnackbarProvider
            ref={snackbarRef}
            autoHideDuration={3000}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            action={(id) => <CloseSnackbarAction id={id} />}
        >
            <div className={classes.LoginWrapper}>
                {showing === 'login' && <LoginPart />}
                {showing === 'register' && <RegisterPart />}
                {showing === 'forgot' && <ForgotPart />}
            </div>
        </SnackbarProvider>
    );
};

export default Login;

export function loader() {
    const token = getAccessToken();

    if (token) {
        return redirect('/');
    } else {
        return null;
    }
}
