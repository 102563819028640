import classes from './HighlightedText.module.css';

const HighlightedText = (props) => {
    let elClasses = [classes.HighlightedText];

    if (props.type === 'warning') elClasses.push(classes.Warning);
    else if (props.type === 'info') elClasses.push(classes.Info);

    return (
        <div className={elClasses.join(' ')}>
            {props.type === 'warning' && (
                <>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <circle cx='12' cy='12' r='10'></circle>
                        <line x1='12' y1='8' x2='12' y2='12'></line>
                        <line x1='12' y1='16' x2='12' y2='16'></line>
                    </svg>

                    <span>
                        <strong>Warning!</strong>
                        {props.text}
                    </span>
                </>
            )}

            {props.type === 'info' && (
                <>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    >
                        <circle cx='12' cy='12' r='10'></circle>
                        <line x1='12' y1='16' x2='12' y2='12'></line>
                        <line x1='12' y1='8' x2='12.01' y2='8'></line>
                    </svg>
                    <span>
                        <strong>Info!</strong>
                        {props.text}
                    </span>
                </>
            )}
        </div>
    );
};

export default HighlightedText;
