import React, { useState, useRef, useEffect, useCallback } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import { useResizeDetector } from 'react-resize-detector';

import classes from './Charts.module.css';

highchartsAccessibility(Highcharts);

const BarChart = (props) => {
    const chartRef = useRef(null);

    const [chartOptions, setChartOptions] = useState({
        chart: {
            backgroundColor: 'rgba(255,255,255,0)', // Disable background color
            type: 'bar',
            style: {
                fontFamily: 'Nunito, sans-serif',
            },
        },
        title: {
            text: props.title,
        },
        subtitle: {
            text: props.subtitle ? props.subtitle : '',
        },
        xAxis: {
            categories: props.categories,
        },
        yAxis: [
            {
                title: {
                    text: props.yAxisTitle || 'Values1',
                },
            },
        ],
        legend: {
            enabled: props.hideLegend ? false : true,
            align: props.alignLegend ? props.alignLegend : 'left',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            itemStyle: { color: '#515365', fontSize: '1.4rem', fontWeight: 'regular', fontFamily: 'Nunito, sans-serif' },
            itemMarginBottom: 5,
        },
        plotOptions: {
            series: {
                stacking: props.isStacked ? 'normal' : undefined,
                borderWidth: 0,
            },
        },
        series: props.series,
    });

    useEffect(() => {
        setChartOptions({
            series: props.series,
        });
    }, [props.series]);

    const onResize = useCallback(() => {
        const chart = chartRef.current.chart;
        const wrapperWidth = ref?.current?.offsetWidth;
        chart.setSize(wrapperWidth, void 0, false);
    }, []);

    const { width, height, ref } = useResizeDetector({
        handleHeight: false,
        refreshMode: 'debounce',
        refreshRate: 150,
        onResize,
    });

    return (
        <div className={classes.MainChart} ref={ref}>
            <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} />
        </div>
    );
};

export default BarChart;
