import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MainInput from '../../features/UI/MainInput/MainInput';
import MainButton from '../../features/UI/MainButton/MainButton';
import { loginActions } from './loginSlice';
import { sendResetPasswordEmail } from './loginAsyncActions';
import classes from './Login.module.css';
import { showMessage } from '../../utils/messageHandler';

const ForgotPart = () => {
    const dispatch = useDispatch();

    const [forgotEmail, setForgotEmail] = useState('');
    const loginLoading = useSelector((state) => state.login.loginLoading);

    const forgotEmailHandler = (e) => {
        e.preventDefault();

        if (forgotEmail.trim() === '' || forgotEmail.includes('@') === false) {
            showMessage('error', 'Please fill in a valid email');
        } else {
            dispatch(sendResetPasswordEmail(forgotEmail));
        }
    };

    return (
        <div className={classes.Login}>
            <h2>Recover your password</h2>
            <span>Enter your email to send you a link for changing your password</span>

            <form>
                <MainInput type='email' label='Email *' fullWidth onChange={(e) => setForgotEmail(e.target.value)} value={forgotEmail} />

                <div className={classes.ButtonWrapper}>
                    <MainButton
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <line x1='22' y1='2' x2='11' y2='13'></line>
                                <polygon points='22 2 15 22 11 13 2 9 22 2'></polygon>
                            </svg>
                        }
                        label='SEND RESET LINK'
                        color='action'
                        fullWidth
                        onClick={forgotEmailHandler}
                        loading={loginLoading}
                    />
                </div>

                <div className={classes.NoAccount}>
                    <span>Or go to back to </span>
                    <span className={classes.CreateAccount} onClick={() => dispatch(loginActions.setShowing('login'))}>
                        Sign In
                    </span>
                </div>
            </form>
        </div>
    );
};

export default ForgotPart;
