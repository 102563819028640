import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tableIsLoading: false,
    tableIsRefetching: false,
    rowCount: 0,
};

export const myTableServerSlice = createSlice({
    name: 'myTableServer',
    initialState,
    reducers: {
        resetEditData: (state) => {
            state.tableIsLoading = false;
        },
        setTableIsLoading: (state, action) => {
            state.tableIsLoading = action.payload;
        },
        setTableIsRefetching: (state, action) => {
            state.tableIsRefetching = action.payload;
        },
        setRowCount: (state, action) => {
            state.rowCount = action.payload;
        },
    },
});

export const myTableServerActions = myTableServerSlice.actions;

export default myTableServerSlice;
