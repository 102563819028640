import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pageTitle: '',
    menuIsClosed: false,
    showingMap: false,
    showingLogin: false,
    theme: 'light',
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setPageTitle: (state, action) => {
            state.pageTitle = action.payload;
        },
        toggleMenuClosed: (state) => {
            state.menuIsClosed = !state.menuIsClosed;
        },
        setMenuClosed: (state) => {
            state.menuIsClosed = true;
        },
        setShowingMap: (state, action) => {
            state.showingMap = action.payload;
        },
        setShowingLogin: (state, action) => {
            state.showingLogin = action.payload;
        },
        setTheme: (state, action) => {
            state.theme = action.payload;
            document.body.setAttribute('data-theme', action.payload);
            window.localStorage.setItem('theme', action.payload);
        },
    },
});

export const layoutActions = layoutSlice.actions;

export default layoutSlice;
