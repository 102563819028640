import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import { searchActions } from './searchSlice';
import { getSearchData } from './searchAsyncActions';
import classes from './Search.module.css';

const Search = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const inputRef = useRef(null);

    const searchData = useSelector((state) => state.search.searchData);

    const [results, setResults] = useState([]);
    const [showSearch, setShowSearch] = useState(false);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        dispatch(getSearchData());

        return () => {
            dispatch(searchActions.setSearchData(null));
        };
    }, []);

    // Handle Ctrl + s clicked to activate search
    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.which === 191) {
                setShowSearch(true);
                inputRef.current.focus();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    // Click outside
    const useOutsideClick = (callback) => {
        const ref = useRef();

        useEffect(() => {
            const handleClick = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    callback();
                }
            };

            document.addEventListener('click', handleClick);

            return () => {
                document.removeEventListener('click', handleClick);
            };
        }, [ref]);

        return ref;
    };

    const updateSearchResults = (searchStr) => {
        setInputValue(searchStr);

        if (searchStr === '' || searchStr === undefined || searchStr === null) {
            setResults([]);
        }

        const results = searchData.filter((d) => d.label.toLowerCase().includes(searchStr.toLowerCase()));
        setResults(results);
    };

    const ref = useOutsideClick(() => setShowSearch(false));

    let elClasses = [classes.Search];
    if (showSearch) elClasses.push(classes.Show);
    const isMobile = useMediaQuery('(max-width:700px)');
    if (isMobile) elClasses.push(classes.IsMobile);

    return (
        <div ref={ref} className={elClasses.join(' ')} onClick={() => setShowSearch(true)}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                className={classes.SearchIcon}
            >
                <circle cx='11' cy='11' r='8'></circle>
                <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
            </svg>

            <div className={classes.SearchBar}>
                <input
                    ref={inputRef}
                    value={inputValue}
                    placeholder='Search...'
                    type='text'
                    name='search'
                    onChange={(e) => updateSearchResults(e.target.value)}
                />

                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowSearch(false);
                        setInputValue('');
                    }}
                    className={classes.CloseSearch}
                >
                    <line x1='18' y1='6' x2='6' y2='18'></line>
                    <line x1='6' y1='6' x2='18' y2='18'></line>
                </svg>

                <div className={classes.SearchResults}>
                    {results
                        ? results.map((result, index) => {
                              return (
                                  <div
                                      className={classes.SearchResult}
                                      key={`Result${index}`}
                                      onClick={(e) => {
                                          e.stopPropagation();
                                          setShowSearch(false);
                                          navigate(result.link);
                                          setInputValue('');
                                          setResults([]);
                                      }}
                                  >
                                      {result.label}
                                  </div>
                              );
                          })
                        : null}
                </div>
            </div>
            <span className={classes.Badge}>Ctrl + /</span>
        </div>
    );
};

export default Search;
