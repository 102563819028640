import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getAccessToken } from '../../utils/auth';
import { showMessage } from '../../utils/messageHandler';

const PrivateRoute = ({ redirectPath = '/login', roleId, children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state) => state.login.user);
    const token = getAccessToken();
    const [isAllowed, setIsAllowed] = useState(false);

    useEffect(() => {
        let allowed = false;

        if (roleId && user && user.user_role_id <= roleId) allowed = true;
        else if (user && !roleId) allowed = true;

        if (!allowed) {
            navigate(redirectPath);
            showMessage('info', 'Not allowed');
            return;
        }

        setIsAllowed(allowed);
    }, [user, token, location]);

    return isAllowed ? children : null;
};

export default PrivateRoute;
