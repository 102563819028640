import { useNavigate } from 'react-router-dom';

import error_icon from '../../../assets/images/error.svg';
import MainButton from '../../../features/UI/MainButton/MainButton';
import classes from './Page500.module.css';

const Page404 = () => {
    const navigate = useNavigate();

    return (
        <div className={classes.Page500}>
            <div className={classes.Page500Inner}>
                <p className={classes.Title}>Error!</p>
                <p className={classes.Subtitle}>Something went wrong!</p>
                <img src={error_icon} alt='error icon' />
                <div className={classes.ButtonWrapper}>
                    <MainButton label='Go Back' color='action' onClick={() => navigate('/')} />
                </div>
            </div>
        </div>
    );
};

export default Page404;
