import { useEffect, useState } from 'react';

import ColumnChart from '../../../features/Charts/ColumnChart';
import LineChart from '../../../features/Charts/LineChart';
import BarChart from '../../../features/Charts/BarChart';
import MainSelect from '../../../features/UI/MainSelect/MainSelectControlled';
import classes from './AllCharts.module.css';

const ScenarioChartsEnergy = (props) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [tabValues, setTabValues] = useState(null);
    const [usages, setUsages] = useState([]);

    useEffect(() => {
        if (!props.buildingValues) return;
        const usageValues = props.buildingValues.per_usage;
        const usageArr = [];

        for (let i = 0; i < usageValues.length; i++) {
            usageArr.push(usageValues[i].name);
        }

        setUsages(usageArr);
    }, [props.buildingValues]);

    useEffect(() => {
        if (!props.buildingValues) return;

        const usageValues = props.buildingValues.per_usage[currentTab];

        const currentTabValues = {
            name: usageValues.name,
            data: [
                {
                    name: 'Gains',
                    data: [
                        usageValues.system_sensible_hvac_zone_eq_and_other_sensible_air_heating,
                        usageValues.heat_gain_opaque_surface_conduction_and_other_heat_addition,
                        usageValues.heat_gain_infiltration_heat_addition,
                        usageValues.heat_gain_window_heat_addition,
                        usageValues.heat_gain_equipment_sensible_heat_addition,
                        usageValues.heat_gain_lights_sensible_heat_addition,
                        usageValues.heat_gain_people_sensible_heat_addition,
                    ],
                },
                {
                    name: 'Losses',
                    data: [
                        usageValues.system_sensible_hvac_zone_eq_and_other_sensible_air_cooling,
                        usageValues.heat_loss_opaque_surface_conduction_and_other_heat_removal,
                        usageValues.heat_loss_infiltration_heat_removal,
                        usageValues.heat_loss_window_heat_removal,
                        0,
                        0,
                        0,
                    ],
                },
            ],
        };

        setTabValues(currentTabValues);
    }, [currentTab, props.buildingValues]);

    const updateTabFromName = (name) => {
        const foundIndex = props.buildingValues.per_usage.findIndex((u) => u.name === name);

        setCurrentTab(foundIndex);
    };

    return (
        props.buildingValues && (
            <div className={classes.Charts}>
                <div className={classes.Chart}>
                    <h3>Energy</h3>
                    <ColumnChart
                        title={null}
                        alignLegend='center'
                        categories={['Heating', 'Cooling', 'Interior lighting', 'Interior equipment']}
                        yAxisTitle='kWh per sq. m.'
                        series={[
                            {
                                name: 'Energy demand',
                                data: [
                                    props.buildingValues.end_uses_demanded_heating,
                                    props.buildingValues.end_uses_demanded_cooling,
                                    props.buildingValues.end_uses_demanded_interior_lighting,
                                    props.buildingValues.end_uses_demanded_interior_equipment,
                                ],
                            },
                            {
                                name: 'Final energy',
                                data: [
                                    props.buildingValues.end_uses_consumed_heating,
                                    props.buildingValues.end_uses_consumed_cooling,
                                    props.buildingValues.end_uses_consumed_interior_lighting,
                                    props.buildingValues.end_uses_consumed_interior_equipment,
                                ],
                            },
                            {
                                name: 'Primary energy',
                                data: [
                                    props.buildingValues.end_uses_primary_heating,
                                    props.buildingValues.end_uses_primary_cooling,
                                    props.buildingValues.end_uses_primary_interior_lighting,
                                    props.buildingValues.end_uses_primary_interior_equipment,
                                ],
                            },
                        ]}
                    />
                </div>

                <div className={classes.Chart}>
                    <h3>Energy demand breakdown</h3>

                    <ColumnChart
                        title={null}
                        alignLegend='center'
                        categories={['Heating', 'Cooling', 'Interior lighting', 'Other']}
                        yAxisTitle='kWh per sq. m.'
                        series={[
                            {
                                name: 'Energy demand',
                                data: [
                                    props.buildingValues.utility_use_per_conditioned_floor_area_hvac_heating,
                                    props.buildingValues.utility_use_per_conditioned_floor_area_hvac_cooling,
                                    props.buildingValues.utility_use_per_conditioned_floor_area_lighting,
                                    props.buildingValues.utility_use_per_conditioned_floor_area_other,
                                ],
                            },
                        ]}
                    />
                </div>

                <div className={[classes.Chart, classes.TwoColumns].join(' ')}>
                    <h3>Monthly energy demand breakdown</h3>

                    <LineChart
                        title={null}
                        alignLegend='center'
                        categories={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                        series={props.buildingValues.demanded_monthly_data}
                        yAxisTitle='kWh'
                    />
                </div>

                <div className={[classes.Chart, classes.TwoColumns].join(' ')}>
                    <h3>Monthly final energy consumption</h3>

                    <LineChart
                        title={null}
                        alignLegend='center'
                        categories={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                        series={props.buildingValues.consumed_monthly_data}
                        yAxisTitle='kWh'
                    />
                </div>

                <div className={[classes.Chart, classes.TwoColumns].join(' ')}>
                    <h3>Monthly primary energy</h3>

                    <LineChart
                        title={null}
                        alignLegend='center'
                        categories={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
                        series={props.buildingValues.primary_monthly_data}
                        yAxisTitle='kWh'
                    />
                </div>

                {tabValues && (
                    <div className={classes.ChartWithTabs}>
                        <ul className={classes.ChartTabs}>
                            {props.buildingValues.per_usage.map((usage, index) => {
                                return (
                                    <li className={currentTab == index ? classes.ActiveTab : null} key={index} onClick={() => setCurrentTab(index)}>
                                        {usage.name}
                                    </li>
                                );
                            })}
                        </ul>

                        <div className={[classes.Chart, classes.TwoColumns].join(' ')}>
                            <h3 className={classes.SimpleTitle}>Building Energy Balance per usage - {tabValues.name}</h3>

                            <div className={classes.TitleWithSelect}>
                                <h3>Building Energy Balance per usage - </h3>
                                <MainSelect
                                    options={usages}
                                    value={props.buildingValues.per_usage[currentTab].name}
                                    onChange={(e) => updateTabFromName(e.target.value)}
                                    narrow
                                />
                            </div>

                            <BarChart
                                title={null}
                                alignLegend='center'
                                isStacked
                                categories={['System HVAC zone', 'Surface conduction', 'Infiltration', 'Windows', 'Equipment', 'Lights', 'People']}
                                series={tabValues.data}
                                yAxisTitle='kWh'
                            />
                        </div>
                    </div>
                )}
            </div>
        )
    );
};

export default ScenarioChartsEnergy;
