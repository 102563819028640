import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Viewer,
    Scene,
    Globe,
    Cesium3DTileset,
    Clock,
    ScreenSpaceEventHandler,
    ScreenSpaceEvent,
    ImageryLayer,
    AmbientOcclusion,
    // KmlDataSource,
} from 'resium';
import {
    Cartesian2,
    ScreenSpaceEventType,
    defined,
    Color,
    Cesium3DTileStyle,
    Ion,
    BingMapsImageryProvider,
    BingMapsStyle,
    // createWorldTerrain,
    JulianDate,
} from 'cesium';

import MainButton from '../../../features/UI/MainButton/MainButton';
import Modal from '../../../features/UI/Modal/Modal';
import MapFilters from './MapFilters';
import BuildingPopup from './BuildingPopup';
import classes from './ProjectMap.module.css';
import { getScenarioMetrics } from '../MyProjectsAsyncActions';
import { myProjectsActions } from '../myProjectsSlice';
import AllCharts from './AllCharts';

const CONFIG = require('../../../reactConfig.json');

Ion.defaultAccessToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwZTEwYzU4Zi0zYjZlLTQ2YTItODIzYS04ZmI1MmM2MDU5ZWIiLCJpZCI6MzkxMDEsImlhdCI6MTYwNzE2MzY0NX0.jcCjSwZLLqMgXHb5Ses_1WGuDM__F8B89lMhh0i7X30';

const ProjectMap = (props) => {
    const dispatch = useDispatch();
    const viewerRef = React.createRef();

    const [selectedFeature, setSelectedFeature] = useState({
        feature: null,
        originalColor: '',
        position: null,
    });
    const [legend, setLegend] = useState('');
    const [extentTileset, setExtentTileset] = useState(null);
    const [zoomedToExtent, setZoomedToExtent] = useState(false);
    const [filterBadges, setFilterBadges] = useState([]);

    const isFullScreen = useSelector((state) => state.myProjects.isFullScreen);
    const selectedScenario = useSelector((state) => state.myProjects.selectedScenario);
    const colorBasedOn = useSelector((state) => state.myProjects.colorBasedOn);
    const scenariosMetrics = useSelector((state) => state.myProjects.scenariosMetrics);
    const viewLegend = useSelector((state) => state.myProjects.viewLegend);
    const filterData = useSelector((state) => state.myProjects.filterData);
    const viewAllCharts = useSelector((state) => state.myProjects.viewAllCharts);
    const theme = useSelector((state) => state.layout.theme);

    const propertyLabels = useMemo(() => {
        return {
            height: 'Height',
            year: 'Construction year',
            area: 'Building area',
            use: 'Building use',
        };
    }, []);

    useEffect(() => {
        return () => {
            dispatch(myProjectsActions.setScenarioMetric({}));
            if (window.ABORT_CONTROLLER) window.ABORT_CONTROLLER.abort();
        };
    }, []);

    useEffect(() => {
        if (!filterData.length) {
            dispatch(myProjectsActions.setScenarioFilteredMetrics(null));
        }

        let newFilterBadges = [];
        for (let i = 0; i < filterData.length; i++) {
            const filterItem = filterData[i];
            const newFilterBadge = (
                <div key={i} className={classes.MapFiltersBadge}>
                    <span>
                        {propertyLabels[filterItem.property]} {filterItem.comparison} {filterItem.value}
                    </span>
                    <div className={classes.RemoveMapFilterBadge} onClick={() => dispatch(myProjectsActions.removeFilterByIndex(i))}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        >
                            <line x1='18' y1='6' x2='6' y2='18'></line>
                            <line x1='6' y1='6' x2='18' y2='18'></line>
                        </svg>
                    </div>
                </div>
            );

            newFilterBadges.push(newFilterBadge);
        }
        setFilterBadges(newFilterBadges);
    }, [filterData]);

    useEffect(() => {
        setSelectedFeature({
            feature: null,
            originalColor: '',
            position: null,
        });

        //if (scenariosMetrics[selectedScenario.id]) return; // Don't get scenario metrics, if already have them. DOES NOT RELOAD THE VIEWER

        dispatch(getScenarioMetrics(selectedScenario.id, props.projectId));
    }, [selectedScenario.id, props.projectId]);

    const getBuildingSatisfiesFilters = (buildingData) => {
        let buildingValue;
        let buildingValues = [];
        let satisfiesFilter;
        let satisfiesFilters = {};

        // Check if filter item and populate satisfiesFilters
        for (let i = 0; i < filterData.length; i++) {
            const filterItem = filterData[i];

            // Get 3d tiles value
            if (filterItem.property === 'height') {
                buildingValue = buildingData?.building_metrics?.building_metric?.height;
            } else if (filterItem.property === 'year') {
                buildingValue = buildingData?.building_metrics?.building_metric?.construction_year;
            } else if (filterItem.property === 'area') {
                buildingValue = buildingData?.building_metrics?.building_metric?.building_area_net_conditioned_building_area;
            } else if (filterItem.property === 'use') {
                for (let m = 0; m < buildingData?.building_usage_metrics.length; m++) {
                    buildingValues.push(buildingData.building_usage_metrics[m].building_usage_metric.usage_name);
                }
            }

            // Get filter applies
            if (filterItem.property === 'use') {
                satisfiesFilter = buildingValues.includes(filterItem.value) ? true : false;
            } else if (filterItem.comparison === '=') {
                satisfiesFilter = buildingValue == filterItem.value ? true : false;
            } else if (filterItem.comparison === '>') {
                satisfiesFilter = buildingValue > filterItem.value ? true : false;
            } else if (filterItem.comparison === '>=') {
                satisfiesFilter = buildingValue >= filterItem.value ? true : false;
            } else if (filterItem.comparison === '<') {
                satisfiesFilter = buildingValue < filterItem.value ? true : false;
            } else if (filterItem.comparison === '<=') {
                satisfiesFilter = buildingValue <= filterItem.value ? true : false;
            }

            if (!satisfiesFilters[filterItem.property]) satisfiesFilters[filterItem.property] = [];
            satisfiesFilters[filterItem.property].push(satisfiesFilter);
        }

        // If there is no true in any of the different filter properties, then do not show the building
        let result = true;
        for (const [key, valuesArr] of Object.entries(satisfiesFilters)) {
            const satisfiesNum = valuesArr.filter((v) => v === true).length;
            if (satisfiesNum === 0) result = false;
        }

        return result;
    };

    const buildingStyle = useMemo(() => {
        return new Cesium3DTileStyle({
            // show: "${feature['height']} > 30",
            show: {
                evaluate: function (feature) {
                    // No filter, return all
                    if (filterData.length === 0) return true;

                    const buildingData = JSON.parse(feature.getProperty('building_data'));
                    const result = getBuildingSatisfiesFilters(buildingData);

                    return result;
                },
            },
            // translucencyByDistance: 'vec4(1.5e2, 1.0, 1.5e7, 0.2)',
            color: {
                evaluateColor: function (feature) {
                    if (Object.keys(scenariosMetrics).length === 0) return Color.WHITESMOKE.withAlpha(1.0);

                    const buildingData = JSON.parse(feature.getProperty('building_data'));
                    let stops = [];
                    let stop;
                    let stopsNum;
                    let min;
                    let max;
                    let diff;
                    let dataValue;
                    let colorIndex;
                    let colors = [];
                    let color;
                    let units;

                    let noDataColor = '#dddddd';

                    if (colorBasedOn === 'Height') {
                        min = scenariosMetrics[selectedScenario.id].min_height;
                        dataValue = buildingData.building_metrics.building_metric.height;
                        stops = [min, 6, 12, 18, 24, 36];
                        colors = ['#72ae9f', '#648397', '#8da7b8', '#918cc2', '#946f9d', '#be75a5'];
                        units = <span>(m)</span>;
                    } else if (colorBasedOn === 'Construction year') {
                        min = scenariosMetrics[selectedScenario.id].min_construction_year;
                        dataValue = buildingData.building_metrics.building_metric.construction_year;
                        stops = [min, 1920, 1950, 1970, 1980, 1990, 2000, 2010];
                        colors = ['#648397', '#72ae9f', '#51bc8a', '#9fc888', '#c8c77f', '#b3b0a4', '#bea6a0', '#b16356'];
                        units = '';
                    } else if (colorBasedOn === 'Building area') {
                        min = scenariosMetrics[selectedScenario.id].min_area;
                        dataValue = buildingData.building_metrics.building_metric.building_area_net_conditioned_building_area;
                        stops = [min, 500, 2000, 5000, 10000, 20000];
                        colors = ['#e78474', '#e5a089', '#be75a5', '#946f9d', '#8da7b8', '#648397'];
                        units = (
                            <span>
                                (m<sup>2</sup>)
                            </span>
                        );
                    } else if (colorBasedOn === 'Building use') {
                        dataValue = buildingData.building_usage_metrics[0].building_usage_metric.usage_name;
                        if (buildingData.building_usage_metrics.length > 1) dataValue = 'Mixed'; // If more than 1 use, then it is mixed

                        stops = [
                            { label: 'Accomodation', categories: ['Accomodation', 'Other accomodation'], color: '#b3b0a4' },
                            { label: 'Offices', categories: ['Offices', 'Office (small sized)', 'Offices (medium sized)'], color: '#8b8483' },
                            { label: 'Residential', categories: ['Residential'], color: '#bea6a0' },
                            { label: 'Retail', categories: ['Retail'], color: '#ffe5da' },
                            { label: 'Education', categories: ['Education'], color: '#e5a089' },
                            { label: 'Meeting spaces', categories: ['Meeting spaces'], color: '#e7b574' },
                            { label: 'Healthcare', categories: ['Healthcare', 'Other healthcare uses'], color: '#e7e674' },
                            { label: 'Sport', categories: ['Sport'], color: '#9fc888' },
                            { label: 'Industrial', categories: ['Industrial', 'Industry'], color: '#51bc8a' },
                            { label: 'Parking', categories: ['Parking'], color: '#648397' },
                            { label: 'Other', categories: ['Other'], color: '#72ae9f' },
                            { label: 'Mixed', categories: ['Mixed'], color: '#8da7b8' },
                        ];
                        colors = [
                            '#b3b0a4',
                            '#8b8483',
                            '#bea6a0',
                            '#ffe5da',
                            '#e5a089',
                            '#e7b574',
                            '#e7e674',
                            '#9fc888',
                            '#51bc8a',
                            '#648397',
                            '#72ae9f',
                            '#8da7b8',
                        ];

                        units = '';
                    } else if (colorBasedOn === 'Primary energy') {
                        min = scenariosMetrics[selectedScenario.id].min_primary_energy;
                        max = scenariosMetrics[selectedScenario.id].max_primary_energy;
                        dataValue = buildingData.building_metrics.building_metric.end_uses_primary_per_m2;
                        stopsNum = 10;
                        diff = (max - min) / (stopsNum - 1);

                        stops = [min];
                        for (let i = 1; i < stopsNum; i++) {
                            stop = Math.round(min + diff * i);
                            stops.push(stop);
                        }

                        colors = ['#648397', '#8da7b8', '#72ae9f', '#08968c', '#51bc8a', '#9fc888', '#e7e674', '#e7b574', '#e5a089', '#b16356'];
                        units = (
                            <span>
                                (kWh/m<sup>2</sup>)
                            </span>
                        );
                    } else if (colorBasedOn === 'Carbon footprint') {
                        min = scenariosMetrics[selectedScenario.id].min_co2_per_m2;
                        max = scenariosMetrics[selectedScenario.id].max_co2_per_m2;
                        dataValue = buildingData.building_metrics.building_metric.end_uses_co2_per_m2;
                        stopsNum = 10;
                        diff = (max - min) / (stopsNum - 1);

                        stops = [min];
                        for (let i = 1; i < stopsNum; i++) {
                            stop = Math.round(min + diff * i);
                            stops.push(stop);
                        }

                        colors = ['#648397', '#8da7b8', '#72ae9f', '#08968c', '#51bc8a', '#9fc888', '#e7e674', '#e7b574', '#e5a089', '#b16356'];
                        units = <span>(kgCO2eq)</span>;
                    }

                    colorIndex = findArrIndex(stops, dataValue, colorBasedOn);
                    if (colorIndex > -1) color = colors[colorIndex];
                    else color = noDataColor;

                    prepareLegend(stops, colors, colorBasedOn, units);

                    return Color.fromCssColorString(color);
                },
            },
        });
    }, [scenariosMetrics, colorBasedOn, filterData]);

    const findArrIndex = (arr, value, colorBasedOn) => {
        if (colorBasedOn === 'Building use') {
            let cIndex = -1;
            for (let i = 0; i < arr.length; i++) {
                const categories = arr[i].categories;
                const found = categories.find((s) => s.toLowerCase() === value.toLowerCase());
                if (found) {
                    cIndex = i;
                    break;
                }
            }
            if (cIndex === -1) cIndex = arr.length - 2; // If not found, then assign the 'Other' color

            return cIndex;
        } else if (value >= arr[arr.length - 1]) return arr.length - 1;
        else {
            for (let i = 1; i < arr.length; i++) {
                if (value < arr[i]) return i - 1;
            }
        }
        return -1;
    };

    const prepareLegend = (stops, colors, colorBasedOn, units) => {
        let legendItems;

        if (colorBasedOn === 'Building use') legendItems = getEqualLegendItems(stops, colors);
        else legendItems = getRangeLegendItems(stops, colors);

        const legend = (
            <div className={classes.Legend}>
                <h4>Legend</h4>
                <span className={classes.LegendTitle}>
                    {colorBasedOn} {units}
                </span>
                <div className={classes.LegendItems}>{legendItems}</div>
            </div>
        );

        setLegend(legend);
    };

    const getRangeLegendItems = (stops, colors) => {
        const legendItems = stops.map((stop, index) => {
            return (
                <div key={`legendItem_${index}`} className={classes.LegendRow}>
                    <div className={classes.LegendColor} style={{ backgroundColor: colors[index] }}></div>

                    {index === 0 && (
                        <span>
                            {'<'} {stops[index + 1]}
                        </span>
                    )}

                    {index > 0 && index < stops.length - 1 && (
                        <span>
                            {stop} - {stops[index + 1]}
                        </span>
                    )}
                    {index === stops.length - 1 && (
                        <span>
                            {'>='} {stop}
                        </span>
                    )}
                </div>
            );
        });

        return legendItems;
    };
    const getEqualLegendItems = (stops, colors) => {
        const legendItems = stops.map((stop, index) => {
            return (
                <div key={`legendItem_${index}`} className={classes.LegendRow}>
                    <div className={classes.LegendColor} style={{ backgroundColor: colors[index] }}></div>
                    <span>{stop.label}</span>
                </div>
            );
        });
        return legendItems;
    };

    const imageryProvider = useMemo(() => {
        return new BingMapsImageryProvider({
            url: 'https://dev.virtualearth.net',
            key: 'AsCCUmkUKRlk_m7oiOJbuUHPL5M342CU6SwB0vMUdBwyJRQofZX-TCrjV0ueEvrT', //'get-yours-at-https://www.bingmapsportal.com/',
            mapStyle: theme === 'light' ? BingMapsStyle.CANVAS_LIGHT : BingMapsStyle.CANVAS_DARK,
        });
    }, [theme]);

    const onMapClick = (evt) => {
        const viewer = viewerRef.current.cesiumElement;

        let selected = { ...selectedFeature };

        // If a feature was previously selected, undo the highlight
        if (selected.feature) {
            selected.feature.color = selected.originalColor;
            selected.feature = null;
        }

        const pickedFeature = viewer.scene.pick(evt.position);

        if (!defined(pickedFeature)) {
            setSelectedFeature({
                feature: null,
                originalColor: '',
                position: null,
            });
            return;
        }

        if (pickedFeature) {
            const originalColor = pickedFeature.color;
            selected.feature = pickedFeature;
            selected.originalColor = originalColor;
            selected.position = evt.position;
            pickedFeature.color = Color.WHITE.withAlpha(1.0);

            setSelectedFeature(selected);
        }
    };

    const clearSelected = () => {
        let selected = { ...selectedFeature };

        // If a feature was previously selected, undo the highlight
        if (selected.feature) {
            selected.feature.color = selected.originalColor;
            selected.feature = null;
        }

        setSelectedFeature({
            feature: null,
            originalColor: '',
            position: null,
        });
    };

    // useEffect(() => {

    //     if (!extentTileset || extentTileset._tilesLoaded === false) return;

    //     if (selectedFeature.feature && viewerRef && viewerRef.current) {
    //         const selPosition = selectedFeature.position;

    //         const viewer = viewerRef.current.cesiumElement;
    //         const pickedFeature = viewer.scene.pick(selPosition);

    //         if (pickedFeature) {
    //             let selected = { ...selectedFeature };

    //             const originalColor = pickedFeature.color;
    //             selected.feature = pickedFeature;
    //             selected.originalColor = originalColor;
    //             selected.position = selPosition;
    //             pickedFeature.color = Color.WHITE.withAlpha(1.0);

    //             setSelectedFeature(selected);
    //         }
    //     }
    // }, []);

    const zoomToDataset = (tileset) => {
        if (!tileset) return;
        viewerRef.current.cesiumElement.zoomTo(tileset);
    };

    useEffect(() => {
        if (!viewerRef) return;
        if (!extentTileset) return;
        if (zoomedToExtent) return;
        viewerRef.current.cesiumElement.zoomTo(extentTileset);
        setZoomedToExtent(true);
    }, [extentTileset]);

    return (
        <div className={isFullScreen ? [classes.ViewerWrapper, classes.FullScreen].join(' ') : classes.ViewerWrapper}>
            <Modal
                title='Charts'
                show={viewAllCharts}
                onClose={() => dispatch(myProjectsActions.setViewAllCharts(false))}
                noPadding
                buttons={
                    <MainButton
                        label='Close'
                        icon={
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            >
                                <line x1='18' y1='6' x2='6' y2='18'></line>
                                <line x1='6' y1='6' x2='18' y2='18'></line>
                            </svg>
                        }
                        color='grey'
                        onClick={() => dispatch(myProjectsActions.setViewAllCharts(false))}
                    />
                }
            >
                <AllCharts selectedFeature={selectedFeature} />
            </Modal>

            <MapFilters onZoomToBounds={() => zoomToDataset(extentTileset)} />

            {selectedScenario && Object.keys(scenariosMetrics).length > 0 ? (
                <div className={classes.Viewer}>
                    <div className={selectedFeature.feature ? [classes.CustomPopup, classes.Show].join(' ') : classes.CustomPopup}>
                        <BuildingPopup selectedFeature={selectedFeature} onClose={clearSelected} />
                    </div>

                    <div className={viewLegend ? [classes.LegendWrapper, classes.Show].join(' ') : classes.LegendWrapper}>{legend}</div>
                    <div className={classes.MapFiltersBadges}>{filterBadges}</div>

                    <Viewer
                        ref={viewerRef}
                        full
                        timeline={false}
                        animation={false}
                        vrButton={false}
                        sceneModePicker={false}
                        homeButton={false}
                        infoBox={false}
                        baseLayerPicker={false}
                        scene3DOnly={true}
                        imageryProvider={false}
                        // terrainProvider={createWorldTerrain({ requestVertexNormals: true, requestWaterMask: true })}
                    >
                        <Clock currentTime={JulianDate.fromIso8601('2021-06-23T14:00')} />

                        <ScreenSpaceEventHandler>
                            <ScreenSpaceEvent action={onMapClick} type={ScreenSpaceEventType.LEFT_CLICK} />
                        </ScreenSpaceEventHandler>

                        <Scene>
                            <Globe depthTestAgainstTerrain={true} />
                            <ImageryLayer imageryProvider={imageryProvider} />
                            <AmbientOcclusion intensity={0} />

                            <Cesium3DTileset
                                url={`${CONFIG.data}/tiles/scenarios/${selectedScenario.tiles_folder}/tileset.json`}
                                style={buildingStyle}
                                // shadows={{ ShadowMode: 'DISABLED' }}
                                // outlineColor={Color.WHITE}
                                imageBasedLightingFactor={new Cartesian2(1.0, 1.0)}
                                // luminanceAtZenith={10}
                                onReady={(tileset) => {
                                    setExtentTileset(tileset);
                                }}
                                // onTileLoad={(tile) => {
                                //     let buildingPoints = [];
                                //     let min = 10000000000;
                                //     let max = 0;
                                //     for (let i = 0; i < tile.content.featuresLength; ++i) {
                                //         const feature = tile.content.getFeature(i);
                                //         const centroidX = feature.getProperty('centroidx');
                                //         const centroidY = feature.getProperty('centroidy');

                                //         const buildingData = JSON.parse(feature.getProperty('building_data'));

                                //         // const value = buildingData.buildingArea.totalBuildingArea;
                                //         // min = value < min ? value : min;
                                //         // max = value > max ? value : max;

                                //         const buildingPoint = { x: centroidX, y: centroidY, value: value };
                                //         buildingPoints.push(buildingPoint);
                                //     }
                                //     // addHeatMap(buildingPoints, min, max);
                                // }}
                            />
                        </Scene>
                    </Viewer>
                </div>
            ) : null}
        </div>
    );
};

export default ProjectMap;
