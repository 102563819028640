import Wavebox2 from '../Wavebox/Wavebox2';
import classes from './SimpleHeader.module.css';

const SimpleHeader = (props) => {
    return (
        <div className={classes.SimpleHeaderWrapper}>
            <Wavebox2>
                <div className={classes.SimpleHeader}>
                    <h1>{props.title}</h1>
                    <span>{props.subtitle}</span>
                </div>
            </Wavebox2>
        </div>
    );
};

export default SimpleHeader;
