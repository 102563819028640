import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dashboardData: null,
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setDashboardData: (state, action) => {
            state.dashboardData = action.payload;
        },
    },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice;
