import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import classes from './CheckoutForm.module.css';
import { checkout } from '../websiteAsyncActions';
import SimpleHeader from '../../features/SimpleHeader/SimpleHeader';
import MainButton from '../../features/UI/MainButton/MainButton';
import { useMemo } from 'react';
import image8 from '../../assets/images/image8.jpg';

const CheckoutForm = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const plans = useSelector((state) => state.website.plans);
    const checkoutLoading = useSelector((state) => state.website.checkoutLoading);

    useEffect(() => {
        if (plans.length === 0) navigate('/pricing');
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();

        dispatch(checkout(props.productId));
    };

    const product = useMemo(() => {
        const found = plans.find((p) => p.id == props.productId);
        if (found) return found;
        return null;
    }, [plans, props.productId]);

    return (
        <div className={classes.CheckoutForm}>
            <SimpleHeader title='Order' />

            {product && (
                <div className={classes.WithMarginsWrapper}>
                    <div className={classes.WithMargins}>
                        <section>
                            <div className={classes.Product}>
                                <div className={classes.ProductImage}>
                                    <img src={image8} alt='Product' />
                                </div>

                                <div className={classes.ProductDescription}>
                                    <span>Plan</span>
                                    <span>Price</span>

                                    <span>{product.name} plan</span>
                                    <span>€ {product.price.toLocaleString('en-US', { minimumFractionDigits: 2 })}</span>
                                </div>
                            </div>

                            <div className={classes.Totals}>
                                <h3>Total</h3>
                                <span>€ {product.price.toLocaleString('en-US', { minimumFractionDigits: 2 })}</span>
                            </div>

                            <form className={classes.SubmitForm} onSubmit={onSubmit} method='POST'>
                                <MainButton label='Checkout' color='accent' loading={checkoutLoading} />
                            </form>
                        </section>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CheckoutForm;
